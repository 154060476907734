"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateFormsCustomFields = exports.updateFormCustomFields = exports.updateCustomFieldGroup = exports.updateCustomField = exports.statusColor = void 0;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// formsHelpers.js
const statusColor = statusCode => {
  switch (statusCode) {
    case 0:
    case 4:
    default:
      return 'warning';
    case 1:
    case 5:
      return 'secondary';
    case 2:
      return 'success';
    case 3:
    case 6:
      return 'alert';
  }
};
exports.statusColor = statusColor;
const updateCustomField = (customField, matchingField, response) => {
  const updatedField = _objectSpread({}, customField);
  if (response.formLanguageOverrideCode && customField.formFieldCode === 'FormLanguageCode') {
    updatedField.value = response.formLanguageOverrideCode;
  } else if (matchingField && matchingField.resetCustomValue) {
    updatedField.value = matchingField.value;
  }
  return updatedField;
};
exports.updateCustomField = updateCustomField;
const updateCustomFieldGroup = (customFieldGroup, matchingGroup, response) => {
  return _objectSpread(_objectSpread({}, customFieldGroup), {}, {
    fields: customFieldGroup.fields.map(customField => {
      const matchingField = matchingGroup.fields.find(field => field.formFieldCode === customField.formFieldCode);
      return updateCustomField(customField, matchingField, response);
    })
  });
};
exports.updateCustomFieldGroup = updateCustomFieldGroup;
const updateFormCustomFields = (form, response) => {
  return _objectSpread(_objectSpread({}, form), {}, {
    customFields: form.customFields.map(customFieldGroup => {
      const matchingGroup = response.groups.find(group => group.formFieldsGroupCode === customFieldGroup.formFieldsGroupCode);
      if (matchingGroup) {
        return updateCustomFieldGroup(customFieldGroup, matchingGroup, response);
      }
      return customFieldGroup;
    })
  });
};
exports.updateFormCustomFields = updateFormCustomFields;
const updateFormsCustomFields = (forms, results) => {
  return forms.map(form => {
    const resObj = results.find(res => res.form.formId === form.formId && res.form.instanceId === form.instanceId);
    const response = resObj === null || resObj === void 0 ? void 0 : resObj.response;
    if (response) {
      return updateFormCustomFields(form, response);
    }
    return form;
  });
};
exports.updateFormsCustomFields = updateFormsCustomFields;