"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkForErrors = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _TradeInVehicleHelpers = require("./TradeInVehicleHelpers");
const _excluded = ["paymentType", "payment", "balance", "expirationDate"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const validateFormField = (name, value) => {
  switch (name) {
    case 'year':
      return value && !Number.isNaN(Number(value)) && value > 1900 && value <= 9999;
    case 'make':
    case 'model':
    case 'exteriorColor':
      return (value === null || value === void 0 ? void 0 : value.length) > 0;
    case 'kilometers':
      return value && !Number.isNaN(Number(value)) && value > 0 && value <= 9999999;
    case 'vin':
      return (0, _commonsUtils.optional)(_commonsUtils.validVin)(value);
    case 'payment':
    case 'balance':
      return value && !Number.isNaN(Number(value));
    case 'expirationDate':
      const match = value && /^(1[0-2]|0[1-9]|\d)\/(2\d{3}|19\d{2})$/.test(value);
      if (match) {
        const splittedDate = value.split('/');
        const month = splittedDate[0];
        const formattedMonth = Number(month);
        const year = splittedDate[1];
        const expiration = new Date(year, formattedMonth - 1, 1);
        const now = new Date();
        const currentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const isValid = currentMonth <= expiration;
        return isValid;
      }
      return false;
    default:
      return true;
  }
};
const checkForErrors = tradeIn => {
  const {
      paymentType,
      payment,
      balance,
      expirationDate
    } = tradeIn,
    otherValues = _objectWithoutProperties(tradeIn, _excluded);
  let validateValues = _objectSpread({}, otherValues);
  if (paymentType) {
    if (!(0, _TradeInVehicleHelpers.isPaid)(paymentType) && !(0, _TradeInVehicleHelpers.isUnknown)(paymentType)) {
      validateValues = _objectSpread({}, validateValues);
    }
    if ((0, _TradeInVehicleHelpers.isLoan)(paymentType)) {
      validateValues = _objectSpread({}, validateValues);
    }
  }
  return Object.entries(validateValues).reduce((acc, formField) => {
    const [fieldName, fieldValue] = formField;
    const isValid = validateFormField(fieldName, fieldValue);
    if (!isValid) {
      acc[fieldName] = true;
    }
    return acc;
  }, {});
};
exports.checkForErrors = checkForErrors;