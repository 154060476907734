"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTradeIn = exports.getSingleLeaseMethodOptions = exports.getSalesPersonnel = exports.getSalesPerson = exports.getQuote = exports.getPurchaseMethod = exports.getOfferFinancingDates = exports.getNotes = exports.getNewVehicle = exports.getLeaseOrFinanceMethod = exports.getLeaseMethodOptions = exports.getFinanceWithBalloonMethodOptions = exports.getFinanceMethodOptions = exports.getDealDetails = exports.getContact = exports.getCashMethod = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _items = require("../../constants/items");
var _payment = require("../../constants/payment");
var _selectors = require("../selectors");
var _vehicle = require("./vehicle");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  LEASE,
  CASH,
  FINANCE,
  FINANCE_WITH_BALLOON,
  SINGLE_PAYMENT_LEASE
} = _payment.PURCHASE_METHODS;
const {
  ACCESSORY,
  DISCOUNT,
  REBATE,
  CUSTOMER_NOTE,
  OPTION,
  WARRANTY,
  PROTECTION,
  DEPOSIT,
  TAX,
  TO_PAY,
  OTHER_FEE_TAXES_INCLUDED,
  BASE,
  FEE,
  TOTAL,
  TAXES_AND_DUTIES,
  OTHER_FEE,
  CASH_DOWN,
  TRADE_IN,
  TRADE_IN_TAXES_INCLUDED,
  LIEN,
  INSURANCE_TAXES_INCLUDED,
  WARRANTY_TAXES_INCLUDED,
  PROTECTION_TAXES_INCLUDED
} = _items.ITEM;

/**
 * @param {Object} state
 * @returns {Object}
 */
const getQuote = state => {
  const {
    quote
  } = state;
  return (0, _commonsUtils.isPlainObject)(quote) ? quote : {};
};

/** @param {Object} state
 * @returns {Number}
 */
exports.getQuote = getQuote;
const getLien = state => {
  const {
    lien
  } = getQuote(state);
  return lien;
};
const getTradeIn = state => {
  const {
    tradeIn
  } = state;
  const tradeInObject = _objectSpread(_objectSpread({}, tradeIn), {}, {
    tradeInLien: getLien(state)
  });
  return (0, _commonsUtils.isPlainObject)(tradeInObject) ? tradeInObject : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTradeIn = getTradeIn;
const getContact = state => {
  const {
    contact
  } = state;
  if (!(0, _commonsUtils.isPlainObject)(contact)) {
    return {};
  }
  const {
    address,
    address2,
    city,
    province,
    postalCode,
    mobilePhone,
    homePhone,
    workPhone,
    email1,
    email2,
    firstname,
    lastname,
    businessName
  } = contact;
  const fullAddress = [address, address2, city, province, postalCode].filter(Boolean).join(' ');
  const phone = mobilePhone || homePhone || workPhone;
  const email = email1 || email2;
  const fullName = [firstname, lastname].filter(Boolean).join(' ');
  return {
    firstname,
    fullName,
    fullAddress,
    businessName,
    phone,
    email
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getContact = getContact;
const getNewVehicle = state => {
  const {
    vehicle
  } = getQuote(state);
  if (!(0, _commonsUtils.isPlainObject)(vehicle)) {
    return {};
  }
  const vehicleDetailDates = (0, _vehicle.getVehicleDetailDates)(state);
  const {
    dateService
  } = vehicleDetailDates;
  const inServiceDate = dateService;
  const {
    year,
    make,
    model,
    modelTrim,
    engine,
    transmission,
    exteriorColourPhotos,
    interiorTrimPhotos,
    vin,
    odometer,
    condition,
    stockNumber,
    modelTrimCode,
    packageCode,
    driveTrain
  } = vehicle;
  const {
    fabricDescription,
    colourDescription
  } = interiorTrimPhotos;
  const fullTransmission = [transmission.name, transmission.speeds].filter(Boolean).join(' - ');
  const interiorTrimFull = "".concat(fabricDescription, " - ").concat(colourDescription);
  return {
    year,
    make: make.name,
    model: model.name,
    modelTrim: modelTrim.name,
    engine: engine && engine.name,
    transmission: fullTransmission,
    color: exteriorColourPhotos && exteriorColourPhotos.description,
    interior: interiorTrimFull,
    vin,
    stock: stockNumber,
    odometer,
    status: condition,
    inServiceDate,
    modelTrimCode,
    packageCode,
    driveTrainAbbreviation: driveTrain && driveTrain.abbreviation
  };
};
exports.getNewVehicle = getNewVehicle;
const getFirstPayment = transaction => {
  const deposit = transaction.find(item => item.category === DEPOSIT);
  let firstPaymentAmount = 0;
  if (deposit) {
    const firstPaymentItem = deposit.items.find(depositItem => depositItem.code === 'FIRST.PAYMENT');
    if (firstPaymentItem) {
      firstPaymentAmount = firstPaymentItem.pricing.amountAtDelivery;
    }
  }
  return firstPaymentAmount;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getLeaseOrFinanceMethod = state => {
  const {
    financeOptions,
    finance,
    transaction
  } = getQuote(state);
  const locale = (0, _selectors.getLocale)(state);
  let securityDeposits = {};
  let residualDetails = {};
  if (!Array.isArray(financeOptions)) {
    return {};
  }
  const selectedPurchaseMethod = financeOptions.find(option => option.isSelected);
  if (finance) {
    securityDeposits = _objectSpread({}, finance.term.securityDepositDetails);
    residualDetails = _objectSpread({}, finance.term.residualDetails);
  }
  if (!(0, _commonsUtils.isPlainObject)(selectedPurchaseMethod)) {
    return {};
  }
  const {
    buyBackAmount,
    financePlanDescription,
    kmPerYearPlan,
    paymentFrequency,
    selectedApr,
    selectedPayment,
    selectedTerm,
    dueAtDelivery
  } = selectedPurchaseMethod;
  return {
    title: financePlanDescription,
    term: selectedTerm,
    interestRate: selectedApr,
    kmPlan: kmPerYearPlan,
    buyBackAmount,
    payment: selectedPayment.payment,
    gstpst: selectedPayment.paymentTaxes,
    dueAtDelivery,
    totalPayment: {
      amount: selectedPayment.totalPayment,
      frequency: paymentFrequency
    },
    securityDeposits,
    residualDetails,
    firstPayment: getFirstPayment(transaction),
    locale
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getLeaseOrFinanceMethod = getLeaseOrFinanceMethod;
const getCashMethod = state => {
  const {
    cashPurchase,
    cashPurchaseOption
  } = getQuote(state);
  if (!(0, _commonsUtils.isPlainObject)(cashPurchase) || !(0, _commonsUtils.isPlainObject)(cashPurchaseOption)) {
    return {};
  }
  const {
    incentives,
    retailDetails,
    salesDeposit,
    sellingPrice,
    totalBalanceDue,
    totalToPay,
    tradeInTaxCreditsDetails
  } = cashPurchase;
  const {
    transaction
  } = cashPurchaseOption;
  const tax = transaction.find(item => item.category === TAX && item.isExpandable);
  const deposit = transaction.find(item => item.category === DEPOSIT);
  const total = transaction.find(item => item.category === TO_PAY && item.isTotal);
  return {
    basePrice: retailDetails.msrp,
    basePriceAfterRetail: retailDetails.total,
    tradeIn: tradeInTaxCreditsDetails.totalTradeIn,
    incentives: incentives.total,
    sellingPrice,
    tax: tax && tax.amount,
    deposit: deposit && deposit.amount,
    total: total && total.amount,
    salesDeposit,
    totalBalanceDue,
    totalToPay
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getCashMethod = getCashMethod;
const getPurchaseMethod = state => {
  const {
    purchaseMethod
  } = getQuote(state);
  switch (purchaseMethod) {
    case FINANCE_WITH_BALLOON:
    case SINGLE_PAYMENT_LEASE:
    case FINANCE:
    case LEASE:
      return _objectSpread(_objectSpread({}, getLeaseOrFinanceMethod(state)), {}, {
        purchaseMethod
      });
    case CASH:
      return _objectSpread(_objectSpread({}, getCashMethod(state)), {}, {
        purchaseMethod
      });
    default:
      return {};
  }
};

/**
 * @param {Object} transaction
 * @param {String} category
 * @returns {Object}
 */
exports.getPurchaseMethod = getPurchaseMethod;
const getCategory = (transaction, category) => {
  const selection = transaction.find(item => item.category === category);
  if (!(0, _commonsUtils.isPlainObject)(selection)) {
    return {};
  }
  const isMultiLine = selection.isExpandable && !selection.isAlwaysExpanded;
  let items = [];
  if (selection.items && selection.items.length) {
    if (!isMultiLine) {
      items = [{
        description: selection.name,
        amount: selection.pricing.amount,
        amountAtDelivery: selection.pricing.amountAtDelivery + selection.pricing.amountOutsideTransaction
      }];
    } else {
      items = selection.items.map(item => ({
        description: item.description,
        amount: item.pricing.amount,
        amountAtDelivery: item.pricing.amountAtDelivery + item.pricing.amountOutsideTransaction
      }));
    }
  }
  return {
    category: selection.category,
    name: selection.name,
    amount: selection.pricing.amount,
    amountAtDelivery: selection.pricing.amountAtDelivery + selection.pricing.amountOutsideTransaction,
    items
  };
};

/** @param {Object} state
 * @returns {Object}
 */
const getOtherFees = transaction => {
  // selector meant to retrieve and return only otherFeetaxesIncluded items that AREN'T customer notes
  const baseNotes = transaction.find(item => item.category === OTHER_FEE_TAXES_INCLUDED);
  let items = [];
  const otherFees = baseNotes && baseNotes.items.length > 0 ? baseNotes.items.filter(item => item.category !== CUSTOMER_NOTE) : [];
  if (otherFees.length > 0) {
    items = otherFees.map(item => ({
      description: item.description,
      amount: item.pricing.amount,
      amountAtDelivery: item.pricing.amountAtDelivery
    }));
  } else {
    // if no valid entries are returned, must receive an empty object to be properly parsed in the deal details section
    return {};
  }
  return {
    category: baseNotes.category,
    name: baseNotes.name,
    amount: baseNotes.pricing.amount,
    amountAtDelivery: baseNotes.pricing.amountAtDelivery,
    items
  };
};

/** @param {Object} state
 * @returns {Object}
 */
const getNotes = state => {
  const {
    transaction
  } = getQuote(state);
  const baseNotes = transaction.find(item => item.category === OTHER_FEE_TAXES_INCLUDED);
  let items = [];
  if (baseNotes && baseNotes.items.length) {
    items = baseNotes.items.filter(item => item.category === CUSTOMER_NOTE).map(item => ({
      description: item.description
    }));
  }
  return {
    items
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getNotes = getNotes;
const getDealDetails = state => {
  const {
    transaction
  } = getQuote(state);
  if (!Array.isArray(transaction)) {
    return {};
  }
  // STDESK-642 Condition on lease payment was a request from Audi Thornhill
  // const TRADE_IN_OPTION = getIsTradeInPaymentTypeLease(state) ? TRADE_IN_TAXES_INCLUDED : TRADE_IN;
  const tradeInWithoutTax = getCategory(transaction, TRADE_IN);
  const tradeInTaxesIncluded = getCategory(transaction, TRADE_IN_TAXES_INCLUDED);
  const basePrice = getCategory(transaction, BASE);
  const freightAndPDI = getCategory(transaction, FEE);
  const dealDiscount = getCategory(transaction, DISCOUNT);
  const sellingPrice = getCategory(transaction, TOTAL);
  const leviesAndDuties = getCategory(transaction, TAXES_AND_DUTIES);
  const otherFees = getCategory(transaction, OTHER_FEE);
  const accessories = getCategory(transaction, ACCESSORY);
  const warranties = getCategory(transaction, WARRANTY);
  const protections = getCategory(transaction, PROTECTION);
  const taxes = getCategory(transaction, TAX);
  const deposit = getCategory(transaction, DEPOSIT);
  const toPay = getCategory(transaction, TO_PAY);
  const option = getCategory(transaction, OPTION);
  const cashDown = getCategory(transaction, CASH_DOWN);
  const tradeIn = !(0, _commonsUtils.isEmpty)(tradeInWithoutTax) ? tradeInWithoutTax : tradeInTaxesIncluded;
  const lien = getCategory(transaction, LIEN);
  const rebate = getCategory(transaction, REBATE);
  const insurance = getCategory(transaction, INSURANCE_TAXES_INCLUDED);
  const warrantyTaxesIncluded = getCategory(transaction, WARRANTY_TAXES_INCLUDED);
  const protectionTaxesIncluded = getCategory(transaction, PROTECTION_TAXES_INCLUDED);
  const otherFeesTaxesIncluded = getOtherFees(transaction);

  // the return defines the order they are to appear in when rendered in the offersheet.
  // verify order when making any changes to the return

  return {
    basePrice,
    option,
    dealDiscount,
    freightAndPDI,
    sellingPrice,
    leviesAndDuties,
    otherFees,
    accessories,
    warranties,
    protections,
    tradeIn,
    taxes,
    warrantyTaxesIncluded,
    protectionTaxesIncluded,
    lien,
    insurance,
    otherFeesTaxesIncluded,
    rebate,
    cashDown,
    deposit,
    toPay
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getDealDetails = getDealDetails;
const getMethodOptions = (financeOptions, methodName) => {
  const lease = financeOptions.find(option => option.purchaseMethod === methodName && option.isAvailable);
  if (!(0, _commonsUtils.isPlainObject)(lease)) {
    return {};
  }
  const {
    financePlanDescription,
    cashDownOptions,
    paymentFrequency,
    kmPerYearPlan,
    selectedCashDown,
    isSelected,
    selectedTerm
  } = lease;
  const {
    terms
  } = cashDownOptions.find(option => option.cashDown === selectedCashDown);
  const options = terms.map(term => _objectSpread(_objectSpread({}, term), {}, {
    taxes: term.totalPayment - term.payment
  }));
  return {
    financePlanDescription,
    options,
    paymentFrequency,
    kmPerYearPlan,
    isSelected,
    selectedTerm
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getLeaseMethodOptions = state => {
  const {
    financeOptions
  } = getQuote(state);
  if (!Array.isArray(financeOptions)) {
    return {};
  }
  return getMethodOptions(financeOptions, LEASE);
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getLeaseMethodOptions = getLeaseMethodOptions;
const getFinanceMethodOptions = state => {
  const {
    financeOptions
  } = getQuote(state);
  if (!Array.isArray(financeOptions)) {
    return {};
  }
  return getMethodOptions(financeOptions, FINANCE);
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getFinanceMethodOptions = getFinanceMethodOptions;
const getFinanceWithBalloonMethodOptions = state => {
  const {
    financeOptions
  } = getQuote(state);
  if (!Array.isArray(financeOptions)) {
    return {};
  }
  return getMethodOptions(financeOptions, FINANCE_WITH_BALLOON);
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getFinanceWithBalloonMethodOptions = getFinanceWithBalloonMethodOptions;
const getSingleLeaseMethodOptions = state => {
  const {
    financeOptions
  } = getQuote(state);
  if (!Array.isArray(financeOptions)) {
    return {};
  }
  return getMethodOptions(financeOptions, SINGLE_PAYMENT_LEASE);
};

/** @params {Object} state
 * @returns {Object}
 */
exports.getSingleLeaseMethodOptions = getSingleLeaseMethodOptions;
const getSalesPersonnel = state => {
  const {
    salesPersonnel
  } = state;
  return salesPersonnel;
};

/**
 * @params {Object} state
 * @returns {Object}
 */
exports.getSalesPersonnel = getSalesPersonnel;
const getSalesPerson = state => {
  const {
    salesperson
  } = getSalesPersonnel(state);
  return salesperson;
};

/**
 * @params {Object} state
 * @returns {Object}
 */
exports.getSalesPerson = getSalesPerson;
const getOfferFinancingDates = state => {
  const {
    saleDate,
    deliveryDate
  } = getQuote(state);
  return {
    saleDate,
    deliveryDate
  };
};
exports.getOfferFinancingDates = getOfferFinancingDates;