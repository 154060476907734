"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setPriceIncreaseRollCount = exports.reducers = exports.Types = exports.Creators = void 0;
var _reduxsauce = require("reduxsauce");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  Types,
  Creators
} = (0, _reduxsauce.createActions)({
  setPriceIncreaseRollCount: ['priceIncreaseRollCount']
});
exports.Creators = Creators;
exports.Types = Types;
const setPriceIncreaseRollCount = (state, _ref) => {
  let {
    priceIncreaseRollCount
  } = _ref;
  const parsedCount = parseInt(priceIncreaseRollCount, 10);
  return _objectSpread(_objectSpread({}, state), {}, {
    priceIncreaseRollCount: Number.isNaN(parsedCount) ? 0 : parsedCount
  });
};
exports.setPriceIncreaseRollCount = setPriceIncreaseRollCount;
const reducers = exports.reducers = {
  [Types.SET_PRICE_INCREASE_ROLL_COUNT]: setPriceIncreaseRollCount
};