"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submitCreditApplication = exports.saveCreditApplication = exports.getSubmissionTypes = exports.getCreditApplicationDecision = exports.getCreditApplication = exports.default = void 0;
var _appsCore = require("@sm360/apps-core");
var _uiLibrary = require("@sm360/ui-library");
/* eslint-disable max-len */

const SHOWROOM_API_URL = process.env.REACT_APP_SHOWROOM_API_URL;
const getSubmissionTypes = async (quoteId, orgId, orgUnitId, location, lang, creditPortalId) => {
  try {
    const response = await _appsCore.axios.get("".concat(SHOWROOM_API_URL, "/lead/creditApplication/submissionTypes/").concat(quoteId, "?") + "orgId=".concat(orgId, "&") + "orgUnitId=".concat(orgUnitId, "&") + "location=".concat(location, "&") + "lang=".concat(lang, "&") + "creditPortalId=".concat(creditPortalId), {
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
    return response ? response.data : null;
  } catch (error) {
    return null;
  }
};
exports.getSubmissionTypes = getSubmissionTypes;
const saveCreditApplication = async (creditApplication, orgId, orgUnitId, location, lang) => {
  if (!creditApplication) {
    throw new Error('No credit-application to create.');
  }
  try {
    const creditAppResponse = await _appsCore.axios.put("".concat(SHOWROOM_API_URL, "/lead/creditApplication?") + "orgId=".concat(orgId, "&") + "orgUnitId=".concat(orgUnitId, "&") + "location=".concat(location, "&") + "lang=".concat(lang), creditApplication, {
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
    return creditAppResponse ? creditAppResponse.data : null;
  } catch (error) {
    throw new Error("Failed to create credit-application: ".concat(JSON.stringify(creditApplication)));
  }
};
exports.saveCreditApplication = saveCreditApplication;
const submitCreditApplication = async (t, submissionType, quoteId, orgId, orgUnitId, location, lang, creditPortalId) => {
  if (!quoteId) {
    throw new Error('QuoteID is undefined');
  }
  if (!creditPortalId) {
    throw new Error('creditPortalId is undefined');
  }
  try {
    const creditAppResponse = await _appsCore.axios.put("".concat(SHOWROOM_API_URL, "/lead/creditApplication/submission/").concat(quoteId, "?") + "orgId=".concat(orgId, "&") + "orgUnitId=".concat(orgUnitId, "&") + "location=".concat(location, "&") + "lang=".concat(lang, "&") + "creditPortalId=".concat(creditPortalId, "&") + "submissionType=".concat(submissionType), null, {
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
    if (creditAppResponse && creditAppResponse.data) {
      const {
        errMsg
      } = creditAppResponse.data;
      if (errMsg && errMsg.length) {
        _uiLibrary.Toast.toast.error("".concat(t('creditApplication.FINANCING.TOASTS.ERROR.SUBMIT'), " : ").concat(errMsg));
      } else {
        _uiLibrary.Toast.toast.success(t('creditApplication.FINANCING.TOASTS.SUCCESS.SUBMIT'));
      }
      return creditAppResponse.data;
    }
    return null;
  } catch (error) {
    const message = error && error.response && error.response.data ? error.response.data.errMsg : t('commons.NA');
    _uiLibrary.Toast.toast.error("".concat(t('creditApplication.FINANCING.TOASTS.ERROR.SUBMIT'), " : ").concat(message));

    // throw new Error(`Failed to submit credit-application with quoteID: ${JSON.stringify(quoteId)}`);
    return null;
  }
};
exports.submitCreditApplication = submitCreditApplication;
const getCreditApplication = async (leadId, orgId, orgUnitId, location, lang) => {
  try {
    const creditAppResponse = await _appsCore.axios.get("".concat(SHOWROOM_API_URL, "/lead/creditApplication/").concat(leadId, "?") + "orgId=".concat(orgId, "&") + "orgUnitId=".concat(orgUnitId, "&") + "location=".concat(location, "&") + "lang=".concat(lang), {
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
    return creditAppResponse ? creditAppResponse.data : null;
  } catch (error) {
    throw new Error("Failed getting credit-application with leadId ".concat(leadId));
  }
};
exports.getCreditApplication = getCreditApplication;
const getCreditApplicationDecision = (quoteId, orgId, orgUnitId, location, lang) => {
  try {
    return _appsCore.axios.get("".concat(SHOWROOM_API_URL, "/lead/creditApplication/decision/").concat(quoteId, "?") + "orgId=".concat(orgId, "&") + "orgUnitId=".concat(orgUnitId, "&") + "location=".concat(location, "&") + "lang=".concat(lang), {
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
  } catch (error) {
    throw new Error("Failed getting credit-application decision with quoteId ".concat(quoteId));
  }
};
exports.getCreditApplicationDecision = getCreditApplicationDecision;
var _default = exports.default = {
  getSubmissionTypes,
  saveCreditApplication,
  submitCreditApplication,
  getCreditApplication,
  getCreditApplicationDecision
};