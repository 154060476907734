"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateEmailTemplate = exports.getPaginatedEmailTemplates = exports.getFilledEmailTemplate = exports.getEmailTemplates = exports.getEmailTemplateTags = exports.getEmailTemplate = exports.deleteEmailTemplate = exports.createEmailTemplate = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _request = require("../../tools/request");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const CRM_API_URL = process.env.REACT_APP_CRM_API_URL;

/**
 * Returns a paginated sample of the mail templates (will also return the mail templates french/english content)
 * @param {Object} filters
 * @param {Number} pageSize
 * @param {Number} pageIndex
 * @param {Array} request
 * @param {Array} sortBy
 * @returns {Promise}
 */
const getPaginatedEmailTemplates = _ref => {
  let {
    filters,
    pageSize,
    pageIndex,
    request,
    sortBy
  } = _ref;
  const newQuery = {
    filters: _objectSpread(_objectSpread({}, filters), {}, {
      searchTerms: request ? [(0, _commonsUtils.sanitizeSearch)(request)] : []
    }),
    meta: {
      from: pageIndex,
      size: pageSize,
      sort: sortBy
    }
  };
  return (0, _request.post)("".concat(CRM_API_URL, "/emailTemplates/search"), newQuery, null, null, 'getPaginatedEmailTemplates');
};

/**
 *  Returns all the mail templates for a given set of dealers (will only return the id, title and subscriptionContextSlug for each of them)
 * @param {Array} organizationUnitIds
 * @returns {Promise}
 */
exports.getPaginatedEmailTemplates = getPaginatedEmailTemplates;
const getEmailTemplates = function () {
  let organizationUnitIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return (0, _request.get)("".concat(CRM_API_URL, "/emailTemplates/titles?").concat(organizationUnitIds.map(id => "organizationUnitId=".concat(id)).join('&')), null, null, null, 'getEmailTemplates');
};

/**
 * @param {Number} id
 * @returns {Promise}
 */
exports.getEmailTemplates = getEmailTemplates;
const getEmailTemplate = id => {
  return (0, _request.get)("".concat(CRM_API_URL, "/emailTemplates/").concat(id), null, null, 'getEmailTemplate');
};

/**
 * @param {Object} body
 * @returns {Promise}
 */
exports.getEmailTemplate = getEmailTemplate;
const createEmailTemplate = body => {
  return (0, _request.post)("".concat(CRM_API_URL, "/emailTemplates"), body, null, null, 'createEmailTemplate');
};

/**
 * @param {Number} id
 * @param {Object} body
 * @returns {Promise}
 */
exports.createEmailTemplate = createEmailTemplate;
const updateEmailTemplate = body => {
  return (0, _request.put)("".concat(CRM_API_URL, "/emailTemplates/").concat(body.id), body, null, null, 'updateEmailTemplate');
};

/**
 * @param {Number} id
 * @returns {Promise}
 */
exports.updateEmailTemplate = updateEmailTemplate;
const deleteEmailTemplate = id => {
  return (0, _request.deleteHttp)("".concat(CRM_API_URL, "/emailTemplates/").concat(id), null, null, null, 'deleteEmailTemplate');
};

/**
 * @returns {Promise}
 */
exports.deleteEmailTemplate = deleteEmailTemplate;
const getEmailTemplateTags = () => {
  return (0, _request.get)("".concat(CRM_API_URL, "/emailTemplates/tags"), null, null, 'getEmailTemplateTags');
};

/**
 * @param {Number} id
 * @param {String} lang
 * @param {Number} contactId
 * @param {Number} leadId
 * @param {Number} organizationUnitId
 * @param {String} to
 * @param {Number} userId
 * @returns {Promise}
 */
exports.getEmailTemplateTags = getEmailTemplateTags;
const getFilledEmailTemplate = _ref2 => {
  let {
    id,
    lang,
    contactId,
    leadId,
    organizationUnitId,
    to,
    userId
  } = _ref2;
  const leadIdQuery = leadId ? "&leadId=".concat(leadId) : '';
  const organizationUnitIdQuery = "&organizationUnitId=".concat(organizationUnitId || -1);
  return (0, _request.get)("".concat(CRM_API_URL, "/emailTemplates/").concat(id, "/filled?contactId=").concat(contactId, "&to=").concat(to, "&userId=").concat(userId, "&language=").concat(lang).concat(leadIdQuery).concat(organizationUnitIdQuery), null, null, 'getFilledEmailTemplate');
};
exports.getFilledEmailTemplate = getFilledEmailTemplate;