"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useEmailTemplates = void 0;
var _appsCore = require("@sm360/apps-core");
var _EmailTemplateService = require("../../api/EmailTemplateService");
var _utils = require("../../tools/utils");
const _excluded = ["organizationUnitIds", "select"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const transformTemplatesToOptions = data => (0, _utils.transformDataToOptions)(data, 'title');

/**
 * Returns a list of email templates with id / title
 * @param {Array.<number>} organizationUnitIds
 * @returns {Object} a Query object
 */
const useEmailTemplates = params => {
  const _ref = params || {},
    {
      organizationUnitIds,
      select
    } = _ref,
    rest = _objectWithoutProperties(_ref, _excluded);
  const sanitizedOrganizationUnitIds = (0, _utils.sanitizeOrganizationUnitIds)(organizationUnitIds);
  const queryKey = ['emailTemplates', {
    organizationUnitIds: sanitizedOrganizationUnitIds
  }];
  const queryFn = () => (0, _EmailTemplateService.getEmailTemplates)(sanitizedOrganizationUnitIds);
  const enabled = sanitizedOrganizationUnitIds.length > 0;
  return (0, _appsCore.useQuery)(_objectSpread({
    queryKey,
    queryFn,
    enabled,
    select: select || transformTemplatesToOptions
  }, rest));
};
exports.useEmailTemplates = useEmailTemplates;