"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SERVICE_FORM = void 0;
const sourceString = 'leadServiceVehicle';
const translationPrefix = 'leads.updateView.serviceForm';
const SERVICE_FORM = exports.SERVICE_FORM = [{
  legend: "".concat(translationPrefix, ".VEHICLE"),
  fieldset: '',
  fields: [{
    input: 'input',
    className: 'small-12 medium-5 large-3',
    name: "".concat(sourceString, ".make"),
    label: "".concat(translationPrefix, ".MAKE")
  }, {
    input: 'input',
    className: 'small-12 medium-5 large-3',
    name: "".concat(sourceString, ".model"),
    label: "".concat(translationPrefix, ".MODEL")
  }, {
    input: 'select',
    className: 'small-12 medium-2 large-2',
    name: "".concat(sourceString, ".year"),
    label: "".concat(translationPrefix, ".YEAR"),
    optionLabelValue: 'label'
  }, {}, {
    input: 'input',
    className: 'small-12 medium-6 large-4',
    name: "".concat(sourceString, ".vin"),
    label: "".concat(translationPrefix, ".VIN"),
    customValidation: /^[A-HJ-NPR-Z0-9]{17}$/i,
    customMessage: {
      customValidation: "".concat(translationPrefix, ".INVALID_VIN")
    }
  }, {
    input: 'inputNumber',
    className: 'small-12 medium-6 large-4',
    name: "".concat(sourceString, ".odometer"),
    label: "".concat(translationPrefix, ".MILEAGE"),
    addon: 'km',
    isNatural: true
  }]
}, {
  legend: "".concat(translationPrefix, ".SERVICE_REQUEST"),
  fieldset: '',
  fields: [{
    input: 'datePicker',
    className: 'small-12 medium-5 large-3',
    name: "".concat(sourceString, ".appointmentDate"),
    label: "".concat(translationPrefix, ".APPOINTMENT_DATE"),
    showTimeInput: true
  }, {}, {
    input: 'textarea',
    className: 'small-12 medium-12 large-8',
    name: "".concat(sourceString, ".serviceDetails"),
    label: "".concat(translationPrefix, ".SERVICE_DETAILS")
  }]
}];