"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVehiclesWidgetParams = exports.getValidationContent = exports.getTransactionToken = exports.getTradeIn = exports.getSalesPersonnel = exports.getQueryParams = exports.getPurchaseMethod = exports.getPlan = exports.getPermissions = exports.getNav = exports.getLeadId = exports.getIsPaymentUpdating = exports.getIsLoading = exports.getIsHidingPrices = exports.getHasTaxesIncluded = exports.getHasDeskingAccess = exports.getDeskingWidgetParams = exports.getConflictModalImage = exports.getConfig = exports.getBaseProps = exports.getBasePathParams = exports.getBaseBodyParams = exports.composeNewBaseProps = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _deskingCommons = require("@sm360/desking-commons");
var _stepOptionItems = require("./selectors/stepOptionItems");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * @param {Object} state
 * @returns {Object}
 */
const getIsLoading = state => state.isLoading;

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getIsLoading = getIsLoading;
const getBaseProps = state => {
  const {
    baseProps
  } = state;
  return (0, _commonsUtils.isPlainObject)(baseProps) ? baseProps : {};
};

/**
 * Used when we change a modelTrim (packages, options...), but not when we change modelTrim.
 * - If the transactionToken is passed in parameter, it "overrides" the above.
 * - We have to keep the previous values (useful in modelValidation).
 *
 * @param {Object} state - current state
 * @param {Number} configurationId - new value
 * @param {String} modelTrimCode - new value
 * @param {String|undefined} transactionToken - new value
 * @returns {Object}
 */
exports.getBaseProps = getBaseProps;
const composeNewBaseProps = (state, configurationId, modelTrimCode, transactionToken) => {
  const {
    configurationId: previousConfigurationId,
    modelTrimCode: previousModelTrimCode,
    transactionToken: previousTransactionToken
  } = getBaseProps(state);
  const newTransactionToken = transactionToken || previousTransactionToken;
  return {
    configurationId,
    modelTrimCode,
    transactionToken: newTransactionToken,
    previousConfigurationId,
    previousModelTrimCode
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.composeNewBaseProps = composeNewBaseProps;
const getConfig = state => {
  const {
    config
  } = state;
  return (0, _commonsUtils.isPlainObject)(config) ? config : {};
};

/**
 * @param {Object} state
 * @returns {Number|null}
 */
exports.getConfig = getConfig;
const getLeadId = state => {
  const {
    leadId
  } = getConfig(state);
  return typeof leadId === 'number' ? leadId : null;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getLeadId = getLeadId;
const getPermissions = state => {
  const {
    permissions
  } = state;
  return (0, _commonsUtils.isPlainObject)(permissions) ? permissions : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getPermissions = getPermissions;
const getNav = state => {
  const {
    nav
  } = state;
  return (0, _commonsUtils.isPlainObject)(nav) ? nav : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getNav = getNav;
const getPlan = state => {
  const {
    plan
  } = state;
  return (0, _commonsUtils.isPlainObject)(plan) ? plan : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getPlan = getPlan;
const getPurchaseMethod = state => {
  const {
    purchaseMethod
  } = state;
  return (0, _commonsUtils.isPlainObject)(purchaseMethod) ? purchaseMethod : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getPurchaseMethod = getPurchaseMethod;
const getTradeIn = state => {
  const {
    tradeIn
  } = state;
  return (0, _commonsUtils.isPlainObject)(tradeIn) ? tradeIn : {};
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getTradeIn = getTradeIn;
const getHasTaxesIncluded = state => state.hasTaxesIncluded === true;

/**
 * Always show prices on step 6
 *
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getHasTaxesIncluded = getHasTaxesIncluded;
const getIsHidingPrices = state => {
  const {
    isHidingPrices
  } = getPermissions(state);
  return isHidingPrices;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getIsHidingPrices = getIsHidingPrices;
const getValidationContent = state => {
  const {
    validation
  } = state;
  return (0, _commonsUtils.isPlainObject)(validation) ? validation : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getValidationContent = getValidationContent;
const getQueryParams = state => {
  const {
    lang,
    location,
    organizationId,
    organizationUnitId
  } = getConfig(state);
  return {
    lang,
    location,
    orgId: organizationId,
    orgUnitId: organizationUnitId,
    module: 1
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getQueryParams = getQueryParams;
const getBaseBodyParams = state => {
  const {
    saleType,
    taxPlan,
    vehicleCondition,
    odometer
  } = getPlan(state);
  const {
    purchaseMethod,
    kmPerYearPlan,
    paymentFrequency,
    term,
    cashDown
  } = getPurchaseMethod(state);
  const {
    tradeIn,
    lien
  } = getTradeIn(state);
  return {
    saleType,
    taxPlan,
    vehicleCondition,
    odometer,
    purchaseMethod,
    kmPerYearPlan,
    paymentFrequency,
    term,
    cashDown,
    tradeIn,
    lien
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getBaseBodyParams = getBaseBodyParams;
const getDeskingWidgetParams = state => {
  const {
    accountId,
    contactId,
    organizationId,
    organizationUnitId
  } = getConfig(state);
  const leadId = getLeadId(state);
  return {
    leadId,
    accountId,
    contactId,
    organizationId,
    organizationUnitId
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getDeskingWidgetParams = getDeskingWidgetParams;
const getVehiclesWidgetParams = state => {
  const {
    location
  } = getConfig(state);
  const leadId = getLeadId(state);
  return _objectSpread({
    leadId,
    location
  }, getDeskingWidgetParams(state));
};

/**
 * @param {Object} state
 * @returns {String[]}
 */
exports.getVehiclesWidgetParams = getVehiclesWidgetParams;
const getBasePathParams = state => {
  const {
    bodyStyle,
    make,
    model
  } = getConfig(state);
  return [make, model, bodyStyle];
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getBasePathParams = getBasePathParams;
const getTransactionToken = state => {
  var _validation$negociati;
  const baseProps = getBaseProps(state);
  const validation = getValidationContent(state);
  const negociationTransaction = validation === null || validation === void 0 || (_validation$negociati = validation.negociationTransaction) === null || _validation$negociati === void 0 ? void 0 : _validation$negociati.transactionToken;
  const transactionToken = !(0, _commonsUtils.isEmpty)(validation) && negociationTransaction || baseProps.transactionToken;
  return transactionToken;
};

/**
 * For now we can only handle when it's coming from an item choice
 *
 * @param {Object} state
 * @returns {String}
 */
exports.getTransactionToken = getTransactionToken;
const getConflictModalImage = state => {
  const {
    photo
  } = (0, _stepOptionItems.getSelectedItem)(state);
  const formattedImage = photo && photo.length > 0 ? (0, _deskingCommons.prefixOptions)(photo) : null;
  return formattedImage;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getConflictModalImage = getConflictModalImage;
const getIsPaymentUpdating = state => state.isPaymentUpdating === true;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsPaymentUpdating = getIsPaymentUpdating;
const getHasDeskingAccess = state => {
  const {
    hasDeskingAccess
  } = getPermissions(state);
  return hasDeskingAccess === true;
};

/** @params {Object} state
 * @returns {Object}
 */
exports.getHasDeskingAccess = getHasDeskingAccess;
const getSalesPersonnel = state => {
  const {
    salesPersonnel
  } = state;
  return salesPersonnel;
};
exports.getSalesPersonnel = getSalesPersonnel;