"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _tabProtection = require("../../selectors/tabProtection");
var _reloadVehicle = _interopRequireDefault(require("../reloadVehicle"));
var _selectors = require("../../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("../api/updatePayOptAndTransDetails"));
var _validateFNIItem = _interopRequireDefault(require("./validateFNIItem"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * @param {String} fniType
 * @param {String} contentType
 * @param {String} persistentId
 * @param {String} planPersistentId
 * @param {Object} payload
 * @returns {Object}
 */
function _default(_ref) {
  let {
    fniType,
    contentType,
    persistentId,
    planPersistentId,
    payload
  } = _ref;
  return function* () {
    yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItems());
    const item = yield (0, _effects.select)(_tabProtection.getValidationFNIItemPlan, fniType, contentType, persistentId, planPersistentId, payload);
    try {
      const response = yield (0, _effects.call)(_validateFNIItem.default, item);
      if (response) {
        if (response.isValid) {
          const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
          yield (0, _effects.call)(_updatePayOptAndTransDetails.default, _objectSpread(_objectSpread({}, paymentOptions), {}, {
            transactionToken: response.negociationTransaction.transactionToken,
            callCommission: true
          }));
          yield (0, _effects.call)(_reloadVehicle.default);
          const validationItem = _objectSpread({
            isSelected: item.isSelected
          }, response.negociationTransaction);
          yield (0, _effects.put)(_actions.Creators.updateFNIItemPlanSuccess(fniType, contentType, persistentId, planPersistentId, validationItem));
        } else {
          yield (0, _effects.put)(_actions.Creators.openInvalidResponseModal('', [response.validationMessage]));
        }
      }
      yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItemsDone());
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateFNIItemPlanCoverageParameters'));
      yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItemsDone());
    }
  }();
}