"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _payment = require("../../constants/payment");
var _items = require("../../constants/items");
var _selectors = require("../../redux/selectors");
var _finance = require("../../redux/selectors/finance");
var _vehicle = require("../../redux/selectors/vehicle");
var _actions = require("../../redux/actions");
var _VehicleDetails = _interopRequireDefault(require("./VehicleDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const {
  TO_PAY
} = _items.ITEM;

/**
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps = state => {
  var _quote$transaction;
  const {
    odometer
  } = (0, _selectors.getPaymentOptions)(state);
  const {
    lang
  } = (0, _selectors.getConfig)(state);
  const {
    incentiveEffectiveDate,
    description
  } = (0, _finance.getEffectiveIncentiveProgram)(state);
  const quote = (0, _selectors.getQuote)(state);
  const isCashSelected = (0, _selectors.getIsCashSelected)(state);
  const total = quote === null || quote === void 0 || (_quote$transaction = quote.transaction) === null || _quote$transaction === void 0 ? void 0 : _quote$transaction.find(item => item.category === TO_PAY && item.isTotal);
  const downPayment = total !== null && total !== void 0 && total.pricing ? isCashSelected ? total.pricing.amount + total.pricing.amountAtDelivery : total.pricing.amountAtDelivery : 0;
  return {
    quote,
    hasTaxesIncluded: (0, _selectors.getHasTaxesIncluded)(state),
    isCashSelected,
    isCatalog: (0, _vehicle.getIsCatalog)(state),
    isDemo: (0, _vehicle.getIsDemo)(state),
    isQuoteInProgress: (0, _vehicle.getIsQuoteInProgress)(state),
    isUsed: (0, _vehicle.getIsUsed)(state),
    newIncentive: (0, _vehicle.getNewIncentive)(state),
    paymentDetails: (0, _vehicle.getPaymentDetails)(state),
    downPayment,
    vehicle: (0, _vehicle.getVehicle)(state),
    odometer,
    lang,
    incentiveEffectiveDate,
    incentiveDescription: description
  };
};
const mapDispatchToProps = dispatch => ({
  changeOdometer: value => dispatch(_actions.Creators.changePaymentOptions({
    [_payment.KEY_ODOMETER]: parseInt(value, 10)
  })),
  setCustomerVisibility: isVisible => dispatch(_actions.Creators.setCustomerVisibility(isVisible))
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), _deskingError.withErrorWrapper)(_VehicleDetails.default);