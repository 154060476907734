"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * @param {Object} payload - Data from action
 */
function* setQuoteFlags(payload) {
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
  const quote = yield (0, _effects.select)(_selectors.getQuote);
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const body = {
    transactionToken: paymentOptions.transactionToken,
    saleType: quote.saleType,
    taxPlan: quote.taxPlan,
    vehicleCondition: quote.vehicleCondition,
    odometer: paymentOptions.odometer,
    purchaseMethod: paymentOptions.purchaseMethod,
    financePlan: paymentOptions.financePlan,
    kmPerYearPlan: paymentOptions.kmPerYearPlan,
    paymentFrequency: paymentOptions.paymentFrequency,
    term: paymentOptions.term,
    tradeIn: paymentOptions.tradeIn,
    lien: paymentOptions.lien,
    cashDownIncludesTaxes: payload.params.cashDownIncludesTaxes,
    rebatesIncludesTaxes: payload.params.rebatesIncludesTaxes,
    securityDepositIsWaived: payload.params.securityDepositIsWaived,
    cashDown: payload.params.cashDown,
    salesDeposit: payload.params.salesDeposit,
    cashOnlyRebatesAdjustment: payload.params.cashOnlyRebatesAdjustment,
    adminFeesNotChargedToCashAdjustment: payload.params.adminFeesNotChargedToCashAdjustment
  };
  try {
    const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.setQuoteFlags, quoteId, queryParams, body);
    yield (0, _effects.call)(_updatePayOptAndTransDetails.default, _objectSpread(_objectSpread({}, paymentOptions), {}, {
      cashDown: response.negociationTransaction.cashDown,
      transactionToken: response.negociationTransaction.transactionToken
    }));
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'setQuoteFlags'));
  }
}
var _default = exports.default = setQuoteFlags;