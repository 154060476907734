"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUndefinedFieldsFromObject = exports.getMergedValue = exports.getGender = exports.getContactRelatedFields = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _constants = require("./constants");
const getGender = (sexId, t) => {
  const parsedSexId = parseInt(sexId);
  switch (parsedSexId) {
    case 1:
      return t("contact-merger.gender.MALE");
    case 2:
      return t("contact-merger.gender.FEMALE");
    case 3:
      return t("contact-merger.gender.PREFER_NOT_TO_SAY");
    case 0:
    default:
      return t("contact-merger.gender.OTHER");
  }
};
exports.getGender = getGender;
const getMergedValue = (baseValue, duplicateValue) => {
  const parsedBaseValue = (0, _commonsUtils.nullOrString)(baseValue);
  const parsedDuplicateValue = (0, _commonsUtils.nullOrString)(duplicateValue);

  // both are unset: return one of the values as is
  if (parsedBaseValue === null && parsedDuplicateValue === null) {
    return baseValue;
  }
  // first value is set but not the second value
  else if (parsedBaseValue !== null && parsedDuplicateValue === null) {
    return baseValue;
  }
  // second value is set but not the first value
  else if (parsedBaseValue === null && parsedDuplicateValue !== null) {
    return duplicateValue;
  }
  // both are set
  else {
    const hasDiff = parsedBaseValue !== parsedDuplicateValue;
    if (hasDiff) return undefined; // conflict!
    else return baseValue;
  }
};
exports.getMergedValue = getMergedValue;
const getUndefinedFieldsFromObject = object => {
  let undefinedFields = [];
  for (const field in object) {
    if (object[field] === undefined) undefinedFields.push(field);
  }
  return undefinedFields;
};
exports.getUndefinedFieldsFromObject = getUndefinedFieldsFromObject;
const getContactRelatedFields = (field, contact) => {
  if (!contact || !field) return [];
  const relatedFields = _constants.INTERCHANGEABLE_FIELDS.find(fields => fields.includes(field));
  if (relatedFields) {
    return relatedFields.filter(f => f !== field).reduce((acc, field) => {
      const value = contact === null || contact === void 0 ? void 0 : contact[field];
      const valueAlreadyExists = acc.find(v => v.value === value);
      if (valueAlreadyExists || !value) return acc;else return [...acc, {
        name: field,
        value
      }];
    }, []);
  } else return [];
};
exports.getContactRelatedFields = getContactRelatedFields;