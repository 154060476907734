"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUsedVehicles = exports.getNewVehicles = exports.getNewVehicle = exports.getCatalogVehicles = void 0;
var _request = require("../../tools/request");
const VEHICLE_API_URL = process.env.REACT_APP_VEHICLE_API_URL;

/**
 * @param {String} url
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Object[]}
 */
const getVehicles = (url, queryParams, body) => (0, _request.post)(url, body, {
  params: queryParams
});
const getNewVehicles = function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  return getVehicles("".concat(VEHICLE_API_URL, "/inventory/vehicles-new"), ...args);
};
exports.getNewVehicles = getNewVehicles;
const getUsedVehicles = function () {
  for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    args[_key2] = arguments[_key2];
  }
  return getVehicles("".concat(VEHICLE_API_URL, "/inventory/vehicles-used"), ...args);
};
exports.getUsedVehicles = getUsedVehicles;
const getCatalogVehicles = function () {
  for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
    args[_key3] = arguments[_key3];
  }
  return getVehicles("".concat(VEHICLE_API_URL, "/catalog"), ...args);
};
exports.getCatalogVehicles = getCatalogVehicles;
const getNewVehicle = (vehicleId, queryParams, body) => {
  const url = "".concat(VEHICLE_API_URL, "/inventory/vehicle/vdp/").concat(vehicleId);
  return (0, _request.post)(url, body, {
    params: queryParams
  });
};
exports.getNewVehicle = getNewVehicle;