"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _commonsUtils = require("@sm360/commons-utils");
var _effects = require("redux-saga/effects");
var _actions = require("../../actions");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("../api/updatePayOptAndTransDetails"));
var _selectors = require("../../selectors");
var _tabProtection = require("../../selectors/tabProtection");
var _tabProtection2 = require("../../../constants/tabProtection");
var _getItems = _interopRequireDefault(require("../items/getItems"));
var _getFNIItems = _interopRequireDefault(require("./getFNIItems"));
const _excluded = ["transactionToken", "error", "errMsg"];
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function* _default(reloadFNIItems) {
  const {
    categories,
    name
  } = yield (0, _effects.select)(_tabProtection.getSelectedTab);
  const lgmItemsLoaded = yield (0, _effects.select)(_tabProtection.areLGMItemsLoaded);
  const bmwItemsLoaded = yield (0, _effects.select)(_tabProtection.areBMWItemsLoaded);
  const fniType = name;
  let items;
  let hasError = false;
  if (name !== _tabProtection2.TAB_PROTECTION.INSURANCE && name !== _tabProtection2.TAB_PROTECTION.PROTECTION && name !== _tabProtection2.TAB_PROTECTION.WARRANTY) {
    if (reloadFNIItems || name === _tabProtection2.TAB_PROTECTION.LGM && !lgmItemsLoaded || name === _tabProtection2.TAB_PROTECTION.BMW && !bmwItemsLoaded) {
      yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItems());
      items = yield (0, _effects.call)(_getFNIItems.default, fniType);
      const {
          transactionToken,
          error,
          errMsg
        } = items,
        rest = _objectWithoutProperties(items, _excluded);
      if (error && error !== 0) {
        hasError = true;
        yield (0, _effects.put)(_actions.Creators.setFNIItemsError(fniType, error, errMsg));
      } else {
        yield (0, _effects.put)(_actions.Creators.setFNIItemsError(fniType, 0, ''));
        if (!(0, _commonsUtils.isEmpty)(transactionToken)) {
          const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
          yield (0, _effects.call)(_updatePayOptAndTransDetails.default, _objectSpread(_objectSpread({}, paymentOptions), {}, {
            transactionToken
          }));
        }
        items = rest;
      }
      yield (0, _effects.put)(_actions.Creators.setFNIItemsAreLoaded(fniType));
      yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItemsDone());
    }
  } else {
    items = yield (0, _effects.call)(_getItems.default, categories);
  }
  if (items && !hasError) {
    yield (0, _effects.put)(_actions.Creators.reloadTabProtectionSuccess(name, items));
  }
}