"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isLeadTypeAllowedInGroup = exports.isCallExpired = exports.internallyDismissNotification = exports.getNotificationIdsByLeadId = exports.getGroupIdByLeadType = exports.formatContactName = exports.computeLeadGroups = void 0;
var _constants = require("./constants");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// Returns a filtered list of allEmails having a certain leadId
const getNotificationIdsByLeadId = (allEmails, leadId) => {
  const notificationsWithSameLead = allEmails.filter(n => n.leadId === leadId);
  return notificationsWithSameLead.map(n => n.activityId);
};

// Returns if a certain date is older than 20 minutes
exports.getNotificationIdsByLeadId = getNotificationIdsByLeadId;
const isCallExpired = dateCreated => {
  if (typeof dateCreated !== 'string') return false;
  const notifDate = new Date(dateCreated).getTime();
  const now = new Date();
  const diffMs = now.getTime() - notifDate;
  const diffMins = Math.round(diffMs % 86400000 % 3600000 / 60000); // minutes
  return Math.abs(diffMins) >= 20;
};

// Dispatch a CustomEvent to dismiss a notification in all NotificationsProviders of the browser
exports.isCallExpired = isCallExpired;
const internallyDismissNotification = (type, id) => {
  const event = new CustomEvent("dismiss_".concat(type), {
    detail: {
      message: JSON.stringify(id)
    }
  });
  dispatchEvent(event);
};
exports.internallyDismissNotification = internallyDismissNotification;
const formatContactName = (name, t) => {
  if (!name || (name === null || name === void 0 ? void 0 : name.length) === 0) return t('commons.NA');
  return String(name).replace(',', ' ').trim();
};
exports.formatContactName = formatContactName;
const isLeadTypeAllowedInGroup = (leadType, group) => (group.excludedTypes.length > 0 ? !group.excludedTypes.includes(leadType) : true) && (group.includedTypes.length > 0 ? group.includedTypes.includes(leadType) : true);
exports.isLeadTypeAllowedInGroup = isLeadTypeAllowedInGroup;
const computeLeadGroups = function () {
  let groupBy = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const typeGroups = groupBy.map(id => _constants.LEAD_GROUPS === null || _constants.LEAD_GROUPS === void 0 ? void 0 : _constants.LEAD_GROUPS[id]).filter(group => !!group);

  // ensure the base group never display what the other groups are displaying
  const allIncludedTypes = typeGroups.reduce((acc, group) => [...acc, ...((group === null || group === void 0 ? void 0 : group.includedTypes) || [])], []);
  return [_objectSpread(_objectSpread({}, _constants.LEAD_GROUPS.BASE), {}, {
    excludedTypes: allIncludedTypes
  }), ...typeGroups];
};
exports.computeLeadGroups = computeLeadGroups;
const getGroupIdByLeadType = (leadType, groups) => {
  var _groups$find;
  return (_groups$find = groups.find(group => isLeadTypeAllowedInGroup(leadType, group))) === null || _groups$find === void 0 ? void 0 : _groups$find.id;
};
exports.getGroupIdByLeadType = getGroupIdByLeadType;