"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const isNil = val => val == null;
const toNumberOrZero = number => Number(number) || 0;
const omitBy = (obj, check) => {
  obj = _objectSpread({}, obj);
  Object.entries(obj).forEach(_ref => {
    let [key, value] = _ref;
    return check(value) && delete obj[key];
  });
  return obj;
};
var _default = (state, payload) => {
  const {
    queryParams
  } = payload;
  const {
    accountId = null,
    bodyStyle,
    cashDown,
    contactId = null,
    includeTaxes,
    kmPerYearPlan,
    lang,
    leadId,
    lien,
    location = null,
    make,
    model,
    organizationId,
    organizationUnitId,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn
  } = queryParams;

  // Force true, have to be exactly "false" in query param to disable it
  let hasTaxesIncluded = true;
  if (includeTaxes && includeTaxes.toLowerCase() === 'false') {
    hasTaxesIncluded = false;
  }
  return _objectSpread(_objectSpread({}, state), {}, {
    config: {
      accountId: toNumberOrZero(accountId),
      bodyStyle,
      lang,
      leadId: toNumberOrZero(leadId),
      location,
      make,
      model,
      organizationId,
      organizationUnitId,
      contactId
    },
    plan: _objectSpread(_objectSpread({}, (0, _selectors.getPlan)(state)), omitBy({
      saleType,
      taxPlan
    }, isNil)),
    purchaseMethod: _objectSpread(_objectSpread({}, (0, _selectors.getPurchaseMethod)(state)), {}, {
      kmPerYearPlan: toNumberOrZero(kmPerYearPlan) || state.purchaseMethod.kmPerYearPlan,
      paymentFrequency: toNumberOrZero(paymentFrequency) || state.purchaseMethod.paymentFrequency,
      term: toNumberOrZero(term) || state.purchaseMethod.term,
      cashDown: toNumberOrZero(cashDown)
    }, omitBy({
      purchaseMethod
    }, isNil)),
    tradeIn: _objectSpread(_objectSpread({}, (0, _selectors.getTradeIn)(state)), {}, {
      tradeIn: toNumberOrZero(tradeIn),
      lien: toNumberOrZero(lien)
    }),
    hasTaxesIncluded
  });
};
exports.default = _default;