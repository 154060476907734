"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _selectors = require("../selectors");
var _actions = require("../actions");
var _quoteChanges = require("../../constants/quoteChanges");
var _api = require("./api");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function* _default(payload) {
  const accountId = yield (0, _effects.select)(_selectors.getAccountId);
  const dates = yield (0, _effects.select)(_selectors.getFinancingDates);
  const quote = yield (0, _effects.call)(_api.addVehicleToGarage, payload.selectedVehicle);
  if (quote) {
    const newQuote = yield (0, _effects.call)(_api.loadQuote, quote.id);
    const {
      id,
      quoteRevision
    } = newQuote;
    const body = {
      quoteId: id,
      accountId,
      quoteRevision
    };
    yield (0, _effects.fork)(_api.setPreferredQuote, body);

    /** Get dates from previous quote and apply same dates to new selected quote */
    yield (0, _effects.put)(_actions.Creators.setFinancingDates(_objectSpread({}, dates)));
    yield (0, _effects.put)(_actions.Creators.setFinancingDatesChanged(true));
    yield (0, _effects.put)(_actions.Creators.setQuoteChanged(false, _quoteChanges.QUOTE_CHANGES_TYPE.MODIFY_QUOTE));
    yield (0, _effects.put)(_actions.Creators.updateQuoteInformation());
  }
}