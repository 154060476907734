"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submitContract = exports.saveContract = exports.getContractDecision = exports.getContract = exports.default = void 0;
var _appsCore = require("@sm360/apps-core");
const SHOWROOM_API_URL = process.env.REACT_APP_SHOWROOM_API_URL;
const getContract = async (quoteId, orgId, orgUnitId, location, language, creditPortalId) => {
  /* Gets the credit application submission types for a specified quote */
  try {
    const response = await _appsCore.axios.get("".concat(SHOWROOM_API_URL, "/lead/contractValidation/customFields/").concat(quoteId, "?") + "quoteId=".concat(quoteId, "&") + "orgId=".concat(orgId, "&") + "orgUnitId=".concat(orgUnitId, "&") + "location=".concat(location, "&") + "lang=".concat(language, "&") + "creditPortalId=".concat(creditPortalId), {
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
    // ContractValidationSteps
    return response ? response.data : null;
  } catch (error) {
    throw new Error("Failed getting contract-validation for quoteId ".concat(quoteId));
  }
};
exports.getContract = getContract;
const getContractDecision = async (quoteId, orgId, orgUnitId, location, language, creditPortalDecisionHistory) => {
  /* Loads contract validationn decisions and communications for a specified lead */
  try {
    const response = await _appsCore.axios.get("".concat(SHOWROOM_API_URL, "/lead/contractValidation/decision/").concat(quoteId, "?") + "quoteId=".concat(quoteId, "&") + "orgId=".concat(orgId, "&") + "orgUnitId=".concat(orgUnitId, "&") + "location=".concat(location, "&") + "language=".concat(language, "&") + "creditPortalDecisionHistory=".concat(creditPortalDecisionHistory), {
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
    /* ContractValidationDecision
        id: string
        contractId: string
        status: ['Pending', 'Passed', 'Failed', 'Error']
        submittedDateTime: number
        decisionDateTime: number
        contractNumber: string
        communications: array cf. ContractValidationDecisionDetails'
        errors: array[string]
    */

    return response ? response.data : null;
  } catch (error) {
    throw new Error("Failed getting contract-validation for quoteId ".concat(quoteId));
  }
};
exports.getContractDecision = getContractDecision;
const saveContract = async (contract, quoteId, orgId, orgUnitId, location, language, creditPortalId) => {
  /* Saves contract validation for a specified quote */
  if (!contract) {
    throw new Error('No contract-validation to create.');
  }
  try {
    const response = await _appsCore.axios.put("".concat(SHOWROOM_API_URL, "/lead/contractValidation/customFields/").concat(quoteId) + "?quoteId=".concat(quoteId) + "&orgId=".concat(orgId) + "&orgUnitId=".concat(orgUnitId) + "&location=".concat(location) + "&language=".concat(language) + "&creditPortalId=".concat(creditPortalId), contract, {
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
    // '204' Successful operation
    return response ? response.data : null;
  } catch (error) {
    throw new Error("Failed saving for contract-validation with quoteId ".concat(quoteId));
  }
};
exports.saveContract = saveContract;
const submitContract = async (body, quoteId, orgId, orgUnitId, location, language, creditPortalId) => {
  /* Submits contract validation for a specified quote */
  try {
    const response = await _appsCore.axios.put("".concat(SHOWROOM_API_URL, "/lead/contractValidation/submission/").concat(quoteId) + "?quoteId=".concat(quoteId) + "&orgId=".concat(orgId) + "&orgUnitId=".concat(orgUnitId) + "&location=".concat(location) + "&language=".concat(language) + "&creditPortalId=".concat(creditPortalId), body, {
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
    // '204' Successful operation
    return response ? response.data : null;
  } catch (error) {
    var _error$response;
    if ((_error$response = error.response) !== null && _error$response !== void 0 && (_error$response = _error$response.data) !== null && _error$response !== void 0 && _error$response.errMsg) {
      throw new Error(error.response.data.errMsg);
    } else {
      throw new Error("Failed submiting for contract-validation with quoteId ".concat(quoteId));
    }
  }
};
exports.submitContract = submitContract;
var _default = exports.default = {
  getContract,
  getContractDecision,
  saveContract,
  submitContract
};