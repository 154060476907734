"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _uiLibrary = require("@sm360/ui-library");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 */
function _default(_ref) {
  let {
    creditPortalId,
    creditPortalFormIds
  } = _ref;
  return function* () {
    const t = yield (0, _effects.getContext)('t');
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdating());
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
    try {
      const customFields = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.getContractValidationCustomFields, quoteId, _objectSpread(_objectSpread({}, queryParams), {}, {
        creditPortalId
      }));
      const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.submitDealJacketForms, quoteId, _objectSpread(_objectSpread({}, queryParams), {}, {
        formSubmissions: true
      }), _objectSpread(_objectSpread({}, customFields !== null && customFields !== void 0 ? customFields : {}), {}, {
        formSubmissions: creditPortalFormIds
      }));
      if (response) {
        var _response$data;
        if ((_response$data = response.data) !== null && _response$data !== void 0 && _response$data.errMsg) {
          _uiLibrary.Toast.toast.error(response.data.errMsg);
        } else if (response.errMsg) {
          _uiLibrary.Toast.toast.error(response.errMsg);
        } else {
          _uiLibrary.Toast.toast.success(t('deskingDesking.forms.toasts.successfulSubmission'));
        }
      }
      yield (0, _effects.delay)(2000);
      yield (0, _effects.put)(_actions.Creators.reloadTabForms());
    } catch (error) {
      _uiLibrary.Toast.toast.error("".concat(error));
      yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
    }
  }();
}