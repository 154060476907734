"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPaymentOptions = exports.getPaymentBarData = exports.getMetadata = exports.getIsLeaseSelected = exports.getIsCashSelected = void 0;
Object.defineProperty(exports, "getPurchaseMethod", {
  enumerable: true,
  get: function () {
    return _selectors.getPurchaseMethod;
  }
});
exports.getSelectedPurchaseMethodIndex = exports.getPurchaseMethodList = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _selectors = require("../selectors");
var _payment = require("../../constants/payment");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * @param {Object} state
 * @returns {Object}
 */
const getPaymentOptions = state => {
  const {
    paymentOptions
  } = state;
  return (0, _commonsUtils.isPlainObject)(paymentOptions) ? paymentOptions : {};
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getPaymentOptions = getPaymentOptions;
const getMetadata = state => {
  const {
    metadata
  } = getPaymentOptions(state);
  return Array.isArray(metadata) ? metadata : [];
};

/**
 * @param {Object} state
 * @param {String} method
 * @returns {Object}
 */
exports.getMetadata = getMetadata;
const getFinanceMetaData = (state, method) => {
  const metaData = getMetadata(state);
  const found = metaData.find(_ref => {
    let {
      purchaseMethod
    } = _ref;
    return purchaseMethod === method;
  });
  return (0, _commonsUtils.isPlainObject)(found) ? found : {};
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
const getIsCashSelected = state => {
  const paymentOptions = getPaymentOptions(state);
  const {
    purchaseMethod
  } = paymentOptions;
  return purchaseMethod === _payment.PURCHASE_METHOD_CASH;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsCashSelected = getIsCashSelected;
const getIsLeaseSelected = state => {
  const paymentOptions = getPaymentOptions(state);
  const {
    purchaseMethod
  } = paymentOptions;
  return purchaseMethod === _payment.PURCHASE_METHOD_LEASE;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsLeaseSelected = getIsLeaseSelected;
const getSelectedPurchaseMethodIndex = state => {
  const paymentOptions = getPaymentOptions(state);
  const {
    purchaseMethod
  } = paymentOptions;
  switch (purchaseMethod) {
    case _payment.PURCHASE_METHOD_CASH:
      return 0;
    case _payment.PURCHASE_METHOD_FINANCE:
      return 1;
    case _payment.PURCHASE_METHOD_LEASE:
      return 2;
    default:
      throw new Error('Purchase method is not valid');
  }
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getSelectedPurchaseMethodIndex = getSelectedPurchaseMethodIndex;
const getPurchaseMethodList = state => {
  const {
    purchaseMethod: selectedPurchaseMethod
  } = getPaymentOptions(state);
  const metadata = getMetadata(state);

  // Cash always available
  const list = [{
    name: _payment.PURCHASE_METHOD_CASH,
    isAvailable: true,
    isSelected: selectedPurchaseMethod === _payment.PURCHASE_METHOD_CASH
  }];
  let found;
  let item;
  [_payment.PURCHASE_METHOD_FINANCE, _payment.PURCHASE_METHOD_LEASE].forEach(name => {
    item = {
      name,
      isAvailable: false,
      isSelected: false
    };
    found = metadata.find(_ref2 => {
      let {
        purchaseMethod
      } = _ref2;
      return purchaseMethod === name;
    });
    if (!(0, _commonsUtils.isPlainObject)(found)) {
      list.push(item);
      return;
    }
    const {
      isAvailable
    } = found;
    item.isAvailable = isAvailable;
    item.isSelected = selectedPurchaseMethod === name;
    list.push(item);
  });
  return list;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getPurchaseMethodList = getPurchaseMethodList;
const getCashPurchase = state => {
  const {
    cashPurchase
  } = getPaymentOptions(state);
  return (0, _commonsUtils.isPlainObject)(cashPurchase) ? cashPurchase : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getCashPurchaseData = state => {
  const {
    cashDown
  } = getPaymentOptions(state);
  const {
    lien,
    tradeIn,
    sellingPrice: payment
  } = getCashPurchase(state);
  return {
    cashDown,
    lien,
    payment,
    tradeIn
  };
};

/**
 * For finance and lease
 *
 * @param {Object} state
 * @returns {Object}
 */
const getFinanceData = state => {
  const {
    cashDown,
    kmPerYearPlan,
    lien,
    paymentFrequency,
    term,
    tradeIn
  } = getPaymentOptions(state);
  return {
    cashDown,
    kmPerYearPlan,
    lien,
    paymentFrequency,
    term,
    tradeIn
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getPaymentBarData = state => {
  const purchaseMethod = (0, _selectors.getPurchaseMethod)(state);
  let selector;
  let kmPerYearPlans = [];
  let paymentFrequencies = [];
  let terms = [];
  switch (purchaseMethod) {
    case _payment.PURCHASE_METHOD_CASH:
      selector = getCashPurchaseData;
      break;
    case _payment.PURCHASE_METHOD_FINANCE:
    case _payment.PURCHASE_METHOD_LEASE:
      selector = getFinanceData;
      ({
        kmPerYearPlans,
        paymentFrequencies,
        terms
      } = getFinanceMetaData(state, purchaseMethod));
      break;
    default:
      throw new TypeError('The stored purchase method is incorrect.');
  }
  return _objectSpread({
    isCashSelected: getIsCashSelected(state),
    kmPerYearPlans,
    paymentFrequencies,
    purchaseMethod,
    terms
  }, selector.call(null, state));
};
exports.getPaymentBarData = getPaymentBarData;