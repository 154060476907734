"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.INITIAL_STATE = void 0;
var _reduxsauce = require("reduxsauce");
var _deskingError = require("@sm360/desking-error");
var _deskingCommons = require("@sm360/desking-commons");
var _filters = require("../constants/filters");
var _config = require("../constants/config");
var _vehicles = require("../constants/vehicles");
var _actions = require("./actions");
var _reducers = _interopRequireDefault(require("./reducers"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const INITIAL_STATE = exports.INITIAL_STATE = _objectSpread(_objectSpread(_objectSpread({}, _deskingError.errorInitialState), _deskingCommons.priceIncreaseInitialState), {}, {
  isLoading: true,
  // Config, values who can't be changed, only populated server side (initializeStore action)
  config: {
    accountId: 0,
    lang: 'string',
    leadId: 0,
    location: 'string',
    organizationId: 0,
    organizationUnitId: 0
  },
  permissions: {
    hasDeskingAccess: false,
    isHidingCatalogPrices: false
  },
  // Filtering the vehicle list
  filters: {
    category: _vehicles.CATEGORY_USED,
    searchText: '',
    priceRange: {
      minPrice: _filters.MIN_PRICE,
      maxPrice: _filters.MAX_PRICE,
      selectedMaxPrice: _filters.MAX_PRICE
    },
    odometerRange: {
      minOdometer: _filters.MIN_ODOMETER,
      maxOdometer: _filters.MAX_ODOMETER,
      selectedMaxOdometer: _filters.MAX_ODOMETER
    },
    onlineVehiclesOnly: false,
    dealerVehiclesOnly: false,
    // Dropdown available choices
    options: {
      [_filters.KEYS.BODY]: [],
      [_filters.KEYS.DRIVE]: [],
      [_filters.KEYS.ENGINE]: [],
      [_filters.KEYS.EXTERNAL_COLOR]: [],
      [_filters.KEYS.FUEL]: [],
      [_filters.KEYS.INTERNAL_COLOR]: [],
      [_filters.KEYS.MAKE]: [],
      [_filters.KEYS.MODEL]: [],
      [_filters.KEYS.TRIM]: [],
      [_filters.KEYS.TRANSMISSION]: [],
      [_filters.KEYS.YEAR]: []
    },
    // Current selection of the user
    selected: {
      [_filters.KEYS.BODY]: [],
      [_filters.KEYS.DRIVE]: [],
      [_filters.KEYS.ENGINE]: [],
      [_filters.KEYS.EXTERNAL_COLOR]: [],
      [_filters.KEYS.FUEL]: [],
      [_filters.KEYS.INTERNAL_COLOR]: [],
      [_filters.KEYS.MAKE]: [],
      [_filters.KEYS.MODEL]: [],
      [_filters.KEYS.TRIM]: [],
      [_filters.KEYS.TRANSMISSION]: [],
      [_filters.KEYS.YEAR]: []
    }
  },
  sort: {
    vehicleSortParameter: _filters.SORT_TYPE.DATE,
    direction: _filters.SORT_DIR.ASC
  },
  // Pagination
  pagination: {
    pageNumber: 1,
    pageSize: _config.PAGE_SIZE,
    totalElements: 0
  },
  // Payment options
  paymentOptions: {
    cashDown: 0,
    financePlan: '',
    kmPerYearPlan: 0,
    lien: 0,
    metadata: [],
    paymentFrequency: 0,
    purchaseMethod: 'cash',
    saleType: 'retail',
    taxPlan: 'standard',
    term: 0,
    tradeIn: 0
  },
  // List of vehicles
  vehicles: [],
  addedVehicles: [],
  hasTaxesIncluded: true,
  dealersList: []
});
var _default = exports.default = (0, _reduxsauce.createReducer)(INITIAL_STATE, _objectSpread(_objectSpread(_objectSpread({}, _deskingError.errorReducers), _deskingCommons.priceIncreaseReducers), {}, {
  [_actions.Types.SET_LOADING_SUCCESS]: _reducers.default.setLoadingSuccess,
  [_actions.Types.SELECT_VEHICLE_SUCCESS]: _reducers.default.selectVehicleSuccess,
  [_actions.Types.INITIALIZE_STORE]: _reducers.default.initializeStore,
  [_actions.Types.LOAD_VEHICLES_SUCCESS]: _reducers.default.loadVehiclesSuccess,
  [_actions.Types.SET_CURRENT_PAGE]: _reducers.default.setCurrentPage,
  [_actions.Types.SET_PERMISSION]: _reducers.default.setPermission,
  [_actions.Types.SET_LOCATION]: _reducers.default.setLocation,
  [_actions.Types.SET_LEAD_INFO]: _reducers.default.setLeadInfo,
  [_actions.Types.SET_DEALERS_LIST]: _reducers.default.setDealersList,
  [_actions.Types.SET_ORGANIZATION_UNIT_ID]: _reducers.default.setOrganizationUnitId,
  // Payment options
  [_actions.Types.UPDATE_CASH_DOWN]: _reducers.default.updateCashDown,
  [_actions.Types.UPDATE_LIEN]: _reducers.default.updateLien,
  [_actions.Types.UPDATE_TRADE_IN]: _reducers.default.updateTradeIn,
  [_actions.Types.UPDATE_PAYMENT_FREQUENCY]: _reducers.default.updatePaymentFrequency,
  [_actions.Types.UPDATE_TERM]: _reducers.default.updateTerm,
  [_actions.Types.UPDATE_KM_PLAN]: _reducers.default.updateKmPlan,
  [_actions.Types.SELECT_PURCHASE_METHOD]: _reducers.default.selectPurchaseMethod,
  [_actions.Types.UPDATE_TAXES_INCLUDED]: _reducers.default.updateTaxesIncluded,
  [_actions.Types.SET_PAYMENT_OPTIONS_METADATA]: _reducers.default.setPaymentOptionsMetadata,
  // Filters
  [_actions.Types.APPLY_SEARCH_TEXT]: _reducers.default.updateSearchText,
  [_actions.Types.LOAD_INVENTORY_FILTERS_SUCCESS]: _reducers.default.loadInventoryFiltersSuccess,
  [_actions.Types.RESET_FILTERS]: _reducers.default.resetFilters,
  [_actions.Types.SORT_VEHICLES]: _reducers.default.sortVehicles,
  [_actions.Types.UPDATE_CATEGORY]: _reducers.default.updateCategory,
  [_actions.Types.UPDATE_FILTER_OPTIONS]: _reducers.default.updateFilterOptions,
  [_actions.Types.UPDATE_SEARCH_TEXT]: _reducers.default.updateSearchText,
  [_actions.Types.UPDATE_PRICE_RANGE]: _reducers.default.updatePriceRange,
  [_actions.Types.UPDATE_ODOMETER_RANGE]: _reducers.default.updateOdometerRange,
  [_actions.Types.SET_ONLINE_VEHICLES_ONLY]: _reducers.default.setOnlineVehiclesOnly,
  [_actions.Types.SET_DEALER_VEHICLES_ONLY]: _reducers.default.setDealerVehiclesOnly
}));