"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTaskTypes = exports.getEmailByActivityId = exports.getAppointmentTypes = exports.getActivityTypes = exports.getActivity = void 0;
var _request = require("../../tools/request");
const CRM_API_URL = process.env.REACT_APP_CRM_API_URL;

/**
 * @param {String} activityId
 * @returns {Promise}
 */
const getActivity = activityId => {
  return (0, _request.get)("".concat(CRM_API_URL, "/activities/").concat(activityId), null, null, 'getActivity');
};
/**
 * @returns {Promise}
 */
exports.getActivity = getActivity;
const getActivityTypes = () => {
  return (0, _request.get)("".concat(CRM_API_URL, "/activities/static/activityTypesInfo"), null, null, 'getActivityTypes');
};

/**
 * @param {String} activityId
 * @returns {Promise}
 */
exports.getActivityTypes = getActivityTypes;
const getEmailByActivityId = activityId => {
  return (0, _request.get)("".concat(CRM_API_URL, "/mails?activityId=").concat(activityId), null, null, 'getEmailByActivityId');
};

/**
 * @returns {Promise}
 */
exports.getEmailByActivityId = getEmailByActivityId;
const getAppointmentTypes = () => {
  return (0, _request.get)("".concat(CRM_API_URL, "/activities/appointments/types"), null, null, 'getAppointmentTypes');
};

/**
 * @returns {Promise}
 */
exports.getAppointmentTypes = getAppointmentTypes;
const getTaskTypes = () => {
  return (0, _request.get)("".concat(CRM_API_URL, "/tasks/types"), null, null, 'getTaskTypes');
};
exports.getTaskTypes = getTaskTypes;