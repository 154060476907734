"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCustomer = exports.default = void 0;
var _appsCore = require("@sm360/apps-core");
var _request = require("../../../tools/request");
const SHOWROOM_API_URL = process.env.REACT_APP_SHOWROOM_API_URL;
const urlPrefix = 'quote/desking';
const urlTransactionPrefix = 'quote/desking/transaction';

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @param {Object} body
 * @param {String} urlPart
 * @returns {Promise}
 */
const basePostRequest = (quoteId, queryParams, body, urlPart) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/").concat(urlPart, "/").concat(quoteId);
  return (0, _request.post)(url, body, {
    params: queryParams
  }, true);
};
const basePostRequestTransaction = (quoteId, queryParams, body, urlPart) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlTransactionPrefix, "/").concat(urlPart, "/").concat(quoteId);
  return (0, _request.post)(url, body, {
    params: queryParams
  }, true);
};
const addItem = function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  return basePostRequest(...args, 'transaction/addItem');
};
const aprRoll = function () {
  for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    args[_key2] = arguments[_key2];
  }
  return basePostRequest(...args, 'aprRoll');
};
const validateNegativeEquity = function () {
  for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
    args[_key3] = arguments[_key3];
  }
  return basePostRequest(...args, 'transaction/negativeEquityValidation');
};
const getCommissionDetails = function () {
  for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
    args[_key4] = arguments[_key4];
  }
  return basePostRequest(...args, 'commission');
};
const getResidualDetails = function () {
  for (var _len5 = arguments.length, args = new Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
    args[_key5] = arguments[_key5];
  }
  return basePostRequest(...args, 'residual');
};
const getTransactionItems = function () {
  for (var _len6 = arguments.length, args = new Array(_len6), _key6 = 0; _key6 < _len6; _key6++) {
    args[_key6] = arguments[_key6];
  }
  return basePostRequest(...args, 'transaction/items');
};
const getFNIItems = function () {
  for (var _len7 = arguments.length, args = new Array(_len7), _key7 = 0; _key7 < _len7; _key7++) {
    args[_key7] = arguments[_key7];
  }
  return basePostRequest(...args, 'transaction/fni/items');
};
const paymentRoll = function () {
  for (var _len8 = arguments.length, args = new Array(_len8), _key8 = 0; _key8 < _len8; _key8++) {
    args[_key8] = arguments[_key8];
  }
  return basePostRequest(...args, 'paymentRoll');
};
const profitRoll = function () {
  for (var _len9 = arguments.length, args = new Array(_len9), _key9 = 0; _key9 < _len9; _key9++) {
    args[_key9] = arguments[_key9];
  }
  return basePostRequest(...args, 'profitRoll');
};
const blendedRateRoll = function () {
  for (var _len10 = arguments.length, args = new Array(_len10), _key10 = 0; _key10 < _len10; _key10++) {
    args[_key10] = arguments[_key10];
  }
  return basePostRequest(...args, 'blendedRateRoll');
};
const dueAtDeliveryRoll = function () {
  for (var _len11 = arguments.length, args = new Array(_len11), _key11 = 0; _key11 < _len11; _key11++) {
    args[_key11] = arguments[_key11];
  }
  return basePostRequest(...args, 'dueAtDeliveryRoll');
};
const totalToPayRoll = function () {
  for (var _len12 = arguments.length, args = new Array(_len12), _key12 = 0; _key12 < _len12; _key12++) {
    args[_key12] = arguments[_key12];
  }
  return basePostRequest(...args, 'totalToPayRoll');
};
const msdRoll = function () {
  for (var _len13 = arguments.length, args = new Array(_len13), _key13 = 0; _key13 < _len13; _key13++) {
    args[_key13] = arguments[_key13];
  }
  return basePostRequest(...args, 'msdRoll');
};
const residualRoll = function () {
  for (var _len14 = arguments.length, args = new Array(_len14), _key14 = 0; _key14 < _len14; _key14++) {
    args[_key14] = arguments[_key14];
  }
  return basePostRequest(...args, 'residualRoll');
};
const updateFinancingDates = function () {
  for (var _len15 = arguments.length, args = new Array(_len15), _key15 = 0; _key15 < _len15; _key15++) {
    args[_key15] = arguments[_key15];
  }
  return basePostRequest(...args, 'setDates');
};
const updatePaymentOptions = function () {
  for (var _len16 = arguments.length, args = new Array(_len16), _key16 = 0; _key16 < _len16; _key16++) {
    args[_key16] = arguments[_key16];
  }
  return basePostRequest(...args, 'paymentOptions');
};
const updateTransactionDetails = function () {
  for (var _len17 = arguments.length, args = new Array(_len17), _key17 = 0; _key17 < _len17; _key17++) {
    args[_key17] = arguments[_key17];
  }
  return basePostRequest(...args, 'transaction');
};
const validateResidual = function () {
  for (var _len18 = arguments.length, args = new Array(_len18), _key18 = 0; _key18 < _len18; _key18++) {
    args[_key18] = arguments[_key18];
  }
  return basePostRequest(...args, 'transaction/residualValidation');
};
const validateTaxableAmount = function () {
  for (var _len19 = arguments.length, args = new Array(_len19), _key19 = 0; _key19 < _len19; _key19++) {
    args[_key19] = arguments[_key19];
  }
  return basePostRequest(...args, 'transaction/taxableAmountValidation');
};
const validateTransactionAmount = function () {
  for (var _len20 = arguments.length, args = new Array(_len20), _key20 = 0; _key20 < _len20; _key20++) {
    args[_key20] = arguments[_key20];
  }
  return basePostRequest(...args, 'transaction/amountValidation');
};
const validateCommissionAmount = function () {
  for (var _len21 = arguments.length, args = new Array(_len21), _key21 = 0; _key21 < _len21; _key21++) {
    args[_key21] = arguments[_key21];
  }
  return basePostRequest(...args, 'commission/amountValidation');
};
const addCommissionAdjustment = function () {
  for (var _len22 = arguments.length, args = new Array(_len22), _key22 = 0; _key22 < _len22; _key22++) {
    args[_key22] = arguments[_key22];
  }
  return basePostRequest(...args, 'commission/addAdjustment');
};
const deleteCommissionAdjustment = function () {
  for (var _len23 = arguments.length, args = new Array(_len23), _key23 = 0; _key23 < _len23; _key23++) {
    args[_key23] = arguments[_key23];
  }
  return basePostRequest(...args, 'commission/deleteAdjustment');
};
const validateTransactionItem = function () {
  for (var _len24 = arguments.length, args = new Array(_len24), _key24 = 0; _key24 < _len24; _key24++) {
    args[_key24] = arguments[_key24];
  }
  return basePostRequest(...args, 'transaction/itemValidation');
};
const validateFNIItem = function () {
  for (var _len25 = arguments.length, args = new Array(_len25), _key25 = 0; _key25 < _len25; _key25++) {
    args[_key25] = arguments[_key25];
  }
  return basePostRequest(...args, 'transaction/fni/itemValidation');
};
const bookFNIItems = function () {
  for (var _len26 = arguments.length, args = new Array(_len26), _key26 = 0; _key26 < _len26; _key26++) {
    args[_key26] = arguments[_key26];
  }
  return basePostRequest(...args, 'transaction/fni/bookItems');
};
const setQuoteFlags = function () {
  for (var _len27 = arguments.length, args = new Array(_len27), _key27 = 0; _key27 < _len27; _key27++) {
    args[_key27] = arguments[_key27];
  }
  return basePostRequest(...args, 'setQuoteFlags');
};
const setItemFlags = function () {
  for (var _len28 = arguments.length, args = new Array(_len28), _key28 = 0; _key28 < _len28; _key28++) {
    args[_key28] = arguments[_key28];
  }
  return basePostRequestTransaction(...args, 'setItemFlags');
};

/**
 * @param {String} quoteId
 * @param {String} formInstanceId
 * @param {Object} queryParams
 * @returns {Promise}
 */
const getTransactionPdfCustomFields = async (quoteId, formInstanceId, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/form/customFields/").concat(quoteId, "/").concat(formInstanceId);
  return await (0, _request.get)(url, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
const getTransactionPdfList = async (quoteId, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/forms/").concat(quoteId);
  return await (0, _request.get)(url, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {String} formInstanceId
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
const transactionPdfRequest = (quoteId, formInstanceId, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/form/").concat(quoteId, "/").concat(formInstanceId);
  return (0, _request.post)(url, body, {
    params: queryParams,
    responseType: 'arraybuffer'
  }, true);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
const updateQuoteInformation = (quoteId, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/").concat(quoteId);
  return (0, _request.put)(url, body, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
const getAccTemplates = (quoteId, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/quote/desking/accounting/").concat(quoteId);
  return (0, _request.get)(url, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {String} templateId
 * @param {Object} queryParams
 * @returns {Promise}
 */
const getAccTemplateData = (quoteId, templateId, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/quote/desking/accounting/").concat(quoteId, "/").concat(templateId);
  return (0, _request.get)(url, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
const getLocationOutsideProvince = (quoteId, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/quote/desking/location/").concat(quoteId);
  return (0, _request.get)(url, {
    params: queryParams
  }, true);
};

/**
 * @param {String} leadId
 * @param {Object} queryParams
 * @returns {Promise}
 */
const getCustomer = (leadId, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/lead/customer/").concat(leadId);
  return (0, _request.get)(url, {
    params: queryParams
  }, true);
};

/**
 * @param {String} leadId
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */

const updateCustomer = (leadId, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/lead/customer/").concat(leadId);
  return (0, _request.put)(url, body, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.updateCustomer = updateCustomer;
const getCreditApplicationDecision = (quoteId, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/lead/creditApplication/decision/").concat(quoteId);
  return (0, _request.get)(url, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
const getContractValidationDecision = (quoteId, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/lead/contractValidation/decision/").concat(quoteId);
  return (0, _request.get)(url, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
const sendQuoteToSerti = (quoteId, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/quote/desking/dmsDeal/").concat(quoteId);
  return (0, _request.put)(url, {}, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
const submitRDR = (quoteId, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/quote/desking/oemRDR/").concat(quoteId);
  return (0, _request.put)(url, {}, {
    params: queryParams
  }, true);
};

/**
 * @param {Object} body
 * @returns {Promise}
 */
const createDealFromQuote = body => {
  const url = "".concat(SHOWROOM_API_URL, "/deals/createDealFromQuote");
  return (0, _request.post)(url, body);
};

/**
 * @param {String} quoteId
 * @param {String} orgUnitId
 * @returns {Promise}
 */
const deleteDeal = (quoteId, orgUnitId) => {
  const url = "".concat(SHOWROOM_API_URL, "/deals/deleteByQuoteId/").concat(quoteId, "?organizationUnitId=").concat(orgUnitId);
  return (0, _request.post)(url);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
const getDealJacket = async (quoteId, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/dealJacket/").concat(quoteId);
  return (0, _request.get)(url, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
const updateDealJacket = async (quoteId, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/dealJacket/").concat(quoteId);
  try {
    const response = await _appsCore.axios.put(url, body, {
      params: queryParams,
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
    return response ? response.data : null;
  } catch (error) {
    var _error$response;
    if ((_error$response = error.response) !== null && _error$response !== void 0 && (_error$response = _error$response.data) !== null && _error$response !== void 0 && _error$response.errMsg) {
      throw new Error(error.response.data.errMsg);
    } else {
      throw new Error("Forms update failed");
    }
  }
};

/**
 * @param {String} uploadedFormId
 * @param {Object} queryParams
 * @returns {Promise}
 */
const loadDealJacketForm = async (uploadedFormId, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/dealJacket/form/").concat(uploadedFormId);
  return (0, _request.get)(url, {
    params: queryParams,
    responseType: 'arraybuffer',
    fullResponse: true
  }, true);
};

/**
 * @param {String} quoteId
 * @param {String} formInstanceId
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
const uploadDealJacketForm = async (quoteId, formInstanceId, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/dealJacket/uploadForm/").concat(quoteId, "/").concat(formInstanceId);
  try {
    const response = await _appsCore.axios.put(url, body, {
      params: queryParams,
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
    return response ? response.data : null;
  } catch (error) {
    var _error$response2;
    if ((_error$response2 = error.response) !== null && _error$response2 !== void 0 && (_error$response2 = _error$response2.data) !== null && _error$response2 !== void 0 && _error$response2.errMsg) {
      throw new Error(error.response.data.errMsg);
    } else {
      throw new Error("Form upload failed");
    }
  }
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
const addDealJacketForm = async (quoteId, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/dealJacket/addForm/").concat(quoteId);
  try {
    const response = await _appsCore.axios.put(url, body, {
      params: queryParams,
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
    return response ? response.data : null;
  } catch (error) {
    var _error$response3;
    if ((_error$response3 = error.response) !== null && _error$response3 !== void 0 && (_error$response3 = _error$response3.data) !== null && _error$response3 !== void 0 && _error$response3.errMsg) {
      throw new Error(error.response.data.errMsg);
    } else {
      throw new Error("Form creation failed");
    }
  }
};

/**
 * @param {String} quoteId
 * @param {String} uploadedFormId
 * @param {Object} queryParams
 * @returns {Promise}
 */
const deleteDealJacketForm = async (quoteId, uploadedFormId, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/dealJacket/deleteForm/").concat(quoteId, "/").concat(uploadedFormId);
  try {
    const response = await _appsCore.axios.delete(url, {
      params: queryParams,
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
    return response ? response.data : null;
  } catch (error) {
    var _error$response4;
    if ((_error$response4 = error.response) !== null && _error$response4 !== void 0 && (_error$response4 = _error$response4.data) !== null && _error$response4 !== void 0 && _error$response4.errMsg) {
      throw new Error(error.response.data.errMsg);
    } else {
      throw new Error("Form deletion failed");
    }
  }
};

/**
 * @param {String} quoteId
 * @param {String[]} uploadedFormsIds
 * @param {Object} queryParams
 * @returns {Promise}
 */
const deleteDealJacketForms = async (quoteId, uploadedFormsIds, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/dealJacket/deleteForm/").concat(quoteId);
  try {
    const response = await _appsCore.axios.delete(url, {
      uploadedFormsIds,
      params: queryParams,
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
    return response ? response.data : null;
  } catch (error) {
    var _error$response5;
    if ((_error$response5 = error.response) !== null && _error$response5 !== void 0 && (_error$response5 = _error$response5.data) !== null && _error$response5 !== void 0 && _error$response5.errMsg) {
      throw new Error(error.response.data.errMsg);
    } else {
      throw new Error("Forms deletion failed");
    }
  }
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
const getContractValidationCustomFields = async (quoteId, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/lead/contractValidation/customFields/").concat(quoteId);
  return (0, _request.get)(url, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
const submitDealJacketForms = async (quoteId, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/lead/contractValidation/submission/").concat(quoteId);
  try {
    const response = await _appsCore.axios.put(url, body, {
      params: queryParams,
      useJwt: true,
      ignoreAppsCoreCatch: true
    });
    return response ? response.data : null;
  } catch (error) {
    var _error$response6;
    if ((_error$response6 = error.response) !== null && _error$response6 !== void 0 && (_error$response6 = _error$response6.data) !== null && _error$response6 !== void 0 && _error$response6.errMsg) {
      throw new Error(error.response.data.errMsg);
    } else {
      throw new Error("Forms submission failed");
    }
  }
};
var _default = exports.default = {
  aprRoll,
  validateNegativeEquity,
  getCommissionDetails,
  getResidualDetails,
  getTransactionItems,
  getFNIItems,
  getTransactionPdfCustomFields,
  getTransactionPdfList,
  addItem,
  paymentRoll,
  profitRoll,
  dueAtDeliveryRoll,
  totalToPayRoll,
  msdRoll,
  residualRoll,
  blendedRateRoll,
  transactionPdfRequest,
  updateFinancingDates,
  updatePaymentOptions,
  updateQuoteInformation,
  updateTransactionDetails,
  validateResidual,
  validateTaxableAmount,
  validateTransactionAmount,
  validateCommissionAmount,
  addCommissionAdjustment,
  deleteCommissionAdjustment,
  validateTransactionItem,
  validateFNIItem,
  bookFNIItems,
  getAccTemplates,
  getAccTemplateData,
  getLocationOutsideProvince,
  getCustomer,
  updateCustomer,
  getCreditApplicationDecision,
  getContractValidationDecision,
  setQuoteFlags,
  setItemFlags,
  sendQuoteToSerti,
  submitRDR,
  createDealFromQuote,
  deleteDeal,
  getDealJacket,
  updateDealJacket,
  loadDealJacketForm,
  uploadDealJacketForm,
  addDealJacketForm,
  deleteDealJacketForm,
  deleteDealJacketForms,
  getContractValidationCustomFields,
  submitDealJacketForms
};