"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateInitialAnswers = exports.generateConditionFormModel = void 0;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const getModelType = CBBType => {
  switch (CBBType) {
    case '$':
      return 'currency';
    default:
      return 'radio';
  }
};
const getShortCode = code => {
  switch (code) {
    case 'Y':
      return true;
    case 'N':
      return false;
    default:
      return code;
  }
};

/**
 * Recursive function generating an object containing each question
 * @param {*} questions      : list of questions
 * @param {*} parentQuestion : parent question of the list of questions
 */
const generateQuestion = (questions, parentQuestion) => {
  return _objectSpread({}, questions.reduce((acc, q) => {
    // Get the parent's answer having showChildren == true
    const showChildrenAnswer = parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.possibleAnswers.find(a => a.showChildren);
    const parentLinkCode = showChildrenAnswer ? {
      onlyFor: getShortCode(showChildrenAnswer.answerCode)
    } : {};
    return _objectSpread(_objectSpread({}, acc), {
      [q.questionId]: {
        type: getModelType(q.type),
        labelKey: q.questionText,
        linkToParent: parentLinkCode,
        choices: q.possibleAnswers.map(a => {
          const code = a.answerCode;
          return {
            value: getShortCode(code),
            labelKey: a.answer
          };
        }),
        // Sub-questions
        questions: q !== null && q !== void 0 && q.childQuestions ? generateQuestion(q.childQuestions, q) : {}
      }
    });
  }, {}));
};
const generateInitialAnswers = questions => {
  return _objectSpread({}, questions.reduce((acc, q) => {
    let childAnswers = {};
    if (q !== null && q !== void 0 && q.childQuestions) {
      childAnswers = generateInitialAnswers(q.childQuestions);
    }
    return _objectSpread(_objectSpread(_objectSpread({}, childAnswers), acc), {}, {
      [q.questionId]: getShortCode(q.answerCode)
    });
  }, {}));
};
exports.generateInitialAnswers = generateInitialAnswers;
const generateConditionFormModel = CBBQuestions => {
  const formattedModel = {
    iconName: 'rapport',
    sections: {
      section1: {
        questions: generateQuestion(CBBQuestions)
      }
    }
  };
  return formattedModel;
};
exports.generateConditionFormModel = generateConditionFormModel;