"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiSchema = exports.Schema = void 0;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const sendEmailPrefix = 'actions.sendEmail.form';
const placeholderPrefix = 'actions.placeholders';
const creatableSelectProps = {
  createLabel: 'commons.actions.ADD',
  clearable: true,
  noDropdownIcon: true,
  noOptionsMessage: true,
  validNewOptionRegex: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};
const Schema = exports.Schema = {
  type: 'object',
  properties: {
    to: {
      title: "".concat(sendEmailPrefix, ".TO"),
      type: 'string',
      otherLabel: "".concat(sendEmailPrefix, ".OTHER")
    },
    replyTo: {
      title: "".concat(sendEmailPrefix, ".FROM"),
      type: 'string'
    },
    cc: _objectSpread({
      title: "".concat(sendEmailPrefix, ".CC"),
      type: 'string',
      enum: []
    }, creatableSelectProps),
    bcc: _objectSpread({
      title: "".concat(sendEmailPrefix, ".BCC"),
      type: 'string',
      enum: []
    }, creatableSelectProps),
    subject: {
      title: "".concat(sendEmailPrefix, ".SUBJECT"),
      type: 'string',
      name: 'email-subject',
      autocomplete: true
    },
    mailContent: {
      title: "".concat(sendEmailPrefix, ".MAIL_CONTENT"),
      type: 'string',
      config: 'full'
    },
    attachments: {
      title: '',
      type: 'string',
      mimeType: '*',
      multiple: true,
      placeholder: "".concat(placeholderPrefix, ".ATTACHMENTS")
    },
    signatures: {
      title: "".concat(sendEmailPrefix, ".SIGNATURE"),
      type: 'string',
      placeholder: "".concat(placeholderPrefix, ".SELECT_SIGNATURE"),
      enum: [],
      enumNames: []
    },
    mailTemplate: {
      title: "".concat(sendEmailPrefix, ".TEMPLATE"),
      type: 'string',
      placeholder: "".concat(placeholderPrefix, ".SELECT_MAIL_TEMPLATE")
    },
    mailTemplateLanguage: {
      title: "".concat(sendEmailPrefix, ".TEMPLATE_LANGUAGE"),
      enum: ['en', 'fr'],
      displayAsButton: true,
      color: 'secondary',
      className: 'width-100'
    },
    subscriptionContext: {
      title: "".concat(sendEmailPrefix, ".SUBSCRIPTION_CONTEXT"),
      type: 'string',
      enum: [],
      enumNames: [],
      placeholder: "".concat(placeholderPrefix, ".SELECT_SUBSCRIPTION_CONTEXT")
    }
  },
  required: ['to', 'replyTo', 'subject', 'mailContent', 'subscriptionContext']
};
const UiSchema = exports.UiSchema = {
  to: {
    'ui:type': 'email'
  },
  replyTo: {
    'ui:type': 'email'
  },
  cc: {
    'ui:widget': 'CreatableSelectWidget'
  },
  bcc: {
    'ui:widget': 'CreatableSelectWidget'
  },
  subject: {},
  mailContent: {
    'ui:widget': 'EditorWidget'
  },
  attachments: {
    'ui:widget': 'UploaderWidget'
  },
  mailTemplate: {},
  mailTemplateLanguage: {
    'ui:widget': 'RadioGroupWidget'
  },
  subscriptionContext: {},
  signatures: {}
};