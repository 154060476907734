"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _deskingCommons = require("@sm360/desking-commons");
var _actions = require("../actions");
var _selectors = require("../selectors");
var _filters = require("../selectors/filters");
var _paymentOptions = require("../selectors/paymentOptions");
var _requestInventoryVehicles = require("../selectors/requestInventoryVehicles");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  getNewVehicles,
  getUsedVehicles
} = _commonsApi.VehicleService;
function* _default() {
  const {
    location,
    organizationId,
    organizationUnitId
  } = yield (0, _effects.select)(_selectors.getConfig);
  const priceIncreaseRollCount = yield (0, _effects.select)(_deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount);
  const queryParams = {
    includeMetadata: true,
    location,
    organizationId,
    organizationUnitId,
    showroomModule: 'desking'
  };
  const isNew = yield (0, _effects.select)(_filters.getIsCategoryNew);
  const {
    pageNumber,
    pageSize
  } = yield (0, _effects.select)(_selectors.getPagination);
  const sortList = yield (0, _effects.select)(_selectors.getSort);
  const paymentOptions = yield (0, _effects.select)(_paymentOptions.getPaymentOptions);
  const vehicle = yield (0, _effects.select)(_requestInventoryVehicles.getVehicleFilters);
  try {
    const callee = isNew ? getNewVehicles : getUsedVehicles;
    const {
      totalElements,
      inventoryVehicles,
      paymentOptionsMetadata
    } = yield (0, _effects.call)(callee, queryParams, {
      pagination: {
        pageNumber,
        pageSize
      },
      paymentOptionRequest: _objectSpread(_objectSpread({}, paymentOptions), {}, {
        priceIncreaseRollCount
      }),
      sortList: [sortList],
      vehicle
    });
    yield (0, _effects.put)(_actions.Creators.loadVehiclesSuccess({
      totalElements,
      vehicles: inventoryVehicles
    }));
    yield (0, _effects.put)(_actions.Creators.setPaymentOptionsMetadata(paymentOptionsMetadata || []));
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getVehicles'));
  }
}