"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateLeadNotification = exports.getLeadNotifications = exports.getLeadNotification = exports.deleteLeadNotification = exports.createLeadNotification = void 0;
var _appsCore = require("@sm360/apps-core");
const NEW_CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getLeadNotifications = async (userId, organizationId) => {
  return await _appsCore.axios.get("".concat(NEW_CRM_ENDPOINT, "/leadNotificationConfigs?organizationId=").concat(organizationId, "&userId=").concat(userId));
};
exports.getLeadNotifications = getLeadNotifications;
const getLeadNotification = async id => {
  return await _appsCore.axios.get("".concat(NEW_CRM_ENDPOINT, "/leadNotificationConfigs/").concat(id));
};
exports.getLeadNotification = getLeadNotification;
const createLeadNotification = async body => {
  return await _appsCore.axios.post("".concat(NEW_CRM_ENDPOINT, "/leadNotificationConfigs"), body);
};
exports.createLeadNotification = createLeadNotification;
const updateLeadNotification = async body => {
  const id = body === null || body === void 0 ? void 0 : body.id;
  return await _appsCore.axios.put("".concat(NEW_CRM_ENDPOINT, "/leadNotificationConfigs/").concat(id), body);
};
exports.updateLeadNotification = updateLeadNotification;
const deleteLeadNotification = async id => {
  return await _appsCore.axios.delete("".concat(NEW_CRM_ENDPOINT, "/leadNotificationConfigs/").concat(id));
};
exports.deleteLeadNotification = deleteLeadNotification;