"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateLeadStatus = exports.getDuplicates = exports.deleteLead = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const deleteLead = async _ref => {
  let {
    leadId
  } = _ref;
  return await _appsCore.axios.delete("".concat(CRM_ENDPOINT, "/leads/").concat(leadId));
};
exports.deleteLead = deleteLead;
const updateLeadStatus = async (leadId, newStatusId, userId, desc) => {
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/leads/").concat(leadId, "/leadStatus?leadStatusId=").concat(newStatusId, "&loggedUserId=").concat(userId, "&description=").concat(desc));
};
exports.updateLeadStatus = updateLeadStatus;
const getDuplicates = async leadId => {
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/leads/").concat(leadId, "/duplicates"));
};
exports.getDuplicates = getDuplicates;