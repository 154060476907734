"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _commonsUtils = require("@sm360/commons-utils");
var _effects = require("redux-saga/effects");
var _deskingError = require("@sm360/desking-error");
var _uiLibrary = require("@sm360/ui-library");
var _actions = require("../../actions");
var _tabProtection = require("../../selectors/tabProtection");
var _reloadVehicle = _interopRequireDefault(require("../reloadVehicle"));
var _selectors = require("../../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("../api/updatePayOptAndTransDetails"));
var _refreshQuote = _interopRequireDefault(require("../refreshQuote"));
var _bookFNIItems = _interopRequireDefault(require("./bookFNIItems"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  toast
} = _uiLibrary.Toast;

/**
 * @returns {Object}
 * @param {String} fniType
 */
function _default(_ref) {
  let {
    fniType
  } = _ref;
  return function* () {
    const t = yield (0, _effects.getContext)('t');
    yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItems());
    const items = yield (0, _effects.select)(_tabProtection.getFNIItemsToBook, fniType);
    try {
      const response = yield (0, _effects.call)(_bookFNIItems.default, items);
      if (response) {
        const bookedItems = [];
        const unBookedItemsMessages = [];
        response.items.forEach(item => {
          if (!item.isBooked && !(0, _commonsUtils.isEmpty)(item.bookError)) {
            unBookedItemsMessages.push(item.bookError);
          } else if (item.isBooked) {
            bookedItems.push(item);
          }
        });
        if (!(0, _commonsUtils.isEmpty)(bookedItems)) {
          yield (0, _effects.put)(_actions.Creators.onBookFNIItemsClickSuccess(fniType, bookedItems));
        }
        if (!(0, _commonsUtils.isEmpty)(unBookedItemsMessages) || !response.isValid) {
          yield (0, _effects.put)(_actions.Creators.openInvalidResponseModal(response.validationMessage, unBookedItemsMessages));
        }
        if (response.isValid && !response.quoteSaved) {
          const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
          yield (0, _effects.call)(_updatePayOptAndTransDetails.default, _objectSpread(_objectSpread({}, paymentOptions), {}, {
            transactionToken: response.transactionToken,
            callCommission: true
          }));
          yield (0, _effects.call)(_reloadVehicle.default);
        } else if (response.quoteSaved) {
          yield (0, _effects.call)(_refreshQuote.default);
          toast.success(t('confirmModifications.changesSaved'));
        }
      }
      yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItemsDone());
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'bookFNIItems'));
      yield (0, _effects.put)(_actions.Creators.setIsFetchingFNIItemsDone());
    }
  }();
}