"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.waitForCallAnswer = exports.updatePhoneActivity = exports.createSendSMS = exports.createSendEmail = exports.createPhoneCall = exports.createPhoneActivity = exports.createNote = exports.cancelCall = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// SMS related
const createSendSMS = async _ref => {
  let {
    contactId,
    leadId,
    organizationId,
    userId,
    phoneNumberFrom,
    phoneNumber,
    smsMessage
  } = _ref;
  const leadIdQuery = leadId ? "&leadId=".concat(leadId) : '';
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/events/sms?contactId=").concat(contactId).concat(leadIdQuery, "&organizationId=").concat(organizationId, "&phoneNumberFrom=").concat(phoneNumberFrom, "&phoneNumber=").concat(phoneNumber, "&userId=").concat(userId), smsMessage);
};

// Email related
exports.createSendSMS = createSendSMS;
const createSendEmail = async _ref2 => {
  let {
    mailContent,
    attachments,
    contactId,
    leadId,
    organizationId,
    organizationUnitId,
    userId,
    to,
    replyTo,
    from,
    cc,
    bcc,
    subject,
    subscriptionContext
  } = _ref2;
  const leadIdQuery = leadId ? "&leadId=".concat(leadId) : '';
  const orgUnitQuery = organizationUnitId ? "&organizationUnitId=".concat(organizationUnitId) : '';
  const hasAttachments = attachments === null || attachments === void 0 ? void 0 : attachments.length;
  const path = "/events/mail".concat(hasAttachments ? 'WithAttachment' : '');
  let body = mailContent;
  if (hasAttachments) {
    body = new FormData();
    body.append('body', mailContent);
    attachments.forEach((file, i) => {
      body.append('attachment', file, (file === null || file === void 0 ? void 0 : file.name) || i);
    });
  }
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT).concat(path, "?contactId=").concat(contactId, "&userId=").concat(userId, "&organizationId=").concat(organizationId, "&to=").concat(to, "&replyTo=").concat(replyTo, "&from=").concat(from, "&cc=").concat(cc, "&bcc=").concat(bcc, "&subject=").concat(subject, "&subscriptionContext=").concat(subscriptionContext).concat(leadIdQuery).concat(orgUnitQuery), body, {
    ignoreAppsCoreCatch: true,
    headers: {
      'Content-Type': hasAttachments ? 'multipart/form-data' : 'text/plain'
    }
  });
};

// Note related
exports.createSendEmail = createSendEmail;
const createNote = async _ref3 => {
  let {
    body,
    contactId,
    leadId,
    organizationId,
    userId
  } = _ref3;
  const leadIdQuery = leadId ? "&leadId=".concat(leadId) : '';
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/activities/note?contactId=").concat(contactId).concat(leadIdQuery, "&organizationId=").concat(organizationId, "&userId=").concat(userId), body);
};

// Phone event related
exports.createNote = createNote;
const createPhoneActivity = async _ref4 => {
  let {
    contactId,
    leadId,
    userId,
    organizationId,
    direction,
    phone,
    interlocutor,
    subject,
    details,
    duration
  } = _ref4;
  const leadIdQuery = leadId ? "&leadId=".concat(leadId) : '';
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/events/phone?contactId=").concat(contactId).concat(leadIdQuery, "&userId=").concat(userId, "&organizationId=").concat(organizationId, "&direction=").concat(direction, "&phone=").concat(phone, "&interlocutor=").concat(interlocutor, "&duration=").concat(duration, "&details=").concat(details, "&subject=").concat(subject));
};
exports.createPhoneActivity = createPhoneActivity;
const updatePhoneActivity = async _ref5 => {
  let {
    id,
    userId,
    organizationUnitId,
    interlocutor,
    subject,
    details
  } = _ref5;
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/events/call/").concat(id, "?&userId=").concat(userId, "&organizationUnitId=").concat(organizationUnitId, "&interlocutor=").concat(interlocutor, "&details=").concat(details, "&subject=").concat(subject));
};

// Call related
exports.updatePhoneActivity = updatePhoneActivity;
const createPhoneCall = async _ref6 => {
  let {
    contactId,
    leadId,
    userId,
    organizationId,
    direction,
    phone,
    interlocutor,
    duration,
    details,
    subject
  } = _ref6;
  const leadIdQuery = leadId ? "&leadId=".concat(leadId) : '';
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/events/call?contactId=").concat(contactId).concat(leadIdQuery, "&userId=").concat(userId, "&organizationId=").concat(organizationId, "&direction=").concat(direction, "&phone=").concat(phone, "&interlocutor=").concat(interlocutor, "&duration=").concat(duration, "&details=").concat(details, "&subject=").concat(subject));
};
exports.createPhoneCall = createPhoneCall;
const waitForCallAnswer = async _ref7 => {
  let {
    callId,
    answeredClient
  } = _ref7;
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/events/call/").concat(callId, "/waitAnswer?answeredClient=").concat(answeredClient));
};
exports.waitForCallAnswer = waitForCallAnswer;
const cancelCall = async _ref8 => {
  let {
    callId
  } = _ref8;
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/events/call/").concat(callId, "/cancel"));
};
exports.cancelCall = cancelCall;