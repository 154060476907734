"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateRoadToSales = exports.getRoadToSales = void 0;
var _appsCore = require("@sm360/apps-core");
const NEW_CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getRoadToSales = async leadId => {
  return await _appsCore.axios.get("".concat(NEW_CRM_ENDPOINT, "/leads/").concat(leadId, "/roadToSales"));
};
exports.getRoadToSales = getRoadToSales;
const updateRoadToSales = async _ref => {
  let {
    leadId,
    leadStatusId,
    description
  } = _ref;
  const descriptionParam = description ? "?description=".concat(encodeURIComponent(description)) : '';
  return await _appsCore.axios.put("".concat(NEW_CRM_ENDPOINT, "/leads/").concat(leadId, "/roadToSales/").concat(leadStatusId).concat(descriptionParam));
};
exports.updateRoadToSales = updateRoadToSales;