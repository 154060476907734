"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _selectors = require("../../selectors");
var _paymentOptions = require("../../selectors/paymentOptions");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * @param {Object} state
 * @param {Object} payload - { response }
 */
var _default = (state, _ref) => {
  let {
    response
  } = _ref;
  const {
    cashDown,
    cashPurchase,
    configurationId,
    finance,
    financeOptions,
    kmPerYearPlan,
    lien,
    modelTrimCode,
    odometer,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn,
    transactionToken,
    vehicleCondition,
    disclaimer,
    __paymentOptionsMeta
  } = response;
  const baseProps = (0, _selectors.composeNewBaseProps)(state, configurationId, modelTrimCode, transactionToken);
  const newMetaData = Array.isArray(__paymentOptionsMeta) ? __paymentOptionsMeta : [];
  let metaData = (0, _paymentOptions.getMetaData)(state);

  // Don't replace metaData if it's the same, avoiding to rerender components depending on it
  if (!(0, _commonsUtils.isEqual)(metaData, newMetaData)) {
    metaData = newMetaData;
  }
  return _objectSpread(_objectSpread({}, state), {}, {
    baseProps,
    plan: {
      odometer,
      saleType,
      taxPlan,
      vehicleCondition
    },
    purchaseMethod: {
      cashDown,
      kmPerYearPlan,
      paymentFrequency,
      purchaseMethod,
      term
    },
    tradeIn: {
      lien,
      tradeIn
    },
    paymentOptions: {
      cashPurchase,
      finance,
      financeOptions,
      disclaimer,
      metaData
    }
  });
};
exports.default = _default;