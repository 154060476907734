"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _deskingCommons = require("@sm360/desking-commons");
var _vehicles = require("../selectors/vehicles");
var _requestCatalogVehicles = require("../selectors/requestCatalogVehicles");
var _selectors = require("../selectors");
var _addVehicleToGarage = _interopRequireDefault(require("./addVehicleToGarage"));
var _assignLead = _interopRequireDefault(require("./assignLead"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function* _default(payload) {
  const navigate = yield (0, _effects.getContext)('navigate');
  const {
    priceIncreaseRollCount
  } = yield (0, _effects.select)(_requestCatalogVehicles.getBody);
  const addedVehicles = yield (0, _effects.select)(_vehicles.getAddedVehicles);
  const {
    vehicle
  } = payload;
  const vehicleAdded = addedVehicles.find(addedVehicle => addedVehicle.vehicleId === vehicle.vehicleId);
  const salesPersonnel = yield (0, _effects.select)(_selectors.getSalesPersonnel);
  const deskingWidgetParams = yield (0, _effects.select)(_selectors.getDeskingWidgetParams);
  const {
    salesPerson
  } = salesPersonnel;
  const hasSalesPerson = salesPerson && Object.entries(salesPerson).length > 0;
  if (!hasSalesPerson) {
    yield (0, _effects.call)(_assignLead.default, _deskingCommons.SALES_PERSONNEL.SALES_PERSON);
  }
  if (vehicleAdded) {
    navigate((0, _deskingCommons.getDeskingPath)(_objectSpread(_objectSpread({}, deskingWidgetParams), {}, {
      quoteId: vehicleAdded.quoteId,
      upg: priceIncreaseRollCount
    })));
  } else {
    const quote = yield (0, _addVehicleToGarage.default)(payload);
    if (!(0, _commonsUtils.isEmpty)(quote)) {
      navigate((0, _deskingCommons.getDeskingPath)(_objectSpread(_objectSpread({}, deskingWidgetParams), {}, {
        quoteId: quote.id,
        upg: priceIncreaseRollCount
      })));
    }
  }
}