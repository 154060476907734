"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformContactFromBackend = void 0;
var _commonsUtils = require("@sm360/commons-utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// Transform/clean the BE contact structure to the FE contact structure
const transformContactFromBackend = entry => {
  const {
    mobilePhone,
    homePhone,
    workPhone,
    workPhoneExtension
  } = entry;
  const formattedEntry = _objectSpread(_objectSpread({}, entry), {}, {
    mobilePhone: (0, _commonsUtils.validPhoneNumber)(mobilePhone) ? (0, _commonsUtils.cleanPhoneNumber)(mobilePhone) : '',
    homePhone: (0, _commonsUtils.validPhoneNumber)(homePhone) ? (0, _commonsUtils.cleanPhoneNumber)(homePhone) : '',
    workPhone: (0, _commonsUtils.validPhoneNumber)(workPhone) ? (0, _commonsUtils.cleanPhoneNumber)(workPhone) : '',
    workPhoneExtension: workPhoneExtension ? Number(workPhoneExtension) : null
  });
  return formattedEntry;
};
exports.transformContactFromBackend = transformContactFromBackend;