"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shouldLockVehicle = exports.isVehicleDetailsValid = exports.isNew = exports.isNeverCreated = exports.isAllowedToEvaluateVehicle = exports.expirationDate = exports.checkIsUpdatingTradeIn = exports.checkIsLoadingTradeIn = exports.checkIsDeletingTradeIn = exports.checkIfLoading = void 0;
var _TradeInVehicleValidation = require("../tools/helpers/TradeInVehicleValidation");
const _excluded = ["paymentType", "payment", "balance", "lienHolderName", "expirationDate"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const isVehicleDetailsValid = state => {
  const _ref = state.tradeIn || {},
    {
      paymentType,
      payment,
      balance,
      lienHolderName,
      expirationDate
    } = _ref,
    validateValues = _objectWithoutProperties(_ref, _excluded);
  const errors = (0, _TradeInVehicleValidation.checkForErrors)(validateValues);
  return Object.keys(errors).length === 0;
};
exports.isVehicleDetailsValid = isVehicleDetailsValid;
const isNeverCreated = state => {
  const {
    tradeIn
  } = state;
  if (!tradeIn) return false;
  const {
    id
  } = tradeIn || {};
  return typeof id === 'undefined' || !id;
};
exports.isNeverCreated = isNeverCreated;
const isAllowedToEvaluateVehicle = state => {
  var _state$access;
  return (_state$access = state.access) === null || _state$access === void 0 ? void 0 : _state$access.EvaluateVehicle;
};
exports.isAllowedToEvaluateVehicle = isAllowedToEvaluateVehicle;
const shouldLockVehicle = state => {
  // Being a vehicle appraiser always allow to edit and bypass the vehicle lock
  const canAppraiseVehicle = isAllowedToEvaluateVehicle(state);
  return !canAppraiseVehicle && state.isReadOnlyMode;
};
exports.shouldLockVehicle = shouldLockVehicle;
const isNew = state => {
  return isNeverCreated(state) ? true : !isVehicleDetailsValid(state);
};
exports.isNew = isNew;
const expirationDate = state => {
  const {
    tradeIn
  } = state;
  const {
    dueMonth,
    dueYear
  } = tradeIn || {};
  return dueMonth && dueYear ? "".concat(dueMonth + 1, "/").concat(dueYear) : '';
};
exports.expirationDate = expirationDate;
const checkIfLoading = function (state) {
  for (var _len = arguments.length, actionsToCheck = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    actionsToCheck[_key - 1] = arguments[_key];
  }
  return state.loader.actions.some(action => actionsToCheck.includes(action.name));
};
exports.checkIfLoading = checkIfLoading;
const checkIsUpdatingTradeIn = state => {
  return checkIfLoading(state, 'UPDATE_TRADE_IN');
};
exports.checkIsUpdatingTradeIn = checkIsUpdatingTradeIn;
const checkIsLoadingTradeIn = state => {
  return checkIfLoading(state, 'LOAD_TRADE_IN');
};
exports.checkIsLoadingTradeIn = checkIsLoadingTradeIn;
const checkIsDeletingTradeIn = state => {
  return checkIfLoading(state, 'DELETE_TRADE_IN');
};
exports.checkIsDeletingTradeIn = checkIsDeletingTradeIn;