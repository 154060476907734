"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.groupTypesByCategory = void 0;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// Convert a flat list of leadTypes into a list of leadCategories which contain leadTypes
const groupTypesByCategory = types => {
  return types.reduce((acc, type) => {
    const groupIndex = acc.findIndex(group => group.groupId === String(type.leadCategory.id));
    if (groupIndex >= 0) {
      // group already exists, push new lead type in it
      const group = acc[groupIndex];
      const updatedGroup = _objectSpread(_objectSpread({}, group), {}, {
        options: [...group.options, {
          id: String(type.id),
          label: type.type
        }]
      });
      const updatedAcc = [...acc];
      updatedAcc.splice(groupIndex, 1, updatedGroup);
      return updatedAcc;
    } else {
      // group doesn't exist yet, create it then push new lead type in it
      return [...acc, {
        groupId: String(type.leadCategory.id),
        groupLabel: type.leadCategory.category,
        options: [{
          id: String(type.id),
          label: type.type
        }]
      }];
    }
  }, []).sort((a, b) => a.groupId.localeCompare(b.groupId));
};
exports.groupTypesByCategory = groupTypesByCategory;