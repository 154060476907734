"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVehicleTransmissionTypes = exports.getVehicleSubCategories = exports.getVehicleModels = exports.getVehicleMakes = exports.getVehicleInteriorColors = exports.getVehicleFuelTypes = exports.getVehicleExteriorColors = exports.getVehicleEngineTypes = exports.getVehicleDriveTrains = exports.getVehicleCategories = exports.getVehicleBodyStyle = exports.getShowroomLockedFields = void 0;
var _request = require("../../tools/request");
const XMS_API_URL = process.env.REACT_APP_XMS_API_URL;

/**
 * @param {String} orgId
 * @returns {Object}
 */

const getVehicleMakes = async organizationId => {
  const response = await (0, _request.get)("".concat(XMS_API_URL, "/staticData/").concat(organizationId, "/makes"));
  const sortedMakes = response.sort((a, b) => a.name.localeCompare(b.name));
  return sortedMakes;
};

/**
 * @param {String} orgId
 * @param {Number} makeId
 * @returns {Object}
 */
exports.getVehicleMakes = getVehicleMakes;
const getVehicleModels = async (organizationId, makeId) => {
  const response = await (0, _request.get)("".concat(XMS_API_URL, "/staticData/").concat(organizationId, "/makes/").concat(makeId, "/models"));
  const sortedModels = response.sort((a, b) => a.name.localeCompare(b.name));
  return sortedModels;
};

/**
 * @param {String} industry
 * @param {String} allAccessIndustry
 * @returns {Object}
 */
exports.getVehicleModels = getVehicleModels;
const getVehicleCategories = (industry, allAccessIndustry) => {
  let params = '';
  if (industry && !allAccessIndustry) params = "?autoIndustry=".concat(industry.toUpperCase());
  return (0, _request.get)("".concat(XMS_API_URL, "/staticData/vehicleCategories").concat(params));
};

/**
 * @param {Number} category
 * @returns {Object}
 */
exports.getVehicleCategories = getVehicleCategories;
const getVehicleSubCategories = category => (0, _request.get)("".concat(XMS_API_URL, "/staticData/vehicleCategories/").concat(category, "/subtypes"));

/**
 * @param {Number} category
 * @returns {Object}
 */
exports.getVehicleSubCategories = getVehicleSubCategories;
const getVehicleBodyStyle = category => (0, _request.get)("".concat(XMS_API_URL, "/staticData/frameStyles?vehicleCategoryId=").concat(category));

/**
 * @returns {Object}
 */
exports.getVehicleBodyStyle = getVehicleBodyStyle;
const getVehicleDriveTrains = () => (0, _request.get)("".concat(XMS_API_URL, "/staticData/driveTrains"));

/**
 * @returns {Object}
 */
exports.getVehicleDriveTrains = getVehicleDriveTrains;
const getVehicleEngineTypes = () => (0, _request.get)("".concat(XMS_API_URL, "/staticData/engineTypes"));

/**
 * @returns {Object}
 */
exports.getVehicleEngineTypes = getVehicleEngineTypes;
const getVehicleExteriorColors = () => (0, _request.get)("".concat(XMS_API_URL, "/staticData/colors/exterior"));

/**
 * @returns {Object}
 */
exports.getVehicleExteriorColors = getVehicleExteriorColors;
const getVehicleFuelTypes = () => (0, _request.get)("".concat(XMS_API_URL, "/staticData/fuelTypes"));

/**
 * @returns {Object}
 */
exports.getVehicleFuelTypes = getVehicleFuelTypes;
const getVehicleInteriorColors = () => (0, _request.get)("".concat(XMS_API_URL, "/staticData/colors/interior"));

/**
 * @returns {Object}
 */
exports.getVehicleInteriorColors = getVehicleInteriorColors;
const getVehicleTransmissionTypes = () => (0, _request.get)("".concat(XMS_API_URL, "/staticData/transmissionTypes"));

/**
 * @returns {Array}
 */
exports.getVehicleTransmissionTypes = getVehicleTransmissionTypes;
const getShowroomLockedFields = orgUnitId => (0, _request.get)("".concat(XMS_API_URL, "/staticData/").concat(orgUnitId, "/showroomFields"));
exports.getShowroomLockedFields = getShowroomLockedFields;