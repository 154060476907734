"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTask = exports.editTask = exports.createTask = void 0;
var _appsCore = require("@sm360/apps-core");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Get Task
const getTask = async id => {
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/tasks/").concat(id));
};

// Edit Task
exports.getTask = getTask;
const editTask = async _ref => {
  let {
    body
  } = _ref;
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/tasks/").concat(body.id), body);
};

// Create new task from leads
exports.editTask = editTask;
const createTask = async _ref2 => {
  let {
    body,
    contactId,
    leadId,
    organizationId
  } = _ref2;
  const contactIdQuery = contactId ? "contactId=".concat(contactId) : '';
  const leadIdQuery = leadId ? "&leadId=".concat(leadId) : '';
  const requestBody = _objectSpread(_objectSpread({}, body), {}, {
    organizationId,
    result: 'SUCCESS',
    type: 'TASK_CREATED',
    userId: body.assignedUserId
  });
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/tasks?").concat(contactIdQuery).concat(leadIdQuery), requestBody);
};
exports.createTask = createTask;