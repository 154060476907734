"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _payment = require("../../../constants/payment");
var _selectors = require("../../selectors");
var _paymentOptions = require("../../selectors/paymentOptions");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * When changing a method, we have to update the root parameters,
 * from the parameters of the financeOption item, of the selected purchaseMethod
 *
 * @param {Object} state
 * @param {Object} payload - { purchaseMethod }
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    purchaseMethod
  } = _ref;
  // When cash, pretty simple, we don't need to update/reset other parameters in relation with finance/lease
  if (purchaseMethod === _payment.PURCHASE_METHOD_CASH) {
    const purchaseMethodProps = (0, _selectors.getPurchaseMethod)(state);
    return _objectSpread(_objectSpread({}, state), {}, {
      isPaymentUpdating: true,
      purchaseMethod: _objectSpread(_objectSpread({}, purchaseMethodProps), {}, {
        purchaseMethod
      })
    });
  }
  const plan = (0, _paymentOptions.getFinanceOptionsPlan)(state, purchaseMethod);

  // Safety check, it should never happen, we can't let the user choose a purchaseMethod which is not in financeOptions
  if (!(0, _commonsUtils.isPlainObject)(plan)) {
    throw new TypeError("The selected purchase method is not defined: ".concat(purchaseMethod));
  }
  const {
    kmPerYearPlan,
    paymentFrequency,
    selectedCashDown: cashDown,
    selectedTerm: term
  } = plan;
  return _objectSpread(_objectSpread({}, state), {}, {
    isPaymentUpdating: true,
    purchaseMethod: {
      cashDown,
      kmPerYearPlan,
      paymentFrequency,
      purchaseMethod,
      term
    }
  });
};
exports.default = _default;