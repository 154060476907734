"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showInvalidResponseModal = exports.isFetchingFNIItems = exports.invalidResponseTitle = exports.invalidResponseMessages = exports.getValidationFNIItemPlan = exports.getTabWarrantyContent = exports.getTabProtectionWarranty = exports.getTabProtectionProtection = exports.getTabProtectionLGM = exports.getTabProtectionInsurance = exports.getTabProtectionContent = exports.getTabProtectionBMW = exports.getTabProtection = exports.getTabLGMContent = exports.getTabInsuranceContent = exports.getTabBMWContent = exports.getSelectedTab = exports.getSelectedIndex = exports.getSelectedFNIItemPlans = exports.getRequestBookFNIItemsBody = exports.getPlanProtectionCoverageParameters = exports.getPlanInsuranceCoverageParameters = exports.getLGMWarrantiesTableData = exports.getLGMProtectionsTableData = exports.getLGMItemsErrorMessage = exports.getLGMItemsError = exports.getLGMInsurancesTableData = exports.getFNITab = exports.getFNIItemsToBook = exports.getFNIItems = exports.getFNIItemPlan = exports.getFNIItem = exports.getBMWItemsErrorMessage = exports.getBMWItemsError = exports.areLGMItemsLoaded = exports.areBMWItemsLoaded = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _tabProtection = require("../../constants/tabProtection");
var _selectors = require("../selectors");
var _tabItems = require("./tabItems");
const _excluded = ["cashPurchaseOption", "financeOptions"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const isNil = val => val == null;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
const isFetchingFNIItems = state => state.isFetchingFNIItems;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.isFetchingFNIItems = isFetchingFNIItems;
const getLGMItemsError = state => state.lgmItemsError;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getLGMItemsError = getLGMItemsError;
const getLGMItemsErrorMessage = state => state.lgmItemsErrorMessage;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getLGMItemsErrorMessage = getLGMItemsErrorMessage;
const areLGMItemsLoaded = state => state.lgmItemsAreLoaded;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.areLGMItemsLoaded = areLGMItemsLoaded;
const getBMWItemsError = state => state.bmwItemsError;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getBMWItemsError = getBMWItemsError;
const getBMWItemsErrorMessage = state => state.bmwItemsErrorMessage;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getBMWItemsErrorMessage = getBMWItemsErrorMessage;
const areBMWItemsLoaded = state => state.bmwItemsAreLoaded;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.areBMWItemsLoaded = areBMWItemsLoaded;
const showInvalidResponseModal = state => state.showInvalidResponseModal;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.showInvalidResponseModal = showInvalidResponseModal;
const invalidResponseMessages = state => state.invalidResponseMessages;

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.invalidResponseMessages = invalidResponseMessages;
const invalidResponseTitle = state => state.invalidResponseTitle;

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.invalidResponseTitle = invalidResponseTitle;
const getTabProtection = state => {
  const {
    tabProtection
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabProtection) ? tabProtection : {};
};

/**
 * @param {Object} state
 * @returns {Number}
 */
exports.getTabProtection = getTabProtection;
const getSelectedIndex = state => {
  const {
    selectedIndex
  } = getTabProtection(state);
  return Number.isInteger(selectedIndex) ? selectedIndex : 0;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getSelectedIndex = getSelectedIndex;
const getTabProtectionProtection = state => {
  const {
    tabProtectionProtection
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabProtectionProtection) ? tabProtectionProtection : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabProtectionProtection = getTabProtectionProtection;
const getTabProtectionWarranty = state => {
  const {
    tabProtectionWarranty
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabProtectionWarranty) ? tabProtectionWarranty : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabProtectionWarranty = getTabProtectionWarranty;
const getTabProtectionInsurance = state => {
  const {
    tabProtectionInsurance
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabProtectionInsurance) ? tabProtectionInsurance : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabProtectionInsurance = getTabProtectionInsurance;
const getTabProtectionLGM = state => {
  const {
    meta,
    tabProtectionLGM
  } = state;
  const {
    fniProviders
  } = meta;
  const lgmProvider = fniProviders.find(fniProvider => fniProvider.providerId === 'FNI.LGM');
  return (0, _commonsUtils.isPlainObject)(tabProtectionLGM) ? _objectSpread(_objectSpread({}, tabProtectionLGM), {}, {
    isAvailable: !isNil(lgmProvider) && lgmProvider.isAvailable,
    providerName: !isNil(lgmProvider) ? lgmProvider.providerName : '',
    uiIndex: !isNil(lgmProvider) && lgmProvider.isAvailable ? 3 : 4
  }) : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabProtectionLGM = getTabProtectionLGM;
const getTabProtectionBMW = state => {
  const {
    meta,
    tabProtectionBMW
  } = state;
  const {
    fniProviders
  } = meta;
  const lgmProvider = fniProviders.find(fniProvider => fniProvider.providerId === 'FNI.LGM');
  const bmwProvider = fniProviders.find(fniProvider => fniProvider.providerId === 'FNI.BMW');

  // TODO: Change backend meta so that provider name is 'SI/SI+'
  return (0, _commonsUtils.isPlainObject)(tabProtectionBMW) ? _objectSpread(_objectSpread({}, tabProtectionBMW), {}, {
    isAvailable: !isNil(bmwProvider) && bmwProvider.isAvailable,
    providerName: !isNil(bmwProvider) ? 'SI/SI+' : '',
    uiIndex: !isNil(lgmProvider) && lgmProvider.isAvailable ? 4 : 3
  }) : {};
};

/**
 * Prepare data for UI
 *
 * @param {String} contentType
 * @param {Object} item
 * @returns {Object}
 */
exports.getTabProtectionBMW = getTabProtectionBMW;
const formatFNIItemData = (contentType, item) => {
  const {
    code,
    dataTypeId,
    description,
    supplier,
    typeId
  } = item;
  const formattedItem = structuredClone(item);
  formattedItem.plans = formattedItem.plans.map(plan => {
    plan.description = (0, _tabItems.ucFirst)(plan.description);
    return plan;
  }).sort((a, b) => a.rank - b.rank);
  return _objectSpread(_objectSpread({}, formattedItem), {}, {
    description: (0, _tabItems.ucFirst)(description),
    payload: {
      contentType,
      code,
      dataTypeId,
      supplier,
      typeId
    }
  });
};

/**
 * @param {Object} state
 * @param {Object} items
 * @returns {Object[]}
 */
const formatFNIItems = (state, items) => {
  const formattedItems = {};
  if (Array.isArray(items.protections)) {
    formattedItems.protections = items.protections.sort((a, b) => a.rank - b.rank).map(item => formatFNIItemData('protections', item)).flat();
  }
  if (Array.isArray(items.warranties)) {
    formattedItems.warranties = items.warranties.sort((a, b) => a.rank - b.rank).map(item => formatFNIItemData('warranties', item)).flat();
  }
  if (Array.isArray(items.insurances)) {
    formattedItems.insurances = items.insurances.sort((a, b) => a.rank - b.rank).map(item => formatFNIItemData('insurances', item)).flat();
  }
  return formattedItems;
};

/**
 * @param {Object} state
 * @param {Function} selector
 * @returns {Object}
 */
const getTabContentFromFNI = (state, selector) => {
  const {
    content
  } = selector.call(null, state);
  return (0, _commonsUtils.isPlainObject)(content) && Object.values(content).every(value => Array.isArray(value)) ? formatFNIItems(state, content) : {};
};

/**
 * @param {Object} state
 * @param {String} fniType
 * @returns {Object}
 */
const getFNITab = (state, fniType) => {
  let fniTab = {};
  if (fniType === _tabProtection.TAB_PROTECTION.LGM) {
    fniTab = getTabProtectionLGM(state);
  } else if (fniType === _tabProtection.TAB_PROTECTION.BMW) {
    fniTab = getTabProtectionBMW(state);
  }
  return fniTab;
};

/**
 * @param {Object} state
 * @param {String} fniType
 * @param {String} contentType
 * @returns {Object}
 */
exports.getFNITab = getFNITab;
const getFNIItems = (state, fniType, contentType) => {
  const fniTab = getFNITab(state, fniType);
  if (!(0, _commonsUtils.isEmpty)(fniTab) && fniTab.content[contentType]) {
    return fniTab.content[contentType];
  }
  return [];
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @returns {Object}
 */
exports.getFNIItems = getFNIItems;
const getFNIItem = (state, fniType, contentType, persistentId) => {
  const items = getFNIItems(state, fniType, contentType);
  const item = items.find(currentItem => currentItem.persistentId === persistentId);
  return item !== null && item !== void 0 ? item : {};
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @param {string} planPersistentId
 * @returns {Object}
 */
exports.getFNIItem = getFNIItem;
const getFNIItemPlan = (state, fniType, contentType, persistentId, planPersistentId) => {
  var _plan;
  const item = getFNIItem(state, fniType, contentType, persistentId);
  let plan;
  if (!(0, _commonsUtils.isEmpty)(item)) {
    plan = item.plans.find(currentPlan => currentPlan.persistentId === planPersistentId);
  }
  return (_plan = plan) !== null && _plan !== void 0 ? _plan : {};
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @returns {Object}
 */
exports.getFNIItemPlan = getFNIItemPlan;
const getSelectedFNIItemPlans = (state, fniType, contentType, persistentId) => {
  const item = getFNIItem(state, fniType, contentType, persistentId);
  let plans = [];
  if (!(0, _commonsUtils.isEmpty)(item)) {
    plans = item.plans.filter(currentPlan => currentPlan.isSelected);
  }
  return plans;
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @param {string} planPersistentId
 * @param {Object} selectedProtectionCoverageParameters
 * @returns {Object}
 */
exports.getSelectedFNIItemPlans = getSelectedFNIItemPlans;
const getPlanProtectionCoverageParameters = (state, fniType, contentType, persistentId, planPersistentId, selectedProtectionCoverageParameters) => {
  const plan = getFNIItemPlan(state, fniType, contentType, persistentId, planPersistentId);
  if ((0, _commonsUtils.isEmpty)(plan)) {
    return {
      terms: [],
      kms: [],
      deductibleAmounts: [],
      maximumCoverageAmount: 0,
      limitPerRepairAmount: 0,
      protectionAddOns: []
    };
  }
  if (!(0, _commonsUtils.isEmpty)(selectedProtectionCoverageParameters)) {
    const {
      term,
      km,
      deductibleAmount,
      limitPerRepairAmount,
      protectionAddOns
    } = selectedProtectionCoverageParameters;
    const protectionCoverageParameters = structuredClone(plan.protectionCoverageParameters);
    protectionCoverageParameters.terms = protectionCoverageParameters.terms.map(currentTerm => {
      currentTerm.isSelected = currentTerm.term === term;
      return currentTerm;
    });
    protectionCoverageParameters.kms = protectionCoverageParameters.kms.map(currentKm => {
      currentKm.isSelected = currentKm.km === km;
      return currentKm;
    });
    protectionCoverageParameters.deductibleAmounts = protectionCoverageParameters.deductibleAmounts.map(currentDeductibleAmount => {
      currentDeductibleAmount.isSelected = currentDeductibleAmount.amount === deductibleAmount;
      return currentDeductibleAmount;
    });
    protectionCoverageParameters.limitPerRepairAmounts = protectionCoverageParameters.limitPerRepairAmounts.map(currentLimitPerRepairAmount => {
      currentLimitPerRepairAmount.isSelected = currentLimitPerRepairAmount.amount === limitPerRepairAmount;
      return currentLimitPerRepairAmount;
    });
    protectionCoverageParameters.protectionAddOns = protectionCoverageParameters.protectionAddOns.map(currentProtectionAddOn => {
      currentProtectionAddOn.isSelected = !!(protectionAddOns !== null && protectionAddOns !== void 0 && protectionAddOns.includes(currentProtectionAddOn.id));
      return currentProtectionAddOn;
    });
    return protectionCoverageParameters;
  }
  return plan.protectionCoverageParameters;
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @param {string} planPersistentId
 * @param {Object} payload
 * @returns {Object}
 */
exports.getPlanProtectionCoverageParameters = getPlanProtectionCoverageParameters;
const getSelectedPlanProtectionCoverageParameters = (state, fniType, contentType, persistentId, planPersistentId, payload) => {
  const plan = getFNIItemPlan(state, fniType, contentType, persistentId, planPersistentId);
  if ((0, _commonsUtils.isEmpty)(plan)) {
    return {
      term: 0,
      km: 0,
      deductibleAmount: 0,
      maximumCoverageAmount: 0,
      limitPerRepairAmount: 0,
      protectionAddOns: []
    };
  }
  const {
    terms,
    kms,
    deductibleAmounts,
    maximumCoverageAmount,
    limitPerRepairAmounts,
    protectionAddOns
  } = plan.protectionCoverageParameters;
  let term = ((payload === null || payload === void 0 ? void 0 : payload.terms) || terms).find(currentTerm => currentTerm.isSelected);
  term = term ? term.term : 0;
  let km = ((payload === null || payload === void 0 ? void 0 : payload.kms) || kms).find(currentKm => currentKm.isSelected);
  km = km ? km.km : 0;
  let deductibleAmount = ((payload === null || payload === void 0 ? void 0 : payload.deductibleAmounts) || deductibleAmounts).find(currentDeductibleAmount => currentDeductibleAmount.isSelected);
  deductibleAmount = deductibleAmount ? deductibleAmount.amount : 0;
  const selectedProtectionAddOns = ((payload === null || payload === void 0 ? void 0 : payload.protectionAddOns) || protectionAddOns).filter(protectionAddOn => protectionAddOn.isSelected).map(protectionAddOn => protectionAddOn.id);
  let limitPerRepairAmount = ((payload === null || payload === void 0 ? void 0 : payload.limitPerRepairAmounts) || limitPerRepairAmounts).find(currentLimitPerRepairAmount => currentLimitPerRepairAmount.isSelected);
  limitPerRepairAmount = limitPerRepairAmount ? limitPerRepairAmount.amount : 0;
  return {
    term,
    km,
    deductibleAmount,
    maximumCoverageAmount,
    protectionAddOns: selectedProtectionAddOns,
    limitPerRepairAmount
  };
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @param {string} planPersistentId
 * @param {Object} selectedInsuranceCoverageParameters
 * @returns {Object}
 */
const getPlanInsuranceCoverageParameters = (state, fniType, contentType, persistentId, planPersistentId, selectedInsuranceCoverageParameters) => {
  const plan = getFNIItemPlan(state, fniType, contentType, persistentId, planPersistentId);
  if ((0, _commonsUtils.isEmpty)(plan)) {
    return {
      truncatedTerms: [],
      buyersCovered: [],
      coverageWaitingPeriod: []
    };
  }
  if (!(0, _commonsUtils.isEmpty)(selectedInsuranceCoverageParameters)) {
    const {
      truncatedTerm,
      buyersCoveredType,
      buyersCoveredThirdPartyType,
      coverageWaitingPeriodDays
    } = selectedInsuranceCoverageParameters;
    const insuranceCoverageParameters = structuredClone(plan.insuranceCoverageParameters);
    insuranceCoverageParameters.truncatedTerms = insuranceCoverageParameters.truncatedTerms.map(currentTerm => {
      currentTerm.isSelected = currentTerm.term === truncatedTerm;
      return currentTerm;
    });
    insuranceCoverageParameters.buyersCovered = insuranceCoverageParameters.buyersCovered.map(currentBuyerCovered => {
      currentBuyerCovered.isSelected = currentBuyerCovered.type === buyersCoveredType && currentBuyerCovered.thirdPartyType === buyersCoveredThirdPartyType;
      return currentBuyerCovered;
    });
    insuranceCoverageParameters.coverageWaitingPeriod = insuranceCoverageParameters.coverageWaitingPeriod.map(currentPeriod => {
      currentPeriod.isSelected = currentPeriod.days === coverageWaitingPeriodDays;
      return currentPeriod;
    });
    return insuranceCoverageParameters;
  }
  return plan.insuranceCoverageParameters;
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @param {string} planPersistentId
 * @param {Object} payload
 * @returns {Object}
 */
exports.getPlanInsuranceCoverageParameters = getPlanInsuranceCoverageParameters;
const getSelectedPlanInsuranceCoverageParameters = (state, fniType, contentType, persistentId, planPersistentId, payload) => {
  const plan = getFNIItemPlan(state, fniType, contentType, persistentId, planPersistentId);
  if ((0, _commonsUtils.isEmpty)(plan)) {
    return {
      buyersCoveredType: 'single',
      buyersCoveredThirdPartyType: '',
      coverageWaitingPeriodDays: 0,
      coverageisRetroactive: true,
      coverageThirdPartyType: '',
      truncatedTerm: 0
    };
  }
  const {
    truncatedTerms,
    buyersCovered,
    coverageWaitingPeriod
  } = plan.insuranceCoverageParameters;
  let truncatedTerm = ((payload === null || payload === void 0 ? void 0 : payload.truncatedTerms) || truncatedTerms).find(currentTerm => currentTerm.isSelected);
  truncatedTerm = truncatedTerm ? truncatedTerm.term : 0;
  const buyerCovered = ((payload === null || payload === void 0 ? void 0 : payload.buyersCovered) || buyersCovered).find(currentBuyerCovered => currentBuyerCovered.isSelected);
  const buyersCoveredType = buyerCovered ? buyerCovered.type : 'single';
  const buyersCoveredThirdPartyType = buyerCovered ? buyerCovered.thirdPartyType : '';
  const selectedCoverageWaitingPeriod = ((payload === null || payload === void 0 ? void 0 : payload.coverageWaitingPeriod) || coverageWaitingPeriod).find(currentPeriod => currentPeriod.isSelected);
  const coverageWaitingPeriodDays = selectedCoverageWaitingPeriod ? selectedCoverageWaitingPeriod.days : 0;
  const coverageisRetroactive = selectedCoverageWaitingPeriod ? selectedCoverageWaitingPeriod.isRetroactive : true;
  const coverageThirdPartyType = selectedCoverageWaitingPeriod ? selectedCoverageWaitingPeriod.thirdPartyType : '';
  return {
    truncatedTerm,
    buyersCoveredType,
    buyersCoveredThirdPartyType,
    coverageWaitingPeriodDays,
    coverageisRetroactive,
    coverageThirdPartyType
  };
};

/**
 * @param {Object} state
 * @param {string} fniType
 * @param {string} contentType
 * @param {string} persistentId
 * @param {string} planPersistentId
 * @param {Object} payload
 * @returns {Object[]}
 */
const getValidationFNIItemPlan = (state, fniType, contentType, persistentId, planPersistentId, payload) => {
  const item = getFNIItem(state, fniType, contentType, persistentId);
  let validateItem = {};
  if (!(0, _commonsUtils.isEmpty)(item)) {
    const plan = item.plans.find(currentPlan => currentPlan.persistentId === planPersistentId);
    if (plan) {
      validateItem = structuredClone(plan);
      if (contentType === 'warranties' || contentType === 'protections') {
        validateItem.protectionCoverageParameters = getSelectedPlanProtectionCoverageParameters(state, fniType, contentType, persistentId, planPersistentId, payload);
      } else if (contentType === 'insurances') {
        validateItem.insuranceCoverageParameters = getSelectedPlanInsuranceCoverageParameters(state, fniType, contentType, persistentId, planPersistentId, payload);
      }
      if (payload && payload.pricing) {
        validateItem.pricing = _objectSpread({}, payload.pricing);
      }
    }
  }
  return validateItem;
};

/**
 * @param {Object} state
 * @param {String} fniType
 * @returns {Object[]}
 */
exports.getValidationFNIItemPlan = getValidationFNIItemPlan;
const getFNIItemsToBook = (state, fniType) => {
  const protections = getFNIItems(state, fniType, 'protections');
  const warranties = getFNIItems(state, fniType, 'warranties');
  const insurances = getFNIItems(state, fniType, 'insurances');
  const itemsToBook = [];
  protections.forEach(protection => {
    const selectedPlans = getSelectedFNIItemPlans(state, fniType, 'protections', protection.persistentId);
    selectedPlans.forEach(selectedPlan => {
      if (!selectedPlan.isBooked) {
        itemsToBook.push(_objectSpread(_objectSpread({
          supplier: protection.supplier
        }, selectedPlan), {}, {
          protectionCoverageParameters: getSelectedPlanProtectionCoverageParameters(state, fniType, 'protections', protection.persistentId, selectedPlan.persistentId)
        }));
      }
    });
  });
  warranties.forEach(warranty => {
    const selectedPlans = getSelectedFNIItemPlans(state, fniType, 'warranties', warranty.persistentId);
    selectedPlans.forEach(selectedPlan => {
      if (!selectedPlan.isBooked) {
        itemsToBook.push(_objectSpread(_objectSpread({
          supplier: warranty.supplier
        }, selectedPlan), {}, {
          protectionCoverageParameters: getSelectedPlanProtectionCoverageParameters(state, fniType, 'warranties', warranty.persistentId, selectedPlan.persistentId)
        }));
      }
    });
  });
  insurances.forEach(insurance => {
    const selectedPlans = getSelectedFNIItemPlans(state, fniType, 'insurances', insurance.persistentId);
    selectedPlans.forEach(selectedPlan => {
      if (!selectedPlan.isBooked) {
        itemsToBook.push(_objectSpread(_objectSpread({
          supplier: insurance.supplier
        }, selectedPlan), {}, {
          protectionCoverageParameters: getSelectedPlanInsuranceCoverageParameters(state, fniType, 'insurances', insurance.persistentId, selectedPlan.persistentId)
        }));
      }
    });
  });
  return itemsToBook;
};

/**
 * @param {Object} state
 * @param {Object[]} items
 * @returns {Object}
 */
exports.getFNIItemsToBook = getFNIItemsToBook;
const getRequestBookFNIItemsBody = (state, items) => {
  const _getPaymentOptions = (0, _selectors.getPaymentOptions)(state),
    {
      cashPurchaseOption,
      financeOptions
    } = _getPaymentOptions,
    paymentOptionsData = _objectWithoutProperties(_getPaymentOptions, _excluded);
  return _objectSpread(_objectSpread({}, paymentOptionsData), {}, {
    items
  });
};
exports.getRequestBookFNIItemsBody = getRequestBookFNIItemsBody;
const getTabProtectionContent = state => (0, _tabItems.getTabContent)(state, getTabProtectionProtection);
exports.getTabProtectionContent = getTabProtectionContent;
const getTabWarrantyContent = state => (0, _tabItems.getTabContent)(state, getTabProtectionWarranty);
exports.getTabWarrantyContent = getTabWarrantyContent;
const getTabInsuranceContent = state => (0, _tabItems.getTabContent)(state, getTabProtectionInsurance);
exports.getTabInsuranceContent = getTabInsuranceContent;
const getTabLGMContent = state => getTabContentFromFNI(state, getTabProtectionLGM);
exports.getTabLGMContent = getTabLGMContent;
const getTabBMWContent = state => getTabContentFromFNI(state, getTabProtectionBMW);

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabBMWContent = getTabBMWContent;
const getLGMWarrantiesTableData = state => {
  var _items$warranties;
  const isReadOnly = (0, _selectors.getIsReadOnly)(state);
  const canEditWarranties = (0, _selectors.getCanEditWarranties)(state);
  const canMoveItemOutsideTransaction = (0, _selectors.getCanMoveItemOutsideTransaction)(state);
  const items = getTabLGMContent(state);
  return (items === null || items === void 0 || (_items$warranties = items.warranties) === null || _items$warranties === void 0 ? void 0 : _items$warranties.map(row => [{
    description: row.description,
    code: row.displayCode,
    isMain: true
  }].concat(row.plans.map(plan => {
    var _plan$protectionCover, _plan$protectionCover2, _plan$protectionCover3, _plan$protectionCover4, _plan$protectionCover5;
    return {
      fniType: _tabProtection.TAB_PROTECTION.LGM,
      contentType: 'warranties',
      persistentId: row.persistentId,
      planPersistentId: plan.persistentId,
      description: plan.description,
      code: plan.displayCode,
      terms: ((_plan$protectionCover = plan.protectionCoverageParameters) === null || _plan$protectionCover === void 0 ? void 0 : _plan$protectionCover.terms) || [],
      kms: ((_plan$protectionCover2 = plan.protectionCoverageParameters) === null || _plan$protectionCover2 === void 0 ? void 0 : _plan$protectionCover2.kms) || [],
      deductibleAmounts: ((_plan$protectionCover3 = plan.protectionCoverageParameters) === null || _plan$protectionCover3 === void 0 ? void 0 : _plan$protectionCover3.deductibleAmounts) || [],
      protectionAddOns: ((_plan$protectionCover4 = plan.protectionCoverageParameters) === null || _plan$protectionCover4 === void 0 ? void 0 : _plan$protectionCover4.protectionAddOns) || [],
      limitPerRepairAmounts: ((_plan$protectionCover5 = plan.protectionCoverageParameters) === null || _plan$protectionCover5 === void 0 ? void 0 : _plan$protectionCover5.limitPerRepairAmounts) || [],
      pricing: plan.pricing,
      canSlideToAmount: !isReadOnly && canEditWarranties && !plan.isBooked && plan.pricing.isEditable && !plan.pricing.inclusionIsLocked && plan.pricing.isDueAtDelivery,
      canSlideToAmountAtDelivery: !isReadOnly && canEditWarranties && !plan.isBooked && plan.pricing.isEditable && !plan.pricing.inclusionIsLocked && !plan.pricing.isDueAtDelivery,
      canSlideToOutside: !isReadOnly && canEditWarranties && !plan.isBooked && canMoveItemOutsideTransaction && plan.pricing.isEditable && plan.pricing.canBeSetOutsideTransaction && plan.pricing.isDueAtDelivery,
      isSelected: plan.isSelected,
      isDisabled: isReadOnly || !canEditWarranties || plan.isBooked
    };
  }))).flat()) || [];
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getLGMWarrantiesTableData = getLGMWarrantiesTableData;
const getLGMProtectionsTableData = state => {
  var _items$protections;
  const isReadOnly = (0, _selectors.getIsReadOnly)(state);
  const canEditProtections = (0, _selectors.getCanEditProtections)(state);
  const canMoveItemOutsideTransaction = (0, _selectors.getCanMoveItemOutsideTransaction)(state);
  const items = getTabLGMContent(state);
  return (items === null || items === void 0 || (_items$protections = items.protections) === null || _items$protections === void 0 ? void 0 : _items$protections.map(row => [{
    description: row.description,
    code: row.displayCode,
    isMain: true
  }].concat(row.plans.map(plan => {
    var _plan$protectionCover6, _plan$protectionCover7, _plan$protectionCover8, _plan$protectionCover9, _plan$protectionCover10;
    return {
      fniType: _tabProtection.TAB_PROTECTION.LGM,
      contentType: 'protections',
      persistentId: row.persistentId,
      planPersistentId: plan.persistentId,
      description: plan.description,
      code: plan.displayCode,
      terms: ((_plan$protectionCover6 = plan.protectionCoverageParameters) === null || _plan$protectionCover6 === void 0 ? void 0 : _plan$protectionCover6.terms) || [],
      kms: ((_plan$protectionCover7 = plan.protectionCoverageParameters) === null || _plan$protectionCover7 === void 0 ? void 0 : _plan$protectionCover7.kms) || [],
      deductibleAmounts: ((_plan$protectionCover8 = plan.protectionCoverageParameters) === null || _plan$protectionCover8 === void 0 ? void 0 : _plan$protectionCover8.deductibleAmounts) || [],
      protectionAddOns: ((_plan$protectionCover9 = plan.protectionCoverageParameters) === null || _plan$protectionCover9 === void 0 ? void 0 : _plan$protectionCover9.protectionAddOns) || [],
      limitPerRepairAmounts: ((_plan$protectionCover10 = plan.protectionCoverageParameters) === null || _plan$protectionCover10 === void 0 ? void 0 : _plan$protectionCover10.limitPerRepairAmounts) || [],
      pricing: plan.pricing,
      canSlideToAmount: !isReadOnly && canEditProtections && !plan.isBooked && plan.pricing.isEditable && !plan.pricing.inclusionIsLocked && plan.pricing.isDueAtDelivery,
      canSlideToAmountAtDelivery: !isReadOnly && canEditProtections && !plan.isBooked && plan.pricing.isEditable && !plan.pricing.inclusionIsLocked && !plan.pricing.isDueAtDelivery,
      canSlideToOutside: !isReadOnly && canEditProtections && !plan.isBooked && canMoveItemOutsideTransaction && plan.pricing.isEditable && plan.pricing.canBeSetOutsideTransaction && plan.pricing.isDueAtDelivery,
      isSelected: plan.isSelected,
      isDisabled: isReadOnly || !canEditProtections || plan.isBooked
    };
  }))).flat()) || [];
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getLGMProtectionsTableData = getLGMProtectionsTableData;
const getLGMInsurancesTableData = state => {
  var _items$insurances;
  const isReadOnly = (0, _selectors.getIsReadOnly)(state);
  const canEditInsurances = (0, _selectors.getCanEditInsurance)(state);
  const canMoveItemOutsideTransaction = (0, _selectors.getCanMoveItemOutsideTransaction)(state);
  const items = getTabLGMContent(state);
  return (items === null || items === void 0 || (_items$insurances = items.insurances) === null || _items$insurances === void 0 ? void 0 : _items$insurances.map(row => [{
    description: row.description,
    code: row.displayCode,
    isMain: true
  }].concat(row.plans.map(plan => {
    var _plan$insuranceCovera, _plan$insuranceCovera2, _plan$insuranceCovera3;
    return {
      fniType: _tabProtection.TAB_PROTECTION.LGM,
      contentType: 'insurances',
      persistentId: row.persistentId,
      planPersistentId: plan.persistentId,
      description: plan.description,
      code: plan.displayCode,
      truncatedTerms: ((_plan$insuranceCovera = plan.insuranceCoverageParameters) === null || _plan$insuranceCovera === void 0 ? void 0 : _plan$insuranceCovera.truncatedTerms) || [],
      buyersCovered: ((_plan$insuranceCovera2 = plan.insuranceCoverageParameters) === null || _plan$insuranceCovera2 === void 0 ? void 0 : _plan$insuranceCovera2.buyersCovered) || [],
      coverageWaitingPeriod: ((_plan$insuranceCovera3 = plan.insuranceCoverageParameters) === null || _plan$insuranceCovera3 === void 0 ? void 0 : _plan$insuranceCovera3.coverageWaitingPeriod) || [],
      pricing: plan.pricing,
      canSlideToAmount: !isReadOnly && canEditInsurances && !plan.isBooked && plan.pricing.isEditable && !plan.pricing.inclusionIsLocked && plan.pricing.isDueAtDelivery,
      canSlideToAmountAtDelivery: !isReadOnly && canEditInsurances && !plan.isBooked && plan.pricing.isEditable && !plan.pricing.inclusionIsLocked && !plan.pricing.isDueAtDelivery,
      canSlideToOutside: !isReadOnly && canEditInsurances && !plan.isBooked && canMoveItemOutsideTransaction && plan.pricing.isEditable && plan.pricing.canBeSetOutsideTransaction && plan.pricing.isDueAtDelivery,
      isSelected: plan.isSelected,
      isDisabled: isReadOnly || !canEditInsurances || plan.isBooked
    };
  }))).flat()) || [];
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getLGMInsurancesTableData = getLGMInsurancesTableData;
const getSelectedTab = state => {
  const selectedIndex = getSelectedIndex(state);
  const list = [getTabProtectionWarranty(state), getTabProtectionProtection(state), getTabProtectionInsurance(state), getTabProtectionLGM(state), getTabProtectionBMW(state)];
  const found = list.find(_ref => {
    let {
      uiIndex
    } = _ref;
    return uiIndex === selectedIndex;
  });
  return (0, _commonsUtils.isPlainObject)(found) ? found : {};
};
exports.getSelectedTab = getSelectedTab;