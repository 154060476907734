"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TradeInVehicleHelpers = require("../helpers/TradeInVehicleHelpers");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const getSelectedExteriorColor = tradeInVehicle => {
  const {
    exteriorColor,
    availableExteriorColors
  } = tradeInVehicle || {};
  const selectedExteriorColor = availableExteriorColors && availableExteriorColors.find(color => color.description === exteriorColor);
  if (selectedExteriorColor) {
    selectedExteriorColor.colorCode = selectedExteriorColor.htmlColour;
  }
  return selectedExteriorColor;
};
const getSelectedInteriorColor = tradeInVehicle => {
  const {
    interiorColor,
    availableInteriorColors
  } = tradeInVehicle || {};
  const selectedInteriorColor = availableInteriorColors && availableInteriorColors.find(color => color.description === interiorColor);
  if (selectedInteriorColor) {
    selectedInteriorColor.colorCode = selectedInteriorColor.htmlColour;
  }
  return selectedInteriorColor;
};
const mapVehicleExtraInfo = tradeInVehicle => {
  let vehicleExtraInfo = {};
  vehicleExtraInfo = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, vehicleExtraInfo), {}, {
    accidentStatus: (tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.accidentStatus) || '',
    paymentType: (tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.paymentType) || 'unknown',
    vehicleCondition: (tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.vehicleCondition) || ''
  }, (tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.balance) && {
    balance: tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.balance
  }), (tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.payment) && {
    payment: tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.payment
  }), (tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.lien) && {
    lienHolderName: tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.lien.owner
  }), (tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.dueMonth) && (tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.dueYear) && {
    expirationDate: "".concat(tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.dueMonth, "/").concat(tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.dueYear)
  });
  return vehicleExtraInfo;
};
/**
 * @param {Object} TradeInVehicle
 */
const tradeInVehicleToInternalTradeIn = (tradeInVehicle, t) => {
  if (typeof tradeInVehicle === 'object' && Object.keys(tradeInVehicle).length > 0) {
    return {
      exteriorColor: getSelectedExteriorColor(tradeInVehicle) || (0, _TradeInVehicleHelpers.getOtherColor)(t),
      exteriorColors: tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.availableExteriorColors,
      interiorColor: getSelectedInteriorColor(tradeInVehicle) || (0, _TradeInVehicleHelpers.getOtherColor)(t),
      interiorColors: tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.availableInteriorColors,
      make: tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.make,
      model: tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.model,
      options: tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.availableOptions,
      style: tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.bodyStyle,
      tradeVehicleToken: tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.tradeVehicleToken,
      trim: tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.trim,
      id: (tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.id) || '',
      name: (tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.vehicleId) || '',
      value: (tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.vehicleId) || '',
      year: tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.year,
      vin: tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.vin,
      kilometers: (tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.kilometers) || 0,
      vehicleOptions: tradeInVehicle === null || tradeInVehicle === void 0 ? void 0 : tradeInVehicle.vehicleOptions,
      vehicleExtraInfo: mapVehicleExtraInfo(tradeInVehicle)
    };
  } else return {};
};
var _default = exports.default = {
  tradeInVehicleToInternalTradeIn
};