"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateVehicleDetail = exports.updateLeadUser = exports.updateLeadType = exports.updateLeadStatus = exports.updateLeadSalesman = exports.updateLeadSalesManager = exports.updateLeadOrganizationUnit = exports.updateLeadBusinessManager = exports.updateLead = exports.sendLeadToMerlin = exports.getVehicleDetail = exports.getUserSignaturesByUserId = exports.getTrackingTypes = exports.getThirdpartyPushBySourcesAndLeadId = exports.getSubscriptionContexts = exports.getMailTemplatesByOrganizationUnitId = exports.getListByLeadIds = exports.getList = exports.getLead = exports.getContacts = exports.getContact = exports.findContactLeads = exports.createLead = void 0;
var _appsCore = require("@sm360/apps-core");
var _commonsUtils = require("@sm360/commons-utils");
var _contact = require("../helpers/contact");
var _payload = require("../config/payload.transform");
const _excluded = ["leadsDateRange", "leadSourceType", "userId", "salesManagerIds", "salespersonIds"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Get leads list
const getList = async _ref => {
  let {
    body,
    pageSize,
    pageIndex,
    request = '',
    sortBy,
    salesManagerIdCount = 0
  } = _ref;
  const _body$filters = body.filters,
    {
      leadsDateRange,
      leadSourceType,
      userId,
      salesManagerIds,
      salespersonIds
    } = _body$filters,
    restOfFilters = _objectWithoutProperties(_body$filters, _excluded);
  const newQuery = {
    filters: _objectSpread(_objectSpread({}, restOfFilters), {}, {
      contactIds: [],
      leadSourceIds: leadSourceType,
      ownerIds: userId || [],
      salesManagerIds: salesManagerIdCount === salesManagerIds.length || salesManagerIdCount === 0 ? [] : salesManagerIds,
      salespersonIds: salespersonIds || [],
      searchTerms: request ? (0, _commonsUtils.sanitizeSearch)(request).split(' ') : []
    }),
    meta: {
      from: pageIndex,
      size: pageSize,
      sort: sortBy
    }
  };
  if (leadsDateRange !== null && leadsDateRange !== void 0 && leadsDateRange.min && leadsDateRange !== null && leadsDateRange !== void 0 && leadsDateRange.max) {
    newQuery.filters.leadsDateRange = {
      min: leadsDateRange.min,
      max: leadsDateRange.max
    };
  } else {
    delete newQuery.filters.leadsDateRange;
  }
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/leads/search"), newQuery);
};
exports.getList = getList;
const getListByLeadIds = async data => {
  const {
    body,
    pageSize,
    pageIndex,
    request,
    sortBy
  } = data;
  const {
    displayArchived,
    leadIds,
    organizationId,
    organizationUnitIds,
    leadsDateRange,
    ownerIds,
    salespersonIds,
    salesManagerIds,
    leadTypeIds,
    leadStatusIds,
    leadSourceIds,
    displayWithQuote
  } = body;
  const newQuery = {
    filters: {
      displayArchived,
      leadIds,
      organizationId,
      organizationUnitIds,
      searchTerms: request ? (0, _commonsUtils.sanitizeSearch)(request).split(' ') : [],
      // additional filters
      ownerIds: ownerIds || [],
      salespersonIds: salespersonIds || [],
      salesManagerIds: salesManagerIds || [],
      leadTypeIds,
      leadStatusIds,
      leadSourceIds,
      displayWithQuote
    },
    meta: {
      from: pageIndex,
      size: pageSize,
      sort: sortBy
    }
  };
  if (leadsDateRange !== null && leadsDateRange !== void 0 && leadsDateRange.min && leadsDateRange !== null && leadsDateRange !== void 0 && leadsDateRange.max) {
    newQuery.filters.leadsDateRange = {
      min: leadsDateRange.min,
      max: leadsDateRange.max
    };
  } else {
    delete newQuery.filters.leadsDateRange;
  }
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/leads/findByIds"), newQuery);
};

// get a lead only the logged in user is allowed to see it
// returns HTTP 400 if not
exports.getListByLeadIds = getListByLeadIds;
const getLead = id => {
  return _appsCore.axios.get("".concat(CRM_ENDPOINT, "/leads/").concat(id)).then(results => {
    if (!results.data) return Promise.reject({
      response: {
        status: 404
      }
    });
    return (0, _payload.transformLeadFromBackend)(results.data);
  });
};

// create a new lead
exports.getLead = getLead;
const createLead = async _ref2 => {
  let {
    body,
    orgId
  } = _ref2;
  let contact;
  const {
    leadSourceType,
    leadStatusIds,
    leadTypeIds,
    salespersonIds,
    salesManagerIds
  } = body;

  // if no contact is present in body, this will create one
  if (!body.contact) {
    contact = (0, _contact.formatContact)(body);
  } else {
    contact = body.contact;
  }

  // cleans body of fields that should be in contact object
  const cleanedBody = (0, _contact.removeFields)(Object.assign({}, body));
  const requestBody = _objectSpread(_objectSpread({}, cleanedBody), {}, {
    contact,
    organizationId: orgId,
    salesManagerId: salesManagerIds,
    salesmanUserId: salespersonIds,
    leadSourceType: {
      id: parseInt(leadSourceType)
    },
    leadType: {
      id: parseInt(leadTypeIds)
    },
    leadStatus: {
      id: parseInt(leadStatusIds)
    },
    active: true
  });
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/leads"), requestBody);
};

// update lead
exports.createLead = createLead;
const updateLead = async _ref3 => {
  let {
    body,
    userId
  } = _ref3;
  const requestBody = (0, _payload.transformLeadToBackend)(body);
  await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/leads/").concat(body.id).concat(userId ? "?loggedUserId=".concat(userId) : ''), requestBody);
  return getLead(body.id);
};
exports.updateLead = updateLead;
const getContacts = async _ref4 => {
  let {
    queryParams,
    body
  } = _ref4;
  const queryString = Object.keys(queryParams).map(key => "".concat(key, "=").concat(queryParams[key])).join('&');
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/contacts/search/disambiguated?").concat(queryString), body, {
    ignoreAppsCoreCatch: true
  });
};
exports.getContacts = getContacts;
const findContactLeads = async contactId => {
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/leads/findByContactId?contactId=").concat(contactId));
};
exports.findContactLeads = findContactLeads;
const getContact = async id => {
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/contacts/").concat(id));
};

// Get subscriptions contexts by organization id
exports.getContact = getContact;
const getSubscriptionContexts = async id => {
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/subscriptionContexts?organizationId=").concat(id));
};

// Get a list of mail templates by organization unit id
exports.getSubscriptionContexts = getSubscriptionContexts;
const getMailTemplatesByOrganizationUnitId = async unitsQuery => {
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/search/mailTemplates?").concat(unitsQuery));
};

// Get list of user signatures by user id
exports.getMailTemplatesByOrganizationUnitId = getMailTemplatesByOrganizationUnitId;
const getUserSignaturesByUserId = async id => {
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/users/signatures?userId=").concat(id));
};
exports.getUserSignaturesByUserId = getUserSignaturesByUserId;
const getVehicleDetail = async id => {
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/clientVehicles/").concat(id));
};
exports.getVehicleDetail = getVehicleDetail;
const updateVehicleDetail = async (id, body) => {
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/clientVehicles/").concat(id), body);
};
exports.updateVehicleDetail = updateVehicleDetail;
const updateLeadType = async (leadId, newTypeId, userId, desc) => {
  const encodedDescription = desc ? "&description=".concat(encodeURIComponent(desc)) : '';
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/leads/").concat(leadId, "/leadType?newLeadTypeId=").concat(newTypeId, "&loggedUserId=").concat(userId).concat(encodedDescription));
};
exports.updateLeadType = updateLeadType;
const updateLeadStatus = async (leadId, newStatusId, userId, desc) => {
  const encodedDescription = desc ? "&description=".concat(encodeURIComponent(desc)) : '';
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/leads/").concat(leadId, "/leadStatus?leadStatusId=").concat(newStatusId, "&loggedUserId=").concat(userId).concat(encodedDescription));
};
exports.updateLeadStatus = updateLeadStatus;
const updateLeadOrganizationUnit = async (leadId, organizationUnitId, userId) => {
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/leads/").concat(leadId, "/assignOrganizationUnit?organizationUnitId=").concat(organizationUnitId, "&userId=").concat(userId));
};
exports.updateLeadOrganizationUnit = updateLeadOrganizationUnit;
const updateLeadBusinessManager = async (leadId, newBusinessManagerId, userId) => {
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/leads/").concat(leadId, "/assignBusinessManager?newBusinessManagerId=").concat(newBusinessManagerId, "&userId=").concat(userId));
};
exports.updateLeadBusinessManager = updateLeadBusinessManager;
const updateLeadSalesManager = async (leadId, newSalesManagerId, userId) => {
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/leads/").concat(leadId, "/assignSalesManager?newSalesManagerId=").concat(newSalesManagerId, "&userId=").concat(userId));
};
exports.updateLeadSalesManager = updateLeadSalesManager;
const updateLeadSalesman = async (leadId, newSalesmanUserId, userId) => {
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/leads/").concat(leadId, "/assignSalesman?newSalesmanUserId=").concat(newSalesmanUserId, "&userId=").concat(userId));
};
exports.updateLeadSalesman = updateLeadSalesman;
const updateLeadUser = async (leadId, newUserId, userId, orgId) => {
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/leads/").concat(leadId, "/assignUser?newUserId=").concat(newUserId, "&userId=").concat(userId, "&currentOrganizationUnitId=").concat(orgId));
};
exports.updateLeadUser = updateLeadUser;
const getTrackingTypes = async () => {
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/leads/static/trackingTypes"));
};
exports.getTrackingTypes = getTrackingTypes;
const getThirdpartyPushBySourcesAndLeadId = async (leadId, thirdpartySources) => {
  const thirdPartySourceQuery = thirdpartySources ? thirdpartySources.map(s => "&thirdpartySources=".concat(s)) : '';
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/thirdparty/push?leadId=").concat(leadId).concat(thirdPartySourceQuery), {
    ignoreAppsCoreCatch: true
  });
};
exports.getThirdpartyPushBySourcesAndLeadId = getThirdpartyPushBySourcesAndLeadId;
const sendLeadToMerlin = async lead => {
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/leads/thirdparty/publish"), (0, _payload.transformLeadToBackend)(lead));
};
exports.sendLeadToMerlin = sendLeadToMerlin;