"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVehicle = exports.getSimilarInventory = exports.getInventory = exports.getInventoriesIds = exports.getInteriorPhotos = exports.getInteriorColors = exports.getExteriorPhotos = exports.getExteriorColors = exports.getCatalogVehicleDetails = exports.getCatalogVehicleByMakeModelYear = exports.getCatalogVehicle = exports.getCatalogVdpUrl = void 0;
var _appsCore = require("@sm360/apps-core");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const XMS_ENDPOINT = process.env.REACT_APP_XMS_API_URL;
const NEW_CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getInventoriesIds = async orgUnitsIds => {
  const query = orgUnitsIds.map(o => "organizationUnitId=".concat(o)).join('&');
  const results = await _appsCore.axios.get("".concat(NEW_CRM_ENDPOINT, "/inventories?").concat(query));
  return results;
};
exports.getInventoriesIds = getInventoriesIds;
const getInventory = async (ids, request, pageSize, pageNumber) => {
  const body = {
    filters: {
      inventoryIds: ids.map(inv => inv.id),
      searchTerms: request ? request.replace(/\s+/g, ' ').split(' ') : [],
      searchableProperties: ['model.make.name', 'model.name', 'carYear', 'trim.name', 'price', 'odometer', 'stockNo', 'serialNo', 'newVehicleText', 'vehicleInventoryStatusText']
    },
    meta: {
      from: pageNumber,
      size: pageSize,
      sort: [{
        id: 'dateCreated',
        desc: false
      }]
    }
  };
  return await _appsCore.axios.post("".concat(NEW_CRM_ENDPOINT, "/vehicleInventories/searchByInventory"), body);
};
exports.getInventory = getInventory;
const getSimilarInventory = _ref => {
  let {
    invId,
    page,
    limit,
    orgUnitId
  } = _ref;
  const queryString = "organizationUnitId=".concat(orgUnitId, "&page=").concat(page, "&limit=").concat(limit);
  return _appsCore.axios.get("".concat(NEW_CRM_ENDPOINT, "/vehicleInventories/").concat(invId, "/similars?").concat(queryString));
};
exports.getSimilarInventory = getSimilarInventory;
const getVehicle = async inventoryId => {
  const results = await _appsCore.axios.get("".concat(XMS_ENDPOINT, "/delta/vehicles/").concat(inventoryId), {
    ignoreAppsCoreCatch: true
  });
  return results.data;
};
exports.getVehicle = getVehicle;
const getCatalogVehicle = async inventoryId => {
  return await _appsCore.axios.get("".concat(NEW_CRM_ENDPOINT, "/catalogVehicles/").concat(inventoryId), {
    ignoreAppsCoreCatch: true
  }).then(response => {
    if (response.status === 204) return Promise.reject("No catalog vehicle found for inventoryId ".concat(inventoryId));
    return Promise.resolve(response);
  });
};
exports.getCatalogVehicle = getCatalogVehicle;
const getExteriorPhotos = _ref2 => {
  let {
    newCarTrimId,
    exteriorColor
  } = _ref2;
  if (!newCarTrimId || !exteriorColor) return Promise.reject('Missing parameters');
  const colorIdQuery = exteriorColor ? "&colorId=".concat(exteriorColor) : '';
  return _appsCore.axios.get("".concat(XMS_ENDPOINT, "/catalog/exteriorPhotos?newCarTrimId=").concat(newCarTrimId).concat(colorIdQuery), {
    ignoreAppsCoreCatch: true
  });
};
exports.getExteriorPhotos = getExteriorPhotos;
const getInteriorPhotos = _ref3 => {
  let {
    newCarTrimId,
    interiorColor
  } = _ref3;
  if (!newCarTrimId || !interiorColor) return Promise.reject('Missing parameters');
  return _appsCore.axios.get("".concat(XMS_ENDPOINT, "/catalog/interiorPhotos?newCarTrimId=").concat(newCarTrimId, "&interiorColorId=").concat(interiorColor), {
    ignoreAppsCoreCatch: true
  });
};
exports.getInteriorPhotos = getInteriorPhotos;
const getCatalogVehicleByMakeModelYear = _ref4 => {
  let {
    makeId,
    modelId,
    year
  } = _ref4;
  return _appsCore.axios.get("".concat(NEW_CRM_ENDPOINT, "/catalogVehicles/vehicleByMakeYearModel?makeId=").concat(makeId, "&modelId=").concat(modelId, "&year=").concat(year), {
    ignoreAppsCoreCatch: true
  });
};
exports.getCatalogVehicleByMakeModelYear = getCatalogVehicleByMakeModelYear;
const getExteriorColors = newCarTrimId => {
  if (!newCarTrimId) return Promise.reject('Missing parameters');
  return _appsCore.axios.get("".concat(XMS_ENDPOINT, "/catalog/exteriorColors?newCarTrimId=").concat(newCarTrimId), {
    ignoreAppsCoreCatch: true
  });
};
exports.getExteriorColors = getExteriorColors;
const getInteriorColors = newCarTrimId => {
  if (!newCarTrimId) return Promise.reject('Missing parameters');
  return _appsCore.axios.get("".concat(XMS_ENDPOINT, "/catalog/interiorColors?newCarTrimId=").concat(newCarTrimId), {
    ignoreAppsCoreCatch: true
  });
};
exports.getInteriorColors = getInteriorColors;
const getCatalogVdpUrl = _ref5 => {
  let {
    newCarTrimId,
    orgUnitId,
    lang = 'en'
  } = _ref5;
  if (!newCarTrimId || !orgUnitId) return Promise.reject('Missing parameters');
  return _appsCore.axios.get("".concat(XMS_ENDPOINT, "/newCarTrims/").concat(newCarTrimId, "/url?orgUnitId=").concat(orgUnitId, "&lang=").concat(lang), {
    ignoreAppsCoreCatch: true
  });
};
exports.getCatalogVdpUrl = getCatalogVdpUrl;
const getCatalogVehicleDetails = _ref6 => {
  let {
    inventoryId,
    newCarTrimId,
    exteriorColor,
    interiorColor,
    orgUnitId,
    lang
  } = _ref6;
  return getCatalogVehicle(inventoryId).then(response => {
    var _catalogVehicle$vehic;
    const catalogVehicle = _objectSpread({}, response.data);
    const carTrimId = newCarTrimId || (catalogVehicle === null || catalogVehicle === void 0 || (_catalogVehicle$vehic = catalogVehicle.vehicleTrims) === null || _catalogVehicle$vehic === void 0 || (_catalogVehicle$vehic = _catalogVehicle$vehic[0]) === null || _catalogVehicle$vehic === void 0 ? void 0 : _catalogVehicle$vehic.id); // defaults to first available trim

    return Promise.allSettled([getExteriorColors(carTrimId)]).then(_ref7 => {
      var _exteriorColorsRes$va, _allExteriorColors$;
      let [exteriorColorsRes] = _ref7;
      // exterior color
      const allExteriorColors = Array.isArray(exteriorColorsRes === null || exteriorColorsRes === void 0 || (_exteriorColorsRes$va = exteriorColorsRes.value) === null || _exteriorColorsRes$va === void 0 ? void 0 : _exteriorColorsRes$va.data) ? exteriorColorsRes.value.data : [];
      let selectedColorId = null;
      if (exteriorColorsRes.status === 'fulfilled') {
        const selectedColor = allExteriorColors.find(d => d.value === exteriorColor);
        selectedColorId = selectedColor === null || selectedColor === void 0 ? void 0 : selectedColor.value;
        catalogVehicle.exteriorColorName = (selectedColor === null || selectedColor === void 0 ? void 0 : selectedColor.label) || '';
      }

      // fallback to first possible color to ensure we can fetch an exterior photo
      if (!selectedColorId) selectedColorId = allExteriorColors === null || allExteriorColors === void 0 || (_allExteriorColors$ = allExteriorColors[0]) === null || _allExteriorColors$ === void 0 ? void 0 : _allExteriorColors$.value;
      return Promise.allSettled([getExteriorPhotos({
        newCarTrimId: carTrimId,
        exteriorColor: selectedColorId
      }), getInteriorColors(carTrimId), getCatalogVdpUrl({
        newCarTrimId: carTrimId,
        orgUnitId,
        lang
      })]).then(_ref8 => {
        let [exteriorPhotosRes, interiorColorsRes, vdpUrlRes] = _ref8;
        // exterior photo
        if (exteriorPhotosRes.status === 'fulfilled') {
          var _exteriorPhotosRes$va;
          catalogVehicle.exteriorCatalogPhoto = ((_exteriorPhotosRes$va = exteriorPhotosRes.value.data) === null || _exteriorPhotosRes$va === void 0 ? void 0 : _exteriorPhotosRes$va[0]) || null;
        }

        // interior color
        if (interiorColorsRes.status === 'fulfilled') {
          var _interiorColorsRes$va;
          catalogVehicle.interiorColorName = ((_interiorColorsRes$va = interiorColorsRes.value.data.find(d => d.value === interiorColor)) === null || _interiorColorsRes$va === void 0 ? void 0 : _interiorColorsRes$va.label) || '';
        }

        // vdp url
        if (vdpUrlRes.status === 'fulfilled') {
          var _vdpUrlRes$value;
          catalogVehicle.vehicleDetailPageUrl = ((_vdpUrlRes$value = vdpUrlRes.value) === null || _vdpUrlRes$value === void 0 ? void 0 : _vdpUrlRes$value.data) || null;
        }
        return Promise.resolve(catalogVehicle);
      });
    });
  });
};
exports.getCatalogVehicleDetails = getCatalogVehicleDetails;