"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.yearData = exports.dateData = exports.buildOptions = void 0;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const buildOptions = (arr, t) => {
  return arr.map(item => {
    return _objectSpread(_objectSpread({}, item), {}, {
      label: typeof item.label === 'string' ? t(item.label) : item.label
    });
  });
};
exports.buildOptions = buildOptions;
const yearData = () => {
  const currentYear = new Date().getFullYear();
  const range = [...Array(currentYear - 15 - 1900).keys()].reverse().map(i => {
    const yearOption = i + 1900;
    return {
      value: yearOption,
      label: yearOption
    };
  });
  return range;
};
exports.yearData = yearData;
const dateData = () => {
  return Array.from({
    length: 31
  }, (v, k) => k + 1).map(i => {
    return {
      value: i,
      id: i,
      label: i
    };
  });
};
exports.dateData = dateData;