"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _tabProtection = require("../selectors/tabProtection");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * @param {Object} state
 * @param {String} fniType
 * @param {Object[]} bookedItems
 * @returns {Object}
 */
var _default = (state, _ref) => {
  let {
    fniType,
    bookedItems
  } = _ref;
  const fniTab = (0, _tabProtection.getFNITab)(state, fniType);
  const clonedBookedItems = structuredClone(bookedItems);
  if ((0, _commonsUtils.isEmpty)(fniTab) || (0, _commonsUtils.isEmpty)(clonedBookedItems)) {
    return state;
  }
  const updateIsBooked = items => {
    items.every((item, itemsIndex) => {
      return item.plans.every((plan, planIndex) => {
        const matchingBookedItemIndex = clonedBookedItems.findIndex(bookedItem => bookedItem.persistentId === plan.persistentId);
        if (matchingBookedItemIndex !== -1) {
          items[itemsIndex].plans[planIndex].isBooked = true;
          clonedBookedItems.splice(matchingBookedItemIndex, 1);
        }
        return !(0, _commonsUtils.isEmpty)(clonedBookedItems);
      });
    });
  };
  updateIsBooked(fniTab.content.warranties);
  if (!(0, _commonsUtils.isEmpty)(clonedBookedItems)) {
    updateIsBooked(fniTab.content.protections);
  }
  if (!(0, _commonsUtils.isEmpty)(clonedBookedItems)) {
    updateIsBooked(fniTab.content.insurances);
  }
  return _objectSpread(_objectSpread({}, state), fniTab);
};
exports.default = _default;