"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTabForms = exports.getIsPrinting = exports.getFormsToEdit = exports.getDealJacketSubmittedRequiredForms = exports.getDealJacketSubmittableForms = exports.getDealJacketSubmissionDocuments = exports.getDealJacketRequiredDocuments = exports.getDealJacketRequiredCreditPortalFormIds = exports.getDealJacketForms = exports.getDealJacketDocumentsOptions = exports.getDealJacketDocuments = exports.getDealJacketCreditPortalSubmissionStatus = exports.getDealJacketCreditPortalSubmissionIsAvailable = exports.getDealJacketCreditPortalFundingStatusCode = exports.getDealJacketCreditPortalFundingStatus = exports.getDealJacket = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _tabForms = require("../../constants/tabForms");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  FUNDING_STATUS,
  E_DOCS
} = _tabForms.TAB_FORMS;
const {
  RECEIVED,
  HELD,
  SENT,
  FAILED
} = FUNDING_STATUS;
const {
  OTHER
} = E_DOCS;
const RECEIVED_CODE = RECEIVED.code;
const HELD_CODE = HELD.code;
const SENT_CODE = SENT.code;
const FAILED_CODE = FAILED.code;
const OTHER_CODE = OTHER.code;
const REQUIRED_DOCUMENTS_CODES = Object.keys(E_DOCS).filter(key => E_DOCS[key].isRequired).map(key => E_DOCS[key].code);

/**
 * @param {Object} state
 * @returns {Object}
 */
const getTabForms = state => {
  const {
    tabForms
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabForms) ? tabForms : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabForms = getTabForms;
const getDealJacket = state => {
  const {
    dealJacket
  } = getTabForms(state);
  return dealJacket !== null && dealJacket !== void 0 ? dealJacket : {};
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getDealJacket = getDealJacket;
const getDealJacketDocuments = state => {
  const {
    creditPortalDocuments
  } = getDealJacket(state);
  return creditPortalDocuments !== null && creditPortalDocuments !== void 0 ? creditPortalDocuments : [];
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getDealJacketDocuments = getDealJacketDocuments;
const getDealJacketDocumentsOptions = state => {
  const documents = getDealJacketDocuments(state);
  return documents.map(doc => ({
    id: doc.creditPortalFormId,
    value: doc.creditPortalFormId,
    label: doc.description,
    code: doc.code,
    isMapped: doc.isMapped
  }));
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getDealJacketDocumentsOptions = getDealJacketDocumentsOptions;
const getDealJacketRequiredDocuments = state => {
  const documents = getDealJacketDocuments(state);
  const forms = getDealJacketForms(state);
  const formsToSubmit = forms.filter(form => form.creditPortalFormId && !form.hasBeenSubmitted).map(form => form.creditPortalFormId);
  const documentsToAssociate = [];
  const documentsToSubmit = [];
  documents.forEach(doc => {
    if (doc.isRequired) {
      if (!doc.isMapped) {
        documentsToAssociate.push({
          description: doc.description,
          creditPortalFormId: doc.creditPortalFormId
        });
      } else if (formsToSubmit.includes(doc.creditPortalFormId)) {
        documentsToSubmit.push(doc.description);
      }
    }
  });
  return {
    documentsToAssociate,
    documentsToSubmit
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getDealJacketRequiredDocuments = getDealJacketRequiredDocuments;
const getDealJacketSubmissionDocuments = state => {
  const documentsOptions = getDealJacketDocumentsOptions(state);
  const requiredCreditPortalFormIds = getDealJacketRequiredCreditPortalFormIds(state);
  const submittableForms = getDealJacketSubmittableForms(state);
  const submittedRequiredForms = getDealJacketSubmittedRequiredForms(state);
  const documentsOptionsForSubmission = [];
  const missingDocumentsOptionsForSubmission = [];
  documentsOptions.forEach(document => {
    const {
      value,
      label,
      code,
      isMapped
    } = document;
    const isRequired = requiredCreditPortalFormIds.includes(value);
    const isSubmittedRequired = submittedRequiredForms.some(form => form.creditPortalFormId === value);
    if (isMapped) {
      const submittableForm = submittableForms.find(form => form.creditPortalFormId === value);
      if (submittableForm) {
        if (submittableForm.uploadedFormId) {
          const {
            description,
            instanceDescription
          } = submittableForm;
          const updatedLabel = code === OTHER_CODE ? label + "".concat(!(0, _commonsUtils.isEmpty)(description) ? " - ".concat(description) : '') + "".concat(!(0, _commonsUtils.isEmpty)(instanceDescription) ? " - ".concat(instanceDescription) : '') : label;
          documentsOptionsForSubmission.push(_objectSpread(_objectSpread({}, document), {}, {
            label: updatedLabel,
            disabled: isRequired && !isSubmittedRequired
          }));
        } else if (isRequired && !isSubmittedRequired) {
          missingDocumentsOptionsForSubmission.push(document);
        }
      }
    } else if (isRequired && !isSubmittedRequired) {
      missingDocumentsOptionsForSubmission.push(document);
    }
  });
  return {
    documentsOptionsForSubmission,
    missingDocumentsOptionsForSubmission
  };
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getDealJacketSubmissionDocuments = getDealJacketSubmissionDocuments;
const getDealJacketRequiredCreditPortalFormIds = state => {
  const documents = getDealJacketDocuments(state);
  return documents.filter(doc => !(0, _commonsUtils.isEmpty)(REQUIRED_DOCUMENTS_CODES) && REQUIRED_DOCUMENTS_CODES.includes(doc.code)).map(doc => doc.creditPortalFormId);
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getDealJacketRequiredCreditPortalFormIds = getDealJacketRequiredCreditPortalFormIds;
const getDealJacketCreditPortalFundingStatus = state => {
  const {
    creditPortalFundingStatus
  } = getDealJacket(state);
  return creditPortalFundingStatus !== null && creditPortalFundingStatus !== void 0 ? creditPortalFundingStatus : '';
};

/**
 * @param {Object} state
 * @returns {Number}
 */
exports.getDealJacketCreditPortalFundingStatus = getDealJacketCreditPortalFundingStatus;
const getDealJacketCreditPortalFundingStatusCode = state => {
  const {
    creditPortalFundingStatusCode
  } = getDealJacket(state);
  return creditPortalFundingStatusCode !== null && creditPortalFundingStatusCode !== void 0 ? creditPortalFundingStatusCode : 0;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getDealJacketCreditPortalFundingStatusCode = getDealJacketCreditPortalFundingStatusCode;
const getDealJacketCreditPortalSubmissionIsAvailable = state => {
  const {
    creditPortalSubmissionIsAvailable
  } = getDealJacket(state);
  return !!creditPortalSubmissionIsAvailable;
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getDealJacketCreditPortalSubmissionIsAvailable = getDealJacketCreditPortalSubmissionIsAvailable;
const getDealJacketCreditPortalSubmissionStatus = state => {
  const {
    creditPortalSubmissionStatus
  } = getDealJacket(state);
  return creditPortalSubmissionStatus !== null && creditPortalSubmissionStatus !== void 0 ? creditPortalSubmissionStatus : '';
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getDealJacketCreditPortalSubmissionStatus = getDealJacketCreditPortalSubmissionStatus;
const getDealJacketForms = state => {
  const {
    forms
  } = getDealJacket(state);
  return forms !== null && forms !== void 0 ? forms : [];
};

/**
 * @param {Object} state
 * @returns {{fromPrint: boolean, forms: *[]}}
 */
exports.getDealJacketForms = getDealJacketForms;
const getFormsToEdit = state => {
  const {
    formsToEdit
  } = getTabForms(state);
  return formsToEdit !== null && formsToEdit !== void 0 ? formsToEdit : {
    forms: [],
    fromPrint: false
  };
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getFormsToEdit = getFormsToEdit;
const getDealJacketSubmittableForms = state => {
  const creditPortalFundingStatusCode = getDealJacketCreditPortalFundingStatusCode(state);
  const forms = getDealJacketForms(state);
  return forms.filter(form => {
    const {
      creditPortalFormId,
      hasBeenSubmitted,
      creditPortalSubmissionStatusCode
    } = form;
    return creditPortalFormId && ((creditPortalFundingStatusCode === RECEIVED_CODE || creditPortalFundingStatusCode === HELD_CODE || creditPortalFundingStatusCode === FAILED_CODE) && creditPortalSubmissionStatusCode === SENT_CODE || !hasBeenSubmitted);
  });
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getDealJacketSubmittableForms = getDealJacketSubmittableForms;
const getDealJacketSubmittedRequiredForms = state => {
  const dealJacketRequiredCreditPortalFormIds = getDealJacketRequiredCreditPortalFormIds(state);
  const forms = getDealJacketForms(state);
  return forms.filter(form => dealJacketRequiredCreditPortalFormIds.includes(form.creditPortalFormId) && form.hasBeenSubmitted);
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getDealJacketSubmittedRequiredForms = getDealJacketSubmittedRequiredForms;
const getIsPrinting = state => {
  const {
    isPrinting
  } = getTabForms(state);
  return !!isPrinting;
};
exports.getIsPrinting = getIsPrinting;