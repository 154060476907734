"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LEAD_GROUPS = void 0;
const LEAD_GROUPS = exports.LEAD_GROUPS = {
  BASE: {
    id: 'BASE',
    iconClassName: 'fa fw fa-folder',
    prefix: '',
    includedTypes: [],
    excludedTypes: []
  },
  DEPOSIT: {
    id: 'DEPOSIT',
    iconClassName: 'fa-solid fa-credit-card',
    prefix: 'notifications.leads.group.DEPOSIT',
    // using labels instead of ids because the BE is not returning IDs in the notification object (and they don't have enough bandwidth to fix it)
    includedTypes: ['Checkout - Deposit (New)/Achat - Dépôt (neuf)', 'Checkout - Deposit (Used)/Achat - Dépôt (occasion)', 'Checkout - Confirmation (New)/Achat - Confirmation (neuf)', 'Checkout - Confirmation (Used)/Achat - Confirmation (occasion)', 'Checkout - Financing (New)/Achat - Financement (neuf)', 'Checkout - Financing (Used)/Achat - Financement (occasion)'],
    excludedTypes: []
  }
};