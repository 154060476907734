"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vehicles = require("../../constants/vehicles");
var _filters = require("../../constants/filters");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const toNumberOrZero = number => Number(number) || 0;
const isNil = value => value === null || value === undefined;
const omitBy = (obj, predicate) => {
  const entries = Object.entries(obj);
  const filteredEntries = entries.filter(_ref => {
    let [key, value] = _ref;
    return !predicate(value);
  });
  return Object.fromEntries(filteredEntries);
};
var _default = (state, payload) => {
  const {
    filters: defaultFilters
  } = state;
  const {
    queryParams
  } = payload;
  const {
    lang,
    location,
    category,
    leadId,
    organizationId,
    organizationUnitId,
    contactId: inputContactId,
    accountId: inputAccountId,
    cashDown,
    drive,
    financePlan,
    includeTaxes,
    kmPerYearPlan,
    lien,
    make,
    model,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn,
    transmission,
    trim,
    year
  } = queryParams;
  const parsedAccountId = parseInt(inputAccountId, 10);
  const accountId = !Number.isNaN(parsedAccountId) ? parsedAccountId : 0;
  const parsedContactId = parseInt(inputContactId, 10);
  const contactId = !Number.isNaN(parsedContactId) && parsedContactId > 0 ? parsedContactId : null;
  const options = {
    drive,
    make,
    model,
    transmission,
    trim,
    year
  };
  defaultFilters.selected = Object.keys(_filters.KEYS).reduce((result, key) => {
    let optionsKey = options[_filters.KEYS[key]];
    result[_filters.KEYS[key]] = [];
    const parsedOptionsKey = !Number.isNaN(parseInt(optionsKey, 10)) ? parseInt(optionsKey, 10) : null;
    if (parsedOptionsKey) {
      if (_filters.KEYS[key] !== _filters.KEYS.YEAR) optionsKey = parsedOptionsKey;
      result[_filters.KEYS[key]].push(optionsKey);
    }
    return result;
  }, {});

  // Have to keep the default category if category not valid from queryParams
  if (_vehicles.CATEGORIES.indexOf(category) !== -1) {
    defaultFilters.category = category;
  }

  // Force true, have to be exactly "false" in query param to disable it
  let hasTaxesIncluded = true;
  if (includeTaxes && includeTaxes.toLowerCase() === 'false') {
    hasTaxesIncluded = false;
  }
  return _objectSpread(_objectSpread({}, state), {}, {
    config: {
      lang,
      location,
      organizationId: toNumberOrZero(organizationId),
      organizationUnitId: toNumberOrZero(organizationUnitId),
      contactId: toNumberOrZero(contactId),
      accountId: toNumberOrZero(accountId),
      leadId: toNumberOrZero(leadId)
    },
    filters: defaultFilters,
    paymentOptions: _objectSpread(_objectSpread({}, state.paymentOptions), {}, {
      kmPerYearPlan: toNumberOrZero(kmPerYearPlan) || state.paymentOptions.kmPerYearPlan,
      paymentFrequency: toNumberOrZero(paymentFrequency) || state.paymentOptions.paymentFrequency,
      term: toNumberOrZero(term) || state.paymentOptions.term,
      cashDown: toNumberOrZero(cashDown),
      tradeIn: toNumberOrZero(tradeIn),
      lien: toNumberOrZero(lien)
    }, omitBy({
      saleType,
      taxPlan,
      financePlan,
      purchaseMethod
    }, isNil)),
    hasTaxesIncluded
  });
};
exports.default = _default;