"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getQueryParams = exports.getCatalogBody = exports.getBody = void 0;
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../selectors");
var _filters = require("./filters");
var _paymentOptions = require("./paymentOptions");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * @param {Object} state
 * @returns {Object}
 */
const getQueryParams = state => {
  const {
    location,
    organizationId,
    organizationUnitId
  } = (0, _selectors.getConfig)(state);
  return {
    includeMetadata: true,
    location,
    organizationId,
    organizationUnitId
  };
};
exports.getQueryParams = getQueryParams;
const getPaymentOptionRequest = state => {
  const {
    cashDown,
    kmPerYearPlan,
    lien,
    paymentFrequency,
    purchaseMethod,
    term,
    tradeIn
  } = (0, _paymentOptions.getPaymentOptions)(state);
  return {
    cashDown,
    kmPerYearPlan,
    lien,
    paymentFrequency,
    priceIncreaseRollCount: _deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount(state),
    purchaseMethod,
    term,
    tradeIn
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getBody = state => {
  return _objectSpread({}, getPaymentOptionRequest(state));
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getBody = getBody;
const getCatalogBody = state => {
  return {
    vehicle: {
      featuredOnly: false,
      textSearch: (0, _filters.getSearchText)(state)
    },
    paymentOptionRequest: getPaymentOptionRequest(state)
  };
};
exports.getCatalogBody = getCatalogBody;