"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiSchema = exports.Schema = void 0;
const appointmentPrefix = 'actions.appointment.form';
const placeholderPrefix = 'actions.placeholders';
const sharedPrefix = 'actions.shared';
const Schema = exports.Schema = {
  type: 'object',
  properties: {
    startDate: {
      title: "".concat(appointmentPrefix, ".START_DATE"),
      type: 'string',
      showTimeInput: true,
      ioFormat: 'Timestamp',
      shouldCloseOnSelect: false,
      timeInputLabel: "".concat(appointmentPrefix, ".TIME"),
      datePickerStartYear: 2000,
      extraSelectableYears: 10
    },
    endDate: {
      title: "".concat(appointmentPrefix, ".END_DATE"),
      type: 'string',
      showTimeInput: true,
      ioFormat: 'Timestamp',
      shouldCloseOnSelect: false,
      timeInputLabel: "".concat(appointmentPrefix, ".TIME"),
      datePickerStartYear: 2000,
      extraSelectableYears: 10
    },
    assignedUserId: {
      title: "".concat(appointmentPrefix, ".OWNER"),
      type: 'string',
      enum: [],
      placeholder: "".concat(placeholderPrefix, ".SELECT_EMPLOYEE"),
      clearable: true
    },
    assignedSalespersonId: {
      title: "".concat(appointmentPrefix, ".SALESPERSON"),
      type: 'string',
      enum: [],
      placeholder: "".concat(placeholderPrefix, ".SELECT_EMPLOYEE"),
      clearable: true
    },
    assignedManagerId: {
      title: "".concat(appointmentPrefix, ".BUSINESS_MANAGER"),
      type: 'string',
      enum: [],
      placeholder: "".concat(placeholderPrefix, ".SELECT_EMPLOYEE"),
      clearable: true
    },
    appointmentType: {
      title: '',
      type: 'object',
      properties: {
        id: {
          title: "".concat(appointmentPrefix, ".APPOINTMENT_TYPE"),
          type: 'string',
          enum: [],
          placeholder: "".concat(placeholderPrefix, ".SELECT_APPOINTMENT_TYPE")
        }
      },
      required: ['id']
    },
    activityStatusId: {
      title: "".concat(sharedPrefix, ".activityStatuses.TITLE"),
      type: 'string',
      default: 1,
      enum: [1, 3, 4, 5, 6, 7, 8],
      enumNames: ["".concat(sharedPrefix, ".activityStatuses.OPTIONS.ACTIVE"), "".concat(sharedPrefix, ".activityStatuses.OPTIONS.CANCELED"), "".concat(sharedPrefix, ".activityStatuses.OPTIONS.COMPLETED"), "".concat(sharedPrefix, ".activityStatuses.OPTIONS.NA"), "".concat(sharedPrefix, ".activityStatuses.OPTIONS.DELETED"), "".concat(sharedPrefix, ".activityStatuses.OPTIONS.INVALID"), "".concat(sharedPrefix, ".activityStatuses.OPTIONS.DUPLICATE")]
    },
    guests: {
      title: "".concat(appointmentPrefix, ".GUESTS"),
      type: 'string',
      enum: [],
      placeholder: "".concat(placeholderPrefix, ".ADD_GUEST"),
      description: "".concat(appointmentPrefix, ".GUESTS_DESCRIPTION"),
      descriptionPosition: 'left',
      clearable: true,
      maxMenuHeight: 250,
      validNewOptionRegex: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },
    location: {
      title: "".concat(appointmentPrefix, ".LOCATION"),
      type: 'string',
      name: 'appointment-location',
      autocomplete: true,
      clearable: true
    },
    subject: {
      title: "".concat(sharedPrefix, ".SUBJECT"),
      type: 'string',
      name: 'appointment-subject',
      autocomplete: true,
      clearable: true
    },
    details: {
      title: "".concat(appointmentPrefix, ".DETAILS"),
      type: 'string',
      clearable: true
    }
  },
  required: ['details', 'subject', 'startDate', 'endDate', 'appointmentType', 'assignedUserId']
};
const UiSchema = exports.UiSchema = {
  startDate: {
    'ui:widget': 'dateWidget'
  },
  endDate: {
    'ui:widget': 'dateWidget'
  },
  details: {
    'ui:widget': 'textareaWidget'
  },
  assignedUserId: {},
  assignedSalespersonId: {},
  assignedManagerId: {},
  appointmentType: {},
  activityStatusId: {},
  guests: {
    'ui:widget': 'CreatableSelectWidget'
  },
  location: {},
  subject: {}
};