"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiSchema = exports.Schema = void 0;
const taskPrefix = 'actions.task.form';
const placeholderPrefix = 'actions.placeholders';
const sharedPrefix = 'actions.shared';
const Schema = exports.Schema = {
  type: 'object',
  properties: {
    startDate: {
      title: "".concat(taskPrefix, ".START_DATE"),
      type: 'string',
      showTimeInput: true,
      ioFormat: 'Timestamp',
      shouldCloseOnSelect: false,
      timeInputLabel: "".concat(taskPrefix, ".TIME"),
      datePickerStartYear: 2000,
      extraSelectableYears: 10
    },
    dueDate: {
      title: "".concat(taskPrefix, ".DUE_DATE"),
      type: 'string',
      showTimeInput: true,
      ioFormat: 'Timestamp',
      shouldCloseOnSelect: false,
      timeInputLabel: "".concat(taskPrefix, ".TIME"),
      datePickerStartYear: 2000,
      extraSelectableYears: 10
    },
    assignedUserId: {
      title: "".concat(taskPrefix, ".ASSIGNED_EMPLOYEE"),
      type: 'string',
      enum: [],
      placeholder: "".concat(placeholderPrefix, ".SELECT_EMPLOYEE")
    },
    taskType: {
      title: '',
      type: 'object',
      properties: {
        id: {
          title: "".concat(taskPrefix, ".TASK_TYPE"),
          type: 'string',
          enum: [],
          enumNames: []
        }
      },
      required: ['id']
    },
    activityStatusId: {
      title: "".concat(sharedPrefix, ".activityStatuses.TITLE"),
      type: 'string',
      default: 1,
      enum: [1, 3, 4, 5, 6, 7, 8],
      enumNames: ["".concat(sharedPrefix, ".activityStatuses.OPTIONS.ACTIVE"), "".concat(sharedPrefix, ".activityStatuses.OPTIONS.CANCELED"), "".concat(sharedPrefix, ".activityStatuses.OPTIONS.COMPLETED"), "".concat(sharedPrefix, ".activityStatuses.OPTIONS.NA"), "".concat(sharedPrefix, ".activityStatuses.OPTIONS.DELETED"), "".concat(sharedPrefix, ".activityStatuses.OPTIONS.INVALID"), "".concat(sharedPrefix, ".activityStatuses.OPTIONS.DUPLICATE")]
    },
    priority: {
      title: '',
      type: 'object',
      properties: {
        id: {
          title: "".concat(taskPrefix, ".PRIORITY.TITLE"),
          type: 'string',
          default: 2,
          enum: [1, 2],
          enumNames: ["".concat(taskPrefix, ".PRIORITY.OPTIONS.HIGH_PRIORITY"), "".concat(taskPrefix, ".PRIORITY.OPTIONS.NORMAL_PRIORITY")]
        }
      }
    },
    subject: {
      title: "".concat(sharedPrefix, ".SUBJECT"),
      type: 'string',
      name: 'task-subject',
      autocomplete: true
    },
    details: {
      title: "".concat(taskPrefix, ".DETAILS"),
      type: 'string'
    }
  },
  required: ['details', 'subject', 'startDate', 'dueDate', 'assignedUserId']
};
const UiSchema = exports.UiSchema = {
  startDate: {
    'ui:widget': 'dateWidget'
  },
  dueDate: {
    'ui:widget': 'dateWidget'
  },
  details: {
    'ui:widget': 'textareaWidget'
  },
  assignedUserId: {},
  taskType: {},
  activityStatusId: {},
  priority: {},
  subject: {}
};