"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getQueryParams = exports.getBodyVehicleMatch = exports.getBody = void 0;
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../selectors");
var _paymentOptions = require("./paymentOptions");
var _content = require("./content");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * @param {Object} state
 * @returns {Object}
 */
const getBody = state => {
  const {
    accountId,
    make
  } = (0, _selectors.getConfig)(state);
  const {
    configurationId,
    modelTrimCode,
    transactionToken
  } = (0, _selectors.getBaseProps)(state);
  const year = (0, _content.getYear)(state);
  const financePlan = (0, _paymentOptions.getSelectedFinancePlan)(state);
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, (0, _selectors.getPlan)(state)), (0, _selectors.getPurchaseMethod)(state)), (0, _selectors.getTradeIn)(state)), {}, {
    accountId,
    configurationId,
    financePlan,
    make,
    modelTrimCode,
    transactionToken,
    year
  });
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getBody = getBody;
const getQueryParams = state => {
  const {
    location,
    organizationId,
    organizationUnitId
  } = (0, _selectors.getConfig)(state);
  return {
    location,
    organizationId,
    organizationUnitId,
    priceIncreaseRollCount: _deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount(state)
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getQueryParams = getQueryParams;
const getBodyVehicleMatch = (state, vehicle) => {
  const {
    accountId,
    leadId
  } = (0, _selectors.getConfig)(state);
  const selectedFinancePlan = (0, _paymentOptions.getSelectedFinancePlan)(state);
  const {
    configurationId,
    make,
    modelTrimCode,
    model,
    odometer,
    paymentOptions,
    retail,
    serialNo: vin,
    stockNo: stockNumber,
    vehicleId,
    year
  } = vehicle;
  const {
    cashDown,
    kmPerYearPlan,
    lien,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn,
    vehicleCondition
  } = paymentOptions;
  return {
    accountId,
    cashDown,
    configurationId,
    financePlan: selectedFinancePlan,
    kmPerYearPlan,
    leadId,
    lien,
    make: make.name,
    model: model.name,
    modelTrimCode,
    odometer,
    paymentFrequency,
    purchaseMethod,
    retail,
    saleType,
    stockNumber,
    taxPlan,
    term,
    tradeIn,
    vehicleCondition,
    vehicleId,
    vin,
    year,
    transactionTokenCopy: paymentOptions.transactionToken
  };
};
exports.getBodyVehicleMatch = getBodyVehicleMatch;