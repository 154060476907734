"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTradeVehicleToken = exports.searchCatalogVehicles = exports.getVehicleAvailableData = exports.getTradeInById = exports.default = exports.createTradeInWithToken = void 0;
var _appsCore = require("@sm360/apps-core");
var _commonsUtils = require("@sm360/commons-utils");
var _TradeInVehicleHelpers = require("../tools/helpers/TradeInVehicleHelpers");
const CRM_API_URL = process.env.REACT_APP_CRM_API_URL;

/**
 * /tradeVehicle/catalog: Call to get vehicle information as per the vehicle typed in auto complete
 * @param {String} [query] The input from auto complete
 * @param {Obj} [orgDetails] Organization details - orgId, orgUnitId
 * @param {String} [language] Language
 * @returns {Promise} An array list of vehicles matched with autocomplete input
 */
const searchCatalogVehicles = async (query, orgDetails, language, t) => {
  if (!query) {
    return Promise.resolve([]);
  }
  const {
    orgId,
    orgUnitId
  } = orgDetails || {};
  // Considered for this version we might have isVIN and maxEntries values remain same
  const isVIN = false;
  const maxEntries = 10;
  const params = "orgId=".concat(orgId, "&orgUnitId=").concat(orgUnitId, "&lang=").concat(language, "&vin=").concat(isVIN, "&maxEntries=").concat(maxEntries);
  const url = "".concat(CRM_API_URL, "/tradeVehicle/catalog?").concat(params);
  const catalogVehiclesList = [];
  return _appsCore.axios.post(url, query).then(_ref => {
    let {
      data
    } = _ref;
    if (data.error) {
      return Promise.reject(data.error);
    }
    if (Array.isArray(data.years)) {
      data.years.map(year => {
        return year.makes.map(make => {
          return make.models.map(model => {
            return model.trims.map(trim => {
              return trim.styles.map(style => {
                const vehicleString = "".concat(year.year, " ").concat(make.make, " ").concat(model.model, " ").concat(trim.trim, " ").concat(style.style);
                const vehicleItem = {
                  value: vehicleString,
                  make: make.make,
                  model: model.model,
                  year: year.year,
                  style: style.style,
                  trim: trim.trim,
                  exteriorColors: (0, _TradeInVehicleHelpers.formatVehicleColors)(style.exteriorColours, t),
                  interiorColors: (0, _TradeInVehicleHelpers.formatVehicleColors)(style.interiorTrims, t),
                  options: style.options,
                  tradeVehicleToken: style.tradeVehicleToken,
                  vin: style.vin
                };
                return catalogVehiclesList.push({
                  label: vehicleString,
                  value: vehicleString,
                  info: JSON.stringify(vehicleItem)
                });
              });
            });
          });
        });
      });
    }
    return Promise.resolve(catalogVehiclesList);
  }).catch(err => {
    return Promise.reject(err);
  });
};

/**
 * /tradeVehicle/token: Call to get updated token after selecting color, kilometers and options for the trade vehicle
 * @param {String} [tradeVehicleToken] Token retrieved from update token endpoint
 * @param {Integer} [mileage] Selected kilometers value
 * @param {String} [exteriorColor] Selected color
 * @param {Array} [selectedOptions] Array of selected options code
 * @param {Obj} [orgDetails] Organization details - orgId, orgUnitId
 * @param {String} [vin] vehicle identification number
 * @param {Integer} [valuation] official value for the vehicle
 * @param {String} [language] Language
 * @returns {String} A Updated valuation token which would be use to get an estimation on later step
 */
exports.searchCatalogVehicles = searchCatalogVehicles;
const updateTradeVehicleToken = async (tradeVehicleToken, mileage, exteriorColor, interiorColor, selectedOptions, orgDetails, vin, valuation, language) => {
  const {
    orgId,
    orgUnitId
  } = orgDetails || {};

  // For this version considered questions would be null all the time
  // In future if need to have, pass it as param
  const questions = null;
  const params = "orgId=".concat(orgId, "&orgUnitId=").concat(orgUnitId, "&lang=").concat(language);
  const body = {
    tradeVehicleToken,
    mileage,
    exteriorColor,
    interiorTrim: interiorColor,
    selectedOptions,
    questions,
    vin,
    valuation
  };
  const url = "".concat(CRM_API_URL, "/tradeVehicle/token?").concat(params);
  return _appsCore.axios.post(url, body).then(_ref2 => {
    let {
      data
    } = _ref2;
    if (data.error) {
      return Promise.reject(data.error);
    }
    return Promise.resolve(data);
  }).catch(err => {
    return Promise.reject(err);
  });
};

/**
 * Call to get trade-in vehicle from Showroom backend API
 * @param {String} [id] Trade-in vehicle Id, got by /tradeVehicle endpoint
 * @returns {Promise} An object representing the vehicle
 */
exports.updateTradeVehicleToken = updateTradeVehicleToken;
const getTradeInById = async id => {
  const url = "".concat(CRM_API_URL, "/tradeVehicle/").concat(id);
  return _appsCore.axios.get(url).then(_ref3 => {
    let {
      data
    } = _ref3;
    if (data.error) {
      return Promise.reject(data.error);
    }
    return Promise.resolve(data);
  }).catch(err => {
    return Promise.reject(err.response.data.errMsg);
  });
};

/**
 * /tradeVehicle: Call to create a trade-in entity in the backend using a tradeVehicleToken
 * @param {String} [tradeVehicleToken] Trade-in vehicle token which encodes several vehicle informations
 * @returns {Promise} The id of the created trade-in entity or an error message if the creation failed
 */
exports.getTradeInById = getTradeInById;
const createTradeInWithToken = async tradeVehicleToken => {
  const url = "".concat(CRM_API_URL, "/tradeVehicle");
  return _appsCore.axios.put(url, {
    tradeVehicleToken
  }).then(_ref4 => {
    let {
      data
    } = _ref4;
    if (data.error) {
      return Promise.reject(data.error);
    }
    return Promise.resolve(data.id);
  }).catch(err => {
    return Promise.reject(err.response.data.errMsg);
  });
};
exports.createTradeInWithToken = createTradeInWithToken;
const getVehicleAvailableData = async (tradeVehicle, orgDetails, language, t) => {
  return searchCatalogVehicles({
    query: tradeVehicle.vehicleId
  }, orgDetails, language, t).then(tradeVehicleAvailableData => {
    if ((tradeVehicleAvailableData === null || tradeVehicleAvailableData === void 0 ? void 0 : tradeVehicleAvailableData.length) > 0) {
      const trimmedVehicleId = tradeVehicle.vehicleId.replace(/\s+/g, ' ').trim();
      const filteredData = tradeVehicleAvailableData.find(vehicle => {
        // ensure vehicle label's extra spaces are trimmed
        const trimmedVehicleLabel = vehicle.label.replace(/\s+/g, ' ').trim();
        return trimmedVehicleLabel === trimmedVehicleId;
      });
      const vehicleInfos = (0, _commonsUtils.validJson)(filteredData === null || filteredData === void 0 ? void 0 : filteredData.info) ? JSON.parse(filteredData.info) : null;
      const {
        exteriorColors,
        interiorColors,
        options
      } = vehicleInfos || {};
      return Promise.resolve({
        exteriorColors,
        interiorColors,
        options
      });
    }
    return Promise.reject();
  }).catch(err => {
    return Promise.reject(err);
  });
};
exports.getVehicleAvailableData = getVehicleAvailableData;
var _default = exports.default = {
  searchCatalogVehicles,
  updateTradeVehicleToken,
  getTradeInById,
  createTradeInWithToken,
  getVehicleAvailableData
};