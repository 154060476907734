"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllMatchVehicles = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _request = require("../../tools/request");
var _StatusService = require("../StatusService");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const VEHICLE_API_URL = process.env.REACT_APP_VEHICLE_API_URL;
const TYPE_ALL = 'all';

/**
 * @param {String} transactionToken
 * @param {Object} queryParams
 * @param {Object} paymentOptions
 * @param {String} matchType
 * @param {Boolean} onlyCount
 * @returns {Promise}
 */
const baseRequest = async function (transactionToken, queryParams, paymentOptions) {
  let matchType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : TYPE_ALL;
  let onlyCount = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  const params = _objectSpread(_objectSpread({}, queryParams), {}, {
    matchType
  });
  const body = {
    transactionToken,
    filters: {
      onlyCount,
      vehicleInventoryStatuses: [_StatusService.VEHICLE_STATUS.FOR_SALE, _StatusService.VEHICLE_STATUS.ON_HOLD]
    },
    paymentOptionRequest: paymentOptions
  };
  return (0, _request.post)("".concat(VEHICLE_API_URL, "/inventory/vehicles/match"), body, {
    params
  });
};

/**
 * @param {Object} response
 * @param {String} keyName - Name of the prop to get
 * @return {Object[]}
 */
const transformResponseToList = (response, keyName) => {
  const matches = response[keyName];
  if (!(0, _commonsUtils.isPlainObject)(matches)) {
    return [];
  }
  const {
    inventoryVehicles
  } = matches;
  return Array.isArray(inventoryVehicles) ? inventoryVehicles : [];
};

/**
 * @param {String} transactionToken
 * @param {Object} queryParams
 * @param {Object} paymentOptions
 * @returns {Array} [perfectMatches, similarMatches]
 */
const getAllMatchVehicles = async function () {
  const response = await baseRequest(...arguments);
  if (!(0, _commonsUtils.isPlainObject)(response)) {
    return [[], []];
  }
  return [transformResponseToList(response, 'perfectMatches'), transformResponseToList(response, 'similarMatches')];
};
exports.getAllMatchVehicles = getAllMatchVehicles;