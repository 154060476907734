"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setVisibleCustomer = exports.setUnlistedVehicleTrims = exports.setUnlistedVehicleModels = exports.setUnlistedVehicleMakes = exports.setUnlistedVehicleFormData = exports.setLocation = exports.setLoadingSuccess = exports.setLeadInfo = exports.setIsUnlistedVehicle = exports.setIsUnlistedNew = exports.setDeskingAccess = exports.setCanCreateUnlistedVehicle = exports.resetUnlistedVehicleTrims = exports.resetUnlistedVehicleMakes = exports.resetUnlistedVehicleFormData = exports.loadQuoteSuccess = exports.loadGarageSuccess = exports.initializeStore = exports.default = exports.INITIAL_STATE = void 0;
var _reduxsauce = require("reduxsauce");
var _deskingError = require("@sm360/desking-error");
var _deskingCommons = require("@sm360/desking-commons");
var _actions = require("./actions");
var _selectors = require("./selectors");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const INITIAL_STATE = exports.INITIAL_STATE = _objectSpread(_objectSpread(_objectSpread({}, _deskingError.errorInitialState), _deskingCommons.priceIncreaseInitialState), {}, {
  isLoading: true,
  // Config
  config: {
    lang: 'en',
    location: null,
    organizationId: 0,
    organizationUnitId: 0,
    leadId: 0,
    accountId: 0
  },
  // Check some rights
  hasDeskingAccess: false,
  canCreateUnlistedVehicle: false,
  // VehicleDetails
  quote: {},
  garage: {},
  unlistedVehicle: {
    isUnlistedVehicle: false,
    isUnlistedNew: false,
    unlistedVehicleMakes: [],
    unlistedVehicleModels: [],
    unlistedVehicleTrims: [],
    vehicleFormData: {
      make: null,
      model: null,
      trim: null,
      year: new Date().getFullYear(),
      odometer: 0,
      sellingPrice: 0
    }
  }
});
const initializeStore = (state, payload) => {
  const {
    queryParams
  } = payload;
  const {
    lang,
    location = null,
    organizationId,
    organizationUnitId,
    leadId,
    accountId = null,
    contactId
  } = queryParams;
  return _objectSpread(_objectSpread({}, state), {}, {
    config: _objectSpread(_objectSpread({}, state.config), {}, {
      lang,
      location,
      organizationId,
      organizationUnitId,
      leadId,
      accountId,
      contactId
    })
  });
};
exports.initializeStore = initializeStore;
const setLoadingSuccess = state => _objectSpread(_objectSpread({}, state), {}, {
  isLoading: false
});
exports.setLoadingSuccess = setLoadingSuccess;
const loadGarageSuccess = (state, _ref) => {
  let {
    garage
  } = _ref;
  return _objectSpread(_objectSpread({}, state), {}, {
    garage
  });
};
exports.loadGarageSuccess = loadGarageSuccess;
const loadQuoteSuccess = (state, _ref2) => {
  let {
    quote
  } = _ref2;
  return _objectSpread(_objectSpread({}, state), {}, {
    quote
  });
};
exports.loadQuoteSuccess = loadQuoteSuccess;
const setDeskingAccess = (state, _ref3) => {
  let {
    hasDeskingAccess
  } = _ref3;
  return _objectSpread(_objectSpread({}, state), {}, {
    hasDeskingAccess
  });
};
exports.setDeskingAccess = setDeskingAccess;
const setCanCreateUnlistedVehicle = (state, _ref4) => {
  let {
    canCreateUnlistedVehicle
  } = _ref4;
  return _objectSpread(_objectSpread({}, state), {}, {
    canCreateUnlistedVehicle
  });
};
exports.setCanCreateUnlistedVehicle = setCanCreateUnlistedVehicle;
const setLocation = (state, _ref5) => {
  let {
    location
  } = _ref5;
  return _objectSpread(_objectSpread({}, state), {}, {
    config: _objectSpread(_objectSpread({}, (0, _selectors.getConfig)(state)), {}, {
      location
    })
  });
};
exports.setLocation = setLocation;
const setVisibleCustomer = (state, _ref6) => {
  let {
    isVisibleCustomer
  } = _ref6;
  return _objectSpread(_objectSpread({}, state), {}, {
    quote: _objectSpread(_objectSpread({}, (0, _selectors.getQuote)(state)), {}, {
      isVisibleCustomer
    })
  });
};
exports.setVisibleCustomer = setVisibleCustomer;
const setIsUnlistedVehicle = (state, _ref7) => {
  let {
    isUnlistedVehicle
  } = _ref7;
  return _objectSpread(_objectSpread({}, state), {}, {
    unlistedVehicle: _objectSpread(_objectSpread({}, (0, _selectors.getUnlistedVehicle)(state)), {}, {
      isUnlistedVehicle
    })
  });
};
exports.setIsUnlistedVehicle = setIsUnlistedVehicle;
const setIsUnlistedNew = (state, _ref8) => {
  let {
    isUnlistedNew
  } = _ref8;
  return _objectSpread(_objectSpread({}, state), {}, {
    unlistedVehicle: _objectSpread(_objectSpread({}, (0, _selectors.getUnlistedVehicle)(state)), {}, {
      isUnlistedNew
    })
  });
};
exports.setIsUnlistedNew = setIsUnlistedNew;
const setUnlistedVehicleMakes = (state, _ref9) => {
  let {
    unlistedVehicleMakes
  } = _ref9;
  return _objectSpread(_objectSpread({}, state), {}, {
    unlistedVehicle: _objectSpread(_objectSpread({}, (0, _selectors.getUnlistedVehicle)(state)), {}, {
      unlistedVehicleMakes
    })
  });
};
exports.setUnlistedVehicleMakes = setUnlistedVehicleMakes;
const setUnlistedVehicleModels = (state, _ref10) => {
  let {
    unlistedVehicleModels
  } = _ref10;
  return _objectSpread(_objectSpread({}, state), {}, {
    unlistedVehicle: _objectSpread(_objectSpread({}, (0, _selectors.getUnlistedVehicle)(state)), {}, {
      unlistedVehicleModels
    })
  });
};
exports.setUnlistedVehicleModels = setUnlistedVehicleModels;
const setUnlistedVehicleTrims = (state, _ref11) => {
  let {
    unlistedVehicleTrims
  } = _ref11;
  return _objectSpread(_objectSpread({}, state), {}, {
    unlistedVehicle: _objectSpread(_objectSpread({}, (0, _selectors.getUnlistedVehicle)(state)), {}, {
      unlistedVehicleTrims
    })
  });
};
exports.setUnlistedVehicleTrims = setUnlistedVehicleTrims;
const setUnlistedVehicleFormData = (state, _ref12) => {
  let {
    formField,
    formValue
  } = _ref12;
  const formData = Object.assign((0, _selectors.getUnlistedVehicleFormData)(state), {
    [formField]: formValue
  });
  return _objectSpread(_objectSpread({}, state), {}, {
    unlistedVehicle: _objectSpread(_objectSpread({}, (0, _selectors.getUnlistedVehicle)(state)), {}, {
      vehicleFormData: _objectSpread({}, formData)
    })
  });
};
exports.setUnlistedVehicleFormData = setUnlistedVehicleFormData;
const resetUnlistedVehicleMakes = state => _objectSpread(_objectSpread({}, state), {}, {
  unlistedVehicle: _objectSpread(_objectSpread({}, (0, _selectors.getUnlistedVehicle)(state)), {}, {
    unlistedVehicleModels: [],
    vehicleFormData: _objectSpread(_objectSpread({}, state.unlistedVehicle.vehicleFormData), {}, {
      model: null,
      trim: null
    })
  })
});
exports.resetUnlistedVehicleMakes = resetUnlistedVehicleMakes;
const resetUnlistedVehicleTrims = state => _objectSpread(_objectSpread({}, state), {}, {
  unlistedVehicle: _objectSpread(_objectSpread({}, (0, _selectors.getUnlistedVehicle)(state)), {}, {
    unlistedVehicleTrims: [],
    vehicleFormData: _objectSpread(_objectSpread({}, state.unlistedVehicle.vehicleFormData), {}, {
      trim: null
    })
  })
});
exports.resetUnlistedVehicleTrims = resetUnlistedVehicleTrims;
const resetUnlistedVehicleFormData = state => _objectSpread(_objectSpread({}, state), {}, {
  unlistedVehicle: _objectSpread(_objectSpread({}, (0, _selectors.getUnlistedVehicle)(state)), {}, {
    unlistedVehicleMakes: [],
    unlistedVehicleModels: [],
    unlistedVehicleTrims: [],
    vehicleFormData: {
      make: null,
      model: null,
      trim: null,
      year: new Date().getFullYear(),
      odometer: 0,
      sellingPrice: 0
    }
  })
});
exports.resetUnlistedVehicleFormData = resetUnlistedVehicleFormData;
const setLeadInfo = (state, _ref13) => {
  let {
    leadInfo
  } = _ref13;
  return _objectSpread(_objectSpread({}, state), {}, {
    salesPersonnel: _objectSpread({}, leadInfo)
  });
};
exports.setLeadInfo = setLeadInfo;
var _default = exports.default = (0, _reduxsauce.createReducer)(INITIAL_STATE, _objectSpread(_objectSpread(_objectSpread({}, _deskingError.errorReducers), _deskingCommons.priceIncreaseReducers), {}, {
  [_actions.Types.INITIALIZE_STORE]: initializeStore,
  [_actions.Types.LOAD_QUOTE_SUCCESS]: loadQuoteSuccess,
  [_actions.Types.LOAD_GARAGE_SUCCESS]: loadGarageSuccess,
  [_actions.Types.SET_LOADING_SUCCESS]: setLoadingSuccess,
  [_actions.Types.SET_DESKING_ACCESS]: setDeskingAccess,
  [_actions.Types.SET_CAN_CREATE_UNLISTED_VEHICLE]: setCanCreateUnlistedVehicle,
  [_actions.Types.SET_LOCATION]: setLocation,
  [_actions.Types.SET_VISIBLE_CUSTOMER]: setVisibleCustomer,
  [_actions.Types.SET_IS_UNLISTED_VEHICLE]: setIsUnlistedVehicle,
  [_actions.Types.SET_IS_UNLISTED_NEW]: setIsUnlistedNew,
  [_actions.Types.SET_UNLISTED_VEHICLE_MAKES]: setUnlistedVehicleMakes,
  [_actions.Types.SET_UNLISTED_VEHICLE_MODELS]: setUnlistedVehicleModels,
  [_actions.Types.SET_UNLISTED_VEHICLE_TRIMS]: setUnlistedVehicleTrims,
  [_actions.Types.SET_UNLISTED_VEHICLE_FORM_DATA]: setUnlistedVehicleFormData,
  [_actions.Types.RESET_UNLISTED_VEHICLE_FORM_DATA]: resetUnlistedVehicleFormData,
  [_actions.Types.RESET_UNLISTED_VEHICLE_MAKES]: resetUnlistedVehicleMakes,
  [_actions.Types.RESET_UNLISTED_VEHICLE_TRIMS]: resetUnlistedVehicleTrims,
  [_actions.Types.SET_LEAD_INFO]: setLeadInfo
}));