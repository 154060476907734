"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
const useIntersectionObserver = (elementRef, options) => {
  const {
    threshold = 0,
    root = null,
    rootMargin = '0%'
  } = options || {};
  const [entry, setEntry] = (0, _react.useState)();
  const updateEntry = _ref => {
    let [entry] = _ref;
    setEntry(entry);
  };
  (0, _react.useEffect)(() => {
    const node = elementRef === null || elementRef === void 0 ? void 0 : elementRef.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;
    if (!hasIOSupport || !node) return;
    const observerParams = {
      threshold,
      root,
      rootMargin
    };
    const observer = new IntersectionObserver(updateEntry, observerParams);
    observer.observe(node);
    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef === null || elementRef === void 0 ? void 0 : elementRef.current, JSON.stringify(threshold), root, rootMargin]);
  return entry || {};
};
var _default = exports.default = useIntersectionObserver;