"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _selectors = require("../selectors");
var _validationHelpers = require("./validationHelpers");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * - We take the root negociationTransaction in validation, and apply it to the store
 * - The saga takes care of reloading the current step with these new params
 *
 * @param {Object} state
 * @returns {Object}
 */
var _default = state => {
  const {
    negociationTransaction
  } = (0, _selectors.getValidationContent)(state);

  // Safety check, empty data
  if (Object.keys(negociationTransaction).length === 0) {
    return state;
  }

  // Edge case not handled by API yet, but will trigger a fatal error if we store a configurationId at 0
  if (negociationTransaction.configurationId === 0) {
    return _objectSpread(_objectSpread({}, state), {}, {
      validation: {
        isValid: true
      }
    });
  }
  return _objectSpread(_objectSpread({}, state), (0, _validationHelpers.applyNegociationTransaction)(state, negociationTransaction));
};
exports.default = _default;