"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSignature = exports.getSignatures = exports.getSignature = exports.deleteSignature = exports.createSignature = void 0;
var _appsCore = require("@sm360/apps-core");
const NEW_CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Get signatures list
const getSignatures = async userId => {
  return await _appsCore.axios.get("".concat(NEW_CRM_ENDPOINT, "/users/signatures?userId=").concat(userId));
};
exports.getSignatures = getSignatures;
const getSignature = async id => {
  return await _appsCore.axios.get("".concat(NEW_CRM_ENDPOINT, "/users/signatures/").concat(id));
};
exports.getSignature = getSignature;
const createSignature = async body => {
  return await _appsCore.axios.post("".concat(NEW_CRM_ENDPOINT, "/users/signatures"), body);
};
exports.createSignature = createSignature;
const updateSignature = async body => {
  const signatureId = body === null || body === void 0 ? void 0 : body.id;
  return await _appsCore.axios.put("".concat(NEW_CRM_ENDPOINT, "/users/signatures/").concat(signatureId), body);
};
exports.updateSignature = updateSignature;
const deleteSignature = async id => {
  return await _appsCore.axios.delete("".concat(NEW_CRM_ENDPOINT, "/users/signatures/").concat(id));
};
exports.deleteSignature = deleteSignature;