"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasDeskingAccess = exports.hasBuilderPricesAccess = exports.getUserInfosRequest = exports.getOrgRights = exports.canCreateUnlistedVehicle = void 0;
var _request = require("../../tools/request");
const AUTHORIZATION_URL = process.env.REACT_APP_AUTHORIZATION_URL;

/**
 * @param {String} accessName
 * @returns {Boolean}
 */
const baseUserRightRequest = async right => {
  const url = "".concat(AUTHORIZATION_URL, "/users/hasRight");
  const {
    response
  } = await (0, _request.get)(url, {
    params: {
      right
    }
  });
  return response === true;
};

/**
 * @param {Object} params
 * @returns {Object}
 */
const getOrgRightsRequest = async queryParams => {
  const url = "".concat(AUTHORIZATION_URL, "/users/orgRights");
  const {
    response
  } = await (0, _request.get)(url, {
    params: {
      orgId: queryParams.organizationId,
      orgUnitId: queryParams.organizationUnitId
    }
  });
  return response;
};

/**
 * Get the user infos, user rights
 * @param {Object} params
 * @returns {Object}
 */
const getUserInfosRequest = async () => {
  const url = "".concat(AUTHORIZATION_URL, "/user");
  const {
    response
  } = await (0, _request.get)(url);
  return response;
};
exports.getUserInfosRequest = getUserInfosRequest;
const hasDeskingAccess = async () => baseUserRightRequest('DeskingAccess');
exports.hasDeskingAccess = hasDeskingAccess;
const hasBuilderPricesAccess = async () => baseUserRightRequest('BuilderPricesRead');
exports.hasBuilderPricesAccess = hasBuilderPricesAccess;
const canCreateUnlistedVehicle = async () => baseUserRightRequest('DeskingCreateUnlistedVehicle');
exports.canCreateUnlistedVehicle = canCreateUnlistedVehicle;
const getOrgRights = async params => getOrgRightsRequest(params);
exports.getOrgRights = getOrgRights;