"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUser = exports.getUsers = exports.fetchUsersByDealer = exports.fetchUsers = exports.fetchUserToken = exports.fetchUser = exports.deleteUserToken = exports.createUserToken = exports.createUser = exports.cloneUser = void 0;
var _request = require("../../tools/request");
const CRM_API_URL = process.env.REACT_APP_CRM_API_URL;
const XMS_API = process.env.REACT_APP_XMS_API_URL;

/**
 * @param {String} right
 * @param {String} organizationUnitIds
 * @param {String} activeOnly
 * @returns {Promise}
 */
const getUsers = (right, organizationUnitIds, activeOnly) => {
  return (0, _request.post)("".concat(CRM_API_URL, "/users?activeOnly=").concat(activeOnly, "&withOrganizationUnitRight=").concat(right), Array.isArray(organizationUnitIds) ? organizationUnitIds : [], null, null, 'getUsers');
};

/**
 * Get all Users within an Organization
 * @param {String} orgId
 * @returns {Promise}
 */
exports.getUsers = getUsers;
const fetchUsers = _ref => {
  let {
    orgId
  } = _ref;
  return (0, _request.get)("".concat(XMS_API, "/organizations/").concat(orgId, "/users"));
};

/**
 * Get all Users within an OrganizationUnit
 * @param {String} orgId
 * @param {String} orgUnitId
 * @returns {Promise}
 */
exports.fetchUsers = fetchUsers;
const fetchUsersByDealer = _ref2 => {
  let {
    orgId,
    orgUnitId
  } = _ref2;
  return (0, _request.get)("".concat(XMS_API, "/organizations/").concat(orgId, "/organizationUnits/").concat(orgUnitId, "/users"));
};

/**
 * Get an User with filtered Roles and Rights
 * FIltered by requesting user (with Bearer)
 * @param {String} orgId
 * @param {String} userId
 * @returns {Promise}
 */
exports.fetchUsersByDealer = fetchUsersByDealer;
const fetchUser = _ref3 => {
  let {
    orgId,
    userId
  } = _ref3;
  return (0, _request.get)("".concat(XMS_API, "/admin/users/").concat(userId, "?organizationId=").concat(orgId));
};

/**
 * Create an User within an Organization
 * @param {String} orgId
 * @param {Object} user
 * @returns {Promise}
 */
exports.fetchUser = fetchUser;
const createUser = _ref4 => {
  let {
    user,
    orgId
  } = _ref4;
  return (0, _request.post)("".concat(XMS_API, "/organizations/").concat(orgId, "/users"), user);
};

/**
 * Update an User within an Organization
 * @param {String} orgId
 * @param {Object} user
 * @returns {Promise}
 */
exports.createUser = createUser;
const updateUser = _ref5 => {
  let {
    user,
    orgId
  } = _ref5;
  return (0, _request.put)("".concat(XMS_API, "/organizations/").concat(orgId, "/users/").concat(user.id), user);
};

/**
 * Create a User from another User
 * Will copy Rights, Roles and Organization/OrganizationUnits association(s)
 * @param {String} userId
 * @returns {Promise}
 */
exports.updateUser = updateUser;
const cloneUser = _ref6 => {
  let {
    userId
  } = _ref6;
  return (0, _request.post)("".concat(XMS_API, "/users/").concat(userId, "/copy"));
};

/**
 * Get list of all EternalTokens of an User
 * @param {String} userId
 * @returns {Promise}
 */
exports.cloneUser = cloneUser;
const fetchUserToken = _ref7 => {
  let {
    userId
  } = _ref7;
  return (0, _request.get)("".concat(XMS_API, "/userEternalTokens?userId=").concat(userId));
};

/**
 * Create an EternalToken
 * @param {Object} body
 * @returns {Promise}
 */
exports.fetchUserToken = fetchUserToken;
const createUserToken = _ref8 => {
  let {
    body
  } = _ref8;
  return (0, _request.post)("".concat(XMS_API, "/userEternalTokens"), body);
};

/**
 * Delete an EternalToken
 * @param {String} tokenId
 * @returns {Promise}
 */
exports.createUserToken = createUserToken;
const deleteUserToken = _ref9 => {
  let {
    tokenId
  } = _ref9;
  return (0, _request.deleteHttp)("".concat(XMS_API, "/userEternalTokens/").concat(tokenId));
};
exports.deleteUserToken = deleteUserToken;