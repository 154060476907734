"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserAccountsByContactId = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Get user accounts by contactId
const getUserAccountsByContactId = async _ref => {
  let {
    id,
    dispatch
  } = _ref;
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/accounts/findByContactId?contactId=").concat(id), {
    ignoreAppsCoreCatch: true
  }).then(response => dispatch({
    type: 'init',
    contactUserAccounts: response.data
  })).catch(() => dispatch({
    type: 'init',
    contactUserAccounts: []
  }));
};
exports.getUserAccountsByContactId = getUserAccountsByContactId;