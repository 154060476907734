"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
var _vehicle = require("../../selectors/vehicle");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * @param {String} params
 */
function* _default(payload) {
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const {
    lang,
    location,
    organizationId,
    organizationUnitId
  } = yield (0, _effects.select)(_selectors.getConfig);
  const {
    saleIsOutsideLocation,
    registrationLocation,
    registrationLocationTaxPlan
  } = payload;
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const queryParams = {
    orgId: organizationId,
    orgUnitId: organizationUnitId,
    location,
    lang,
    saleIsOutsideLocation,
    registrationLocation,
    registrationLocationTaxPlan
  };
  try {
    const newQuote = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.getLocationOutsideProvince, quoteId, queryParams);
    const vehicle = yield (0, _effects.select)(_vehicle.getVehicle);
    yield (0, _effects.put)(_actions.Creators.setQuote(_objectSpread(_objectSpread({}, newQuote), {}, {
      vehicle
    })));
    const {
      cashDown,
      financeOptions,
      financePlan,
      kmPerYearPlan,
      lien,
      odometer,
      paymentFrequency,
      purchaseMethod,
      saleType,
      taxPlan,
      term,
      tradeIn,
      transactionToken,
      vehicleCondition
    } = newQuote;
    const paymentOptionsParams = {
      cashDown,
      financeOptions,
      financePlan,
      kmPerYearPlan,
      lien,
      odometer,
      paymentFrequency,
      purchaseMethod,
      saleType,
      taxPlan,
      term,
      tradeIn,
      transactionToken,
      vehicleCondition
    };
    yield (0, _effects.call)(_updatePayOptAndTransDetails.default, _objectSpread(_objectSpread({}, paymentOptions), paymentOptionsParams));
    return newQuote;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getLocationOutsideProvince'));
    return null;
  }
}