"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSelectedFinancePlan = exports.getSelectedFinanceOptions = exports.getPurchaseMethodList = exports.getPaymentOptions = exports.getPaymentBarData = exports.getMetaData = exports.getIsLeaseSelected = exports.getIsCashSelected = exports.getFinanceOptionsPlan = exports.getFinanceOptions = exports.getDisclaimer = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _payment = require("../../constants/payment");
var _selectors = require("../selectors");
var _content = require("./content");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * @param {Object} state
 * @returns {Boolean}
 */
const getIsCashSelected = state => {
  const {
    purchaseMethod
  } = (0, _selectors.getPurchaseMethod)(state);
  return purchaseMethod === _payment.PURCHASE_METHOD_CASH;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsCashSelected = getIsCashSelected;
const getIsLeaseSelected = state => {
  const {
    purchaseMethod
  } = (0, _selectors.getPurchaseMethod)(state);
  return purchaseMethod === _payment.PURCHASE_METHOD_LEASE;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getIsLeaseSelected = getIsLeaseSelected;
const getPaymentOptions = state => {
  const {
    paymentOptions
  } = state;
  return (0, _commonsUtils.isPlainObject)(paymentOptions) ? paymentOptions : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getPaymentOptions = getPaymentOptions;
const getCashPurchase = state => {
  const {
    cashPurchase
  } = getPaymentOptions(state);
  return (0, _commonsUtils.isPlainObject)(cashPurchase) ? cashPurchase : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getFinance = state => {
  const {
    finance
  } = getPaymentOptions(state);
  return (0, _commonsUtils.isPlainObject)(finance) ? finance : {};
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
const getFinanceOptions = state => {
  const {
    financeOptions
  } = getPaymentOptions(state);
  return Array.isArray(financeOptions) ? financeOptions : [];
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getFinanceOptions = getFinanceOptions;
const getSelectedFinanceOptions = state => {
  const financeOptions = getFinanceOptions(state);
  const found = financeOptions.find(item => item.isSelected);
  return found || {};
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getSelectedFinanceOptions = getSelectedFinanceOptions;
const getSelectedFinancePlan = state => {
  const selectedModelYear = (0, _content.getSelectedModelYear)(state);
  if (getIsCashSelected(state)) {
    return '';
  }
  if (getIsLeaseSelected(state)) {
    return selectedModelYear.lease.financePlan;
  }
  return selectedModelYear.finance.financePlan || '';
};

/**
 * @param {Object} state
 * @param {String} method
 * @returns {Object|undefined}
 */
exports.getSelectedFinancePlan = getSelectedFinancePlan;
const getFinanceOptionsPlan = (state, method) => {
  return getFinanceOptions(state).find(_ref => {
    let {
      purchaseMethod
    } = _ref;
    return purchaseMethod === method;
  });
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getFinanceOptionsPlan = getFinanceOptionsPlan;
const getMetaData = state => {
  const {
    metaData
  } = getPaymentOptions(state);
  return Array.isArray(metaData) ? metaData : [];
};

/**
 * @param {Object} state
 * @returns {String}
 */
exports.getMetaData = getMetaData;
const getDisclaimer = state => {
  const {
    disclaimer
  } = getPaymentOptions(state);
  return disclaimer;
};

/**
 * @param {Object} state
 * @param {String} method
 * @returns {Object}
 */
exports.getDisclaimer = getDisclaimer;
const getFinanceMetaData = (state, method) => {
  const metaData = getMetaData(state);
  const found = metaData.find(_ref2 => {
    let {
      purchaseMethod
    } = _ref2;
    return purchaseMethod === method;
  });
  return (0, _commonsUtils.isPlainObject)(found) ? found : {};
};

/**
 * @param {Object} state
 * @param {Boolean} isHidingPrices
 * @returns {Object}
 */
const getCashPurchaseData = (state, isHidingPrices) => {
  const {
    lien,
    sellingPrice,
    tradeIn,
    incentives
  } = getCashPurchase(state);
  return {
    lien,
    payment: isHidingPrices ? null : sellingPrice,
    incentives,
    tradeIn
  };
};

/**
 * For finance and lease
 *
 * @param {Object} state
 * @param {Boolean} isHidingPrices
 * @returns {Object}
 */
const getFinanceData = (state, isHidingPrices) => {
  const {
    cashDown,
    kmPerYearPlan,
    lien,
    paymentFrequency,
    term: termItem,
    tradeIn,
    incentives
  } = getFinance(state);
  const hasTaxesIncluded = (0, _selectors.getHasTaxesIncluded)(state);
  let apr;
  let paymentBeforeTaxes;
  let term;
  let totalPayment;
  let payment;
  if ((0, _commonsUtils.isPlainObject)(termItem)) {
    ({
      apr,
      payment: paymentBeforeTaxes,
      term,
      totalPayment
    } = termItem);
    if (isHidingPrices) {
      payment = null;
    } else {
      payment = hasTaxesIncluded ? totalPayment : paymentBeforeTaxes;
    }
  }
  return {
    apr,
    cashDown,
    kmPerYearPlan,
    lien,
    payment,
    paymentFrequency,
    term,
    tradeIn,
    incentives
  };
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getPaymentBarData = state => {
  const {
    purchaseMethod
  } = (0, _selectors.getPurchaseMethod)(state);
  const isHidingPrices = (0, _selectors.getIsHidingPrices)(state);
  let selector;
  let kmPerYearPlans = [];
  let paymentFrequencies = [];
  let terms = [];
  switch (purchaseMethod) {
    case _payment.PURCHASE_METHOD_CASH:
      selector = getCashPurchaseData;
      break;
    case _payment.PURCHASE_METHOD_FINANCE:
    case _payment.PURCHASE_METHOD_LEASE:
      selector = getFinanceData;
      ({
        kmPerYearPlans,
        paymentFrequencies,
        terms
      } = getFinanceMetaData(state, purchaseMethod));
      break;
    default:
      throw new TypeError('The stored purchase method is incorrect.');
  }
  return _objectSpread({
    isCashSelected: getIsCashSelected(state),
    kmPerYearPlans,
    paymentFrequencies,
    purchaseMethod,
    terms
  }, selector.call(null, state, isHidingPrices));
};

/**
 * Detect if a purchase method is available or not
 *
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getPaymentBarData = getPaymentBarData;
const getPurchaseMethodList = state => {
  const isCashSelected = getIsCashSelected(state);

  // Cash always available
  const list = [{
    name: _payment.PURCHASE_METHOD_CASH,
    isAvailable: true,
    isSelected: isCashSelected
  }];
  let found;
  let item;
  [_payment.PURCHASE_METHOD_FINANCE, _payment.PURCHASE_METHOD_LEASE].forEach(name => {
    item = {
      name,
      isAvailable: false,
      isSelected: false
    };
    found = getFinanceOptionsPlan(state, name);
    if (!(0, _commonsUtils.isPlainObject)(found)) {
      list.push(item);
      return;
    }
    const {
      isAvailable,
      isSelected
    } = found;
    item.isAvailable = isAvailable;
    // Force data to be consistent, we can't have multiple with isSelected=true
    item.isSelected = !isCashSelected && isAvailable && isSelected;
    list.push(item);
  });
  return list;
};
exports.getPurchaseMethodList = getPurchaseMethodList;