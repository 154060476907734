"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadDocuments = exports.updateDocumentDesc = exports.getLeadDocuments = exports.getDocument = exports.downloadDocument = exports.archiveDocument = void 0;
var _appsCore = require("@sm360/apps-core");
const NEW_CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getLeadDocuments = async id => {
  return await _appsCore.axios.get("".concat(NEW_CRM_ENDPOINT, "/leadDocuments?leadId=").concat(id));
};
exports.getLeadDocuments = getLeadDocuments;
const getDocument = async _ref => {
  let {
    documentId
  } = _ref;
  return await _appsCore.axios.get("".concat(NEW_CRM_ENDPOINT, "/leadDocuments/").concat(documentId));
};
exports.getDocument = getDocument;
const downloadDocument = async _ref2 => {
  let {
    documentId
  } = _ref2;
  return await _appsCore.axios.get("".concat(NEW_CRM_ENDPOINT, "/contactDocuments/").concat(documentId, "/download"), {
    responseType: 'blob'
  });
};
exports.downloadDocument = downloadDocument;
const uploadDocuments = async _ref3 => {
  let {
    organizationId,
    leadId,
    form
  } = _ref3;
  return await _appsCore.axios.post("".concat(NEW_CRM_ENDPOINT, "/leadDocuments/upload?organizationId=").concat(organizationId, "&leadId=").concat(leadId), form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
exports.uploadDocuments = uploadDocuments;
const updateDocumentDesc = async _ref4 => {
  let {
    body
  } = _ref4;
  const documentId = body === null || body === void 0 ? void 0 : body.id;
  return await _appsCore.axios.put("".concat(NEW_CRM_ENDPOINT, "/leadDocuments/").concat(documentId), body);
};
exports.updateDocumentDesc = updateDocumentDesc;
const archiveDocument = async _ref5 => {
  let {
    documentId
  } = _ref5;
  return await _appsCore.axios.put("".concat(NEW_CRM_ENDPOINT, "/leadDocuments/").concat(documentId, "/archive"));
};
exports.archiveDocument = archiveDocument;