"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findNewCarTrimId = exports.default = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _selectors = require("../selectors");
var _content = require("../selectors/content");
const _excluded = ["configurationId", "modelTrimCode", "transactionToken"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
/**
 * @param {Object} model
 * @returns {Number}
 */
const findNewCarTrimId = model => {
  const {
    configurations
  } = model;
  let newCarTrimId;
  if (Array.isArray(configurations)) {
    const found = configurations.find(item => item.isSelected === true);
    if (found) {
      ({
        newCarTrimId
      } = found);
    }
  }
  return newCarTrimId;
};

/**
 * @param {Object} state
 * @param {Object} payload - { response }
 */
exports.findNewCarTrimId = findNewCarTrimId;
var _default = (state, _ref) => {
  let {
    response
  } = _ref;
  const {
      configurationId,
      modelTrimCode,
      transactionToken
    } = response,
    newModel = _objectWithoutProperties(response, _excluded);

  // Compose new baseProps
  const baseProps = (0, _selectors.composeNewBaseProps)(state, configurationId, modelTrimCode, transactionToken);
  const content = (0, _content.getContent)(state);
  let model = (0, _content.getModel)(state);
  let newCarTrimId = (0, _content.getNewCarTrimId)(state);

  // Don't replace response if it's the same, avoiding to rerender components depending on it
  if (!(0, _commonsUtils.isEqual)(model, newModel)) {
    model = newModel;
    newCarTrimId = findNewCarTrimId(newModel);
  }
  return _objectSpread(_objectSpread({}, state), {}, {
    isPaymentUpdating: false,
    baseProps,
    content: _objectSpread(_objectSpread({}, content), {}, {
      model,
      newCarTrimId
    })
  });
};
exports.default = _default;