"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.triggerSavePaymentBarOptions = exports.triggerRefreshLead = exports.triggerEnableExitConfirmation = exports.triggerDisableExitConfirmation = exports.getVehiclesPath = exports.getGaragePath = exports.getDeskingPath = exports.getBuilderPath = void 0;
/**
 * We want to come back in the quote app, so in the lead of the CRM
 *
 * @param {Number} leadId
 */
const getGaragePath = leadId => "/leads/edit/".concat(leadId, "?tab=quote");

/**
 * We want to open desking from a quote
 *
 * @param {String} quoteId
 */
exports.getGaragePath = getGaragePath;
const getDeskingPath = params => {
  const {
    quoteId,
    accountId,
    contactId,
    organizationId,
    organizationUnitId,
    upg,
    leadId
  } = params;
  const optionalParams = {
    accountId,
    contactId,
    organizationId,
    organizationUnitId,
    upg,
    leadId
  };
  let path = "/desking-desking?quoteId=".concat(quoteId);
  Object.entries(optionalParams).forEach(_ref => {
    let [key, value] = _ref;
    if (value) path = path.concat("&".concat(key, "=").concat(value));
  });
  return path;
};

/**
 * We want to open the vehicles app with a category already chosen
 *
 * @param {Object} params
 */
exports.getDeskingPath = getDeskingPath;
const getVehiclesPath = params => {
  const {
    organizationId,
    organizationUnitId,
    location,
    accountId,
    contactId,
    leadId,
    category,
    upg
  } = params;
  const optionalParams = {
    organizationUnitId,
    location,
    accountId,
    contactId,
    leadId,
    category,
    upg
  };
  let path = "/desking-vehicles?organizationId=".concat(organizationId);
  Object.entries(optionalParams).forEach(_ref2 => {
    let [key, value] = _ref2;
    if (value) path = path.concat("&".concat(key, "=").concat(value));
  });
  return path;
};
exports.getVehiclesPath = getVehiclesPath;
const getBuilderPath = params => {
  const {
    organizationId,
    organizationUnitId,
    bodyStyle,
    includeTaxes,
    cashDown,
    kmPerYearPlan,
    leadId,
    lien,
    make,
    model,
    paymentFrequency,
    purchaseMethod,
    saleType,
    taxPlan,
    term,
    tradeIn,
    upg,
    accountId,
    contactId
  } = params;
  return "/desking-builder?bodyStyle=".concat(bodyStyle, "&cashDown=").concat(cashDown, "&includeTaxes=").concat(includeTaxes, "&kmPerYearPlan=").concat(kmPerYearPlan, "&leadId=").concat(leadId, "&lien=").concat(lien, "&make=").concat(make, "&model=").concat(model, "&organizationId=").concat(organizationId, "&organizationUnitId=").concat(organizationUnitId, "&paymentFrequency=").concat(paymentFrequency, "&purchaseMethod=").concat(purchaseMethod, "&saleType=").concat(saleType, "&taxPlan=").concat(taxPlan, "&term=").concat(term, "&tradeIn=").concat(tradeIn, "&upg=").concat(upg, "&accountId=").concat(accountId, "&contactId=").concat(contactId);
};

/**
 * We updated the lead, telling the CRM to reload data of the lead
 */
exports.getBuilderPath = getBuilderPath;
const triggerRefreshLead = () => {};
exports.triggerRefreshLead = triggerRefreshLead;
const triggerSavePaymentBarOptions = paymentOptions => {
  // triggerEvent('save-payment-bar-options', { paymentOptions });
};
exports.triggerSavePaymentBarOptions = triggerSavePaymentBarOptions;
const triggerEnableExitConfirmation = message => {
  // triggerEvent('enable-exit-widget-confirmation', { message });
};
exports.triggerEnableExitConfirmation = triggerEnableExitConfirmation;
const triggerDisableExitConfirmation = () => {
  // triggerEvent('disable-exit-widget-confirmation');
};
exports.triggerDisableExitConfirmation = triggerDisableExitConfirmation;