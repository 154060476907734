"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _appsCore = require("@sm360/apps-core");
var _commonsApi = require("@sm360/commons-api");
var _state = require("../config/state.manager");
var _common = require("../api/common.service");
const useLeadNotifications = () => {
  const {
    state: {
      leads,
      new_leads: newNotification,
      last_leads_received: lastLeadReceived
    }
  } = (0, _react.useContext)(_state.CrudContext);
  const {
    state: {
      organization
    }
  } = (0, _react.useContext)(_appsCore.AppContext);
  const dealers = (0, _commonsApi.useDealers)({
    organizationId: organization.id
  });

  // Filter only leads the user is allowed to see
  const filteredLeads = (0, _react.useMemo)(() => {
    if (leads.length > 0 && dealers !== null && dealers !== void 0 && dealers.data) {
      const restrictedList = leads.filter(lead => {
        if (!lead) return false;
        return dealers.data.map(o => o.id).includes(lead === null || lead === void 0 ? void 0 : lead.dealerId);
      });
      return restrictedList;
    } else return [];
  }, [leads, dealers === null || dealers === void 0 ? void 0 : dealers.data]);

  // Delete a Lead notification for a given leadId for all the dealer group
  const deleteNotification = (0, _react.useCallback)((leadId, onSuccess) => {
    (0, _common.deleteLead)({
      organization,
      id: leadId
    }).then(() => {
      if (typeof onSuccess === 'function') onSuccess();
    });
  }, [organization]);
  return {
    allLeads: filteredLeads,
    newNotification,
    lastLeadReceived,
    deleteNotification
  };
};
var _default = exports.default = useLeadNotifications;