"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../selectors");
var _refreshQuote = _interopRequireDefault(require("./refreshQuote"));
var _helpers = require("./api/helpers");
const _excluded = ["cashPurchaseOption", "financeOptions"];
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
/**
 * @param {Object} params
 */
function* updateQuoteInformation() {
  const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
  const showroomQueryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
  const quote = yield (0, _effects.select)(_selectors.getQuote);
  const _yield$select = yield (0, _effects.select)(_selectors.getPaymentOptions),
    {
      cashPurchaseOption,
      financeOptions
    } = _yield$select,
    paymentOptions = _objectWithoutProperties(_yield$select, _excluded);
  const body = _objectSpread({
    id: quoteId,
    leadId: quote.leadId,
    accountId: quote.accountId,
    quoteRevision: quote.quoteRevision,
    vehicleCondition: quote.vehicle.condition,
    financeOptions: (0, _helpers.transformFinanceOptionsRequest)(financeOptions)
  }, paymentOptions);
  try {
    yield (0, _effects.call)(_commonsApi.ShowroomService.desking.updateQuoteInformation, quoteId, showroomQueryParams, body);
    yield (0, _effects.call)(_refreshQuote.default);
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateQuote'));
  }
}
var _default = exports.default = updateQuoteInformation;