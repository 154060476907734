"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.saveKijijiDealer = exports.saveInventory = exports.saveDealer = exports.getKijijiDealer = exports.getInventory = exports.getInventories = exports.getDealers = exports.getDealer = exports.createInventory = void 0;
var _request = require("../../tools/request");
const XMS_API = process.env.REACT_APP_XMS_API_URL;

/**
 * Get all OrganizationUnits within an Organization
 * @param {String} orgId
 * @param {String} orgUnitId
 * @returns {Promise}
 */
const getDealers = _ref => {
  let {
    orgId
  } = _ref;
  return (0, _request.get)("".concat(XMS_API, "/organizations/").concat(orgId, "/organizationUnits"));
};

/**
 * Get an OrganizationUnit details
 * @param {String} orgId
 * @param {String} orgUnitId
 * @returns {Promise}
 */
exports.getDealers = getDealers;
const getDealer = _ref2 => {
  let {
    orgId,
    orgUnitId
  } = _ref2;
  return (0, _request.get)("".concat(XMS_API, "/organizations/").concat(orgId, "/organizationUnits/").concat(orgUnitId));
};

/**
 * Get an OrganizationUnit details
 * Includes Kijiji informations
 * @param {String} orgId
 * @param {String} orgUnitId
 * @returns {Promise}
 */
exports.getDealer = getDealer;
const getKijijiDealer = _ref3 => {
  let {
    orgId,
    orgUnitId
  } = _ref3;
  return (0, _request.get)("".concat(XMS_API, "/organizations/").concat(orgId, "/kijijiOrganizationUnits/").concat(orgUnitId));
};

/**
 * Update an OrganizationUnit details
 * @param {String} orgId
 * @param {String} orgUnitId
 * @param {Object} body
 * @returns {Promise}
 */
exports.getKijijiDealer = getKijijiDealer;
const saveDealer = _ref4 => {
  let {
    orgId,
    orgUnitId,
    body
  } = _ref4;
  return (0, _request.put)("".concat(XMS_API, "/organizations/").concat(orgId, "/organizationUnits/").concat(orgUnitId), body);
};

/**
 * Update an OrganizationUnit details
 * Includes Kijiji informations
 * @param {String} orgId
 * @param {String} orgUnitId
 * @param {Object} body
 * @returns {Promise}
 */
exports.saveDealer = saveDealer;
const saveKijijiDealer = _ref5 => {
  let {
    orgId,
    orgUnitId,
    body
  } = _ref5;
  return (0, _request.put)("".concat(XMS_API, "/organizations/").concat(orgId, "/kijijiOrganizationUnits/").concat(orgUnitId), body);
};

/**
 * Get all inventories of an OrganizationUnit
 * @param {String} orgId
 * @param {String} orgUnitId
 * @returns {Promise}
 */
exports.saveKijijiDealer = saveKijijiDealer;
const getInventories = _ref6 => {
  let {
    orgId,
    orgUnitId
  } = _ref6;
  return (0, _request.get)("".concat(XMS_API, "/organizations/").concat(orgId, "/organizationUnits/").concat(orgUnitId, "/inventories"));
};

/**
 * Get an inventory details
 * @param {String} orgId
 * @param {String} orgUnitId
 * @param {String} inventoryId
 * @returns {Promise}
 */
exports.getInventories = getInventories;
const getInventory = _ref7 => {
  let {
    orgId,
    orgUnitId,
    inventoryId
  } = _ref7;
  return (0, _request.get)("".concat(XMS_API, "/organizations/").concat(orgId, "/organizationUnits/").concat(orgUnitId, "/inventories/").concat(inventoryId));
};

/**
 * Update an inventory details
 * @param {String} orgId
 * @param {String} orgUnitId
 * @param {String} inventoryId
 * @param {Object} requestBody
 * @returns {Promise}
 */
exports.getInventory = getInventory;
const saveInventory = _ref8 => {
  let {
    orgId,
    orgUnitId,
    inventoryId,
    requestBody
  } = _ref8;
  return (0, _request.put)("".concat(XMS_API, "/organizations/").concat(orgId, "/organizationUnits/").concat(orgUnitId, "/inventories/").concat(inventoryId), requestBody);
};

/**
 * Create an inventory within an OrganizationUnit
 * @param {String} orgId
 * @param {String} orgUnitId
 * @param {String} inventoryId
 * @param {Object} requestBody
 * @returns {Promise}
 */
exports.saveInventory = saveInventory;
const createInventory = _ref9 => {
  let {
    orgId,
    orgUnitId,
    requestBody
  } = _ref9;
  return (0, _request.post)("".concat(XMS_API, "/organizations/").concat(orgId, "/organizationUnits/").concat(orgUnitId, "/inventories"), requestBody);
};
exports.createInventory = createInventory;