"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _uiLibrary = require("@sm360/ui-library");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
var _tabForms = require("../../selectors/tabForms");
function _default(_ref) {
  let {
    updatedForm,
    fromPrint,
    checkedLang
  } = _ref;
  return function* () {
    yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdating());
    yield (0, _effects.put)(_actions.Creators.setDealJacketForm(updatedForm));
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
    const dealJacket = yield (0, _effects.select)(_tabForms.getDealJacket);
    try {
      const updatedDealJacket = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.updateDealJacket, quoteId, queryParams, dealJacket);
      if (updatedDealJacket) {
        yield (0, _effects.put)(_actions.Creators.updateTabFormsSuccess(updatedDealJacket));
      }
      if (fromPrint) {
        yield (0, _effects.put)(_actions.Creators.formPrint(updatedForm.instanceId, Array.isArray(updatedForm.customFields) ? updatedForm.customFields.map(customFieldGroup => customFieldGroup.fields).flat() : [], checkedLang));
      }
      yield (0, _effects.delay)(1500);
      yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
    } catch (error) {
      _uiLibrary.Toast.toast.error("".concat(error));
      yield (0, _effects.put)(_actions.Creators.setIsQuoteUpdatingDone());
    }
  }();
}