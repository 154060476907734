"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateActivityDate = exports.getActivities = exports.deleteActivity = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getActivities = async (contactId, leadId, displayForLeadOnly) => {
  if (displayForLeadOnly ? !leadId : !contactId) {
    return Promise.reject(new Error('Missing parameters for getting activities'));
  }
  if (displayForLeadOnly) {
    return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/activities/filtered?leadId=").concat(leadId));
  }
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/activities/filtered?contactId=").concat(contactId));
};
exports.getActivities = getActivities;
const deleteActivity = _ref => {
  let {
    activityId,
    userId
  } = _ref;
  return _appsCore.axios.delete("".concat(CRM_ENDPOINT, "/activities/").concat(activityId).concat(userId ? "?loggedUserId=".concat(userId) : ''));
};
exports.deleteActivity = deleteActivity;
const updateActivityDate = _ref2 => {
  let {
    activityId,
    date
  } = _ref2;
  return _appsCore.axios.put("".concat(CRM_ENDPOINT, "/activities/").concat(activityId, "/activityDate/").concat(date));
};
exports.updateActivityDate = updateActivityDate;