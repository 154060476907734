"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _payment = require("../../constants/payment");
var _selectors = require("../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("./api/updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * Try to find if the current value is still relevant with the new finance plan.
 *  - If can't find anything in metadata, return null.
 *  - If can't find the same value, return the first value in the list.
 *  - If found the same value, keep it.
 *
 * @param {Object} financePlan - Found in meta data, describe the type of financePlan
 * @param {String} propertyName - Name of the property where to search
 * @param {Number} value - The current value
 * @returns {Number|null}
 */
const findMatchingValueInFinancePlan = (financePlan, propertyName, value) => {
  const valueList = financePlan[propertyName];
  if (!Array.isArray(valueList) || valueList.length === 0) {
    return null;
  }
  return valueList.indexOf(value) !== -1 ? value : valueList[0];
};

/**
 * @param {Object} payload
 */
function* changePlan(payload) {
  const {
    financePlan: newFinancePlan,
    purchaseMethod: newPurchaseMethod,
    isSelected,
    index
  } = payload;

  // Get the metadata of the new finance plan
  const metaFinancePlans = yield (0, _effects.select)(_selectors.getMetaFinancePlans);
  const newFinancePlanMeta = metaFinancePlans.find(item => newFinancePlan === item.financePlan && newPurchaseMethod === item.purchaseMethod);

  // The current card where the change is coming from
  const financeOptions = yield (0, _effects.select)(_selectors.getFinanceOptions);
  const currentFinanceOptions = financeOptions[index];
  const {
    paymentFrequency,
    kmPerYearPlan,
    selectedCashDown,
    selectedTerm
  } = currentFinanceOptions;

  // Try to detect the best values we can with the new finance plan
  const newKmPlan = findMatchingValueInFinancePlan(newFinancePlanMeta, _payment.KEY_META_KMPLAN, kmPerYearPlan);
  const newPaymentFrequency = findMatchingValueInFinancePlan(newFinancePlanMeta, _payment.KEY_META_FREQUENCY, paymentFrequency);
  const newTerm = findMatchingValueInFinancePlan(newFinancePlanMeta, _payment.KEY_META_TERM, selectedTerm);

  // New data to send
  const newOptions = {
    financePlan: newFinancePlan,
    purchaseMethod: newPurchaseMethod,
    paymentFrequency: newPaymentFrequency,
    kmPerYearPlan: newKmPlan,
    selectedTerm: newTerm
  };

  // Deep copy && Update
  const transformedFinanceOptions = structuredClone(financeOptions);
  Object.assign(transformedFinanceOptions[index], newOptions);

  // Build body request
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
  const body = _objectSpread(_objectSpread({}, paymentOptions), {}, {
    financeOptions: transformedFinanceOptions
  });

  // If the card is selected, send new values at the root of the body also
  if (isSelected) {
    Object.assign(body, {
      financePlan: newFinancePlan,
      purchaseMethod: newPurchaseMethod,
      paymentFrequency: newPaymentFrequency,
      kmPerYearPlan: newKmPlan,
      term: newTerm,
      cashDown: selectedCashDown
    });
  }
  yield (0, _effects.call)(_updatePayOptAndTransDetails.default, body);
}
var _default = exports.default = changePlan;