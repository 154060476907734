"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSalesPerson = exports.getSalesManager = exports.getBusinessManager = exports.assignLeadToSalesPerson = exports.assignLeadToSalesManager = exports.assignLeadToBusinessManager = void 0;
var _request = require("../../tools/request");
const LEAD_API_URL = process.env.REACT_APP_LEAD_API_URL;

/**
 * @param {String} leadId
 * @returns {Promise}
 */
const getSalesPerson = leadId => {
  const url = "".concat(LEAD_API_URL, "/leads/salesperson/").concat(leadId);
  return (0, _request.get)(url);
};

/**
 * @param {String} leadId
 * @returns {Promise}
 */
exports.getSalesPerson = getSalesPerson;
const getSalesManager = leadId => {
  const url = "".concat(LEAD_API_URL, "/leads/salesManager/").concat(leadId);
  return (0, _request.get)(url);
};

/**
 * @param {String} leadId
 * @returns {Promise}
 */
exports.getSalesManager = getSalesManager;
const getBusinessManager = leadId => {
  const url = "".concat(LEAD_API_URL, "/leads/businessManager/").concat(leadId);
  return (0, _request.get)(url);
};

/**
 * @param {String} leadId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.getBusinessManager = getBusinessManager;
const assignLeadToSalesPerson = (leadId, queryId) => {
  const url = "".concat(LEAD_API_URL, "/leads/salesperson/").concat(leadId);
  return (0, _request.put)(url, null, {
    params: {
      salespersonId: queryId
    }
  });
};

/**
 * @param {String} leadId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.assignLeadToSalesPerson = assignLeadToSalesPerson;
const assignLeadToSalesManager = (leadId, queryId) => {
  const url = "".concat(LEAD_API_URL, "/leads/salesManager/").concat(leadId);
  return (0, _request.put)(url, null, {
    params: {
      salesManagerId: queryId
    }
  });
};

/**
 * @param {String} leadId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.assignLeadToSalesManager = assignLeadToSalesManager;
const assignLeadToBusinessManager = (leadId, queryId) => {
  const url = "".concat(LEAD_API_URL, "/leads/businessManager/").concat(leadId);
  return (0, _request.put)(url, null, {
    params: {
      businessManagerId: queryId
    }
  });
};
exports.assignLeadToBusinessManager = assignLeadToBusinessManager;