"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _appsCore = require("@sm360/apps-core");
var locales = _interopRequireWildcard(require("../../locales"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const Gender = _ref => {
  let {
    sexId
  } = _ref;
  const parsedSexId = parseInt(sexId);
  const {
    t
  } = (0, _appsCore.useTranslation)('translations', '', locales);
  switch (parsedSexId) {
    case 1:
      return t("crm-commons.gender.MALE");
    case 2:
      return t("crm-commons.gender.FEMALE");
    case 3:
      return t("crm-commons.gender.PREFER_NOT_TO_SAY");
    case 0:
    default:
      return t("crm-commons.gender.OTHER");
  }
};
var _default = exports.default = Gender;