"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _jsStorage = _interopRequireDefault(require("js-storage"));
var _core = require("@babel/core");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const useUserData = function () {
  let optionalContext = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  let key = arguments.length > 1 ? arguments[1] : undefined;
  let defaultValue = arguments.length > 2 ? arguments[2] : undefined;
  const location = (0, _core.useLocation)();
  const {
    userData: oidcUser
  } = (0, _core.useAuth)();
  const storage = (0, _react.useRef)(_jsStorage.default.localStorage).current;
  const userId = oidcUser.profile.sub || 'anonymousUserData';
  let context = optionalContext;
  if (optionalContext === null) {
    context = location.pathname || 'root';
  }
  const fullKey = "".concat(userId, ".preferences.").concat(context, ".").concat(key);
  const [value, setValue] = (0, _react.useState)(() => {
    if (storage.isSet(fullKey)) {
      return storage.get(fullKey);
    }
    return defaultValue;
  });
  const setStoredValue = storedValue => {
    try {
      // allow value to be a function so we have same API as useState
      const valueToStore = typeof storedValue === 'function' ? storedValue(storedValue) : storedValue;
      storage.set(fullKey, valueToStore);
      setValue(valueToStore);
    } catch (e) {
      console.error("Can't set LocalStorage");
    }
  };
  return [value, setStoredValue];
};
var _default = exports.default = useUserData;