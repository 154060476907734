"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userHasAccessTo = void 0;
const userHasAccessTo = function () {
  let userRights = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let rights = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return rights === null || rights === void 0 ? void 0 : rights.every(right => userRights === null || userRights === void 0 ? void 0 : userRights.includes(right));
};
exports.userHasAccessTo = userHasAccessTo;