"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetExtraInfoFromAccount = exports.isUnknown = exports.isPaid = exports.isLoan = exports.getOtherColor = exports.formatVehicleColors = exports.formatShowroomVehicleFromInternalAndAccount = void 0;
var _PaymentTypes = _interopRequireDefault(require("../constants/PaymentTypes"));
const _excluded = ["payment", "balance", "expirationDate", "lienHolderName"];
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
const uniqueArray = function (objects, uniqueBy) {
  let keepFirst = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return Array.from(objects.reduce((map, e) => {
    let key = uniqueBy.map(key => [e[key], typeof e[key]]).flat().join('-');
    if (keepFirst && map.has(key)) return map;
    return map.set(key, e);
  }, new Map()).values());
};
const getOtherColor = t => ({
  description: t('trade-in.VEHICLE_DETAILS.OTHER'),
  htmlColour: 'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)',
  colorCode: 'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)'
});
exports.getOtherColor = getOtherColor;
const formatVehicleColors = function () {
  let vehicleColors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let t = arguments.length > 1 ? arguments[1] : undefined;
  const otherColor = getOtherColor(t);
  vehicleColors.push(otherColor);
  return uniqueArray(vehicleColors, ['htmlColour']);
};
exports.formatVehicleColors = formatVehicleColors;
const resetExtraInfoFromAccount = (vehicleExtraInfo, accountVehicleExtraInfo, inputName, inputValue) => {
  let resetedVehicleExtraInfo;
  if (inputValue === _PaymentTypes.default.PAID || inputValue === _PaymentTypes.default.UNKNOWN || inputValue !== accountVehicleExtraInfo.paymentType) {
    const _ref = vehicleExtraInfo || {},
      {
        payment,
        balance,
        expirationDate,
        lienHolderName
      } = _ref,
      otherValues = _objectWithoutProperties(_ref, _excluded);
    resetedVehicleExtraInfo = _objectSpread(_objectSpread({}, otherValues), {}, {
      [inputName]: inputValue
    });
  } else {
    const {
      payment,
      balance,
      expirationDate,
      lienHolderName
    } = accountVehicleExtraInfo || {};
    resetedVehicleExtraInfo = _objectSpread(_objectSpread(_objectSpread({}, vehicleExtraInfo), {
      payment,
      balance,
      expirationDate,
      lienHolderName
    }), {}, {
      [inputName]: inputValue
    });
  }
  // Removed keys with the value of undefined
  Object.keys(resetedVehicleExtraInfo).forEach(key => resetedVehicleExtraInfo[key] === undefined && delete resetedVehicleExtraInfo[key]);
  return resetedVehicleExtraInfo;
};
exports.resetExtraInfoFromAccount = resetExtraInfoFromAccount;
const formatShowroomVehicleFromInternalAndAccount = (tradeVehicle, internalTradeIn, accountTradeInVehicle) => {
  var _transformed$vehicle, _transformed$vehicle2;
  const transformed = _objectSpread({}, tradeVehicle);
  const {
    make,
    model,
    modelTrim,
    year,
    bodyStyle
  } = tradeVehicle.vehicle;
  const {
    vehicleCondition,
    accidentStatus,
    paymentType,
    payment,
    balance,
    expirationDate,
    lienHolderName
  } = (internalTradeIn === null || internalTradeIn === void 0 ? void 0 : internalTradeIn.vehicleExtraInfo) || {};
  transformed.accountId = accountTradeInVehicle === null || accountTradeInVehicle === void 0 ? void 0 : accountTradeInVehicle.accountId;
  transformed.id = (accountTradeInVehicle === null || accountTradeInVehicle === void 0 ? void 0 : accountTradeInVehicle.id) || '';
  transformed.vehicleId = '';
  transformed.make = make;
  transformed.model = model;
  transformed.trim = modelTrim;
  // eslint-disable-next-line prefer-destructuring
  transformed.bodyStyle = bodyStyle[0];
  transformed.vin = internalTradeIn.vin;
  transformed.vehicle.vin = internalTradeIn.vin;
  transformed.year = year;
  transformed.vehicleOptions = [];
  transformed.vehicleCondition = vehicleCondition;
  transformed.accidentStatus = accidentStatus;
  transformed.paymentType = paymentType;
  // Add lease or finance payment extra info
  if (paymentType === _PaymentTypes.default.LEASE || paymentType === _PaymentTypes.default.LOAN) {
    transformed.payment = payment;
    transformed.balance = balance;
    transformed.lien.amount = balance;
    transformed.lien.owner = lienHolderName;
    if (expirationDate) {
      const [dueMonth, dueYear] = expirationDate.split('/');
      transformed.dueMonth = dueMonth;
      transformed.dueYear = dueYear;
    }
  }
  if (!((_transformed$vehicle = transformed.vehicle) !== null && _transformed$vehicle !== void 0 && _transformed$vehicle.bodyColour)) transformed.exteriorColor = 'unknown';
  if (!((_transformed$vehicle2 = transformed.vehicle) !== null && _transformed$vehicle2 !== void 0 && _transformed$vehicle2.interiorColour)) transformed.interiorColor = 'unknown';
  if (typeof (internalTradeIn === null || internalTradeIn === void 0 ? void 0 : internalTradeIn.evaluatorValues) === 'object' && Object.keys(internalTradeIn.evaluatorValues).length > 0) {
    transformed.evaluatorValues = internalTradeIn.evaluatorValues;
  }
  return transformed;
};
exports.formatShowroomVehicleFromInternalAndAccount = formatShowroomVehicleFromInternalAndAccount;
const isUnknown = paymentType => paymentType === _PaymentTypes.default.UNKNOWN;
exports.isUnknown = isUnknown;
const isPaid = paymentType => paymentType === _PaymentTypes.default.PAID;
exports.isPaid = isPaid;
const isLoan = paymentType => paymentType === _PaymentTypes.default.LOAN;
exports.isLoan = isLoan;