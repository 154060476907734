"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _deskingCommons = require("@sm360/desking-commons");
var _vehicles = require("../../constants/vehicles");
var _selectors = require("../selectors");
var _filters = require("../selectors/filters");
var _paymentOptions = require("../selectors/paymentOptions");
var _setPreferredQuote = _interopRequireDefault(require("./setPreferredQuote"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  addNewVehicle,
  addUsedVehicle
} = _commonsApi.QuoteService;
function _default(_ref) {
  let {
    vehicle
  } = _ref;
  return function* () {
    const {
      leadId,
      location,
      organizationId,
      organizationUnitId,
      accountId
    } = yield (0, _effects.select)(_selectors.getConfig);
    const category = yield (0, _effects.select)(_filters.getCategory);
    const priceIncreaseRollCount = yield (0, _effects.select)(_deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount);
    const paymentOptions = yield (0, _effects.select)(_paymentOptions.getPaymentOptions);
    const body = _objectSpread({
      leadId,
      accountId: accountId || 0,
      year: vehicle.year,
      make: vehicle.make.name,
      model: vehicle.model.name,
      vehicleCondition: vehicle.paymentOptions.vehicleCondition,
      vehicleId: vehicle.vehicleId,
      vin: vehicle.serialNo,
      stockNumber: vehicle.stockNo,
      retail: vehicle.listPrice,
      odometer: vehicle.odometer,
      sellingPrice: vehicle.salePrice
    }, paymentOptions);
    const params = {
      organizationUnitId,
      organizationId,
      location,
      priceIncreaseRollCount
    };
    try {
      const isNew = category === _vehicles.CATEGORY_NEW;
      const isUsed = category === _vehicles.CATEGORY_USED;
      let response;
      if (isNew) {
        response = yield (0, _effects.call)(addNewVehicle, params, body, vehicle.showroomVehicleId);
      } else if (isUsed) {
        response = yield (0, _effects.call)(addUsedVehicle, params, body);
      }
      yield (0, _effects.call)(_setPreferredQuote.default, {
        accountId: accountId || 0,
        quoteId: response.id
      });
      return response;
    } catch (error) {
      return yield (0, _effects.call)(_deskingError.errorSagas.handleAddVehicleError, error, 'selectVehicle');
    }
  }();
}