"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingError = require("@sm360/desking-error");
var _commonsApi = require("@sm360/commons-api");
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../../selectors");
var _config = require("../../../constants/config");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function* _default() {
  const {
    leadId
  } = yield (0, _effects.select)(_selectors.getConfig);
  const navigate = yield (0, _effects.getContext)('navigate');
  const deskingWidgetParams = yield (0, _effects.select)(_selectors.getDeskingWidgetParams);
  const {
    make,
    model,
    year,
    sellingPrice,
    odometer
  } = yield (0, _effects.select)(_selectors.getUnlistedVehicleFormData);
  const quoteServiceQueryParams = yield (0, _effects.select)(_selectors.getQuoteServiceQueryParams);
  const isUnlistedNew = yield (0, _effects.select)(_selectors.getIsUnlistedNew);
  const vehicleCondition = isUnlistedNew ? _config.VEHICLE_CATEGORY_NEW : _config.VEHICLE_CATEGORY_USED;
  const body = {
    leadId,
    accountId: 0,
    year,
    make,
    model,
    vehicleCondition,
    retail: 0,
    sellingPrice,
    odometer,
    // Backend team asked us to hardcode default values
    saleType: 'retail',
    taxPlan: 'standard',
    purchaseMethod: 'cash',
    financePlan: '',
    kmPerYearPlan: 0,
    paymentFrequency: 12,
    term: 0,
    cashDown: 0,
    tradeIn: 0,
    lien: 0,
    transactionTokenCopy: ''
  };
  try {
    const putUnlistedVehicleResponse = yield (0, _effects.call)(_commonsApi.QuoteService.putUnlistedVehicle, quoteServiceQueryParams, body);
    navigate((0, _deskingCommons.getDeskingPath)(_objectSpread(_objectSpread({
      quoteId: putUnlistedVehicleResponse.id
    }, deskingWidgetParams), {}, {
      upg: 0
    })));
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'openUnlistedInDesking'));
  }
}