"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTransactionDetailsSuccess = exports.default = exports.INITIAL_STATE = void 0;
var _reduxsauce = require("reduxsauce");
var _deskingCommons = require("@sm360/desking-commons");
var _deskingError = require("@sm360/desking-error");
var _tabItems = require("../constants/tabItems");
var _tabProtection = require("../constants/tabProtection");
var _actions = require("./actions");
var _reducers = _interopRequireDefault(require("./reducers"));
var _selectors = require("./selectors");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const INITIAL_STATE = exports.INITIAL_STATE = _objectSpread(_objectSpread(_objectSpread({}, _deskingError.errorInitialState), _deskingCommons.priceIncreaseInitialState), {}, {
  isLoading: true,
  // Config
  config: {
    lang: '',
    location: '',
    quoteId: 0,
    organizationId: 0,
    organizationUnitId: 0,
    contactId: null,
    accountId: 0
  },
  dealerInfo: {},
  userRights: [],
  userRoles: [],
  orgRights: {
    LeadsCanBeAssignedAsSalesman: false,
    LeadsCanBeAssignedAsSalesManager: false,
    BusinessManagerAssignationLead: false
  },
  // Quote status and vehicle status unmatch (trigger conflict modal)
  showConflict: false,
  // Printing
  transactionPrintList: [],
  isTransactionListLoading: false,
  // Trade-in information
  tradeIn: {},
  // Contact information
  contact: {},
  customer: {},
  DMSAccountMatches: [],
  DMSAccount: {} /** should be in contact object ? */,
  DMSCustomer: {},
  // Incentives
  incentives: [],
  // Financing information, check if the dates are modified
  isFinancingDatesChanged: false,
  // Credit application (and Contract validation)
  creditApplication: {
    decision: {},
    isCreditApplicationDecisionRefreshing: false
  },
  contractValidation: {
    decision: {},
    isContractValidationDecisionRefreshing: false
  },
  specialPrograms: [],
  quote: {},
  hasTaxesIncluded: true,
  // The status of a validation call on anything related to the payment adjustments
  paymentAdjustmentValidation: {
    isValid: true,
    validationMessage: ''
  },
  // When an action need a manual validation/resolution
  validation: {
    isValid: true,
    mustResetSelection: false,
    negociationTransaction: '',
    photo: '',
    validationItem: {},
    validationMessage: '',
    validResolutionCombinations: []
  },
  // Check if the quote has any unsaved modifications from original quote at any time
  isQuoteChanged: false,
  // Loading when updating a quote, it can take a while
  isQuoteUpdating: false,
  // PaymentBar, PaymentCard
  meta: {},
  paymentOptions: {
    cashDown: 0,
    cashPurchaseOption: {},
    financeOptions: [],
    financePlan: '',
    kmPerYearPlan: 0,
    lien: 0,
    odometer: 0,
    paymentFrequency: 0,
    purchaseMethod: '',
    saleType: '',
    taxPlan: '',
    term: 0,
    tradeIn: 0,
    transactionToken: '',
    vehicleCondition: '',
    disclaimer: ''
  },
  // check if is readOnly mode
  isReadOnly: false,
  // check if has transaction quick edit
  openQuickEdit: '',
  // Matches data
  matches: {
    selectedVehicle: {},
    perfectList: [],
    similarList: []
  },
  // Commission
  commission: {},
  displayCostAndProfit: false,
  // Tab Items
  tabItems: {
    selectedIndex: 0,
    isTabColourDisabled: false
  },
  tabItemsAccessory: {
    categories: [_deskingCommons.ITEM_CATEGORY.ACCESSORY],
    content: [],
    isUpdated: false,
    name: _tabItems.TAB_ITEMS.ACCESSORY,
    uiIndex: 2
  },
  tabItemsColour: {
    categories: [_deskingCommons.ITEM_CATEGORY.EXTERIOR_COLOUR, _deskingCommons.ITEM_CATEGORY.INTERIOR_TRIM],
    content: [],
    isUpdated: false,
    name: _tabItems.TAB_ITEMS.COLOUR,
    uiIndex: 0
  },
  tabItemsOption: {
    categories: [_deskingCommons.ITEM_CATEGORY.OPTION],
    content: [],
    isUpdated: false,
    name: _tabItems.TAB_ITEMS.OPTION,
    uiIndex: 1
  },
  tabItemsRebate: {
    categories: [_deskingCommons.ITEM_CATEGORY.REBATE],
    content: [],
    isUpdated: false,
    name: _tabItems.TAB_ITEMS.REBATE,
    uiIndex: 3
  },
  tabItemsNote: {
    categories: [_deskingCommons.ITEM_CATEGORY.INTERNAL_NOTE, _deskingCommons.ITEM_CATEGORY.CUSTOMER_NOTE],
    content: [],
    isUpdated: false,
    name: _tabItems.TAB_ITEMS.NOTE,
    uiIndex: 4
  },
  accounting: {
    templates: [],
    selectedTemplate: '',
    selectedTemplateData: []
  },
  securityDeposit: {
    msd: []
  },
  // Tab Protection
  isFetchingFNIItems: false,
  lgmItemsError: 0,
  lgmItemsErrorMessage: '',
  lgmItemsAreLoaded: false,
  bmwItemsError: 0,
  bmwItemsErrorMessage: '',
  bmwItemsAreLoaded: false,
  showInvalidResponseModal: false,
  invalidResponseTitle: '',
  invalidResponseMessages: [],
  tabProtection: {
    selectedIndex: 0
  },
  tabProtectionProtection: {
    categories: [_deskingCommons.ITEM_CATEGORY.PROTECTION],
    content: [],
    isUpdated: false,
    name: _tabProtection.TAB_PROTECTION.PROTECTION,
    uiIndex: 0
  },
  tabProtectionWarranty: {
    categories: [_deskingCommons.ITEM_CATEGORY.WARRANTY],
    content: [],
    isUpdated: false,
    name: _tabProtection.TAB_PROTECTION.WARRANTY,
    uiIndex: 1
  },
  tabProtectionInsurance: {
    categories: [_deskingCommons.ITEM_CATEGORY.INSURANCE],
    content: [],
    isUpdated: false,
    name: _tabProtection.TAB_PROTECTION.INSURANCE,
    uiIndex: 2
  },
  tabProtectionLGM: {
    isAvailable: false,
    providerName: '',
    categories: [],
    content: {},
    isUpdated: false,
    name: _tabProtection.TAB_PROTECTION.LGM
  },
  tabProtectionBMW: {
    isAvailable: false,
    providerName: '',
    categories: [],
    content: {},
    isUpdated: false,
    name: _tabProtection.TAB_PROTECTION.BMW
  },
  // Tab Forms
  tabForms: {
    dealJacket: {},
    formsToEdit: {
      forms: [],
      fromPrint: false
    },
    isPrinting: false
  }
});

/**
 * @param {Object} state
 * @param {Object} payload - type, response
 * @returns {Object} Return updated state
 */
const updateTransactionDetailsSuccess = (state, payload) => {
  const {
    transaction
  } = payload.response;
  return _objectSpread(_objectSpread({}, state), {}, {
    quote: _objectSpread(_objectSpread({}, state.quote), {}, {
      transaction
    })
  });
};

/**
 * @param {Object} state - global state
 * @param {Object} payload - type, hasTaxesIncluded
 * @returns {Object} new state
 */
exports.updateTransactionDetailsSuccess = updateTransactionDetailsSuccess;
const updateTaxesIncluded = (state, payload) => {
  const {
    hasTaxesIncluded
  } = payload;
  return _objectSpread(_objectSpread({}, state), {}, {
    hasTaxesIncluded
  });
};

/**
 * @param {Object} state - global state
 * @param {Object} payload - type, isQuoteChanged
 * @returns {Object} new state
 */

const setQuoteChanged = (state, payload) => {
  const {
    isQuoteChanged,
    quoteChangeType
  } = payload;
  return _objectSpread(_objectSpread({}, state), {}, {
    isQuoteChanged,
    quoteChangeType
  });
};

/**
 * @param {Object} state - global state
 * @param {Object} payload - type, isFinancingDatesChanged
 * @returns {Object} new state
 */
const setFinancingDatesChanged = (state, payload) => {
  const {
    isFinancingDatesChanged
  } = payload;
  return _objectSpread(_objectSpread({}, state), {}, {
    isFinancingDatesChanged
  });
};

/**
 * @param {Object} state - global state
 * @param {Object} payload - type, displayCostAndProfit
 * @returns {Object} new state
 */
const setDisplayCostAndProfit = (state, payload) => {
  const {
    displayCostAndProfit
  } = payload;
  return _objectSpread(_objectSpread({}, state), {}, {
    displayCostAndProfit
  });
};
const setReadOnly = (state, _ref) => {
  let {
    isReadOnly
  } = _ref;
  return _objectSpread(_objectSpread({}, state), {}, {
    isReadOnly
  });
};
const setOpenQuickEdit = (state, _ref2) => {
  let {
    openQuickEdit
  } = _ref2;
  return _objectSpread(_objectSpread({}, state), {}, {
    openQuickEdit
  });
};
const setVisibleCustomer = (state, _ref3) => {
  let {
    quote
  } = _ref3;
  return _objectSpread(_objectSpread({}, state), {}, {
    quote: _objectSpread(_objectSpread({}, (0, _selectors.getQuote)(state)), {}, {
      isVisibleCustomer: quote.isVisibleCustomer,
      quoteRevision: quote.quoteRevision
    })
  });
};
var _default = exports.default = (0, _reduxsauce.createReducer)(INITIAL_STATE, _objectSpread(_objectSpread(_objectSpread({}, _deskingError.errorReducers), _deskingCommons.priceIncreaseReducers), {}, {
  [_actions.Types.INITIALIZE_STORE]: _reducers.default.initializeStore,
  [_actions.Types.LOAD_QUOTE_SUCCESS]: _reducers.default.loadQuoteSuccess,
  [_actions.Types.SET_LOADING_SUCCESS]: _reducers.default.setLoadingSuccess,
  [_actions.Types.SET_USER_RIGHTS]: _reducers.default.setUserRights,
  [_actions.Types.SET_USER_ROLES]: _reducers.default.setUserRoles,
  [_actions.Types.SET_ORG_RIGHTS]: _reducers.default.setOrgRights,
  [_actions.Types.SET_QUOTE_CHANGED]: setQuoteChanged,
  [_actions.Types.SET_READ_ONLY]: setReadOnly,
  [_actions.Types.SET_OPEN_QUICK_EDIT]: setOpenQuickEdit,
  [_actions.Types.UPDATE_PAYMENT_OPTIONS_SUCCESS]: _reducers.default.updatePaymentOptionsSuccess,
  [_actions.Types.SET_DEALER_INFO]: _reducers.default.setDealerInfo,
  [_actions.Types.SET_LEAD_INFO]: _reducers.default.setLeadInfo,
  [_actions.Types.UPDATE_TAXES_INCLUDED]: updateTaxesIncluded,
  [_actions.Types.UPDATE_TRANSACTION_DETAILS_SUCCESS]: updateTransactionDetailsSuccess,
  [_actions.Types.RESET_PAY_ADJUSTMENT_VALIDATION_SUCCESS]: _reducers.default.resetPayAdjustmentValidationSuccess,
  [_actions.Types.SET_QUOTE]: _reducers.default.setQuote,
  [_actions.Types.UPDATE_TRANSACTION_TOKEN]: _reducers.default.updateTransactionToken,
  [_actions.Types.SET_CALCULATED_ADJUSTMENTS]: _reducers.default.setCalculatedAdjustments,
  // Matches
  [_actions.Types.LOAD_MATCHES_SUCCESS]: _reducers.default.loadMatchesSuccess,
  [_actions.Types.SET_MATCH_VEHICLE]: _reducers.default.setMatchVehicle,
  // Commission
  [_actions.Types.LOAD_COMMISSION_SUCCESS]: _reducers.default.loadCommissionSuccess,
  [_actions.Types.SET_DISPLAY_COST_AND_PROFIT]: setDisplayCostAndProfit,
  // Items
  [_actions.Types.SELECT_TAB_ITEMS]: _reducers.default.selectTabItems,
  [_actions.Types.RELOAD_TAB_ITEMS_SUCCESS]: _reducers.default.reloadTabItemsSuccess,
  // Protection
  [_actions.Types.SELECT_TAB_PROTECTION]: _reducers.default.selectTabProtection,
  [_actions.Types.RELOAD_TAB_PROTECTION_SUCCESS]: _reducers.default.reloadTabProtectionSuccess,
  [_actions.Types.SET_FNI_ITEMS_ERROR]: _reducers.default.setFNIItemsError,
  [_actions.Types.SET_IS_FETCHING_FNI_ITEMS]: _reducers.default.setIsFetchingFNIItems,
  [_actions.Types.SET_IS_FETCHING_FNI_ITEMS_DONE]: _reducers.default.setIsFetchingFNIItemsDone,
  [_actions.Types.SET_FNI_ITEMS_ARE_LOADED]: _reducers.default.setFNIItemsAreLoaded,
  [_actions.Types.OPEN_INVALID_RESPONSE_MODAL]: _reducers.default.openInvalidResponseModal,
  [_actions.Types.CLOSE_INVALID_RESPONSE_MODAL]: _reducers.default.closeInvalidResponseModal,
  [_actions.Types.SELECT_FNI_ITEM_PLAN_SUCCESS]: _reducers.default.selectFNIItemPlanSuccess,
  [_actions.Types.UPDATE_FNI_ITEM_PLAN_PRICING_SUCCESS]: _reducers.default.updateFNIItemPlanPricingSuccess,
  [_actions.Types.UPDATE_FNI_ITEM_PLAN_COVERAGE_PARAMETERS_SUCCESS]: _reducers.default.updateFNIItemPlanCoverageParametersSuccess,
  [_actions.Types.UPDATE_FNI_ITEM_PLAN_SUCCESS]: _reducers.default.updateFNIItemPlanSuccess,
  [_actions.Types.ON_BOOK_FNI_ITEMS_CLICK_SUCCESS]: _reducers.default.onBookFNIItemsClickSuccess,
  // Validation
  [_actions.Types.APPLY_CONFLICT_RESOLUTION]: _reducers.default.applyConflictResolution,
  [_actions.Types.CANCEL_CONFLICT]: _reducers.default.cancelConflict,
  [_actions.Types.HANDLE_CONFLICT_VALIDATION]: _reducers.default.handleConflictValidation,
  [_actions.Types.HANDLE_PAY_ADJUSTMENT_VALIDATION]: _reducers.default.handlePayAdjustmentValidation,
  // Quote
  [_actions.Types.UPDATE_QUOTE_STATUS]: _reducers.default.updateQuoteStatus,
  [_actions.Types.UPDATE_QUOTE_STATUS_DONE]: _reducers.default.updateQuoteStatusDone,
  [_actions.Types.SET_IS_QUOTE_UPDATING]: _reducers.default.setIsQuoteUpdating,
  [_actions.Types.SET_IS_QUOTE_UPDATING_DONE]: _reducers.default.setIsQuoteUpdatingDone,
  [_actions.Types.SET_VISIBLE_CUSTOMER]: setVisibleCustomer,
  // Dealer
  [_actions.Types.CHANGE_PROVINCE]: _reducers.default.changeProvince,
  [_actions.Types.CHANGE_SALE_TYPE]: _reducers.default.changeSaleType,
  [_actions.Types.CHANGE_TAX_PLAN]: _reducers.default.changeTaxPlan,
  // Trade-in
  [_actions.Types.RELOAD_TRADE_IN_SUCCESS]: _reducers.default.reloadTradeInSuccess,
  [_actions.Types.SET_TRADE_IN_WIDGET]: _reducers.default.setTradeInWidget,
  // Credit-Application (and Contract-Validation)
  [_actions.Types.SET_CREDIT_APPLICATION_DECISION]: _reducers.default.setCreditApplicationDecision,
  [_actions.Types.SET_CONTRACT_VALIDATION_DECISION]: _reducers.default.setContractValidationDecision,
  [_actions.Types.SET_IS_CREDIT_APPLICATION_DECISION_REFRESHING]: _reducers.default.setIsCreditApplicationDecisionRefreshing,
  [_actions.Types.SET_IS_CONTRACT_VALIDATION_DECISION_REFRESHING]: _reducers.default.setIsContractValidationDecisionRefreshing,
  [_actions.Types.SET_CREDIT_APPLICATION_SPECIAL_PROGRAMS]: _reducers.default.setCreditApplicationSpecialPrograms,
  // Contact
  [_actions.Types.RELOAD_CONTACT_SUMMARY_SUCCESS]: _reducers.default.reloadContactSummarySuccess,
  [_actions.Types.LOAD_DMS_ACCOUNT_MATCHES_SUCCESS]: _reducers.default.loadDMSAccountMatchesSuccess,
  [_actions.Types.LOAD_DMS_CUSTOMER_SUCCESS]: _reducers.default.loadDMSCustomerSuccess,
  [_actions.Types.LINK_DMS_ACCOUNT_SUCCESS]: _reducers.default.linkDMSAccountSuccess,
  [_actions.Types.CREATE_DMS_ACCOUNT_SUCCESS]: _reducers.default.createDMSAccountSuccess,
  // Customer
  [_actions.Types.SET_CUSTOMER]: _reducers.default.setCustomer,
  // Incentive
  [_actions.Types.RELOAD_INCENTIVES_SUCCESS]: _reducers.default.reloadIncentivesSuccess,
  // Financing
  [_actions.Types.SET_FINANCING_DATES]: _reducers.default.setFinancingDates,
  [_actions.Types.SET_FINANCING_DATES_CHANGED]: setFinancingDatesChanged,
  // Printing
  [_actions.Types.SET_TRANSACTION_PRINT_LIST]: _reducers.default.setTransactionPrintList,
  [_actions.Types.SET_TRANSACTION_PRINT_CUSTOM_FIELDS]: _reducers.default.setTransactionPrintCustomFields,
  [_actions.Types.SET_IS_TRANSACTION_LIST_LOADING]: _reducers.default.setIsTransactionListLoading,
  [_actions.Types.SET_IS_TRANSACTION_LIST_LOADING_DONE]: _reducers.default.setIsTransactionListLoadingDone,
  // Accounting
  [_actions.Types.SET_ACC_TEMPLATES]: _reducers.default.setAccTemplates,
  [_actions.Types.SET_ACC_SELECTED_TEMPLATE]: _reducers.default.setAccSelectedTemplate,
  [_actions.Types.SET_ACC_TEMPLATE_DATA]: _reducers.default.setAccTemplateData,
  // Security deposit
  [_actions.Types.SET_MSD]: _reducers.default.setMsd,
  // Quote status and vehicle status unmatch (trigger conflict modal)
  [_actions.Types.SET_SHOW_CONFLICT]: _reducers.default.setShowConflict,
  // Forms
  [_actions.Types.RELOAD_TAB_FORMS_SUCCESS]: _reducers.default.reloadTabFormsSuccess,
  [_actions.Types.UPDATE_TAB_FORMS_SUCCESS]: _reducers.default.updateTabFormsSuccess,
  [_actions.Types.SET_TAB_FORMS_IS_PRINTING]: _reducers.default.setTabFormsIsPrinting,
  [_actions.Types.SET_DEAL_JACKET_FORM]: _reducers.default.setDealJacketForm,
  [_actions.Types.UPDATE_DEAL_JACKET_FORMS]: _reducers.default.updateDealJacketForms,
  [_actions.Types.SET_DEAL_JACKET_FORMS_TO_EDIT]: _reducers.default.setDealJacketFormsToEdit,
  [_actions.Types.RESET_DEAL_JACKET_FORMS_TO_EDIT]: _reducers.default.resetDealJacketFormsToEdit
}));