"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLeadTypes = exports.getLeadTrackingTypes = exports.getLeadStatuses = exports.getLeadSources = exports.getLeadMedias = void 0;
var _request = require("../../tools/request");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

/**
 * @param {String} organizationUnitId
 * @returns {Promise}
 */
const getLeadTypes = organizationUnitId => {
  const url = "".concat(CRM_ENDPOINT, "/dealer-configurations/leads/types").concat(organizationUnitId ? "?carDealerId=".concat(organizationUnitId) : '');
  return (0, _request.get)(url);
};

/**
 * @param {String} organizationUnitId
 * @returns {Promise}
 */
exports.getLeadTypes = getLeadTypes;
const getLeadSources = organizationUnitId => {
  const url = "".concat(CRM_ENDPOINT, "/dealer-configurations/leads/sources").concat(organizationUnitId ? "?carDealerId=".concat(organizationUnitId) : '');
  return (0, _request.get)(url);
};

/**
 * @param {String} organizationId
 * @param {String} organizationUnitId
 * @returns {Promise}
 */
exports.getLeadSources = getLeadSources;
const getLeadMedias = (organizationId, organizationUnitId) => {
  const url = "".concat(CRM_ENDPOINT, "/dealer-configurations/leads/medias?carDealerGroupId=").concat(organizationId).concat(organizationUnitId ? "&carDealerId=".concat(organizationUnitId) : '');
  return (0, _request.get)(url);
};

/**
 * @param {String} organizationId
 * @returns {Promise}
 */
exports.getLeadMedias = getLeadMedias;
const getLeadStatuses = organizationId => {
  const url = organizationId ? "".concat(CRM_ENDPOINT, "/configuredLeadStatus?organizationId=").concat(organizationId) : "".concat(CRM_ENDPOINT, "/leads/static/statuses");
  return (0, _request.get)(url);
};

/**
 * @param {String} organizationId
 * @returns {Promise}
 */
exports.getLeadStatuses = getLeadStatuses;
const getLeadTrackingTypes = () => {
  const url = "".concat(CRM_ENDPOINT, "/leads/static/trackingTypes");
  return (0, _request.get)(url);
};
exports.getLeadTrackingTypes = getLeadTrackingTypes;