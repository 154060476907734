"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFullNameFromEntry = void 0;
const getFullNameFromEntry = entry => {
  const contactFirstname = entry === null || entry === void 0 ? void 0 : entry.firstname;
  const contactLastname = entry === null || entry === void 0 ? void 0 : entry.lastname;
  return entry !== null && entry !== void 0 && entry.business ? entry.businessName : "".concat(contactFirstname || '', "  ").concat(contactLastname || '').trim();
};
exports.getFullNameFromEntry = getFullNameFromEntry;