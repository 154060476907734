"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStaticData = void 0;
var _appsCore = require("@sm360/apps-core");
var _commonsApi = require("@sm360/commons-api");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const getStaticData = async _ref => {
  let {
    initialState,
    dispatch
  } = _ref;
  const {
    orgId,
    orgUnitId,
    userId
  } = initialState || {};
  dispatch({
    type: 'uiStartLoading',
    name: 'LOAD_TRADE_IN'
  });

  // user rights verification
  let EvaluateVehicle = false;
  try {
    var _response$data;
    const response = await _appsCore.axios.get("".concat(process.env.REACT_APP_AUTHORIZATION_URL, "/users/").concat(userId, "/hasRight?right=AppraiseTradeInValue"));
    if (response !== null && response !== void 0 && (_response$data = response.data) !== null && _response$data !== void 0 && _response$data.response) EvaluateVehicle = response.data.response;
  } catch (_unused) {}

  // dealer rights verification
  let isShowroomV2 = false;
  let isShowroomV25 = false;
  let isDesking = false;
  try {
    var _orgUnits$find;
    const orgUnits = await _commonsApi.OrganizationService.getDealers({
      orgId
    });
    const orgUnitConfig = orgUnits === null || orgUnits === void 0 || (_orgUnits$find = orgUnits.find(orgUnit => orgUnit.id === orgUnitId)) === null || _orgUnits$find === void 0 ? void 0 : _orgUnits$find.organizationUnitConfiguration;
    if (orgUnitConfig) {
      isShowroomV2 = !!(orgUnitConfig !== null && orgUnitConfig !== void 0 && orgUnitConfig.showroomv2);
      isShowroomV25 = !!(orgUnitConfig !== null && orgUnitConfig !== void 0 && orgUnitConfig.showroomv25);
      isDesking = !!(orgUnitConfig !== null && orgUnitConfig !== void 0 && orgUnitConfig.desking);
    }
  } catch (_unused2) {}
  const state = _objectSpread(_objectSpread({}, initialState), {}, {
    access: {
      EvaluateVehicle,
      isShowroomV2,
      isShowroomV25,
      isDesking
    }
  });
  dispatch({
    type: 'init',
    initialState: state
  });
};
exports.getStaticData = getStaticData;