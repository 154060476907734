"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = require("../../../tools/request");
const SHOWROOM_API_URL = process.env.REACT_APP_SHOWROOM_API_URL;
const urlPrefix = 'buildAndQuote';

/**
 * @param {String[]} pathParams
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 * @throws {TypeError} If the response is not an array
 */
const getItems = async (pathParams, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/items/").concat(pathParams.join('/'));
  const data = await (0, _request.post)(url, body, {
    params: queryParams
  }, true);
  if (!Array.isArray(data)) {
    throw new TypeError("The response of the /".concat(urlPrefix, "/items endpoint is not an array"));
  }
  return data;
};

/**
 * @param {String[]} pathParams
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
const getModel = (pathParams, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/model/").concat(pathParams.join('/'));
  return (0, _request.post)(url, body, {
    params: queryParams
  }, true);
};

/**
 * @param {String[]} pathParams
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 * @throws {TypeError} If the response is not an array
 */
const getModelYears = async (pathParams, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/modelYears/").concat(pathParams.join('/'));
  const data = await (0, _request.post)(url, body, {
    params: queryParams
  }, true);
  if (!Array.isArray(data)) {
    throw new TypeError("The response of the /".concat(urlPrefix, "/modelYears endpoint is not an array"));
  }
  return data;
};

/**
 * @param {String[]} pathParams
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
const updatePaymentOptions = (pathParams, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/paymentOptions/").concat(pathParams.join('/'));
  return (0, _request.post)(url, body, {
    params: queryParams
  }, true);
};

/**
 * @param {String[]} pathParams
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
const validateItems = (pathParams, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/itemValidation/").concat(pathParams.join('/'));
  return (0, _request.post)(url, body, {
    params: queryParams
  }, true);
};

/**
 * @param {String[]} pathParams
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
const validateModel = (pathParams, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/").concat(urlPrefix, "/modelValidation/").concat(pathParams.join('/'));
  return (0, _request.post)(url, body, {
    params: queryParams
  }, true);
};

/**
 * Not used, but can help debugging
 *
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
const matchVehicle = (queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/listing/new/matchVehicle");
  return (0, _request.post)(url, body, {
    params: queryParams
  }, true);
};
var _default = exports.default = {
  getItems,
  getModel,
  getModelYears,
  matchVehicle,
  updatePaymentOptions,
  validateItems,
  validateModel
};