"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.splitOptionsBySuggested = exports.parseBasicInfoToHtml = exports.isVehicleSold = exports.isVehicleOnHold = exports.isVehicleDeleted = exports.groupTypesByCategory = exports.getResumeOptions = exports.getFullNameFromEntry = void 0;
var _commonsUtils = require("@sm360/commons-utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// Convert a flat list of leadTypes into a list of leadCategories which contain leadTypes
const groupTypesByCategory = types => {
  return types.reduce((acc, type) => {
    const groupIndex = acc.findIndex(group => group.groupId === String(type.leadCategory.id));
    if (groupIndex >= 0) {
      // group already exists, push new lead type in it
      const group = acc[groupIndex];
      const updatedGroup = _objectSpread(_objectSpread({}, group), {}, {
        options: [...group.options, {
          id: String(type.id),
          label: type.type
        }]
      });
      const updatedAcc = [...acc];
      updatedAcc.splice(groupIndex, 1, updatedGroup);
      return updatedAcc;
    } else {
      // group doesn't exist yet, create it then push new lead type in it
      return [...acc, {
        groupId: String(type.leadCategory.id),
        groupLabel: type.leadCategory.category,
        options: [{
          id: String(type.id),
          label: type.type
        }]
      }];
    }
  }, []).sort((a, b) => a.groupId.localeCompare(b.groupId));
};
exports.groupTypesByCategory = groupTypesByCategory;
const getFullNameFromEntry = entry => {
  var _entry$contact, _entry$contact2, _entry$contact3;
  const contactFirstname = entry === null || entry === void 0 || (_entry$contact = entry.contact) === null || _entry$contact === void 0 ? void 0 : _entry$contact.firstname;
  const contactLastname = entry === null || entry === void 0 || (_entry$contact2 = entry.contact) === null || _entry$contact2 === void 0 ? void 0 : _entry$contact2.lastname;
  return entry !== null && entry !== void 0 && (_entry$contact3 = entry.contact) !== null && _entry$contact3 !== void 0 && _entry$contact3.business ? entry.contact.businessName : "".concat(contactFirstname || '', "  ").concat(contactLastname || '').trim();
};
exports.getFullNameFromEntry = getFullNameFromEntry;
const parseBasicInfoToHtml = info => {
  if (typeof info !== 'string') return info;
  return (0, _commonsUtils.replaceLinksByHtmlTags)(info, true).replaceAll(/\n/g, '<br/>');
};
exports.parseBasicInfoToHtml = parseBasicInfoToHtml;
const isVehicleOnHold = vehicle => (vehicle === null || vehicle === void 0 ? void 0 : vehicle.vehicleInventoryStatus) !== 'SOLD' && (vehicle === null || vehicle === void 0 ? void 0 : vehicle.vehicleInventoryStatus) !== 'DELETED' && (vehicle === null || vehicle === void 0 ? void 0 : vehicle.onHold);
exports.isVehicleOnHold = isVehicleOnHold;
const isVehicleSold = vehicle => (vehicle === null || vehicle === void 0 ? void 0 : vehicle.vehicleInventoryStatus) === 'SOLD';
exports.isVehicleSold = isVehicleSold;
const isVehicleDeleted = vehicle => (vehicle === null || vehicle === void 0 ? void 0 : vehicle.vehicleInventoryStatus) === 'DELETED';
exports.isVehicleDeleted = isVehicleDeleted;
const getResumeOptions = _ref => {
  var _restrictedOptions$;
  let {
    unavailableValue,
    fullOptions = [],
    restrictedOptions = [],
    t,
    canAccessRestrictedData = false
  } = _ref;
  const _restrictedOptions = restrictedOptions || fullOptions;

  // if first option has an 'options' field => it is a sub-options use case
  const hasSubOptions = Array.isArray(_restrictedOptions === null || _restrictedOptions === void 0 || (_restrictedOptions$ = _restrictedOptions[0]) === null || _restrictedOptions$ === void 0 ? void 0 : _restrictedOptions$.options);
  let optionsWithRestrictedValueDisabled = [];
  if (hasSubOptions) {
    optionsWithRestrictedValueDisabled = _restrictedOptions.map(o => _objectSpread(_objectSpread({}, o), {}, {
      options: o.options.filter(subO => subO.restrictedAccess ? canAccessRestrictedData : true).map(subO => _objectSpread(_objectSpread({}, subO), {}, {
        value: (0, _commonsUtils.nullOrString)(subO.value)
      }))
    }));
  } else {
    optionsWithRestrictedValueDisabled = _restrictedOptions.filter(o => o.restrictedAccess ? canAccessRestrictedData : true).map(o => _objectSpread(_objectSpread({}, o), {}, {
      value: (0, _commonsUtils.nullOrString)(o.value)
    }));
  }

  // Add disabled option for value (if this value is not present in options)
  let additionalOption = null;
  const parsedValue = (0, _commonsUtils.nullOrString)(unavailableValue);
  if (hasSubOptions) {
    const subOptions = optionsWithRestrictedValueDisabled.reduce((acc, o) => [...acc, ...(o.options || [])], []);
    const valueExists = parsedValue ? !!subOptions.find(o => String(o.value) === parsedValue) : true;
    if (!valueExists) {
      var _fullOptions$find;
      additionalOption = {
        id: 'sourcesExcluded',
        isSuggested: true,
        label: t('leads.customFields.EXCLUDED'),
        options: [{
          label: ((_fullOptions$find = fullOptions.find(o => String(o.value) === parsedValue)) === null || _fullOptions$find === void 0 ? void 0 : _fullOptions$find.label) || '',
          id: unavailableValue,
          value: parsedValue,
          isDisabled: true
        }]
      };
    }
  } else {
    const valueExists = parsedValue ? !!optionsWithRestrictedValueDisabled.find(o => String(o.value) === parsedValue) : true;
    if (!valueExists) {
      var _fullOptions$find2;
      additionalOption = {
        label: ((_fullOptions$find2 = fullOptions.find(o => String(o.value) === parsedValue)) === null || _fullOptions$find2 === void 0 ? void 0 : _fullOptions$find2.label) || '',
        id: unavailableValue,
        value: parsedValue,
        isDisabled: true
      };
    }
  }
  return [...(additionalOption ? [additionalOption] : []), ...optionsWithRestrictedValueDisabled];
};
exports.getResumeOptions = getResumeOptions;
const splitOptionsBySuggested = function () {
  var _options$;
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let suggestedOptions = [];
  let otherOptions = [];
  if (Array.isArray(options === null || options === void 0 || (_options$ = options[0]) === null || _options$ === void 0 ? void 0 : _options$.options)) {
    var _options$filter, _options$filter2;
    suggestedOptions = (options === null || options === void 0 || (_options$filter = options.filter(o => o.isSuggested)) === null || _options$filter === void 0 ? void 0 : _options$filter.reduce((acc, a) => [...acc, ...((a === null || a === void 0 ? void 0 : a.options) || [])], [])) || [];
    otherOptions = (options === null || options === void 0 || (_options$filter2 = options.filter(o => !o.isSuggested)) === null || _options$filter2 === void 0 ? void 0 : _options$filter2.reduce((acc, a) => [...acc, ...((a === null || a === void 0 ? void 0 : a.options) || [])], [])) || [];
  } else {
    suggestedOptions = options;
  }
  return {
    suggestedOptions,
    otherOptions
  };
};
exports.splitOptionsBySuggested = splitOptionsBySuggested;