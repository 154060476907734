"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getConfiguredProviders = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getConfiguredProviders = async (organization, userId, redirectUrl) => {
  var _organization$organiz;
  const dealersQueryString = (organization === null || organization === void 0 || (_organization$organiz = organization.organizationUnits) === null || _organization$organiz === void 0 || (_organization$organiz = _organization$organiz.map(u => "&carDealerIds=".concat(u.key))) === null || _organization$organiz === void 0 ? void 0 : _organization$organiz.join('')) || '';
  const userIdQueryString = userId ? "&userId=".concat(userId) : '';
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/dealer-configurations/mailServer/providers?redirectUrl=").concat(redirectUrl).concat(dealersQueryString).concat(userIdQueryString));
};
exports.getConfiguredProviders = getConfiguredProviders;