"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStaticData = exports.getEmployees = void 0;
var _appsCore = require("@sm360/apps-core");
var _commonsUtils = require("@sm360/commons-utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getData = query => (query === null || query === void 0 ? void 0 : query.data) || [];

// Get employees
const getEmployees = async _ref => {
  let {
    key,
    dispatch,
    right,
    body,
    translate
  } = _ref;
  const results = await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/users?activeOnly=true&withOrganizationUnitRight=").concat(right), body);
  dispatch({
    type: 'setStaticData',
    staticData: [key, results.data.map(t => ({
      id: t.id,
      label: (0, _commonsUtils.getUserLabel)(t, translate),
      value: t.id,
      email: t.email
    })).sort((a, b) => (0, _commonsUtils.compareUserLabel)(a, b, translate))]
  });
};
exports.getEmployees = getEmployees;
const getStaticData = async _ref2 => {
  let {
    dispatch,
    organization,
    t,
    queries
  } = _ref2;
  const orgUnits = getData(queries.dealers);
  const orgUnitsIds = orgUnits.map(orgUnit => orgUnit.id);
  const territories = getData(queries.provinces);
  const provinces = territories.filter(t => t.countryCode === 'CA');
  const states = territories.filter(t => t.countryCode === 'US');

  // optional call
  let contactContactTags = {
    data: []
  };
  try {
    contactContactTags = await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/contacts/getContactTags/byOrganizationId?organizationId=").concat(organization.id));
  } catch (_unused) {}
  dispatch({
    type: 'initStaticData',
    staticData: {
      contactContactTags: contactContactTags.data.map(unitToMap => _objectSpread(_objectSpread({}, unitToMap), {}, {
        key: unitToMap.id,
        value: unitToMap.id,
        id: unitToMap.id,
        label: unitToMap.name || t('contacts.forms.profile.contactContactTags.NO_NAME')
      })).sort((a, b) => a.label.localeCompare(b.label)),
      organizationUnitIds: orgUnits,
      country: [{
        label: t("contacts.forms.profile.localization.country.CANADA"),
        value: 'CA',
        id: 'CA'
      }, {
        label: t("contacts.forms.profile.localization.country.USA"),
        value: 'US',
        id: 'US'
      }],
      territories: {
        CA: provinces,
        US: states
      },
      preferredContactMoment: [{
        label: t('contacts.forms.profile.contactInformation.preferredContactMoment.WEEKDAY'),
        value: 'WEEKDAY',
        id: 'WEEKDAY'
      }, {
        label: t('contacts.forms.profile.contactInformation.preferredContactMoment.WEEKEND'),
        value: 'WEEKEND',
        id: 'WEEKEND'
      }, {
        label: t('contacts.forms.profile.contactInformation.preferredContactMoment.ANY'),
        value: 'ANY',
        id: 'ANY'
      }],
      preferredContactTime: [{
        label: t("contacts.forms.profile.contactInformation.preferredContactTime.MORNING"),
        value: 'MORNING',
        id: 'MORNING'
      }, {
        label: t("contacts.forms.profile.contactInformation.preferredContactTime.AFTERNOON"),
        value: 'AFTERNOON',
        id: 'AFTERNOON'
      }, {
        label: t("contacts.forms.profile.contactInformation.preferredContactTime.EVENING"),
        value: 'EVENING',
        id: 'EVENING'
      }, {
        label: t("contacts.forms.profile.contactInformation.preferredContactTime.ANY"),
        value: 'ANY',
        id: 'ANY'
      }]
    },
    query: {
      filters: {
        organizationId: organization.id,
        organizationUnitIds: orgUnitsIds
      }
    }
  });
};
exports.getStaticData = getStaticData;