"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setStatus = exports.VEHICLE_STATUS = exports.STATUS_UNMATCHING_LIST = exports.STATUS_MATCHING_LIST = exports.QUOTE_STATUS = exports.API_STATUS = void 0;
var _request = require("../../tools/request");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const VEHICLE_STATUS_API_URL = process.env.REACT_APP_STATUS_API_URL;
const QUOTE_STATUS = exports.QUOTE_STATUS = {
  APPROVAL: 'approval',
  BOOKED: 'booked',
  CANCELLED: 'cancelled',
  DELIVERED: 'delivered',
  QUOTE: 'quote',
  SOLD: 'sold'
};
const VEHICLE_STATUS = exports.VEHICLE_STATUS = {
  DELIVERED: 'DELIVERED',
  FOR_SALE: 'FOR_SALE',
  ON_HOLD: 'ON_HOLD',
  SOLD: 'SOLD'
};
const API_STATUS = exports.API_STATUS = {
  ACCEPT_OFFER: 'ACCEPT_OFFER',
  CANCEL_DELIVERY: 'CANCEL_DELIVERY',
  CANCEL_SALE: 'CANCEL_SALE',
  CONFIRM_DELIVERY: 'CONFIRM_DELIVERY',
  CREATE_UPDATE_QUOTE: 'CREATE_UPDATE_QUOTE',
  REJECT_OFFER: 'REJECT_OFFER',
  RETURN_TO_SALES_MANAGER: 'RETURN_TO_SALES_MANAGER',
  SEND_TO_SALES_MANAGER: 'SEND_TO_SALES_MANAGER'
};

/**
 * List of matching status between vehicle and quote, indicating if
 * data are properly synced between the quote and the associated vehicle.
 */
const STATUS_MATCHING_LIST = exports.STATUS_MATCHING_LIST = [{
  quoteStatus: QUOTE_STATUS.QUOTE,
  vehicleStatus: VEHICLE_STATUS.FOR_SALE
}, {
  quoteStatus: QUOTE_STATUS.APPROVAL,
  vehicleStatus: VEHICLE_STATUS.ON_HOLD
}, {
  quoteStatus: QUOTE_STATUS.SOLD,
  vehicleStatus: VEHICLE_STATUS.SOLD
}, {
  quoteStatus: QUOTE_STATUS.DELIVERED,
  vehicleStatus: VEHICLE_STATUS.DELIVERED
}];

/**
 * List of non-matching status between vehicle and quote, indicating that data are de-synchronized
 * between the quote and the associated vehicle. The vehicle status is in advance.
 */
const STATUS_UNMATCHING_LIST = exports.STATUS_UNMATCHING_LIST = [{
  quoteStatus: QUOTE_STATUS.QUOTE,
  vehicleStatus: VEHICLE_STATUS.ON_HOLD
}, {
  quoteStatus: QUOTE_STATUS.QUOTE,
  vehicleStatus: VEHICLE_STATUS.SOLD
}, {
  quoteStatus: QUOTE_STATUS.QUOTE,
  vehicleStatus: VEHICLE_STATUS.DELIVERED
}, {
  quoteStatus: QUOTE_STATUS.APPROVAL,
  vehicleStatus: VEHICLE_STATUS.SOLD
}, {
  quoteStatus: QUOTE_STATUS.APPROVAL,
  vehicleStatus: VEHICLE_STATUS.DELIVERED
}, {
  quoteStatus: QUOTE_STATUS.SOLD,
  vehicleStatus: VEHICLE_STATUS.DELIVERED
}];

/**
 * @param {String} status
 * @param {Object} body
 * @returns {Promise}
 */
const setStatus = (status, body) => {
  if (Object.values(API_STATUS).indexOf(status) === -1) {
    throw new TypeError("@sm360/commons-api/ShowroomService/desking/status-service - status '".concat(status, "' unknown"));
  }
  return (0, _request.post)("".concat(VEHICLE_STATUS_API_URL, "/status"), _objectSpread(_objectSpread({}, body), {}, {
    status
  }));
};
exports.setStatus = setStatus;