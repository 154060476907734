"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CONST_TABS = void 0;
var _schema = require("./tabs/schema.contact");
var _schema2 = require("./tabs/schema.finance");
var _schema3 = require("./tabs/schema.service");
const CONST_TABS = exports.CONST_TABS = [{
  title: 'leads.updateView.resumeForm.TAB_LABEL',
  id: 'resume',
  body: [{
    fields: [{
      input: 'resume'
    }]
  }]
}, {
  title: 'leads.updateView.contactForm.TAB_LABEL',
  id: 'contact',
  body: _schema.PROFILE_FORM
},
// {
//     title: 'leads.updateView.activities.TAB_LABEL',
//     id: 'activities',
//     body: [
//         {
//             fields: [
//                 {
//                     input: 'activities',
//                 },
//             ],
//         },
//     ],
// },
{
  title: 'leads.updateView.trackingForm.TAB_LABEL',
  id: 'tracking'
}, {
  title: 'leads.updateView.vehicleForm.TAB_LABEL',
  id: 'vehicle',
  body: [{
    legend: 'leads.updateView.vehicleForm.vehicleDisplay.LEGEND',
    fields: [{
      input: 'vehicleDisplay'
    }]
  }, {
    legend: 'leads.updateView.vehicleForm.addVehicle.LEGEND',
    fields: [{
      input: 'vehicles'
    }]
  }, {
    legend: 'leads.updateView.vehicleForm.extraInfo.LEGEND',
    fields: [{
      input: 'textarea',
      className: 'small-12',
      label: '',
      name: 'leadCarInfo.info',
      skipLayout: true
    }]
  }]
}, {
  title: 'leads.updateView.tradeIn.TAB_LABEL',
  id: 'tradeIn',
  body: [{
    legend: "leads.updateView.tradeIn.TRADE_IN",
    fields: [{
      input: 'tradein',
      // skipLayout: true,
      className: 'width-100'
    }]
  }]
}, {
  title: 'leads.updateView.serviceForm.TAB_LABEL',
  id: 'service',
  body: _schema3.SERVICE_FORM
}, {
  title: 'leads.updateView.financeForm.TAB_LABEL',
  id: 'financeInfo',
  body: _schema2.FINANCE_FORM
}, {
  title: 'leads.updateView.documents.TAB_LABEL',
  id: 'documents',
  body: [{
    fields: [{
      input: 'documents',
      className: 'small-12 medium-4 large-3',
      label: 'documents',
      name: 'documents',
      skipLayout: true
    }]
  }]
}, {
  title: 'leads.updateView.subscriptions.TAB_LABEL',
  id: 'antiSpam',
  body: [{
    fields: [{
      input: 'subscriptions',
      className: 'small-12',
      label: '',
      name: 'subscriptions'
    }]
  }]
}];