"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLeadVehicleKeywords = exports.getLeadInformationKeywords = exports.getLeadEmployeesKeywords = exports.getLeadContactKeywords = void 0;
var _commonsUtils = require("@sm360/commons-utils");
const getLeadInformationKeywords = (lead, lang) => {
  const langSuffix = lang === 'fr' ? 'Fr' : 'En';
  const leadId = (lead === null || lead === void 0 ? void 0 : lead.leadId) || '';
  const leadMedia = (lead === null || lead === void 0 ? void 0 : lead.leadMedia) || '';
  const localizedLeadStatus = (lead === null || lead === void 0 ? void 0 : lead.leadStatus) || (lead === null || lead === void 0 ? void 0 : lead["leadStatus".concat(langSuffix)]) || '';
  const localizedLeadType = (lead === null || lead === void 0 ? void 0 : lead.leadType) || (lead === null || lead === void 0 ? void 0 : lead["leadType".concat(langSuffix)]) || '';
  const localizedLeadSource = (lead === null || lead === void 0 ? void 0 : lead.leadSource) || (lead === null || lead === void 0 ? void 0 : lead["leadSource".concat(langSuffix)]) || '';
  const localizedTrackingType = (lead === null || lead === void 0 ? void 0 : lead.trackingType) || (lead === null || lead === void 0 ? void 0 : lead["trackingType".concat(langSuffix)]) || '';
  return "".concat(localizedLeadStatus, " ").concat(leadId, " ").concat(localizedLeadType, " ").concat(localizedLeadSource, " ").concat(leadMedia, " ").concat(localizedTrackingType).trim();
};
exports.getLeadInformationKeywords = getLeadInformationKeywords;
const getLeadContactKeywords = lead => {
  var _lead$contact, _lead$contact2;
  const contactFirstName = (lead === null || lead === void 0 ? void 0 : lead.contactFirstName) || '';
  const contactLastName = (lead === null || lead === void 0 ? void 0 : lead.contactLastName) || '';
  const contactBusinessName = (lead === null || lead === void 0 ? void 0 : lead.contactBusinessName) || '';
  const contactPhones = lead !== null && lead !== void 0 && (_lead$contact = lead.contact) !== null && _lead$contact !== void 0 && _lead$contact.phones ? Object.entries(lead.contact.phones).filter(p => p[1] !== 'null').map(p => (0, _commonsUtils.cleanPhoneNumber)(p[1])).join(' ') : '';
  const contactEmails = lead !== null && lead !== void 0 && lead.emails ? lead.emails.join(' ') : '';
  const contacTags = (lead === null || lead === void 0 || (_lead$contact2 = lead.contact) === null || _lead$contact2 === void 0 || (_lead$contact2 = _lead$contact2.contactTags) === null || _lead$contact2 === void 0 ? void 0 : _lead$contact2.length) > 0 ? lead.contact.contactTags.map(t => t.name).join(' ') : '';
  return "".concat(contactFirstName, " ").concat(contactLastName, " ").concat(contactBusinessName, " ").concat(contactPhones, " ").concat(contactEmails, " ").concat(contacTags).trim();
};
exports.getLeadContactKeywords = getLeadContactKeywords;
const getLeadVehicleKeywords = lead => {
  var _lead$vehicleSummaryE, _lead$vehicleTradeIn, _lead$serviceVehicle;
  const vehicleSummaryES = (lead === null || lead === void 0 || (_lead$vehicleSummaryE = lead.vehicleSummaryES) === null || _lead$vehicleSummaryE === void 0 ? void 0 : _lead$vehicleSummaryE.description) || '';
  const vehicleTradeIn = (lead === null || lead === void 0 || (_lead$vehicleTradeIn = lead.vehicleTradeIn) === null || _lead$vehicleTradeIn === void 0 ? void 0 : _lead$vehicleTradeIn.description) || '';
  const serviceVehicle = (lead === null || lead === void 0 || (_lead$serviceVehicle = lead.serviceVehicle) === null || _lead$serviceVehicle === void 0 ? void 0 : _lead$serviceVehicle.description) || '';
  return "".concat(vehicleSummaryES, " ").concat(vehicleTradeIn, " ").concat(serviceVehicle).trim();
};
exports.getLeadVehicleKeywords = getLeadVehicleKeywords;
const getLeadEmployeesKeywords = lead => {
  const ownerName = (lead === null || lead === void 0 ? void 0 : lead.ownerName) || '';
  const salesmanName = (lead === null || lead === void 0 ? void 0 : lead.salesmanName) || '';
  const salesManagerName = (lead === null || lead === void 0 ? void 0 : lead.salesManagerName) || '';
  const businessManagerName = (lead === null || lead === void 0 ? void 0 : lead.businessManagerName) || '';
  return "".concat(ownerName, " ").concat(salesmanName, " ").concat(salesManagerName, " ").concat(businessManagerName).trim();
};
exports.getLeadEmployeesKeywords = getLeadEmployeesKeywords;