"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setLoadingSuccess = exports.default = exports.INITIAL_STATE = void 0;
var _reduxsauce = require("reduxsauce");
var _deskingError = require("@sm360/desking-error");
var _deskingCommons = require("@sm360/desking-commons");
var _steps = require("../constants/steps");
var _payment = require("../constants/payment");
var _actions = require("./actions");
var _reducers = _interopRequireDefault(require("./reducers"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * Most of the structure is inspired by the models written in Showroom API's swagger
 */
const INITIAL_STATE = exports.INITIAL_STATE = _objectSpread(_objectSpread(_objectSpread({}, _deskingError.errorInitialState), _deskingCommons.priceIncreaseInitialState), {}, {
  isLoading: true,
  config: {
    lang: '',
    location: null,
    organizationId: 0,
    organizationUnitId: 0,
    accountId: 0,
    leadId: 0,
    make: 'string',
    model: 'string',
    bodyStyle: 'string'
  },
  permissions: {
    hasDeskingAccess: false,
    isHidingPrices: false
  },
  // Base properties that defines build and quote
  baseProps: {
    // Requested model trim code
    modelTrimCode: '',
    // Requested model trim configuration id
    configurationId: 0,
    // Token that represents all selected items in the current vehicle's transaction
    transactionToken: '',
    // Keep the previous base props values, useful for model validation
    previousConfigurationId: '',
    previousModelTrimCode: ''
  },
  // Base properties that defines a transaction
  plan: {
    // Sales transaction type
    // enum: ['retail', 'dealerTrade', 'fleet', 'wholesale', 'consignment', 'aftermarket', 'leaseBuyOut', 'internalSale']
    saleType: 'retail',
    // Sales taxes calculation plan, enum: ['standard', 'registrant', 'registrantPST', 'native', 'native50']
    taxPlan: 'standard',
    // enum: ['new', 'newDemonstrator', 'used', 'usedCertified']
    vehicleCondition: 'new',
    // "Vehicle odometer reading", Number
    odometer: 0
  },
  // Base properties that identify a purchase method
  purchaseMethod: {
    // Purchase method type, enum: ['cash', 'finance', 'financeWithBalloon', 'lease', 'singlePaymentLease']
    purchaseMethod: _payment.PURCHASE_METHOD_DEFAULT,
    // Finance km per year plan (if applicable), Number
    kmPerYearPlan: 0,
    // Payment frequency (number of payments per year), enum: [12, 24, 26, 52]
    paymentFrequency: 0,
    // Payment term (in months), Number
    term: 0,
    // Cash down applied to payment (if applicable), Number
    cashDown: 0
  },
  // Base properties of trade ins
  tradeIn: {
    // Total trade-ins amount, Number
    tradeIn: 0,
    // Total liens (trade-in balance due) amount, Number
    lien: 0
  },
  // Details of possible payments, updated by paymentOptions/ request
  paymentOptions: {
    cashPurchase: {},
    finance: {},
    financeOptions: [],
    metaData: []
  },
  // In payment bar, no API calls when changing
  hasTaxesIncluded: true,
  // Validation/conflicts
  validation: {
    isValid: true,
    mustResetSelection: false,
    negociationTransaction: {},
    validResolutionCombinations: [],
    validationItem: {},
    validationMessage: ''
  },
  salesPersonnel: {
    salesPerson: {},
    salesManager: {},
    businessManager: {}
  },
  // Step navigation
  nav: {
    currentStep: _steps.STEP_DEFAULT,
    lastVisitedStep: _steps.STEP_DEFAULT,
    isNextAvailable: false,
    // Used to send a "one time" event to CRM
    hasSentExitConfirmation: false
  },
  // Loading states
  isPaymentUpdating: false,
  // Content
  content: {
    items: [],
    model: {},
    modelYears: [],
    newCarTrimId: 0,
    perfectMatches: [],
    selectedItem: {},
    similarMatches: [],
    year: 0
  }
});
const setLoadingSuccess = state => _objectSpread(_objectSpread({}, state), {}, {
  isLoading: false
});
exports.setLoadingSuccess = setLoadingSuccess;
var _default = exports.default = (0, _reduxsauce.createReducer)(INITIAL_STATE, _objectSpread(_objectSpread(_objectSpread({}, _deskingError.errorReducers), _deskingCommons.priceIncreaseReducers), {}, {
  [_actions.Types.INITIALIZE_STORE]: _reducers.default.initializeStore,
  [_actions.Types.SET_LOADING_SUCCESS]: _reducers.default.setLoadingSuccess,
  [_actions.Types.SET_CURRENT_STEP]: _reducers.default.setCurrentStep,
  [_actions.Types.SET_PERMISSION]: _reducers.default.setPermission,
  [_actions.Types.SEND_EXIT_CONFIRMATION]: _reducers.default.sendExitConfirmation,
  [_actions.Types.SET_LOCATION]: _reducers.default.setLocation,
  [_actions.Types.SET_LEAD_INFO]: _reducers.default.setLeadInfo,
  // Steps loading
  [_actions.Types.LOAD_STEP_YEAR_SUCCESS]: _reducers.default.loadStepYearSuccess,
  [_actions.Types.LOAD_MODEL_SUCCESS]: _reducers.default.loadModelSuccess,
  [_actions.Types.LOAD_STEP_OPTION_ITEMS_SUCCESS]: _reducers.default.loadStepOptionItemsSuccess,
  [_actions.Types.LOAD_MATCHES_SUCCESS]: _reducers.default.loadMatchesSuccess,
  // Step 1
  [_actions.Types.SELECT_MODEL_YEAR]: _reducers.default.selectModelYear,
  // Step 2
  [_actions.Types.SELECT_TRIM]: _reducers.default.selectTrim,
  // Step 3, 4
  [_actions.Types.UPDATE_MODEL_TRIM]: _reducers.default.updateModelTrim,
  // Step 5
  [_actions.Types.SELECT_OPTION_ITEM]: _reducers.default.selectOptionItem,
  // Payment bar
  [_actions.Types.LOAD_PAYMENT_OPTIONS_SUCCESS]: _reducers.default.loadPaymentOptionsSuccess,
  [_actions.Types.SELECT_PURCHASE_METHOD]: _reducers.default.selectPurchaseMethod,
  [_actions.Types.UPDATE_CASH_DOWN]: _reducers.default.updateCashDown,
  [_actions.Types.UPDATE_KM_PLAN]: _reducers.default.updateKmPlan,
  [_actions.Types.UPDATE_LIEN]: _reducers.default.updateLien,
  [_actions.Types.UPDATE_PAYMENT_FREQUENCY]: _reducers.default.updatePaymentFrequency,
  [_actions.Types.UPDATE_PAYMENT_OPTIONS_SUCCESS]: _reducers.default.loadPaymentOptionsSuccess,
  [_actions.Types.UPDATE_TAXES_INCLUDED]: _reducers.default.updateTaxesIncluded,
  [_actions.Types.UPDATE_TERM]: _reducers.default.updateTerm,
  [_actions.Types.UPDATE_TRADE_IN]: _reducers.default.updateTradeIn,
  // Validation
  [_actions.Types.APPLY_CONFLICT_RESOLUTION]: _reducers.default.applyConflictResolution,
  [_actions.Types.CANCEL_CONFLICT]: _reducers.default.cancelConflict,
  [_actions.Types.VALIDATE_FAILURE]: _reducers.default.validateFailure,
  [_actions.Types.VALIDATE_SUCCESS]: _reducers.default.validateSuccess
}));