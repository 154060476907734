"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformLeadToBackend = exports.transformLeadFromBackend = void 0;
var _commonsUtils = require("@sm360/commons-utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// Transform the FE lead structure to the BE lead structure
const transformLeadToBackend = entry => {
  var _formattedEntry$conta, _formattedEntry$conta2, _formattedEntry$conta3, _formattedEntry$conta4;
  const {
    leadSourceType,
    leadStatusIds,
    leadTypeIds,
    leadServiceVehicle,
    salespersonIds,
    contact
  } = entry;
  const {
    contactFinancing,
    contactLanguage
  } = contact;
  const {
    id: contactFinancingId,
    financingType,
    approvalStatus,
    jobType,
    rentType
  } = contactFinancing || {};
  const checkTypeModified = type => {
    if (!type) return null;
    return typeof type === 'number' ? {
      id: parseInt(type)
    } : type;
  };
  const parsedFinancingType = checkTypeModified(financingType);
  const parsedApprovalStatus = checkTypeModified(approvalStatus);
  const parsedJobType = checkTypeModified(jobType);
  const parsedRentType = checkTypeModified(rentType);
  const contactFinancingEmpty = !parsedFinancingType && !parsedApprovalStatus && !parsedJobType && !parsedRentType;
  const leadServiceVehicleEmpty = Object.values(leadServiceVehicle).every(v => !v);
  const formattedEntry = _objectSpread(_objectSpread({}, entry), {}, {
    contact: _objectSpread(_objectSpread({}, contact), {}, {
      contactLanguage: contactLanguage,
      contactFinancing: !contactFinancingId && contactFinancingEmpty ? null : _objectSpread(_objectSpread({}, contactFinancing), {}, {
        financingType: parsedFinancingType,
        approvalStatus: parsedApprovalStatus,
        jobType: parsedJobType,
        rentType: parsedRentType
      })
    }),
    leadServiceVehicle: leadServiceVehicleEmpty ? null : leadServiceVehicle,
    salesmanUserId: salespersonIds,
    leadSourceType: {
      id: parseInt(leadSourceType)
    },
    leadType: {
      id: parseInt(leadTypeIds)
    },
    leadStatus: {
      id: parseInt(leadStatusIds)
    }
  });
  if ((_formattedEntry$conta = formattedEntry.contact) !== null && _formattedEntry$conta !== void 0 && _formattedEntry$conta.contactFinancing && !parsedFinancingType) delete formattedEntry.contact.contactFinancing.financingType;
  if ((_formattedEntry$conta2 = formattedEntry.contact) !== null && _formattedEntry$conta2 !== void 0 && _formattedEntry$conta2.contactFinancing && !parsedApprovalStatus) delete formattedEntry.contact.contactFinancing.approvalStatus;
  if ((_formattedEntry$conta3 = formattedEntry.contact) !== null && _formattedEntry$conta3 !== void 0 && _formattedEntry$conta3.contactFinancing && !parsedJobType) delete formattedEntry.contact.contactFinancing.jobType;
  if ((_formattedEntry$conta4 = formattedEntry.contact) !== null && _formattedEntry$conta4 !== void 0 && _formattedEntry$conta4.contactFinancing && !parsedRentType) delete formattedEntry.contact.contactFinancing.rentType;
  delete formattedEntry.salespersonIds;
  delete formattedEntry.leadTypeIds;
  delete formattedEntry.leadStatusIds;
  delete formattedEntry.leadCategoryId;
  return formattedEntry;
};

// Transform the BE lead structure to the FE lead structure
exports.transformLeadToBackend = transformLeadToBackend;
const transformLeadFromBackend = entry => {
  var _leadType$leadCategor;
  const {
    leadSourceType,
    leadType,
    leadStatus,
    leadServiceVehicle,
    salesmanUserId,
    contact
  } = entry;
  const {
    mobilePhone,
    homePhone,
    workPhone,
    workPhoneExtension,
    contactFinancing
  } = contact || {};
  const {
    approvalStatus,
    jobType,
    rentType
  } = contactFinancing || {};
  const formattedEntry = _objectSpread(_objectSpread({}, entry), {}, {
    contact: _objectSpread(_objectSpread({}, contact), {}, {
      mobilePhone: (0, _commonsUtils.validPhoneNumber)(mobilePhone) ? (0, _commonsUtils.cleanPhoneNumber)(mobilePhone) : '',
      homePhone: (0, _commonsUtils.validPhoneNumber)(homePhone) ? (0, _commonsUtils.cleanPhoneNumber)(homePhone) : '',
      workPhone: (0, _commonsUtils.validPhoneNumber)(workPhone) ? (0, _commonsUtils.cleanPhoneNumber)(workPhone) : '',
      workPhoneExtension: workPhoneExtension ? Number(workPhoneExtension) : null,
      contactFinancing: _objectSpread(_objectSpread({}, contactFinancing), {}, {
        approvalStatus: (0, _commonsUtils.nullOrNumber)(approvalStatus === null || approvalStatus === void 0 ? void 0 : approvalStatus.id),
        jobType: (0, _commonsUtils.nullOrNumber)(jobType === null || jobType === void 0 ? void 0 : jobType.id),
        rentType: (0, _commonsUtils.nullOrNumber)(rentType === null || rentType === void 0 ? void 0 : rentType.id)
      })
    }),
    leadServiceVehicle: {
      appointmentDate: (leadServiceVehicle === null || leadServiceVehicle === void 0 ? void 0 : leadServiceVehicle.appointmentDate) || null,
      id: (leadServiceVehicle === null || leadServiceVehicle === void 0 ? void 0 : leadServiceVehicle.id) || null,
      make: (leadServiceVehicle === null || leadServiceVehicle === void 0 ? void 0 : leadServiceVehicle.make) || null,
      model: (leadServiceVehicle === null || leadServiceVehicle === void 0 ? void 0 : leadServiceVehicle.model) || null,
      odometer: (leadServiceVehicle === null || leadServiceVehicle === void 0 ? void 0 : leadServiceVehicle.odometer) || null,
      serviceDetails: (leadServiceVehicle === null || leadServiceVehicle === void 0 ? void 0 : leadServiceVehicle.serviceDetails) || null,
      vin: (leadServiceVehicle === null || leadServiceVehicle === void 0 ? void 0 : leadServiceVehicle.vin) || null,
      year: (leadServiceVehicle === null || leadServiceVehicle === void 0 ? void 0 : leadServiceVehicle.year) || null
    },
    salespersonIds: (0, _commonsUtils.nullOrNumber)(salesmanUserId),
    leadSourceType: (0, _commonsUtils.nullOrNumber)(leadSourceType === null || leadSourceType === void 0 ? void 0 : leadSourceType.id),
    leadStatusIds: (0, _commonsUtils.nullOrNumber)(leadStatus === null || leadStatus === void 0 ? void 0 : leadStatus.id),
    leadTypeIds: (0, _commonsUtils.nullOrNumber)(leadType === null || leadType === void 0 ? void 0 : leadType.id),
    leadCategoryId: (0, _commonsUtils.nullOrNumber)(leadType === null || leadType === void 0 || (_leadType$leadCategor = leadType.leadCategory) === null || _leadType$leadCategor === void 0 ? void 0 : _leadType$leadCategor.id)
  });
  delete formattedEntry.salesmanUserId;
  delete formattedEntry.leadType;
  delete formattedEntry.leadStatus;
  return formattedEntry;
};
exports.transformLeadFromBackend = transformLeadFromBackend;