"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ucFirst = exports.getTabRebateContent = exports.getTabRebate = exports.getTabOptionContent = exports.getTabOption = exports.getTabNoteContent = exports.getTabNote = exports.getTabItems = exports.getTabContent = exports.getTabColourContent = exports.getTabColour = exports.getTabAccessoryContent = exports.getTabAccessory = exports.getSelectedTab = exports.getSelectedInteriorTrim = exports.getSelectedIndex = exports.getSelectedExteriorColour = exports.getRequestValidateBody = exports.getRequestFetchBody = exports.getIsTabColourDisabled = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../selectors");
const _excluded = ["cashPurchaseOption", "financeOptions"],
  _excluded2 = ["cashPurchaseOption", "financeOptions"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
/**
 * @param {Object} state
 * @returns {Object}
 */
const getTabItems = state => {
  const {
    tabItems
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabItems) ? tabItems : {};
};

/**
 * @param {Object} state
 * @returns {Number}
 */
exports.getTabItems = getTabItems;
const getSelectedIndex = state => {
  const {
    selectedIndex
  } = getTabItems(state);
  return Number.isInteger(selectedIndex) ? selectedIndex : 0;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getSelectedIndex = getSelectedIndex;
const getIsTabColourDisabled = state => {
  const {
    isTabColourDisabled
  } = getTabItems(state);
  return isTabColourDisabled === true;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getIsTabColourDisabled = getIsTabColourDisabled;
const getTabAccessory = state => {
  const {
    tabItemsAccessory
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabItemsAccessory) ? tabItemsAccessory : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabAccessory = getTabAccessory;
const getTabColour = state => {
  const {
    tabItemsColour
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabItemsColour) ? tabItemsColour : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabColour = getTabColour;
const getTabOption = state => {
  const {
    tabItemsOption
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabItemsOption) ? tabItemsOption : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabOption = getTabOption;
const getTabRebate = state => {
  const {
    tabItemsRebate
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabItemsRebate) ? tabItemsRebate : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabRebate = getTabRebate;
const getTabNote = state => {
  const {
    tabItemsNote
  } = state;
  return (0, _commonsUtils.isPlainObject)(tabItemsNote) ? tabItemsNote : {};
};

/**
 * Capitalize first letter only
 *
 * @param {String} val
 * @returns {String}
 */
exports.getTabNote = getTabNote;
const ucFirst = val => "".concat(val.charAt(0).toUpperCase()).concat(val.slice(1));

/**
 * Prepare data for UI
 *
 * @param {Object} item
 * @param {Function} prefixMethod
 * @param {Boolean} isCashSelected
 * @param {Boolean} hasTaxesIncluded
 * @returns {Object}
 */
exports.ucFirst = ucFirst;
const formatItemData = (item, prefixMethod, isCashSelected, hasTaxesIncluded) => {
  const {
    code,
    displayCode,
    dataTypeId,
    description,
    features,
    isSelected,
    isDisplayCodeAndDescriptionEditable,
    payment: paymentWithoutTaxes,
    photo,
    retail,
    residualRetail,
    supplier,
    totalPayment,
    typeId
  } = item;
  let imageUrl = null;
  let payment = retail;
  if (typeof photo === 'string' && photo.length > 0) {
    const isFullURL = string => {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === 'http:' || url.protocol === 'https:' || url.protocol === 'data:';
    };
    imageUrl = !isFullURL(photo) ? prefixMethod.call(null, photo) : photo;
  }
  if (!isCashSelected) {
    payment = hasTaxesIncluded ? totalPayment : paymentWithoutTaxes;
  }
  return {
    caption: code,
    displayCode,
    description: ucFirst(description),
    imageUrl,
    isCashSelected,
    isDisplayCodeAndDescriptionEditable,
    isSelected,
    listItems: Array.isArray(features) ? features : [],
    // API Payload when the user want to add/delete an item
    payload: {
      code,
      dataTypeId,
      retail,
      supplier,
      typeId
    },
    payment,
    retail,
    residualRetail
  };
};

/**
 * @param {Object} state
 * @param {Object[]} items
 * @param {Function} prefixMethod
 * @returns {Object[]}
 */
const formatItems = (state, items, prefixMethod) => {
  const isCashSelected = (0, _selectors.getIsCashSelected)(state);
  const hasTaxesIncluded = (0, _selectors.getHasTaxesIncluded)(state);
  return (0, _commonsUtils.sortBy)(items, 'description').map(item => formatItemData(item, prefixMethod, isCashSelected, hasTaxesIncluded));
};

/**
 * @param {Object} state
 * @param {Function} selector
 * @returns {Object[]}
 */
const getTabContent = (state, selector) => {
  const {
    content
  } = selector.call(null, state);
  if (!Array.isArray(content)) {
    return [];
  }
  return formatItems(state, content, _deskingCommons.prefixOptions);
};
exports.getTabContent = getTabContent;
const getTabAccessoryContent = state => getTabContent(state, getTabAccessory);
exports.getTabAccessoryContent = getTabAccessoryContent;
const getTabOptionContent = state => getTabContent(state, getTabOption);
exports.getTabOptionContent = getTabOptionContent;
const getTabRebateContent = state => getTabContent(state, getTabRebate);
exports.getTabRebateContent = getTabRebateContent;
const getTabNoteContent = state => getTabContent(state, getTabNote);

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getTabNoteContent = getTabNoteContent;
const getExteriorColourChoices = state => {
  const {
    content
  } = getTabColour(state);
  if (!Array.isArray(content)) {
    return [];
  }
  const items = content.filter(_ref => {
    let {
      category
    } = _ref;
    return category === _deskingCommons.ITEM_CATEGORY.EXTERIOR_COLOUR;
  });
  return formatItems(state, items, _deskingCommons.prefixNewColor);
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getSelectedExteriorColour = state => {
  const choices = getExteriorColourChoices(state);
  const found = choices.find(colour => colour.isSelected === true);
  return (0, _commonsUtils.isPlainObject)(found) ? found : {};
};

/**
 * @param {Object} state
 * @returns {Object[]}
 */
exports.getSelectedExteriorColour = getSelectedExteriorColour;
const getInterioTrimChoices = state => {
  const {
    content
  } = getTabColour(state);
  if (!Array.isArray(content)) {
    return [];
  }
  const items = content.filter(_ref2 => {
    let {
      category
    } = _ref2;
    return category === _deskingCommons.ITEM_CATEGORY.INTERIOR_TRIM;
  });
  return formatItems(state, items, _deskingCommons.prefixNewCloth);
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getSelectedInteriorTrim = state => {
  const choices = getInterioTrimChoices(state);
  const found = choices.find(trim => trim.isSelected === true);
  return (0, _commonsUtils.isPlainObject)(found) ? found : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getSelectedInteriorTrim = getSelectedInteriorTrim;
const getTabColourContent = state => ({
  exteriorColours: getExteriorColourChoices(state),
  interiorTrims: getInterioTrimChoices(state)
});

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTabColourContent = getTabColourContent;
const getSelectedTab = state => {
  const selectedIndex = getSelectedIndex(state);
  const list = [getTabAccessory(state), getTabColour(state), getTabOption(state), getTabRebate(state), getTabNote(state)];
  const found = list.find(_ref3 => {
    let {
      uiIndex
    } = _ref3;
    return uiIndex === selectedIndex;
  });
  return (0, _commonsUtils.isPlainObject)(found) ? found : {};
};

/**
 * @param {Object} state
 * @param {String[]} category
 * @returns {Object}
 */
exports.getSelectedTab = getSelectedTab;
const getRequestFetchBody = (state, category) => {
  const _getPaymentOptions = (0, _selectors.getPaymentOptions)(state),
    {
      cashPurchaseOption,
      financeOptions
    } = _getPaymentOptions,
    paymentOptionsData = _objectWithoutProperties(_getPaymentOptions, _excluded);
  return _objectSpread(_objectSpread({}, paymentOptionsData), {}, {
    category
  });
};

/**
 * @param {Object} state
 * @param {Object} item
 * @returns {Object}
 */
exports.getRequestFetchBody = getRequestFetchBody;
const getRequestValidateBody = (state, item) => {
  const _getPaymentOptions2 = (0, _selectors.getPaymentOptions)(state),
    {
      cashPurchaseOption,
      financeOptions
    } = _getPaymentOptions2,
    paymentOptionsData = _objectWithoutProperties(_getPaymentOptions2, _excluded2);
  return _objectSpread(_objectSpread({}, paymentOptionsData), {}, {
    item
  });
};
exports.getRequestValidateBody = getRequestValidateBody;