"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _uiLibrary = require("@sm360/ui-library");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("../api/updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  toast
} = _uiLibrary.Toast;

/**
 * Not attached to any actions, but this a common behavior when the user want to change something.
 * Showroom APIs always send back the same response to validate the change asked by the user.
 *
 * @param {Object} response
 * @param {String} conflictPicture
 * @returns {Boolean} If validation passed or not
 */
function* _default(response, conflictPicture) {
  const t = yield (0, _effects.getContext)('t');

  // Safety Type check
  if (!(0, _commonsUtils.isPlainObject)(response)) {
    toast.error(t('deskingCommon.error.validation'));
    return false;
  }

  // If a conflict needs to be resolve/validate
  if (response.isValid === false) {
    yield (0, _effects.put)(_actions.Creators.handleConflictValidation(response, conflictPicture));
    return false;
  }
  const paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);

  // Reload all data with new paymentOptions from the response
  yield (0, _effects.call)(_updatePayOptAndTransDetails.default, _objectSpread(_objectSpread(_objectSpread({}, paymentOptions), response.negociationTransaction), {}, {
    callCommission: true
  }));
  return true;
}