"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadDocuments = exports.uploadContactsFile = exports.updateVehicleDetails = exports.updateDocumentDesc = exports.updateContact = exports.optOutContact = exports.invalidateContactDuplicates = exports.getVehicleDetails = exports.getDownloadTemplate = exports.getDocument = exports.getContactsList = exports.getContactsDuplicates = exports.getContactSubscriptions = exports.getContactLeads = exports.getContactDocuments = exports.getContact = exports.downloadDocument = exports.deleteContact = exports.createContactTag = exports.createContact = exports.archiveDocument = void 0;
var _appsCore = require("@sm360/apps-core");
var _commonsUtils = require("@sm360/commons-utils");
var _payload = require("../config/payload.transform");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Get contacts list
const getContactsList = async _ref => {
  let {
    body,
    pageSize,
    pageIndex,
    request,
    sortBy
  } = _ref;
  const newQuery = {
    filters: _objectSpread(_objectSpread({}, body.filters), {}, {
      searchTerms: request ? (0, _commonsUtils.sanitizeSearch)(request).split(' ') : []
    }),
    meta: {
      from: pageIndex,
      size: pageSize,
      sort: sortBy //[{id: 'lastModifiedDate', desc: true}],
    }
  };
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/contacts/search"), newQuery);
};
exports.getContactsList = getContactsList;
const getContact = async id => {
  const contact = await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/contacts/").concat(id));
  return Promise.resolve((0, _payload.transformContactFromBackend)(contact.data));
};
exports.getContact = getContact;
const updateContact = async (id, body) => {
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/contacts/").concat(id), body);
};
exports.updateContact = updateContact;
const deleteContact = async id => {
  return await _appsCore.axios.delete("".concat(CRM_ENDPOINT, "/contacts/").concat(id));
};
exports.deleteContact = deleteContact;
const createContact = async body => {
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/contacts"), body);
};
exports.createContact = createContact;
const optOutContact = async _ref2 => {
  let {
    contactId,
    subscriptionId,
    body
  } = _ref2;
  return _appsCore.axios.post("".concat(CRM_ENDPOINT, "/contacts/").concat(contactId, "/unsubscribe?subscriptionId=").concat(subscriptionId), body);
};
exports.optOutContact = optOutContact;
const getContactLeads = async _ref3 => {
  let {
    id,
    showArchived
  } = _ref3;
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/leads/findByContactId?contactId=").concat(id, "&showArchived=").concat(showArchived));
};
exports.getContactLeads = getContactLeads;
const getContactsDuplicates = async _ref4 => {
  let {
    organizationId,
    pageSize,
    pageIndex
  } = _ref4;
  const body = {
    filters: {
      organizationId
    },
    meta: {
      from: pageIndex,
      size: pageSize
    }
  };
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/possibleMerges"), body);
};
exports.getContactsDuplicates = getContactsDuplicates;
const invalidateContactDuplicates = async id => {
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/possibleMerges/").concat(id, "/invalidate"), {});
};
exports.invalidateContactDuplicates = invalidateContactDuplicates;
const getContactDocuments = async id => {
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/contactDocuments?contactId=").concat(id));
};
exports.getContactDocuments = getContactDocuments;
const getDocument = async _ref5 => {
  let {
    documentId
  } = _ref5;
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/contactDocuments/").concat(documentId));
};
exports.getDocument = getDocument;
const downloadDocument = async _ref6 => {
  let {
    documentId
  } = _ref6;
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/contactDocuments/").concat(documentId, "/download"), {
    responseType: 'blob'
  });
};
exports.downloadDocument = downloadDocument;
const uploadDocuments = async _ref7 => {
  let {
    organizationId,
    contactId,
    form
  } = _ref7;
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/contactDocuments/upload?organizationId=").concat(organizationId, "&contactId=").concat(contactId), form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
exports.uploadDocuments = uploadDocuments;
const updateDocumentDesc = async _ref8 => {
  let {
    body
  } = _ref8;
  const documentId = body === null || body === void 0 ? void 0 : body.id;
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/contactDocuments/").concat(documentId), body);
};
exports.updateDocumentDesc = updateDocumentDesc;
const archiveDocument = async _ref9 => {
  let {
    documentId
  } = _ref9;
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/contactDocuments/").concat(documentId, "/archive"));
};
exports.archiveDocument = archiveDocument;
const getContactSubscriptions = async id => {
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/contacts/").concat(id, "/subscriptions"));
};
exports.getContactSubscriptions = getContactSubscriptions;
const getDownloadTemplate = async () => {
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/contacts/csv/download"));
};
exports.getDownloadTemplate = getDownloadTemplate;
const uploadContactsFile = async _ref10 => {
  let {
    organizationId,
    organizationUnitId,
    implicitConsent,
    explicitConsent,
    file
  } = _ref10;
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/contacts/csv/upload?organizationId=").concat(organizationId, "&organizationUnitId=").concat(organizationUnitId, "&implicitConsent=").concat(implicitConsent, "&explicitConsent=").concat(explicitConsent), file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
exports.uploadContactsFile = uploadContactsFile;
const getVehicleDetails = async id => {
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/clientVehicles/").concat(id));
};
exports.getVehicleDetails = getVehicleDetails;
const updateVehicleDetails = async _ref11 => {
  let {
    id,
    body
  } = _ref11;
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/clientVehicles/").concat(id), body);
};
exports.updateVehicleDetails = updateVehicleDetails;
const createContactTag = async body => {
  return await _appsCore.axios.post("".concat(CRM_ENDPOINT, "/contacts/newContactTag"), body);
};
exports.createContactTag = createContactTag;