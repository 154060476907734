"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiSchema = exports.Schema = void 0;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const sendEmailPrefix = 'actions.sendEmail.form';
const creatableSelectProps = {
  noDropdownIcon: true,
  multiple: true
};
const Schema = exports.Schema = {
  type: 'object',
  properties: {
    from: {
      title: "".concat(sendEmailPrefix, ".FROM"),
      type: 'string'
    },
    to: {
      title: "".concat(sendEmailPrefix, ".TO"),
      type: 'string'
    },
    cc: _objectSpread({
      title: "".concat(sendEmailPrefix, ".CC"),
      type: 'string'
    }, creatableSelectProps),
    bcc: _objectSpread({
      title: "".concat(sendEmailPrefix, ".BCC"),
      type: 'string'
    }, creatableSelectProps),
    subject: {
      title: "".concat(sendEmailPrefix, ".SUBJECT"),
      type: 'string'
    },
    mailContent: {
      title: "".concat(sendEmailPrefix, ".MAIL_CONTENT"),
      type: 'string',
      config: 'full'
    }
  }
};
const UiSchema = exports.UiSchema = {
  from: {
    'ui:disabled': true
  },
  to: {
    'ui:disabled': true
  },
  cc: {
    'ui:disabled': true,
    'ui:widget': 'CreatableSelectWidget'
  },
  bcc: {
    'ui:disabled': true,
    'ui:widget': 'CreatableSelectWidget'
  },
  subject: {
    'ui:disabled': true
  },
  mailContent: {
    'ui:widget': 'EditorWidget',
    'ui:disabled': true
  }
};