"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTerm = exports.getTaxesValidationAmount = exports.getSecurityDepositIsWaived = exports.getSecurityDepositDetails = exports.getSalesDeposit = exports.getResidualDetails = exports.getResidualAdjustmentFieldData = exports.getQuoteFinance = exports.getKmPerYearPlanDetails = exports.getIsRebatesIncludesTaxes = exports.getIsCashDownIncludesTaxes = exports.getIncentives = exports.getEffectiveIncentiveProgram = exports.getCashOnlyRebatesAdjustment = exports.getCashOnlyRebates = exports.getBlendedrateDetails = exports.getAprDetails = exports.getAmountFinancedValidation = exports.getAdminFeesNotChargedToCashAdjustment = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _payment = require("../../constants/payment");
var _selectors = require("../selectors");
/**
 * @param {Object} state
 * @returns {Object}
 */
const getQuoteCashPurchase = state => {
  const {
    cashPurchase
  } = (0, _selectors.getQuote)(state);
  return (0, _commonsUtils.isPlainObject)(cashPurchase) ? cashPurchase : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
const getQuoteFinance = state => {
  const {
    finance
  } = (0, _selectors.getQuote)(state);
  return (0, _commonsUtils.isPlainObject)(finance) ? finance : {};
};

/**
 * @param {Object} state
 * @returns {Number}
 */
exports.getQuoteFinance = getQuoteFinance;
const getSalesDepositFinance = state => {
  const {
    salesDeposit
  } = getQuoteFinance(state);
  return salesDeposit;
};

/**
 * @param {Object} state
 * @returns {Number}
 */
const getSalesDepositCash = state => {
  const {
    salesDeposit
  } = getQuoteCashPurchase(state);
  return salesDeposit;
};

/**
 * @param {Object} state
 * @returns {Number}
 */
const getSalesDeposit = state => {
  const salesDepositCash = getSalesDepositCash(state);
  const salesDepositFinance = getSalesDepositFinance(state);
  return salesDepositCash || salesDepositFinance;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getSalesDeposit = getSalesDeposit;
const getTerm = state => {
  const {
    term
  } = getQuoteFinance(state);
  return (0, _commonsUtils.isPlainObject)(term) ? term : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTerm = getTerm;
const getCashDownTaxesDetails = state => {
  var _getQuoteCashPurchase, _getQuoteFinance;
  const isCash = (0, _selectors.getIsCashSelected)(state);
  const cashDownTaxesDetails = isCash ? (_getQuoteCashPurchase = getQuoteCashPurchase(state)) === null || _getQuoteCashPurchase === void 0 ? void 0 : _getQuoteCashPurchase.cashDownTaxesDetails : (_getQuoteFinance = getQuoteFinance(state)) === null || _getQuoteFinance === void 0 ? void 0 : _getQuoteFinance.cashDownTaxesDetails;
  return (0, _commonsUtils.isPlainObject)(cashDownTaxesDetails) ? cashDownTaxesDetails : {};
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
const getIsCashDownIncludesTaxes = state => {
  const {
    cashDownIncludesTaxes
  } = getCashDownTaxesDetails(state);
  return cashDownIncludesTaxes;
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getIsCashDownIncludesTaxes = getIsCashDownIncludesTaxes;
const getIsRebatesIncludesTaxes = state => {
  const {
    rebatesIncludesTaxes
  } = getCashDownTaxesDetails(state);
  return rebatesIncludesTaxes;
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getIsRebatesIncludesTaxes = getIsRebatesIncludesTaxes;
const getAprDetails = state => {
  const {
    aprDetails
  } = getTerm(state);
  return (0, _commonsUtils.isPlainObject)(aprDetails) ? aprDetails : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getAprDetails = getAprDetails;
const getBlendedrateDetails = state => {
  const {
    blendedRateDetails
  } = getAprDetails(state);
  return (0, _commonsUtils.isPlainObject)(blendedRateDetails) ? blendedRateDetails : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getBlendedrateDetails = getBlendedrateDetails;
const getResidualDetails = state => {
  const {
    residualDetails
  } = getTerm(state);
  return (0, _commonsUtils.isPlainObject)(residualDetails) ? residualDetails : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getResidualDetails = getResidualDetails;
const getSecurityDepositDetails = state => {
  const {
    securityDepositDetails
  } = getTerm(state);
  return (0, _commonsUtils.isPlainObject)(securityDepositDetails) ? securityDepositDetails : {};
};
exports.getSecurityDepositDetails = getSecurityDepositDetails;
const getKmPerYearPlanDetails = state => {
  const {
    kmPerYearPlanDetails
  } = getTerm(state);
  return (0, _commonsUtils.isPlainObject)(kmPerYearPlanDetails) ? kmPerYearPlanDetails : {};
};

/**
 * @param {Object} state
 * @returns {Boolean}
 */
exports.getKmPerYearPlanDetails = getKmPerYearPlanDetails;
const getSecurityDepositIsWaived = state => {
  const {
    securityDepositIsWaived
  } = getSecurityDepositDetails(state);
  return securityDepositIsWaived;
};
/**
 * @param {Object} state
 * @param {String} fieldName
 * @returns {Object}
 */
exports.getSecurityDepositIsWaived = getSecurityDepositIsWaived;
const getResidualAdjustmentFieldData = (state, fieldName) => {
  const {
    buyBackAmount,
    buyBackFee,
    calculatedResidualAmount,
    initialResidualRate,
    initialResidualRetail,
    residualAmountAdjustment,
    residualRateAdjustment,
    residualRetailAdjustment,
    totalResidualAmount,
    totalResidualRate,
    totalResidualRetail,
    baseResidualRetail,
    accessoriesResidualRetail
  } = getResidualDetails(state);
  switch (fieldName) {
    case _payment.RESIDUAL_RETAIL:
      return {
        adjustment: residualRetailAdjustment,
        initial: initialResidualRetail,
        total: totalResidualRetail,
        baseResidualRetail,
        accessoriesResidualRetail
      };
    case _payment.RESIDUAL_AMOUNT:
      return {
        adjustment: residualAmountAdjustment,
        initial: calculatedResidualAmount,
        total: totalResidualAmount
      };
    case _payment.RESIDUAL_RATE:
      return {
        adjustment: residualRateAdjustment,
        initial: initialResidualRate,
        total: totalResidualRate
      };
    case _payment.RESIDUAL_BUYBACK_AMOUNT:
      return {
        amount: buyBackAmount,
        fee: buyBackFee
      };
    default:
      return {};
  }
};

/**
 * @param {Object} state
 * @returns {Object} - { amount, category }
 */
exports.getResidualAdjustmentFieldData = getResidualAdjustmentFieldData;
const getTaxesValidationAmount = state => {
  const {
    taxesValidationAmount
  } = (0, _selectors.getIsCashSelected)(state) ? getQuoteCashPurchase(state) : getQuoteFinance(state);
  return (0, _commonsUtils.isPlainObject)(taxesValidationAmount) ? taxesValidationAmount : {};
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getTaxesValidationAmount = getTaxesValidationAmount;
const getAmountFinancedValidation = state => {
  const {
    amountFinancedValidation
  } = getTerm(state);
  return (0, _commonsUtils.isPlainObject)(amountFinancedValidation) ? amountFinancedValidation : {};
};

/**
 * @param {Object} state
 * @returns {Array}
 */
exports.getAmountFinancedValidation = getAmountFinancedValidation;
const getIncentives = state => {
  const {
    incentives
  } = state;
  return Array.isArray(incentives) ? incentives : [];
};

/**
 * @param {Object} state
 * @returns {Object}
 */
exports.getIncentives = getIncentives;
const getEffectiveIncentiveProgram = state => {
  const isCashSelected = (0, _selectors.getIsCashSelected)(state);
  const {
    cashPurchase,
    finance
  } = (0, _selectors.getQuote)(state);
  let effectiveIncentiveProgram;
  if (isCashSelected && (0, _commonsUtils.isPlainObject)(cashPurchase)) {
    const {
      incentives
    } = cashPurchase;
    if ((0, _commonsUtils.isPlainObject)(incentives)) {
      effectiveIncentiveProgram = incentives.effectiveIncentiveProgram;
    }
  } else if ((0, _commonsUtils.isPlainObject)(finance)) {
    const {
      incentives
    } = finance;
    if ((0, _commonsUtils.isPlainObject)(incentives)) {
      effectiveIncentiveProgram = incentives.effectiveIncentiveProgram;
    }
  }
  return (0, _commonsUtils.isPlainObject)(effectiveIncentiveProgram) ? effectiveIncentiveProgram : {};
};

/**
 * @param {Object} state
 * @returns {Number}
 */
exports.getEffectiveIncentiveProgram = getEffectiveIncentiveProgram;
const getCashOnlyRebates = state => {
  const {
    cashOnlyRebates
  } = getAprDetails(state);
  return cashOnlyRebates;
};

/**
 * @param {Object} state
 * @returns {Number}
 */
exports.getCashOnlyRebates = getCashOnlyRebates;
const getCashOnlyRebatesAdjustment = state => {
  const {
    cashOnlyRebatesAdjustment
  } = getAprDetails(state);
  return cashOnlyRebatesAdjustment;
};

/**
 * @param {Object} state
 * @returns {Number}
 */
exports.getCashOnlyRebatesAdjustment = getCashOnlyRebatesAdjustment;
const getAdminFeesNotChargedToCashAdjustment = state => {
  const {
    adminFeesNotChargedToCashAdjustment
  } = getAprDetails(state);
  return adminFeesNotChargedToCashAdjustment;
};
exports.getAdminFeesNotChargedToCashAdjustment = getAdminFeesNotChargedToCashAdjustment;