import React from 'react';

const Dashboard = React.lazy(() => import('@sm360/crm-dashboard'));
const Calendar = React.lazy(() => import('@sm360/crm-calendar'));
const Leads = React.lazy(() => import('@sm360/crm-leads'));
const Deals = React.lazy(() => import('@sm360/crm-deals'));
const Contacts = React.lazy(() => import('@sm360/crm-contacts'));
const Activities = React.lazy(() => import('@sm360/crm-activities'));
const Reporting = React.lazy(() => import('@sm360/crm-reporting'));
const ActivityDashboard = React.lazy(() => import('@sm360/crm-activity-dashboard'));
const DeliveryDashboard = React.lazy(() => import('@sm360/crm-delivery-dashboard'));
// const Campaign = React.lazy(() => import('@sm360/crm-campaign'));
// const CampaignStatistics = React.lazy(() => import('@sm360/crm-campaign-statistics'));
// const CampaignUpsales = React.lazy(() => import('@sm360/crm-campaign-upsales'));
const SMSLogs = React.lazy(() => import('@sm360/crm-sms-logs'));
const SMSNumbers = React.lazy(() => import('@sm360/crm-sms-numbers'));
const PhonetrackLogs = React.lazy(() => import('@sm360/crm-phonetrack-logs'));
const PhonetrackNumbers = React.lazy(() => import('@sm360/crm-phonetrack-numbers'));
const DeskingVehicles = React.lazy(() => import('@sm360/crm-desking-vehicles'));
const DeskingInventoriesReport = React.lazy(() => import('@sm360/crm-desking-inventory-report'));
const DeskingQuotes = React.lazy(() => import('@sm360/crm-desking-quotes'));
const DeskingSalesReport = React.lazy(() => import('@sm360/crm-desking-sales-report'));

// const SalesObjectives = React.lazy(() => import('@sm360/crm-sales-objectives'));
// const EmailSyncRestrictions = React.lazy(() => import('@sm360/crm-email-sync-restrictions'));
// const MailTemplates = React.lazy(() => import('@sm360/crm-mail-templates'));
const Settings = React.lazy(() => import('@sm360/crm-settings'));

// const IframeCRM = ({ title, url }) => {
//     return (
//         <>
//             {/* <SectionHeading title={title} /> */}
//             <iframe srcDoc="https://crmghost.sm360.ca/login" title="Surveys" frameBorder="0" className="iframe-view width-100 height-100" />
//         </>
//     );
// };

const NavOrganization = [
    {
        name: 'Dashboard',
        translate: 'app.nav.DASHBOARD',
        icon: 'grip',
        right: 'ModuleReportEnabled',
        route: '/dashboard',
        component: Dashboard,
    },
    {
        name: 'CRM 360',
        svg: 'logo-crm',
        description: '',
        right: 'CrmProAccess',
        submenu: [
            {
                name: 'Calendar',
                translate: 'app.nav.CALENDAR',
                route: '/calendar',
                component: Calendar,
            },

            {
                name: 'Leads',
                translate: 'app.nav.LEADS',
                route: '/leads',
                component: Leads,
                right: 'ModuleLeadEnabled',
            },
            {
                route: '/deals',
                name: 'Deals',
                translate: 'app.nav.DEALS',
                component: Deals,
                right: 'CrmDealViewList',
            },
            {
                route: '/contacts',
                name: 'Contacts',
                translate: 'app.nav.CONTACTS',
                component: Contacts,
                right: 'ContactAccess',
            },
            {
                route: '/activities',
                name: 'Activities',
                translate: 'app.nav.ACTIVITIES',
                component: Activities,
            },
            {
                route: '/reporting',
                name: 'Reporting',
                translate: 'app.nav.REPORTING',
                component: Reporting,
                right: 'ModuleReportEnabled',
            },
            // {
            //     route: '/desking-quote',
            //     name: 'Desking',
            //     translate: 'app.nav.DESKING',
            //     component: DeskingWidgets.Quote,
            // },
            {
                route: '/activity-dashboard',
                name: 'Activity Dashboard',
                translate: 'app.nav.ACTIVITY_DASHBOARD',
                component: ActivityDashboard,
                right: 'ModuleReportEnabled',
            },
            {
                route: '/delivery-dashboard',
                name: 'Delivery Dashboard',
                translate: 'app.nav.DELIVERY_DASHBOARD',
                component: DeliveryDashboard,
            },
            // {
            //     route: '/bdc-config',
            //     name: 'BDC Configuration',
            //     translate: 'app.nav.BDC_CONFIG',
            //     component: BdcConfig,
            // },
            // {
            //     route: '/events-config',
            //     name: 'Events Configuration',
            //     translate: 'app.nav.EVENTS_CONFIG',
            //     component: EventsConfig,
            // },
            // {
            //     route: '/surveys',
            //     name: 'Survey',
            //     translate: 'app.nav.SURVEYS',
            //     component: Surveys,
            // },
            // {
            //     name: 'Administration',
            //     submenu: [
            //         {
            //             route: '/bdc-config',
            //             name: 'BDC Configuration',
            //             translate: 'app.nav.BDC_CONFIG',
            //             component: BdcConfig,
            //         },
            //         {
            //             route: '/events-config',
            //             name: 'Events Configuration',
            //             translate: 'app.nav.EVENTS_CONFIG',
            //             component: EventsConfig,
            //         },
            //         {
            //             route: '/administration/sales-objectives',
            //             name: 'Sales Objectives',
            //             description: '',
            //             component: SalesObjectives,
            //         },
            //         {
            //             route: '/administration/email-sync-restrictions',
            //             name: 'Email Sync Restrictions',
            //             description: '',
            //             component: EmailSyncRestrictions,
            //         },
            //         {
            //             route: '/administration/mail-templates',
            //             name: 'Mail Templates',
            //             description: '',
            //             component: MailTemplates,
            //         },
            //     ],
            // },
            // {
            //     name: 'Profile',
            //     submenu: [
            //         // {
            //         //     route: '/profile/account-settings',
            //         //     name: 'Account Settings',
            //         //     description: '',
            //         //     component: ProfileAccountSettings,
            //         // },
            //         {
            //             route: '/profile/phone-numbers',
            //             name: 'Phone Numbers',
            //             description: '',
            //             component: ProfilePhoneNumbers,
            //         },
            //         {
            //             route: '/profile/signatures',
            //             name: 'Signatures',
            //             description: '',
            //             component: ProfileSignatures,
            //         },
            //     ],
            // },
        ],
    },
    {
        name: 'Campaign 360',
        svg: 'logo-campaign',
        right: 'EditCampaign',
        url: '{{REACT_APP_CRM_APP_CAMPAIGN_LEGACY_URL}}',
    },
    {
        name: 'Desking 360',
        img: '/img/logo-desking-nav.svg',
        description: '',
        right: 'DeskingAccess',
        submenu: [
            {
                name: 'Quotes',
                route: '/desking-quotes',
                translate: 'app.nav.DESKING_QUOTES',
                component: DeskingQuotes,
            },
            {
                route: '/desking-vehicles',
                name: 'Vehicles',
                right: 'DeskingSearchVehicles',
                translate: 'app.nav.DESKING_VEHICLES',
                component: DeskingVehicles,
            },
            {
                route: '/inventory-report',
                name: 'Inventory Report',
                translate: 'app.nav.DESKING_INVENTORY_REPORT',
                component: DeskingInventoriesReport,
            },
            {
                route: '/sales-report',
                name: 'Sales Report',
                translate: 'app.nav.DESKING_SALES_REPORT',
                component: DeskingSalesReport,
            },
        ],
    },
    {
        name: 'Phonetrack 360',
        svg: 'logo-phonetrack-small',
        description: '',
        right: 'PhonetrackAccess',
        submenu: [
            {
                route: '/phonetrack/logs',
                name: 'Phonetrack Logs',
                translate: 'app.nav.PHONETRACK_LOGS',
                description: '',
                right: 'PhonetrackAccess',
                component: PhonetrackLogs,
            },
            {
                route: '/phonetrack/numbers',
                name: 'Phonetrack Numbers',
                translate: 'app.nav.PHONETRACK_NUMBERS',
                description: '',
                right: 'IvrEnabled',
                component: PhonetrackNumbers,
            },
        ],
    },
    {
        name: 'SMS 360',
        svg: 'logo-sms-small',
        description: '',
        right: 'CrmSmsNumbersManagement',
        submenu: [
            {
                route: '/sms/logs',
                name: 'SMS Logs',
                translate: 'app.nav.SMS_LOGS',
                description: '',
                right: 'CrmSmsNumbersManagement',
                component: SMSLogs,
            },
            {
                route: '/sms/numbers',
                name: 'SMS Numbers',
                translate: 'app.nav.SMS_NUMBERS',
                description: '',
                right: 'CrmSmsNumbersManagement',
                component: SMSNumbers,
            },
        ],
    },
    {
        name: 'Settings',
        translate: 'app.nav.SETTINGS',
        svg: 'cog',
        description: '',
        right: [
            'CrmProAdministrationSectionAccess',
            'EditBdcConfiguration',
            'LeadStatusConfigManagement',
            'CrmProEditEmailTemplates',
            'CrmSmsNumbersManagement',
            'IvrEnabled',
            'Admin',
        ],
        component: Settings,
        route: '/settings',
    },
    // {
    //     name: 'Loyalty 360',
    //     svg: 'logo-loyalty-small',
    //     description: '',
    //     submenu: [],
    // },
    // {
    //     name: 'Webauto Mobile 360',
    //     svg: 'logo-wam-small',
    //     description: '',
    //     submenu: [],
    // },
];

export { NavOrganization };
