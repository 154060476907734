"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function* _default() {
  const config = yield (0, _effects.select)(_selectors.getConfig);
  const {
    leadId
  } = config;
  try {
    const leadSalesPerson = yield (0, _effects.call)(_commonsApi.LeadInfoService.getSalesPerson, leadId);
    const leadSalesManager = yield (0, _effects.call)(_commonsApi.LeadInfoService.getSalesManager, leadId);
    const leadBusinessManager = yield (0, _effects.call)(_commonsApi.LeadInfoService.getBusinessManager, leadId);
    const validateResult = (obj, key) => {
      return Object.prototype.hasOwnProperty.call(obj, key);
    };
    const salesperson = validateResult(leadSalesPerson, 'salespersonId') ? _objectSpread({}, leadSalesPerson) : {};
    const salesManager = validateResult(leadSalesManager, 'salesManagerId') ? _objectSpread({}, leadSalesManager) : {};
    const businessManager = validateResult(leadBusinessManager, 'businessManagerId') ? _objectSpread({}, leadBusinessManager) : {};
    const leadInfo = {
      salesperson: _objectSpread({}, salesperson),
      salesManager: _objectSpread({}, salesManager),
      businessManager: _objectSpread({}, businessManager)
    };
    yield (0, _effects.put)(_actions.Creators.setLeadInfo(leadInfo));
    return null;
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getLeadInfo'));
    return false;
  }
}