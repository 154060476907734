"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsUtils = require("@sm360/commons-utils");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _requestAddItem = require("../../selectors/requestAddItem");
var _updatePayOptAndTransDetails = _interopRequireDefault(require("../api/updatePayOptAndTransDetails"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * @TODO better handling when calls not valid
 *
 * @param {Object} payload - { items }
 */
function _default(_ref) {
  let {
    items
  } = _ref;
  return function* () {
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
    try {
      let body;
      let paymentOptions;
      let response;
      for (let i = 0; i < items.length; i += 1) {
        paymentOptions = yield (0, _effects.select)(_selectors.getPaymentOptions);
        body = yield (0, _effects.select)(_requestAddItem.getBody, items[i], paymentOptions.transactionToken);
        response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.addItem, quoteId, queryParams, body);
        if ((0, _commonsUtils.isPlainObject)(response) && response.isValid === true) {
          yield (0, _effects.call)(_updatePayOptAndTransDetails.default, _objectSpread(_objectSpread(_objectSpread({}, paymentOptions), response.negociationTransaction), {}, {
            callCommission: true
          }));
        }
      }
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'createItem'));
    }
  }();
}