"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
var _quoteChanges = require("../../../constants/quoteChanges");
var _updateCommissionDetails = _interopRequireDefault(require("../commission/updateCommissionDetails"));
var _updatePaymentOptions = _interopRequireDefault(require("./updatePaymentOptions"));
var _updateTransactionDetails = _interopRequireDefault(require("./updateTransactionDetails"));
const _excluded = ["cashPurchaseOption"];
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
/**
 * Update payment options and transaction details, call the 2 APIs
 * @param {Object} options parameters to send to the APIs
 */
function updatePayOptAndTransDetails(options) {
  let updateStore = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  let callConfirmAlert = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return function* () {
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);

    // Remove some data to not send
    const {
        cashPurchaseOption
      } = options,
      paymentOptions = _objectWithoutProperties(options, _excluded);
    const transactionOptions = _objectSpread({}, paymentOptions);
    delete transactionOptions.financeOptions;
    yield (0, _effects.all)([callConfirmAlert && (0, _effects.put)(_actions.Creators.setQuoteChanged(true, _quoteChanges.QUOTE_CHANGES_TYPE.MODIFY_QUOTE)), (0, _effects.call)(_updatePaymentOptions.default, quoteId, queryParams, paymentOptions, updateStore), (0, _effects.call)(_updateTransactionDetails.default, quoteId, queryParams, transactionOptions, updateStore), options.callCommission && (0, _effects.call)(_updateCommissionDetails.default, quoteId, queryParams, transactionOptions, updateStore)]);
  }();
}
var _default = exports.default = updatePayOptAndTransDetails;