"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getQueryParams = exports.getPathParams = exports.getBody = void 0;
var _deskingCommons = require("@sm360/desking-commons");
var _selectors = require("../selectors");
var _stepTrim = require("./stepTrim");
var _paymentOptions = require("./paymentOptions");
var _content = require("./content");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * @param {Object} state
 * @returns {Object}
 */
const getBody = state => {
  const {
    configurationId,
    modelTrimCode,
    transactionToken
  } = (0, _selectors.getBaseProps)(state);
  const baseBody = (0, _selectors.getBaseBodyParams)(state);
  const financeOptions = (0, _paymentOptions.getFinanceOptions)(state);
  const selectedFinancePlan = (0, _paymentOptions.getSelectedFinancePlan)(state);
  return _objectSpread(_objectSpread({
    configurationId,
    modelTrimCode,
    transactionToken,
    financePlan: selectedFinancePlan
  }, baseBody), {}, {
    financeOptions
  });
};

/**
 * @param {Object} state
 * @returns {Array}
 */
exports.getBody = getBody;
const getPathParams = state => {
  const year = (0, _content.getYear)(state);
  const baseParams = (0, _selectors.getBasePathParams)(state);
  const modelTrim = (0, _stepTrim.getModelTrim)(state);
  return [year, ...baseParams, modelTrim.slug];
};

/**
 * @param {Object} state
 * @return {Object}
 */
exports.getPathParams = getPathParams;
const getQueryParams = state => _objectSpread(_objectSpread({}, (0, _selectors.getQueryParams)(state)), {}, {
  priceIncreaseRollCount: _deskingCommons.priceIncreaseSelectors.getPriceIncreaseRollCount(state),
  includeMeta: true
});
exports.getQueryParams = getQueryParams;