"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setPreferredQuote = exports.setIncentive = exports.setDates = exports.setCustomerVisibility = exports.putUnlistedVehicle = exports.loadIncentives = exports.getUnlistedVehicleTrims = exports.getUnlistedVehicleModels = exports.getUnlistedVehicleMakes = exports.getQuoteDetails = exports.getQuote = exports.getGarageData = exports.addUsedVehicle = exports.addNewVehicle = exports.addCatalogVehicle = void 0;
var _request = require("../../tools/request");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const QUOTE_API_URL = process.env.REACT_APP_QUOTE_API_URL;
const SHOWROOM_API_URL = process.env.REACT_APP_SHOWROOM_API_URL;

/**
 * @param {Object} leadId
 * @param {String[]} pathParams
 * @returns {Promise}
 */
const getGarageData = (leadId, queryParams) => {
  const url = "".concat(QUOTE_API_URL, "/leads/").concat(leadId, "/summaries");
  return (0, _request.get)(url, {
    params: queryParams
  });
};

/**
 * @param {Object} queryParams
 * @param {Object} body
 * @param {Number} showroomVehicleId
 * @returns {Promise}
 */
exports.getGarageData = getGarageData;
const addNewVehicle = (queryParams, body, showroomVehicleId) => {
  const url = "".concat(QUOTE_API_URL, "/quote/vehicle/listingNew/").concat(showroomVehicleId);
  return (0, _request.put)(url, body, {
    params: _objectSpread({}, queryParams)
  });
};

/**
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
exports.addNewVehicle = addNewVehicle;
const addUsedVehicle = (queryParams, body) => {
  const url = "".concat(QUOTE_API_URL, "/quote/vehicle/listingUsed");
  return (0, _request.put)(url, body, {
    params: _objectSpread({}, queryParams)
  });
};

/**
 * @param {Number} leadId
 * @param {Number} newCarTrimId
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
exports.addUsedVehicle = addUsedVehicle;
const addCatalogVehicle = (leadId, newCarTrimId, queryParams, body) => {
  const url = "".concat(QUOTE_API_URL, "/leads/").concat(leadId, "/vehicleCatalog/").concat(newCarTrimId);
  return (0, _request.put)(url, body, {
    params: _objectSpread({}, queryParams)
  });
};

/**
 * @param {Number} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.addCatalogVehicle = addCatalogVehicle;
const getQuoteDetails = (quoteId, queryParams) => {
  const url = "".concat(QUOTE_API_URL, "/").concat(quoteId, "/details");
  return (0, _request.get)(url, {
    params: queryParams
  });
};

/**
 * @param {Number} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.getQuoteDetails = getQuoteDetails;
const getQuote = (quoteId, queryParams) => {
  const url = "".concat(QUOTE_API_URL, "/").concat(quoteId);
  return (0, _request.get)(url, {
    params: queryParams
  });
};

/**
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.getQuote = getQuote;
const setPreferredQuote = (queryParams, body) => {
  const url = "".concat(QUOTE_API_URL, "/quote/setAsPreferred");
  return (0, _request.post)(url, body, {
    params: queryParams
  });
};

/**
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.setPreferredQuote = setPreferredQuote;
const setCustomerVisibility = (queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/quote/setCustomerVisibility");
  return (0, _request.post)(url, body, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.setCustomerVisibility = setCustomerVisibility;
const setDates = (quoteId, queryParams, body) => {
  const url = "".concat(QUOTE_API_URL, "/quote/desking/setDates/").concat(quoteId);
  return (0, _request.post)(url, body, {
    params: queryParams
  });
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.setDates = setDates;
const loadIncentives = (quoteId, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/quote/desking/incentives/").concat(quoteId);
  return (0, _request.post)(url, body, {
    params: queryParams
  }, true);
};

/**
 * @param {String} quoteId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.loadIncentives = loadIncentives;
const setIncentive = (quoteId, queryParams, body) => {
  const url = "".concat(SHOWROOM_API_URL, "/quote/desking/setIncentive/").concat(quoteId);
  return (0, _request.post)(url, body, {
    params: queryParams
  }, true);
};

/**
 * @param {String} vehicleCondition
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.setIncentive = setIncentive;
const getUnlistedVehicleMakes = (vehicleCondition, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/quote/vehicle/makes");
  return (0, _request.get)(url, {
    params: _objectSpread(_objectSpread({}, queryParams), {}, {
      vehicleCondition
    })
  }, true);
};

/**
 * @param {String} vehicleCondition
 * @param {String} makeSlug
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.getUnlistedVehicleMakes = getUnlistedVehicleMakes;
const getUnlistedVehicleModels = (vehicleCondition, makeSlug, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/quote/vehicle/models/").concat(makeSlug);
  return (0, _request.get)(url, {
    params: _objectSpread(_objectSpread({}, queryParams), {}, {
      vehicleCondition
    })
  }, true);
};

/**
 * @param {String} vehicleCondition
 * @param {String} makeSlug
 * @param {String} modelSlug
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.getUnlistedVehicleModels = getUnlistedVehicleModels;
const getUnlistedVehicleTrims = (vehicleCondition, makeSlug, modelSlug, queryParams) => {
  const url = "".concat(SHOWROOM_API_URL, "/quote/vehicle/modelTrims/").concat(makeSlug, "/").concat(modelSlug);
  return (0, _request.get)(url, {
    params: _objectSpread(_objectSpread({}, queryParams), {}, {
      vehicleCondition
    })
  }, true);
};

/**
 * @param {Object} queryParams
 * @param {Object} body
 * @returns {Promise}
 */
exports.getUnlistedVehicleTrims = getUnlistedVehicleTrims;
const putUnlistedVehicle = (queryParams, body) => {
  const url = "".concat(QUOTE_API_URL, "/quote/vehicle/unlisted");
  return (0, _request.put)(url, body, {
    params: _objectSpread({}, queryParams)
  });
};
exports.putUnlistedVehicle = putUnlistedVehicle;