"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unassignCall = exports.invalidateCall = exports.getStaticData = exports.getSMS = exports.getLeads = exports.getEmails = exports.getCalls = exports.fetchSuggestions = exports.extendCallTakenWithConfiguredNumber = exports.dismissSMS = exports.deleteSMS = exports.deleteLead = exports.deleteEmail = exports.associateCall = exports.assignCall = void 0;
var _appsCore = require("@sm360/apps-core");
var _commonsApi = require("@sm360/commons-api");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// GET NOTIFICATIONS
const getCalls = async _ref => {
  let {
    organizationId,
    organizationUnitIds
  } = _ref;
  const orgUnitIdsQuery = (organizationUnitIds === null || organizationUnitIds === void 0 ? void 0 : organizationUnitIds.length) > 0 ? "&organizationUnitIds=".concat(organizationUnitIds.join(',')) : '';
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/notifications/").concat(organizationId, "?type=Call").concat(orgUnitIdsQuery));
};
exports.getCalls = getCalls;
const getSMS = async _ref2 => {
  let {
    user
  } = _ref2;
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/notifications/").concat(user.id, "?type=Sms"));
};
exports.getSMS = getSMS;
const getEmails = async _ref3 => {
  let {
    organizationId
  } = _ref3;
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/notifications/").concat(organizationId, "?type=Email"));
};
exports.getEmails = getEmails;
const getLeads = async _ref4 => {
  let {
    organizationId
  } = _ref4;
  return await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/notifications/").concat(organizationId, "?type=Lead"));
};

// DISMISS NOTIFICATIONS
exports.getLeads = getLeads;
const dismissSMS = async _ref5 => {
  let {
    organization,
    id,
    userId
  } = _ref5;
  return await _appsCore.axios.delete("".concat(CRM_ENDPOINT, "/notifications/").concat(organization.id, "/dismiss?type=Sms&entityId=").concat(id, "&userId=").concat(userId));
};

// DELETE NOTIFICATIONS
// Delete SMS notification for all users
exports.dismissSMS = dismissSMS;
const deleteSMS = async _ref6 => {
  let {
    organization,
    id
  } = _ref6;
  return await _appsCore.axios.delete("".concat(CRM_ENDPOINT, "/notifications/").concat(organization.id, "?type=Sms&entityId=").concat(id));
};

// Delete email notification for all users
exports.deleteSMS = deleteSMS;
const deleteEmail = async _ref7 => {
  let {
    organization,
    id
  } = _ref7;
  return await _appsCore.axios.delete("".concat(CRM_ENDPOINT, "/notifications/").concat(organization.id, "?type=Email&entityId=").concat(id));
};

// Delete lead notification for all users
exports.deleteEmail = deleteEmail;
const deleteLead = async _ref8 => {
  let {
    organization,
    id
  } = _ref8;
  return await _appsCore.axios.delete("".concat(CRM_ENDPOINT, "/notifications/").concat(organization.id, "?type=Lead&entityId=").concat(id));
};
exports.deleteLead = deleteLead;
const fetchSuggestions = async _ref9 => {
  var _String;
  let {
    organization,
    number
  } = _ref9;
  const orgUnits = await _commonsApi.OrganizationService.getDealers({
    orgId: organization.id
  });
  const orgUnitsIds = orgUnits.map(orgUnit => orgUnit.id);
  const rawNumber = (_String = String(number)) === null || _String === void 0 ? void 0 : _String.slice(-10);
  const formattedOrgUnitIds = orgUnitsIds.length ? '&organizationUnitIds='.concat('', orgUnitsIds.join('&organizationUnitIds=')) : '';
  return _appsCore.axios.get("".concat(CRM_ENDPOINT, "/contacts/findSuggestedContactsAndLeads?number=").concat(Number(rawNumber), "&organizationId=").concat(organization.id).concat(formattedOrgUnitIds));
};
exports.fetchSuggestions = fetchSuggestions;
const assignCall = async _ref10 => {
  let {
    id,
    userId,
    organizationId
  } = _ref10;
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/activities/").concat(id, "/call/assign?userId=").concat(userId, "&organizationId=").concat(organizationId));
};
exports.assignCall = assignCall;
const unassignCall = async _ref11 => {
  let {
    id,
    userId,
    organizationId
  } = _ref11;
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/activities/").concat(id, "/call/unassign?userId=").concat(userId, "&organizationId=").concat(organizationId));
};
exports.unassignCall = unassignCall;
const associateCall = async _ref12 => {
  let {
    id,
    leadId,
    contactId,
    userId,
    organizationId,
    organizationUnitId
  } = _ref12;
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/activities/").concat(id, "/call/associateLeadOrContact?userId=").concat(userId, "&organizationId=").concat(organizationId, "&type=Call&contactId=").concat(contactId).concat(leadId ? "&leadId=".concat(leadId) : '').concat(organizationUnitId ? "&organizationUnitId=".concat(organizationUnitId) : ''));
};
exports.associateCall = associateCall;
const invalidateCall = async _ref13 => {
  let {
    id,
    organizationId,
    organizationUnitId,
    userId
  } = _ref13;
  const orgUnitIdParam = organizationUnitId ? "&organizationUnitId=".concat(organizationUnitId) : '';
  return await _appsCore.axios.put("".concat(CRM_ENDPOINT, "/notifications/invalidate?organizationId=").concat(organizationId, "&activityId=").concat(id, "&userId=").concat(userId, "&activityStatusId=7&type=Call").concat(orgUnitIdParam));
};
exports.invalidateCall = invalidateCall;
const extendCallTakenWithConfiguredNumber = async _ref14 => {
  var _fullActivity$data;
  let {
    callTaken,
    dispatch,
    organization
  } = _ref14;
  const fullActivity = await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/activities/").concat(callTaken.activityId));
  const activityConfiguredNumberId = (_fullActivity$data = fullActivity.data) === null || _fullActivity$data === void 0 ? void 0 : _fullActivity$data.configuredNumberId;
  if (activityConfiguredNumberId) {
    var _configuredNumbers$da;
    const configuredNumbers = await _appsCore.axios.get("".concat(CRM_ENDPOINT, "/configuredNumbers/organization/").concat(organization.id));
    if ((_configuredNumbers$da = configuredNumbers.data) !== null && _configuredNumbers$da !== void 0 && _configuredNumbers$da.length) {
      const configuredNumber = configuredNumbers.data.find(c => c.id === activityConfiguredNumberId);
      if (configuredNumber !== null && configuredNumber !== void 0 && configuredNumber.organization_unit_id) {
        dispatch({
          type: 'setCallTaken',
          callTaken: _objectSpread(_objectSpread({}, callTaken), {}, {
            organizationUnitId: configuredNumber.organization_unit_id
          })
        });
      }
    }
  }
};
exports.extendCallTakenWithConfiguredNumber = extendCallTakenWithConfiguredNumber;
const getStaticData = async _ref15 => {
  let {
    dispatch,
    organizationId,
    organizationUnitIds,
    user
  } = _ref15;
  const allPromise = [getEmails({
    organizationId
  })];
  const allPromiseRep = ['emails'];
  if (user.rights.includes('CrmSmsFeatureUse') || user.rights.includes('ReadAllSmsNotifications')) {
    allPromise.push(getSMS({
      user
    }));
    allPromiseRep.push('sms');
  }
  if (user.rights.includes('ReceiveNotificationOnNewLead')) {
    allPromise.push(getLeads({
      organizationId
    }));
    allPromiseRep.push('leads');
  }
  if (user.rights.includes('IvrInbound')) {
    allPromise.push(getCalls({
      organizationId,
      organizationUnitIds
    }));
    allPromiseRep.push('calls');
  }
  Promise.all(allPromise).then(r => {
    const notifications = allPromiseRep.map((t, idx) => {
      return {
        [t]: r[idx].data
      };
    });
    dispatch({
      type: 'initNotifications',
      notifications
    });
  });
};
exports.getStaticData = getStaticData;