"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformDealersToOptions = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _utils = require("../../tools/utils");
const dealerHasDesking = dealer => {
  var _dealer$organizationU;
  return !!(dealer !== null && dealer !== void 0 && (_dealer$organizationU = dealer.organizationUnitConfiguration) !== null && _dealer$organizationU !== void 0 && _dealer$organizationU.desking);
};
const dealerHasShowroom360 = dealer => {
  const relatedConfigurations = ['showroomv2', 'showroomv2Catalog', 'showroomv2New', 'showroomv2Used', 'showroomv25', 'showroomv25Catalog', 'showroomv25New', 'showroomv25Used'];
  const dealerConfiguration = (dealer === null || dealer === void 0 ? void 0 : dealer.organizationUnitConfiguration) || {};
  return relatedConfigurations.some(config => dealerConfiguration === null || dealerConfiguration === void 0 ? void 0 : dealerConfiguration[config]);
};
const transformDealersToOptions = function () {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return data.map(d => {
    var _d$province;
    return {
      id: d.id,
      value: (0, _commonsUtils.nullOrString)(d.id),
      label: d.name,
      address: "".concat(d.address ? "".concat(d.address, " \n") : '') + "".concat(d.city ? "".concat(d.city, " ") : '') + "".concat(d.postalCode ? "".concat(d.postalCode, " ") : '') + "".concat(d.region ? ", ".concat(d.region) : '') + "".concat(d.countryCode ? ", ".concat(d.countryCode) : ''),
      provinceCode: d === null || d === void 0 || (_d$province = d.province1) === null || _d$province === void 0 ? void 0 : _d$province.provinceCode,
      hasDesking: dealerHasDesking(d),
      hasShowroom360: dealerHasShowroom360(d)
    };
  }).sort(_utils.compareByLabel);
};
exports.transformDealersToOptions = transformDealersToOptions;