"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTradeIn = exports.setEvaluationRequest = exports.getTradeIn = exports.deleteTradeIn = void 0;
var _uiLibrary = require("@sm360/ui-library");
var _showroom = _interopRequireDefault(require("../api/showroom.service"));
var _tradein = _interopRequireDefault(require("../api/tradein.service"));
var _TradeInVehicleHelpers = require("../tools/helpers/TradeInVehicleHelpers");
var _selectors = require("./selectors");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const getTradeIn = async _ref => {
  let {
    state,
    locale,
    dispatch,
    t
  } = _ref;
  const {
    leadId,
    orgId,
    orgUnitId
  } = state;
  if (leadId && orgId && orgUnitId) {
    dispatch({
      type: 'uiStartLoading',
      name: 'LOAD_TRADE_IN'
    });
    _tradein.default.getTradeIn(leadId, orgId, orgUnitId).then(tradeIn => {
      // Get trade vehicle available data (exterior colors & options) if tradeVehicleToken is available
      if (tradeIn.tradeVehicleToken) {
        const orgDetails = {
          orgId,
          orgUnitId
        };
        return _showroom.default.getVehicleAvailableData(tradeIn, orgDetails, locale, t).then(_ref2 => {
          let {
            exteriorColors,
            interiorColors,
            options
          } = _ref2;
          tradeIn.availableExteriorColors = exteriorColors || [];
          tradeIn.availableInteriorColors = interiorColors || [];
          tradeIn.availableOptions = options || [];
          return Promise.resolve(tradeIn);
        });
      } else return Promise.resolve(tradeIn);
    }).then(enhancedResult => {
      dispatch({
        type: 'loadTradeInSuccess',
        tradeIn: enhancedResult
      });
    }).catch(() => {
      dispatch({
        type: 'loadTradeInFailed'
      });
    }).finally(() => {
      dispatch({
        type: 'uiStopLoading',
        name: 'LOAD_TRADE_IN'
      });
    });
  } else {
    dispatch({
      type: 'uiStopLoading',
      name: 'LOAD_TRADE_IN'
    });
  }
};
exports.getTradeIn = getTradeIn;
const updateTradeIn = async _ref3 => {
  let {
    newTradeIn,
    isManualEntry,
    state,
    dispatch,
    locale,
    t
  } = _ref3;
  const {
    leadId,
    tradeIn,
    orgId,
    orgUnitId
  } = state;
  const isNew = (0, _selectors.isNeverCreated)({
    tradeIn
  });
  const orgDetails = {
    orgId,
    orgUnitId
  };
  let newTradeInWithToken = _objectSpread({}, newTradeIn);
  const doesNewValuesContainVehicleToken = newTradeIn === null || newTradeIn === void 0 ? void 0 : newTradeIn.tradeVehicleToken;
  if (!leadId) return Promise.reject('Missing leadId');
  dispatch({
    type: 'uiStartLoading',
    name: 'UPDATE_TRADE_IN'
  });
  try {
    // Delete the vehicle token when the vehicle has manually been entered by the user
    if (isManualEntry) {
      tradeIn.tradeVehicleToken = '';
    } else if (doesNewValuesContainVehicleToken) {
      var _tradeIn$evaluatorVal, _tradeIn$estimation;
      // Refresh the vehicle token if there is one
      const mergedValues = _objectSpread(_objectSpread({}, tradeIn), newTradeInWithToken);
      const {
        tradeVehicleToken: tradeToken,
        kilometers,
        exteriorColor,
        interiorColor,
        vin,
        evaluatorValues,
        vehicleOptions = []
      } = mergedValues;
      const selectedOptions = (vehicleOptions === null || vehicleOptions === void 0 ? void 0 : vehicleOptions.length) > 0 ? vehicleOptions.map(_ref4 => {
        let {
          code
        } = _ref4;
        return code;
      }) : [];
      const newOfficialValue = evaluatorValues === null || evaluatorValues === void 0 ? void 0 : evaluatorValues.estimatedValue;
      const currentOfficialValue = tradeIn === null || tradeIn === void 0 || (_tradeIn$evaluatorVal = tradeIn.evaluatorValues) === null || _tradeIn$evaluatorVal === void 0 ? void 0 : _tradeIn$evaluatorVal.estimatedValue;
      const currentEstimateValue = tradeIn === null || tradeIn === void 0 || (_tradeIn$estimation = tradeIn.estimation) === null || _tradeIn$estimation === void 0 ? void 0 : _tradeIn$estimation.amount;

      // Pick right amount to be used in quotes trade-in allowance
      let valuation;
      if (Number.isInteger(newOfficialValue)) {
        // Use new official value only if it's valid, else fallback to `current estimate value`
        valuation = newOfficialValue > 0 ? newOfficialValue : currentEstimateValue || 0;
      } else {
        // If no new official value: fallback to `current official value` THEN to `current estimate value`
        valuation = currentOfficialValue || currentEstimateValue || 0;
      }

      // Compute a new trade vehicle token with new settings
      const {
        tradeVehicleToken
      } = await _showroom.default.updateTradeVehicleToken(tradeToken, kilometers, exteriorColor === null || exteriorColor === void 0 ? void 0 : exteriorColor.description, interiorColor === null || interiorColor === void 0 ? void 0 : interiorColor.description, selectedOptions, orgDetails, vin, valuation, locale);
      const showroomVehicleId = await _showroom.default.createTradeInWithToken(tradeVehicleToken);
      const showroomVehicle = await _showroom.default.getTradeInById(showroomVehicleId);
      newTradeInWithToken = (0, _TradeInVehicleHelpers.formatShowroomVehicleFromInternalAndAccount)(showroomVehicle, newTradeIn, tradeIn);
    }
    const updatedTradeIn = _objectSpread(_objectSpread(_objectSpread({}, tradeIn), newTradeInWithToken), {}, {
      leadId,
      orgId,
      orgUnitId
    });

    // Always remove Dilawri questions if there is any to avoid trade-in-microservice removing the tradeVehicleToken
    if (typeof updatedTradeIn.tradeVehicleQuestions === 'object' && Object.keys(updatedTradeIn.tradeVehicleQuestions).length > 0) updatedTradeIn.tradeVehicleQuestions = [];
    const createOrUpdateTradeIn = isNew ? _tradein.default.createTradeIn : _tradein.default.updateTradeIn;
    const result = await createOrUpdateTradeIn(updatedTradeIn);

    // Get trade vehicle available data (exterior colors & options) if tradeVehicleToken is available
    if (result.tradeVehicleToken) {
      const {
        exteriorColors,
        interiorColors,
        options
      } = await _showroom.default.getVehicleAvailableData(result, orgDetails, locale, t);
      result.availableExteriorColors = exteriorColors || [];
      result.availableInteriorColors = interiorColors || [];
      result.availableOptions = options || [];
    }
    dispatch({
      type: 'loadTradeInSuccess',
      tradeIn: result
    });
    if (newTradeIn.conditionForm) {
      dispatch({
        type: 'setConditionFormSaved',
        saved: true
      });
      dispatch({
        type: 'setConditionFormSaved',
        saved: false
      });
    }
    _uiLibrary.Toast.toast.success(t('trade-in.OFFICIAL_EVALUATION.TOASTS.UPDATE'));
  } catch (e) {
    _uiLibrary.Toast.toast.error(t('trade-in.OFFICIAL_EVALUATION.TOASTS.ERROR'));
  }
  dispatch({
    type: 'uiStopLoading',
    name: 'UPDATE_TRADE_IN'
  });
};
exports.updateTradeIn = updateTradeIn;
const deleteTradeIn = async _ref5 => {
  let {
    id,
    state,
    locale,
    dispatch,
    t
  } = _ref5;
  dispatch({
    type: 'uiStartLoading',
    name: 'DELETE_TRADE_IN'
  });
  try {
    await _tradein.default.deleteTradeIn(id);
    await getTradeIn({
      state,
      locale,
      dispatch,
      t
    });
  } catch (e) {
    _uiLibrary.Toast.toast.error(t('trade-in.OFFICIAL_EVALUATION.TOASTS.ERROR'));
  }
  dispatch({
    type: 'uiStopLoading',
    name: 'DELETE_TRADE_IN'
  });
};
exports.deleteTradeIn = deleteTradeIn;
const setEvaluationRequest = async _ref6 => {
  let {
    userId,
    isManualEntry,
    state,
    dispatch,
    locale,
    t
  } = _ref6;
  const {
    tradeIn,
    leadId
  } = state;
  const {
    evaluationRequest
  } = tradeIn || {};
  try {
    const result = await _tradein.default.evaluationRequest(userId, leadId);
    const {
      id
    } = result;
    if (id) {
      evaluationRequest.isRequested = true;
      evaluationRequest.id = id;
      await updateTradeIn({
        newTradeIn: {
          evaluationRequest
        },
        isManualEntry,
        state,
        dispatch,
        locale,
        t
      });
    }
  } catch (e) {
    _uiLibrary.Toast.toast.error(t('trade-in.OFFICIAL_EVALUATION.TOASTS.ERROR'));
  }
};
exports.setEvaluationRequest = setEvaluationRequest;